import React from "react";
import { useRegionContext } from 'contexts/RegionContext';
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import Card from "components/Card";
import SalePropertyForm from "./SalePropertyForm";
import useAsync from "hooks/useAsync";
import getRESaleById from "services/re/getRESaleById";
import useToast from "hooks/useToast";
import ScreenLoading from "components/ScreenLoading";
import loadRESalePropsService from "services/re/loadRESalePropsService";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function SalePropertyScreen({ match }) {
  const { regionName } = useRegionContext();
  const { saleId } = match.params;
  const { showError } = useToast();
  // eslint-disable-next-line no-unused-vars
  const [fetch, pending, data] = useAsync({
    promiseFn: values =>
      new Promise(async (resolve, reject) => {
        try {
          let promiseResult = await Promise.all([
            getRESaleById({ saleId: values.saleId }),
            loadRESalePropsService({ saleId: values.saleId })
          ]);

          const sale = promiseResult[0];
          const properties = promiseResult[1];

          resolve({
            ...sale,
            property: properties.map(property => ({
              ...property,
              _fromApi: true
            }))
          });
        } catch (error) {
          reject(error);
        }
      }),
    onError: () => showError("Error loading Sale")
  });

  React.useEffect(() => {
    if (saleId) {
      fetch({ saleId });
    }
  }, [saleId, fetch]);

  return (
    <PrivateLayout>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Heading size="md">
            {saleId
              ? `${isAllowed ? "Edit" : "View"} ${regionName} Property Sale - ${saleId}`
              : `New ${regionName} Property Sale`}
          </Heading>
        )}
      </PermissionValidator>
      <Separator />
      <Card>
        {pending ? (
          <ScreenLoading />
        ) : (
          <SalePropertyForm initialValues={data} />
        )}
      </Card>
    </PrivateLayout>
  );
}

export default SalePropertyScreen;
