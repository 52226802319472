import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import omit from "lodash/omit";
import { Row, Box } from "jsxstyle";
import Heading from "components/Heading";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import Button from "components/Button";
import Separator from "components/Separator";
import useForm from "hooks/useForm";
import InBetweenSpacing from "components/InBetweenSpacing";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import saveCLODealService from "services/clo/saveCLODealService";
import history from "browserHistory";
import isRequired from "validators/isRequired";

function DuplicateCLO({ initialValues, onRequestClose, onDuplicate }) {
  const { showError, showSuccess } = useToast();

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveCLODealService,
    onData: ({ deal_code }) => {
      showSuccess("CLO deal saved.");
      onDuplicate();
      onRequestClose();
      history.push(`/clo/edit/${deal_code}`);
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    onSubmit: (values) => {
      fetch({
        values: {
          ...values,
          deal_type: get(values, "deal_type.deal_code", null),
          seller: get(values, "seller.entity_id", null),
          enhancement: get(values, "enhancement.entity_id", null),
          master_servicer: get(values, "master_servicer.entity_id", null),
          sponsor: get(values, "sponsor.entity_id", null),
          current_manager: get(values, "current_manager.entity_id", null),
          trustee: get(values, "trustee.entity_id", null),
          counsel_uw: get(values, "counsel_uw.entity_id", null),
          counsel_issuer: get(values, "counsel_issuer.entity_id", null),
          denomination: get(values, "denomination.currency_code", null),
          col_country: get(values, "col_country.country_code", null),
          col_region: get(values, "col_region.region_code", null),
          distribution_region: get(
            values,
            "distribution_region.region_code",
            null,
          ),
        },
      });
    },
    initialValues: omit(initialValues, "deal_code"),
    validations: { deal_code: [isRequired] },
  });

  return (
    <InBetweenSpacing size="lg">
      <Box>
        <Heading size="sm">New Deal Code</Heading>
        <Box color="#5f646d">
          Please enter a deal code in order to duplicate the selected deal
        </Box>
      </Box>
      <Form onSubmit={() => submit()}>
        <FormGroup
          label="Deal code"
          required
          disabled={isSubmitting}
          error={getError("deal_code")}
        >
          <IntegerInputGroup
            minLength={8}
            maxLength={8}
            intent={getError("deal_code") ? "danger" : null}
            value={getValue("deal_code")}
            onChange={(value) => setValue("deal_code", value)}
            buttonPosition="none"
            disabled={isSubmitting}
          />
        </FormGroup>

        <Row justifyContent="flex-end">
          <Button
            minimal
            text="Cancel"
            onClick={onRequestClose}
            disabled={isSubmitting}
          />
          <Separator size="md" />
          <Button
            intent="primary"
            text="Save"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={() => {}}
          />
        </Row>
      </Form>
    </InBetweenSpacing>
  );
}

DuplicateCLO.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
};

DuplicateCLO.defaultProps = {
  isSubmitting: false,
};

export default DuplicateCLO;
