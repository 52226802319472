import React from "react";
import PropTypes from "prop-types";
import { Navbar, Alignment, Tabs, Tab } from "@blueprintjs/core";
import Card from "components/Card";
import EntityMasterABSRelationshipsTable from "./EntityMasterABSRelationshipsTable";
import EntityMasterCMBSRelationshipsTable from "./EntityMasterCMBSRelationshipsTable";
import EntityMasterCLORelationshipsTable from "./EntityMasterCLORelationshipsTable";
import EntityMasterRERelationshipsTable from "./EntityMasterRERelationshipsTable";

function EntityMasterRelationships({ entityId }) {
  const [tabId, setTabId] = React.useState(0);
  return (
    <React.Fragment>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Tabs
            large={true}
            onChange={nextTabId => setTabId(nextTabId)}
            selectedTabId={tabId}
          >
            <Tab id={0} title="ABS" />
            <Tab id={1} title="CMBS" />
            <Tab id={2} title="CLO" />
            <Tab id={3} title="RE" />
          </Tabs>
        </Navbar.Group>
      </Navbar>
      <Card>
        {
          {
            0: <EntityMasterABSRelationshipsTable entityId={entityId} />,
            1: <EntityMasterCMBSRelationshipsTable entityId={entityId} />,
            2: <EntityMasterCLORelationshipsTable entityId={entityId} />,
            3: <EntityMasterRERelationshipsTable entityId={entityId} />
          }[tabId]
        }
      </Card>
    </React.Fragment>
  );
}

EntityMasterRelationships.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default EntityMasterRelationships;
