import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadCountriesService from "services/common/loadCountriesService";

function SelectCountryCode({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
  region
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadCountriesService}
      onSelect={onSelect}
      textPath="country_name"
      labelPath="country_code"
      idPath="country_code"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      params={{ region_code: region }}
    />
  );
}

SelectCountryCode.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: PropTypes.string
};

SelectCountryCode.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  region: ""
};

export default SelectCountryCode;
