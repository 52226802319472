/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Row } from "jsxstyle";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import saveHYFundPlacementAgentService from "services/hy/saveHYFundPlacementAgentService";
import Form from "components/Form";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import TextArea from "components/TextArea";
import FormGroup from "components/FormGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import isRequired from "validators/isRequired";

function HYFundPlacementAgentForm({ fundId, onData }) {
  const { showSuccess, showError } = useToast();

  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: ({ placement_agent_id, note }) =>
      fetch({
        fundId,
        placementAgentId: placement_agent_id,
        note,
      }),
    validations: {
      placement_agent_id: [isRequired],
    },
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveHYFundPlacementAgentService,
    onData: () => {
      showSuccess("Placement Agent saved.");
      reset();
      onData();
    },
    onError: ({ data: { error, message } = {} }) => {
      const errorMessage =
        {
          duplicated_key: "Duplicate Placement Agent",
        }[error] || "An error happened.";
      showError(errorMessage);
    },
  });

  return (
    <Form onSubmit={submit}>
      <FormGrid>
        <Box className="col-6">
          <FormGroup
            required
            label="Placement Agent"
            error={getError("placement_agent_id")}
            disabled={isSubmitting}
          >
            <SelectEntityMaster
              onSelect={(value) => setValue("placement_agent_id", value)}
              value={getValue("placement_agent_id")}
              error={getError("placement_agent_id")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>

        <Box className="col-6">
          <FormGroup
            label="Note"
            error={getError("note")}
            disabled={isSubmitting}
          >
            <TextArea
              intent={getError("note") ? "danger" : null}
              value={getValue("note")}
              onChange={(value) => setValue("note", value)}
              disabled={isSubmitting}
              fullWidth
            />
          </FormGroup>
        </Box>
      </FormGrid>
      <Row alignItems="flex-start">
        <Button
          type="submit"
          text="Add"
          intent="success"
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </Row>
    </Form>
  );
}

HYFundPlacementAgentForm.propTypes = {};

HYFundPlacementAgentForm.defaultProps = {};

export default HYFundPlacementAgentForm;
