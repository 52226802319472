import React from "react";
import { Row, Box } from "jsxstyle";
import history from "browserHistory";
import get from "lodash/get";
import pickBy from "lodash/pickBy";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import useUrlParams from "hooks/useUrlParams";
import loadHYManagersService from "services/hy/loadHYManagersService";
import Button from "components/Button";
import AddButton from "components/AddButton";
import Pagination from "components/Pagination";
import InBetweenSpacing from "components/InBetweenSpacing";
import HYManagerTable from "./HYManagerTable";
import HYManagerFilters from "./HYManagerFilters";
import Separator from "components/Separator";

const defaultParams = {
  page: 1,
  size: 20,
  order_by: "manager_id.entity_id",
  order_direction: "ASC",
};

function HYManagerListScreen() {
  const { showError } = useToast();
  const [params, setParams] = useUrlParams(defaultParams);

  const [fetch, pending, data = {}] = useAsync({
    eager: true,
    params,
    promiseFn: loadHYManagersService,
    onError: (error) => {
      showError("Error loading HY Managers");
    },
  });

  return (
    <InBetweenSpacing>
      <Row alignItems="flex-start">
        <Box flex={1}>
          <HYManagerFilters
            onChange={(newFilters) => {
              setParams({
                ...pickBy(newFilters),
                ...defaultParams,
              });
            }}
          />
        </Box>
        <Separator size="xl" />
        <Box flexBasis={150}>
          <Button
            icon="cog"
            text="Configure Table"
            onClick={() => history.push("/hy/manager/table-configuration")}
          />
        </Box>
      </Row>
      <Box>
        <AddButton
          text="Add Manager"
          onClick={() => history.push("/hy/manager/new")}
        />
      </Box>
      <HYManagerTable
        onSortChange={(sortObject) =>
          setParams({
            ...params,
            ...pickBy(sortObject),
            page: 1,
          })
        }
        onReload={() => fetch({ params })}
        data={get(data, "data", [])}
        pending={pending}
      />
      {!pending && (
        <Row justifyContent="flex-end">
          <Pagination
            page={params.page}
            size={params.size}
            total={data.total}
            onChange={({ page }) => setParams({ ...params, page })}
          />
        </Row>
      )}
    </InBetweenSpacing>
  );
}

HYManagerListScreen.propTypes = {};

HYManagerListScreen.defaultProps = {};

export default HYManagerListScreen;
