import React from "react";
import PropTypes from "prop-types";
import { DateRangeInput } from "@blueprintjs/datetime";

function DateRange({ onChange, value, allowSingleDayRange }) {
  return (
    <DateRangeInput
      formatDate={date => date.toLocaleDateString()}
      onChange={onChange}
      parseDate={str => new Date(str)}
      value={
        value &&
        value.map(date => (typeof date === "string" ? new Date(date) : date))
      }
      allowSingleDayRange={allowSingleDayRange}
    />
  );
}

DateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.string,
  ]),
  allowSingleDayRange: PropTypes.bool
};

DateRange.defaultProps = {
  allowSingleDayRange: true
};

export default DateRange;
