import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { Spinner } from "@blueprintjs/core";
import Form from "components/Form";
import PropertyFields from "./PropertyFields";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import getREPropertyPortfolioById from "services/re/getREPropertyPortfolioById";
import { Row } from "jsxstyle";
import Button from "components/Button";
import saveRESalePropsPortfolioService from "services/re/saveRESalePropsPortfolioService";
import useToast from "hooks/useToast";
import handleApiError from "utils/handleApiError";

function EditPropertyForm({ saleId, propertyId, onEdit }) {
  const { showError, showSuccess } = useToast();

  const [
    getPortfolioProperty,
    pending = true,
    portfolioPropertyData,
  ] = useAsync({
    promiseFn: getREPropertyPortfolioById,
  });

  const [savePortfolioProperty, isSubmitting] = useAsync({
    promiseFn: saveRESalePropsPortfolioService,
    onData: () => {
      showSuccess("Property saved.");
      onEdit();
    },
    onError: (error) => showError(handleApiError(error)),
  });

  React.useEffect(() => {
    getPortfolioProperty({ saleId, propertyId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleId, propertyId]);

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: portfolioPropertyData,
    validations: {},
    onSubmit: (values) =>
      savePortfolioProperty({
        saleId,
        propertyId,
        values: omit(values, ["sale_id", "property_id"]),
      }),
  });

  return pending ? (
    <Spinner />
  ) : (
    <Form onSubmit={() => submit()}>
      <PropertyFields
        getValue={getValue}
        setValue={setValue}
        getError={getError}
        isSubmitting={isSubmitting}
      />
      <Row justifyContent="flex-end">
        <Button
          text="Save"
          intent="primary"
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </Row>
    </Form>
  );
}

EditPropertyForm.propTypes = {
  saleId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default EditPropertyForm;
