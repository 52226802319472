import React from 'react';
import PrivateLayout from 'components/PrivateLayout';
import { Link } from 'react-router-dom';
import { NonIdealState } from '@blueprintjs/core';

function NotAllowedScreen() {
  return (
    <PrivateLayout>
      <NonIdealState
        icon="blocked-person"
        title="Access denied"
        description="Sorry, you are not allowed to access this page."
        action={<Link to="/">Back to home</Link>}
      />
    </PrivateLayout>
  );
}

export default NotAllowedScreen;
