/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import browserHistory from "browserHistory";
import { Divider, Button } from "@blueprintjs/core";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import useAsync from "hooks/useAsync";
import loadFundsByContactIdService from "services/contact/loadFundsByContactIdService";
import PermissionValidator from "components/PermissionValidator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import ContactFundForm from "./ContactFundForm";
import ContactFundTable from "./ContactFundTable";
import { Row } from "jsxstyle";
import Separator from "components/Separator";

function ContactFundTab({ contact, isNew }) {
  const { contact_id: id } = contact;

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadFundsByContactIdService,
  });

  React.useEffect(() => {
    fetch({ contactId: id });
  }, []);

  return (
    <React.Fragment>
      <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
        <TwoColumnFormLayout title="Add Fund">
          <ContactFundForm
            contact={contact}
            onData={() => fetch({ contactId: id })}
          />
        </TwoColumnFormLayout>
        <Divider style={{ margin: "30px 0 30px 0" }} />
      </PermissionValidator>
      <TwoColumnFormLayout title="Funds">
        <ContactFundTable
          contact={contact}
          data={data}
          pending={pending}
          onReload={() => fetch({ contactId: id })}
        />
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Finish"
                onClick={() => browserHistory.push("/contacts")}
              />
            </Row>
          </React.Fragment>
        )}
      </TwoColumnFormLayout>
    </React.Fragment>
  );
}

ContactFundTab.propTypes = {};

ContactFundTab.defaultProps = {};

export default ContactFundTab;
