import React from "react";
import Amplify, { Auth as AmplifyAuth } from "aws-amplify";
import LoginScreen from "screens/public/LoginScreen";
import { Box } from "jsxstyle";
import { Spinner } from "@blueprintjs/core";
import jwtDecode from "jwt-decode";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  }
});

function Auth({ children }) {
  const [pending, setPending] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);
  React.useEffect(() => {
    const checkSession = async () => {
      try {
        const auth = await AmplifyAuth.currentSession();
        const tokenData = jwtDecode(auth.idToken.jwtToken);
        sessionStorage.setItem("role", tokenData["cognito:groups"][0]);
        setAuthenticated(true);
        setPending(false);
      } catch (error) {
        setAuthenticated(false);
        setPending(false);
      }
    };
    checkSession();
  }, []);

  return pending ? (
    <Box
      width="100%"
      height="100vh"
      backgroundColor="#fafafa"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  ) : authenticated ? (
    children
  ) : (
    <LoginScreen setAuthenticated={() => setAuthenticated(true)} />
  );
}

Auth.propTypes = {};

Auth.defaultProps = {};

export default Auth;
