import React from "react";
import CurrencyFormat from "components/CurrencyFormat";

export default [
  {
    title: "Deal type",
    dataIndex: "deal_type.deal_type_desc",
    key: "deal_type.deal_type_desc",
    sortable: true,
    width: 150,
  },
  {
    title: "Parent code",
    dataIndex: "parent_code",
    key: "parent_code",
    sortable: true,
    width: 150,
  },
  {
    title: "Pricing date",
    dataIndex: "pricing_date",
    key: "pricing_date",
    width: 150,
  },
  {
    title: "Closing date",
    dataIndex: "closing_date",
    key: "closing_date",
    width: 150,
  },
  {
    title: "Abbreviation",
    dataIndex: "abbrv",
    key: "abbrv",
    width: 240,
  },
  {
    title: "Issuer",
    dataIndex: "issuer",
    key: "issuer",
    width: 240,
  },
  {
    title: "Deal amount",
    dataIndex: "deal_amount",
    key: "deal_amount",
    width: 200,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Retained amount",
    dataIndex: "retained_amount",
    key: "retained_amount",
    width: 200,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Collateral",
    dataIndex: "collateral",
    key: "collateral",
    width: 240,
  },
  {
    title: "Collateral sub",
    dataIndex: "collateral_sub",
    key: "collateral_sub",
    width: 240,
  },
  {
    title: "Noncall date",
    dataIndex: "noncall_date",
    key: "noncall_date",
    width: 150,
  },
  {
    title: "End date",
    dataIndex: "end_date",
    key: "end_date",
    width: 150,
  },
  {
    title: "Pay frequency",
    dataIndex: "pay_frequency",
    key: "pay_frequency",
    width: 150,
  },
  {
    title: "Current manager",
    dataIndex: "current_manager.long_name",
    key: "current_manager.long_name",
    width: 240,
  },
  {
    title: "Seller",
    dataIndex: "seller.long_name",
    key: "seller.long_name",
    width: 240,
  },
  {
    title: "Sponsor",
    dataIndex: "sponsor.long_name",
    key: "sponsor.long_name",
    width: 240,
  },
  {
    title: "Master servicer",
    dataIndex: "master_servicer.long_name",
    key: "master_servicer.long_name",
    width: 240,
  },
  {
    title: "Transfer date",
    dataIndex: "transfer_date",
    key: "transfer_date",
    width: 150,
  },
  {
    title: "Enhancement",
    dataIndex: "enhancement.long_name",
    key: "enhancement.long_name",
    width: 240,
  },
  {
    title: "Trustee",
    dataIndex: "trustee.long_name",
    key: "trustee.long_name",
    width: 240,
  },
];
