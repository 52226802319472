import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CMBSSellersForm from "./CMBSSellersForm";
import CMBSSellersTable from "./CMBSSellersTable";
import useAsync from "hooks/useAsync";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadCMBSSellersService from "services/cmbs/loadCMBSSellersService";
import browserHistory from "browserHistory";

function CMBSSellersTab({ deal, isNew }) {
  const [sellerToEdit, setSellerToEdit] = React.useState(null);

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadCMBSSellersService,
  });

  const dealCode = deal.deal_code;
  const dealSize = deal.deal_amount;

  React.useEffect(() => {
    if (dealCode) {
      fetch({ dealCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sellerCredit = data.reduce((acc, seller) => acc + seller.amount, 0);

  return (
    <React.Fragment>
      <Card>
        <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
          <TwoColumnFormLayout
            title={sellerToEdit ? "Edit Seller" : "Add Seller"}
          >
            <CMBSSellersForm
              key={sellerToEdit && sellerToEdit.id}
              onCancel={() => setSellerToEdit(null)}
              dealSize={dealSize}
              sellerCredit={sellerCredit}
              onData={() => {
                setSellerToEdit(null);
                fetch({ dealCode });
              }}
              initialValues={{
                dealCode,
                ...(sellerToEdit
                  ? {
                      ...sellerToEdit,
                      seller_entity_id: sellerToEdit.seller_entity_id.entity_id,
                    }
                  : {}),
              }}
            />
          </TwoColumnFormLayout>
          <Divider style={{ margin: "30px 0 30px 0" }} />
        </PermissionValidator>
        <TwoColumnFormLayout title="Sellers">
          <CMBSSellersTable
            dealSize={dealSize}
            data={data}
            pending={pending}
            onReload={() => fetch({ dealCode })}
            onRequestEdit={(seller) => setSellerToEdit(seller)}
          />
        </TwoColumnFormLayout>
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Next"
                onClick={() =>
                  browserHistory.push(`/cmbs/new/${deal.deal_code}/servicers`)
                }
              ></Button>
            </Row>
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
}

CMBSSellersTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool,
};

CMBSSellersTab.defaultProps = {
  isActive: false,
  isNew: true,
};

export default CMBSSellersTab;
