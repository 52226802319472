import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import deleteHYFundPlacementAgentService from "services/hy/deleteHYFundPlacementAgentService";
import Alert from "components/Alert";
import Button from "components/Button";

function DeletePlacementAgent({ match, placementAgent, onDelete }) {
  const fundId = match.params.id;
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteHYFundPlacementAgentService,
    onError: (error) => {
      showError("Error deleting Placement Agent");
    },
    onData: () => {
      showSuccess("Placement Agent deleted successfully.");
      onDelete();
    },
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() =>
          fetch({
            fundId,
            placementAgentId: placementAgent.placement_agent_id.entity_id,
          })
        }
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Placement Agent{" "}
        <b>{placementAgent.placement_agent_id.long_name}</b>?
      </Alert>
    </>
  );
}

DeletePlacementAgent.propTypes = {
  placementAgent: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

DeletePlacementAgent.defaultProps = {
  placementAgent: {},
};

export default withRouter(DeletePlacementAgent);
