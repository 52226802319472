import React from "react";
import { Row } from "jsxstyle";
import {
  Navbar as BluePrintNavbar,
  Alignment,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import Card from "components/Card";
import RESaleListScreen from "./sale/ReSaleListScreen";
import RePropertyListScreen from "./prop/RePropertyListScreen";
import { REGION_NAMES_MAP } from 'consts';
import { useRegionContext } from "contexts/RegionContext";
import browserHistory from "browserHistory";

const routeMap = {
  0: '/re/sale',
  1: '/re/property',
};

function REListScreen({ location }) {
  const currentTab = location.pathname.includes("property") ? 1 : 0;
  const { region } = useRegionContext();

  return (
    <PrivateLayout>
      <Row alignItems="center">
        <Heading size="md">RE {REGION_NAMES_MAP[region]}</Heading>
      </Row>
      <Separator />
      <>
        <BluePrintNavbar
          style={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
        >
          <BluePrintNavbar.Group align={Alignment.LEFT}>
            <Tabs
              large={true}
              onChange={(nextTabId) => {
                browserHistory.push({ 
                  pathname: routeMap[nextTabId],
                  search: `?region=${region}`
                });
              }}
              selectedTabId={currentTab}
            >
              <Tab id={0} title="Sales" />
              <Tab id={1} title="Properties" />
            </Tabs>
          </BluePrintNavbar.Group>
        </BluePrintNavbar>
        <Card style={{ flexGrow: 1 }}>
          {
            {
              0: <RESaleListScreen />,
              1: <RePropertyListScreen />,
            }[currentTab]
          }
        </Card>
      </>
    </PrivateLayout>
  );
}

export default REListScreen;
