import React from "react";
import { Box } from "jsxstyle";
import { Omnibar } from "@blueprintjs/select";
import { Button, Divider, Spinner, MenuItem } from "@blueprintjs/core";
import get from "lodash/get";
import useDebounceAsync from "hooks/useDebounceAsync";
import loadGlobalSearchService from "services/global-search/loadGlobalSearchService";
import GlobalSearchOmnibarItem from "./GlobalSearchOmnibarItem";
import GlobalSearchOmnibarSectionHeader from "./GlobalSearchOmnibarSectionHeader";

function GlobalSearchOmnibar({ history }) {
  const [opened, setOpened] = React.useState(false);
  const [criteria, setCriteria] = React.useState("");

  const { pending, debouncing, data = {} } = useDebounceAsync({
    promiseFn: loadGlobalSearchService,
    params: { params: { criteria } },
  });

  const omnibarItemRenderer = (item) => {
    const dataABS = get(data, "abs", []);
    const dataCMBS = get(data, "cmbs", []);
    const dataCLO = get(data, "clo", []);
    const dataSALES = get(data, "sales", []);
    const dataPROPERTIES = get(data, "properties", []);
    const dataENTITIES = get(data, "entities", []);
    const dataCONTACTS = get(data, "contacts", []);
    const dataFUNDS = get(data, "funds", []);
    const noResults =
      !dataABS.length &&
      !dataCMBS.length &&
      !dataCLO.length &&
      !dataSALES.length &&
      !dataPROPERTIES.length &&
      !dataENTITIES.length &&
      !dataCONTACTS.length &&
      !dataFUNDS.length;

    if (pending)
      return [
        <Box padding="8px">
          <Spinner />
        </Box>,
      ];
    else if (noResults)
      return [
        <Box padding="8px">
          <MenuItem disabled={true} text="No results" />
        </Box>,
      ];

    return [
      <Box>
        {dataABS.length ? (
          <GlobalSearchOmnibarSectionHeader>
            ABS
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataABS.map((abs, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() => history.push(`/abs/edit/${abs.deal_code}`)}
          >
            <>
              <span>{abs.abbrv || ""}</span>
              <span>{abs.abbrv && abs.issuer ? " - " : ""}</span>
              <span>{abs.issuer || ""}</span>
            </>
          </GlobalSearchOmnibarItem>
        ))}

        {dataCMBS.length ? (
          <GlobalSearchOmnibarSectionHeader>
            CMBS
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataCMBS.map((cmbs, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() => history.push(`/cmbs/edit/${cmbs.deal_code}`)}
          >
            <>
              <span>{cmbs.abbrv || ""}</span>
              <span>{cmbs.abbrv && cmbs.issuer ? " - " : ""}</span>
              <span>{cmbs.issuer || ""}</span>
            </>
          </GlobalSearchOmnibarItem>
        ))}

        {dataCLO.length ? (
          <GlobalSearchOmnibarSectionHeader>
            CLO
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataCLO.map((clo, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() => history.push(`/clo/edit/${clo.deal_code}`)}
          >
            <>
              <span>{clo.abbrv || ""}</span>
              <span>{clo.abbrv && clo.issuer ? " - " : ""}</span>
              <span>{clo.issuer || ""}</span>
            </>
          </GlobalSearchOmnibarItem>
        ))}

        {dataSALES.length ? (
          <GlobalSearchOmnibarSectionHeader>
            Sales
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataSALES.map((sale, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() =>
              sale.portfolio
                ? history.push(`/re/sale/portfolio/${sale.sale_id}`)
                : history.push(`/re/sale/${sale.sale_id}`)
            }
          >
            <span>{sale.portfolio_name || ""}</span>
          </GlobalSearchOmnibarItem>
        ))}

        {dataPROPERTIES.length ? (
          <GlobalSearchOmnibarSectionHeader>
            Properties
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataPROPERTIES.map((property, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() => history.push(`/re/property/${property.property_id}`)}
          >
            <span>{property.property_1 || ""}</span>
          </GlobalSearchOmnibarItem>
        ))}

        {dataENTITIES.length ? (
          <GlobalSearchOmnibarSectionHeader>
            Entities
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataENTITIES.map((entity, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() => history.push(`/entity-master/${entity.entity_id}`)}
          >
            <>
              <span>{entity.entity_id || ""}</span>
              <span>
                {entity.entity_id && entity.editorial_name ? " - " : ""}
              </span>
              <span>{entity.editorial_name || ""}</span>
            </>
          </GlobalSearchOmnibarItem>
        ))}

        {dataCONTACTS.length ? (
          <GlobalSearchOmnibarSectionHeader>
            Contacts
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataCONTACTS.map((contact, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() => history.push(`/contacts/edit/${contact.contact_id}`)}
          >
            <>
              <span>{contact.contact_id || ""}</span>
              <span>
                {contact.contact_id && contact.first_name ? " - " : ""}
              </span>
              <span>{contact.first_name || ""}</span>
            </>
          </GlobalSearchOmnibarItem>
        ))}

        {dataFUNDS.length ? (
          <GlobalSearchOmnibarSectionHeader>
            Funds
          </GlobalSearchOmnibarSectionHeader>
        ) : null}
        {dataFUNDS.map((fund, index) => (
          <GlobalSearchOmnibarItem
            key={index}
            onClick={() =>
              history.push(`/hy/fund/edit/${fund.fund_id.entity_id}`)
            }
          >
            <>
              <span>{fund.fund_id.entity_id || ""}</span>
              <span>
                {fund.fund_id.entity_id && fund.fund_id.long_name ? " - " : ""}
              </span>
              <span>{fund.fund_id.long_name || ""}</span>
            </>
          </GlobalSearchOmnibarItem>
        ))}

        <Box>
          <Divider />
          <Button
            text="See more"
            onClick={() =>
              history.push(
                "/global-search" + (criteria ? "?criteria=" + criteria : ""),
              )
            }
          />
        </Box>
      </Box>,
    ];
  };

  return (
    <div>
      <span>
        <Button
          icon="search"
          text="Global search"
          onClick={() => setOpened(true)}
        />
      </span>
      <Omnibar
        isOpen={opened}
        onClose={() => setOpened(false)}
        itemRenderer={omnibarItemRenderer}
        items={debouncing ? [] : [1]}
        query={criteria}
        onQueryChange={(value) => setCriteria(value)}
      />
    </div>
  );
}

export default GlobalSearchOmnibar;
