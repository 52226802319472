/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CMBSTranchePricesForm from "./CMBSTranchePricesForm";
import CMBSTranchePricesTable from "./CMBSTranchePricesTable";
import useAsync from "hooks/useAsync";
import history from "browserHistory";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadCMBSTranchePricesService from "services/cmbs/loadCMBSTranchePricesService";

function CMBSTranchePricesTab({ deal, isNew }) {
  const [trancheToEdit, setTrancheToEdit] = React.useState(null);

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadCMBSTranchePricesService
  });

  const dealCode = deal.deal_code;
  const dealSize = deal.deal_amount;

  React.useEffect(() => {
    fetch({ dealCode });
  }, []);

  const allocated = data.reduce(
    (acc, tranche) => acc + tranche.class_amount,
    0
  );

  return (
    <React.Fragment>
      <Card>
        <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
          <TwoColumnFormLayout
            title={trancheToEdit ? "Edit Tranche" : "Add Tranche"}
          >
            <CMBSTranchePricesForm
              key={trancheToEdit && trancheToEdit.id}
              onCancel={() => setTrancheToEdit(null)}
              dealSize={dealSize}
              allocated={allocated}
              onData={() => {
                setTrancheToEdit(null);
                fetch({ dealCode });
              }}
              initialValues={{
                dealCode,
                ...trancheToEdit
              }}
            />
          </TwoColumnFormLayout>
          <Divider style={{ margin: "30px 0 30px 0" }} />
        </PermissionValidator>
        <TwoColumnFormLayout title="Tranches">
          <CMBSTranchePricesTable
            dealCode={dealCode}
            data={data}
            pending={pending}
            onReload={() => fetch({ dealCode })}
            onRequestEdit={tranche => setTrancheToEdit(tranche)}
          />
        </TwoColumnFormLayout>
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Finish"
                onClick={() => history.push("/cmbs")}
              />
            </Row>
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
}

CMBSTranchePricesTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool
};

CMBSTranchePricesTab.defaultProps = {
  isActive: false,
  isNew: true
};

export default CMBSTranchePricesTab;
