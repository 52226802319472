import fetch from "../fetch";

export default ({ values, dealCode, geoId }) =>
  fetch({
    path: `/abs/deals/${dealCode}/geo-distributions${geoId ? `/${geoId}` : ""}`,
    method: geoId ? "PUT" : "POST",
    data: {
      geo_country: values.geo_country,
      geo_state: values.geo_state,
      geo_percentage: values.geo_percentage,
    },
  });
