import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import DateInputGroup from "components/DateInputGroup";
import FormGrid from "components/FormGrid";
import TextArea from "components/TextArea";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import SelectEntityType from "components/SelectEntityType";
import SelectCountryCode from "components/SelectCountryCode";
import IntegerInputGroup from "components/IntegerInputGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import PermissionValidator from "components/PermissionValidator";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";
import SelectCreatableEntityMaster from "components/SelectCreatableEntityMaster";

function HYManagerFormFields({
  getValue,
  setValue,
  getError,
  isSubmitting,
  isNew,
}) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <React.Fragment>
          <FormGrid>
            <FormGrid.Item>
              <FormGroup
                label="Manager ID"
                error={getError("manager_id")}
                disabled={isSubmitting || !isNew}
              >
                <SelectCreatableEntityMaster
                  onSelect={(value) => {
                    setValue(
                      "manager_id",
                      value
                        ? {
                            entity_id: get(value, "entity_id"),
                            long_name: get(value, "long_name"),
                            editorial_name: get(value, "editorial_name"),
                            editorial_description: get(
                              value,
                              "editorial_description",
                            ),
                            address: get(value, "address"),
                            city: get(value, "city"),
                            postal_code: get(value, "postal_code"),
                            phone: get(value, "phone"),
                            website: get(value, "website"),
                            year_founded: get(value, "year_founded"),
                            search_term: get(value, "search_term"),
                            entity_type: get(value, "entity_type.entity_code"),
                            parent_entity: get(
                              value,
                              "parent_entity.entity_id",
                            ),
                            country_code: get(
                              value,
                              "country_code.country_code",
                            ),
                            state: get(value, "state.state_code"),
                            date_start: get(value, "date_start"),
                          }
                        : "",
                    );
                  }}
                  onCreate={(value) => {
                    setValue("manager_id", {
                      entity_id: value,
                    });
                  }}
                  value={getValue("manager_id")}
                  error={getError("manager_id")}
                  readOnly={!isAllowed}
                  disabled={isSubmitting || !isNew}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Full name"
                required
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.long_name")}
              >
                <InputGroup
                  intent={getError("manager_id.long_name") ? "danger" : null}
                  value={getValue("manager_id.long_name")}
                  onChange={(value) => setValue("manager_id.long_name", value)}
                  disabled={isSubmitting || !isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Editorial name"
                required
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.editorial_name")}
              >
                <InputGroup
                  intent={
                    getError("manager_id.editorial_name") ? "danger" : null
                  }
                  value={getValue("manager_id.editorial_name")}
                  onChange={(value) =>
                    setValue("manager_id.editorial_name", value)
                  }
                  disabled={isSubmitting || !isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Editorial description"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.editorial_description")}
              >
                <InputGroup
                  intent={
                    getError("manager_id.editorial_description")
                      ? "danger"
                      : null
                  }
                  value={getValue("manager_id.editorial_description")}
                  onChange={(value) =>
                    setValue("manager_id.editorial_description", value)
                  }
                  disabled={isSubmitting || !isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Entity type"
                required
                disabled={isSubmitting || !isAllowed}
              >
                <SelectEntityType
                  onSelect={(value) => {
                    setValue("manager_id.entity_type", value);
                  }}
                  value={getValue("manager_id.entity_type")}
                  disabled={isSubmitting || !isAllowed}
                  error={getError("manager_id.entity_type")}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Parent Entity"
                disabled={isSubmitting || !isAllowed}
              >
                <SelectEntityMaster
                  onSelect={(value) =>
                    setValue("manager_id.parent_entity", value)
                  }
                  value={getValue("manager_id.parent_entity")}
                  error={getError("manager_id.parent_entity")}
                  disabled={isSubmitting || !isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Phone"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.phone")}
              >
                <InputGroup
                  intent={getError("manager_id.phone") ? "danger" : null}
                  value={getValue("manager_id.phone")}
                  onChange={(value) => setValue("manager_id.phone", value)}
                  maxLength={15}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Website"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.website")}
              >
                <InputGroup
                  intent={getError("manager_id.website") ? "danger" : null}
                  value={getValue("manager_id.website")}
                  onChange={(value) => setValue("manager_id.website", value)}
                  maxLength={100}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Year founded"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.year_founded")}
              >
                <IntegerInputGroup
                  intent={getError("manager_id.year_founded") ? "danger" : null}
                  value={getValue("manager_id.year_founded")}
                  onChange={(value) =>
                    setValue("manager_id.year_founded", value)
                  }
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Search term"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.search_term")}
              >
                <InputGroup
                  intent={getError("manager_id.search_term") ? "danger" : null}
                  value={getValue("manager_id.search_term")}
                  onChange={(value) =>
                    setValue("manager_id.search_term", value)
                  }
                  maxLength={100}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
                <FormGroup
                  label="Effective date"
                  disabled={isSubmitting || !isAllowed}
                  error={getError("manager_id.date_start")}
                  required
                >
                  <DateInputGroup
                    intent={getError("manager_id.date_start") ? "danger" : null}
                    value={getValue("manager_id.date_start")}
                    onChange={(value) => setValue("manager_id.date_start", new Date(value).toISOString().split('T')[0])}
                  />
                </FormGroup>
              </FormGrid.Item>
          </FormGrid>
          <FormGrid>
            <FormGrid.Item>
              <FormGroup
                label="Address"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.address")}
              >
                <InputGroup
                  intent={getError("manager_id.address") ? "danger" : null}
                  value={getValue("manager_id.address")}
                  onChange={(value) => setValue("manager_id.address", value)}
                  maxLength={100}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="City"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.city")}
              >
                <InputGroup
                  intent={getError("manager_id.city") ? "danger" : null}
                  value={getValue("manager_id.city")}
                  onChange={(value) => setValue("manager_id.city", value)}
                  maxLength={100}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="State"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.state")}
              >
                <SelectNorthAmericanState
                  onSelect={(value) => {
                    setValue("manager_id.state", value);
                  }}
                  value={getValue("manager_id.state")}
                  disabled={isSubmitting || !isAllowed}
                  error={getError("manager_id.state")}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Postal code"
                disabled={isSubmitting || !isAllowed}
                error={getError("manager_id.postal_code")}
              >
                <InputGroup
                  intent={getError("manager_id.postal_code") ? "danger" : null}
                  value={getValue("manager_id.postal_code")}
                  onChange={(value) =>
                    setValue("manager_id.postal_code", value)
                  }
                  maxLength={9}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Country"
                error={getError("manager_id.country_code")}
                disabled={isSubmitting || !isAllowed}
              >
                <SelectCountryCode
                  onSelect={(value) =>
                    setValue("manager_id.country_code", value)
                  }
                  value={getValue("manager_id.country_code")}
                  error={getError("manager_id.country_code")}
                />
              </FormGroup>
            </FormGrid.Item>
          </FormGrid>
          <FormGrid>
            <FormGrid.Item>
              <FormGroup label="Public Note" error={getError("public_note")}>
                <TextArea
                  intent={getError("public_note") ? "danger" : null}
                  value={getValue("public_note")}
                  onChange={(value) => setValue("public_note", value)}
                  readOnly={!isAllowed}
                  fullWidth
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup label="Private Note" error={getError("private_note")}>
                <TextArea
                  intent={getError("private_note") ? "danger" : null}
                  value={getValue("private_note")}
                  onChange={(value) => setValue("private_note", value)}
                  readOnly={!isAllowed}
                  fullWidth
                />
              </FormGroup>
            </FormGrid.Item>
          </FormGrid>
        </React.Fragment>
      )}
    </PermissionValidator>
  );
}

HYManagerFormFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

HYManagerFormFields.defaultProps = {
  isSubmitting: false,
};

export default HYManagerFormFields;
