import React from "react";
import { withRouter } from "react-router-dom";
import Stepper from "components/Stepper";

const steps = [
  "CMBS Deal",
  "Underwriters",
  "Rating Agencies",
  "Borrowers",
  "Sellers",
  "Servicers",
  "B Piece Buyers",
  "Tranche Prices and Ratings",
];

function NewCMBSStepper({ match, children }) {
  const { currentView } = match.params;
  const currentStep =
    {
      underwriters: 1,
      "rating-agencies": 2,
      borrowers: 3,
      sellers: 4,
      servicers: 5,
      "b-piece-buyers": 6,
      "tranche-prices-and-ratings": 7,
    }[currentView] || 0;

  return (
    <Stepper steps={steps} currentStep={currentStep}>
      {children}
    </Stepper>
  );
}

NewCMBSStepper.propTypes = {};

NewCMBSStepper.defaultProps = {};

export default withRouter(NewCMBSStepper);
