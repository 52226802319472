import React from "react";
import PropTypes from "prop-types";
import { Overlay, Classes } from "@blueprintjs/core";
import { Box } from "jsxstyle";
import Heading from "./Heading";

function Modal({ children, visible, onRequestClose }) {
  return (
    <Overlay
      className={Classes.OVERLAY_SCROLL_CONTAINER}
      isOpen={visible}
      onClose={onRequestClose}
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      enforceFocus
      hasBackdrop
      usePortal
    >
      <div className={Classes.DIALOG_CONTAINER}>
        <div
          style={{ backgroundColor: "#fff", paddingBottom: 0 }}
          className={Classes.DIALOG}
        >
          {children}
        </div>
      </div>
    </Overlay>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  visible: false,
};

export default Modal;

export function ModalTitle({ children }) {
  return (
    <Box padding={20} borderBottom="1px solid #ccc">
      <Heading size="sm">{children}</Heading>
    </Box>
  );
}

export function ModalContent({ children }) {
  return (
    <div style={{ padding: 20, backgroundColor: "#f9f9f9" }}>{children}</div>
  );
}

export function ModalFooter({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "12px 20px",
        borderTop: "1px solid #ccc",
      }}
    >
      {children}
    </div>
  );
}
