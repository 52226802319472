import React from "react";
import PropTypes from "prop-types";
import getREMarketsService from "services/re/getREMarketsService";
import "./AsyncAutoComplete.css";
import AsyncAutoComplete from "./AsyncAutoComplete";

function SelectREMarket({
  city,
  state,
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
}) {
  return (
    <AsyncAutoComplete
      textPath="market"
      labelPath="market"
      idPath="market"
      promiseFn={() => getREMarketsService({ city, state, page: 1, size: 50 })}
      onSelect={onSelect}
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      itemRenderer={(entity) => `${entity.market} - ${entity.city}`}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
    />
  );
}

SelectREMarket.propTypes = {
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  params: PropTypes.object,
  maxLength: PropTypes.number,
};

SelectREMarket.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  params: undefined,
};

export default SelectREMarket;
