import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadCurrenciesService from "services/common/loadCurrenciesService";

function SelectCurrencyCode({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadCurrenciesService}
      onSelect={onSelect}
      textPath="currency_name"
      labelPath="currency_code"
      idPath="currency_code"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
    />
  );
}

SelectCurrencyCode.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SelectCurrencyCode.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search..."
};

export default SelectCurrencyCode;
