import fetch from "../fetch";

export default ({ values, dealCode, sellerId }) =>
  fetch({
    path: `/cmbs/deals/${dealCode}/sellers${sellerId ? `/${sellerId}` : ""}`,
    method: sellerId ? "PUT" : "POST",
    data: {
      seller_entity_id: values.seller_entity_id,
      amount: values.amount
    }
  });
