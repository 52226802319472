import React from "react";
import PropTypes from "prop-types";
import { Box, Row } from "jsxstyle";
import Separator from "./Separator";
import Heading from "./Heading";

const getClassNameBySize = size =>
  ({
    lg: ["col-3", "col-9"],
    sm: ["col-2", "col-10"]
  }[size]);

function TwoColumnFormLayout({ children, title, description, size }) {
  return (
    <Row>
      <Box className={getClassNameBySize(size)[0]}>
        <React.Fragment>
          <Heading size="sm">{title}</Heading>
          {description && (
            <React.Fragment>
              <Separator height={12} />
              <Box component="p" style={{ color: "#ccc" }}>
                {description}
              </Box>
            </React.Fragment>
          )}
          <Separator height={30} />
        </React.Fragment>
      </Box>
      <Box className={getClassNameBySize(size)[1]} overflowX="auto">
        {children}
      </Box>
    </Row>
  );
}

TwoColumnFormLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  size: PropTypes.oneOf(["lg", "sm"])
};

TwoColumnFormLayout.defaultProps = {
  description: null,
  size: "lg"
};

export default TwoColumnFormLayout;
