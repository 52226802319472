import React from "react";
import PropTypes from "prop-types";
import { Row } from "jsxstyle";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import CurrencyFormat from "components/CurrencyFormat";
import DeleteCMBSSeller from "./DeleteCMBSSeller";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import PercentFormat from "components/PercentFormat";

function CMBSSellersTable({
  data,
  dealSize,
  pending,
  onReload,
  onRequestEdit,
}) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          pending={pending}
          data={data}
          rowKey="id"
          columns={[
            {
              title: "Seller",
              dataIndex: "seller_entity_id.long_name",
              key: "seller_entity_id.long_name",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              render: (amount) => <CurrencyFormat amount={amount} />,
            },
            {
              title: "Amount(%)",
              dataIndex: "amount",
              key: "amount",
              render: (amount) => (
                <PercentFormat
                  amount={(((amount || 0) / dealSize) * 100).toFixed(4)}
                />
              ),
            },
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: (item) => (
                      <Row>
                        <Button
                          icon="edit"
                          onClick={() => onRequestEdit(item)}
                        />
                        <Separator />
                        <DeleteCMBSSeller seller={item} onDelete={onReload} />
                      </Row>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </PermissionValidator>
  );
}

CMBSSellersTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onReload: PropTypes.func,
};

CMBSSellersTable.defaultProps = {
  data: [],
  pending: false,
  onReload: () => {},
};

export default CMBSSellersTable;
