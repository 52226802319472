import React from "react";
import Proptypes from "prop-types";
import Modal, { ModalContent } from "components/Modal";
import PropertySelectTable from "./PropertySelectTable";

function PropertySelectModal({ onSelect, visible, onRequestClose }) {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalContent>
        <PropertySelectTable
          onSelect={selectedRow => {
            onSelect(selectedRow);
            onRequestClose();
          }}
          onRequestModalClose={onRequestClose}
        />
      </ModalContent>
    </Modal>
  );
}

PropertySelectModal.propTypes = {
  single: Proptypes.bool,
  onSelect: Proptypes.func.isRequired,
  initialValue: Proptypes.oneOfType([
    Proptypes.array,
    Proptypes.string,
    Proptypes.number
  ])
};

PropertySelectModal.defaultProps = {
  single: true
};

export default PropertySelectModal;
