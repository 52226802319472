import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import useAsync from "hooks/useAsync";
import loadCMBSTranchePriceRatingsService from "services/cmbs/loadCMBSTranchePriceRatingsService";

function CMBSRatingsTable({ dealCode, trancheId }) {
  const [fetch, pending, data] = useAsync({
    promiseFn: loadCMBSTranchePriceRatingsService,
  });

  React.useEffect(() => {
    fetch({ dealCode: dealCode, trancheId: trancheId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealCode, trancheId]);

  return (
    <React.Fragment>
      <Table
        bordered={false}
        rowKey="id"
        pending={pending}
        columns={[
          {
            title: "Agency",
            dataIndex: "entity_id.long_name",
            key: "entity_id.long_name",
          },
          {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
          },
        ]}
        data={data}
      />
    </React.Fragment>
  );
}

CMBSRatingsTable.propTypes = {
  dealCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  trancheId: PropTypes.number.isRequired,
};

CMBSRatingsTable.defaultProps = {};

export default CMBSRatingsTable;
