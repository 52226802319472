import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadNorthAmericanStatesService from "services/common/loadNorthAmericanStatesService";

function SelectNorthAmericanState({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadNorthAmericanStatesService}
      onSelect={onSelect}
      textPath="state_name"
      labelPath="state_code"
      idPath="state_code"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
    />
  );
}

SelectNorthAmericanState.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectNorthAmericanState.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
};

export default SelectNorthAmericanState;
