import React from "react";
import { useDrop } from "react-dnd";
import { Box } from "jsxstyle";
import VisibleColumnItem from "./VisibleColumnItem";

const VisibleColumnsTarget = ({ onDrop, onDrag, items, moveItem }) => {
  const [{ isOver }, drop] = useDrop({
    accept: "fieldItem",
    drop(item, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      if (item.type !== "visibleColumnItem") {
        onDrop(item);
      } else {
        console.log("already added");
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <Box
      props={{ ref: drop }}
      border={isOver ? "1px dashed #000" : "1px solid transparent"}
      height="100%"
    >
      {items.map((item, index) => {
        return (
          <VisibleColumnItem
            onDrag={item => {
              onDrag(item);
            }}
            key={item.index}
            sortableIndex={index}
            index={item.index}
            name={item.name}
            moveItem={moveItem}
          />
        );
      })}
    </Box>
  );
};

VisibleColumnsTarget.propTypes = {};

VisibleColumnsTarget.defaultProps = {};

export default VisibleColumnsTarget;
