import fetch from "../fetch";

/**
 * @param type
 * cmbs
 * abs
 * clo
 * re
 */

export default ({ id, type, params = {} }) =>
  fetch({ path: `/em/entities/${id}/${type}-roles`, params });
