import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import InputGroup from "../../components/InputGroup";
import Form from "components/Form";
import Separator from "components/Separator";
import Button from "components/Button";
import FormGroup from "components/FormGroup";
import { Row, Box } from "jsxstyle";
import AdvancedSearch from "components/AdvancedSearch";
import moment from "moment";
import CurrentFiltersList from "components/CurrentFiltersList";
import SelectCMBSDealType from "components/SelectCMBSDealType";
import DateRange from "components/DateRange";
import Switch from "components/Switch";
import SelectRateType from "components/SelectRateType";
import SelectRegionCode from "components/SelectRegionCode";
import SelectOfferingTypeFilter from "./SelectOfferingTypeFilter";

function CMBSFilters({ onChange, currentFilters }) {
  const [isAdvancedFiltersOpen, setAdvancedFiltersOpen] = React.useState(false);
  const [dealCodeValue, setDealCodeValue] = React.useState(
    currentFilters.deal_code
  );
  const advancedSearchFilters = omit(currentFilters, ["deal_code"]);
  React.useEffect(() => {
    if (!currentFilters.deal_code) {
      setDealCodeValue("");
    }
  }, [currentFilters.deal_code]);

  return (
    <React.Fragment>
      <Row alignItems="flex-start">
        <Form
          onSubmit={() => {
            onChange({ deal_code: dealCodeValue });
          }}
        >
          <Row width={350} alignItems="flex-start">
            <Box flex={1}>
              <FormGroup>
                <InputGroup
                  leftIcon="search"
                  placeholder="Search by deal code"
                  value={dealCodeValue}
                  onChange={(value) => setDealCodeValue(value)}
                  fullWidth
                />
              </FormGroup>
            </Box>
            <Separator />
            <Button
              intent="primary"
              icon="search"
              text="Search"
              type="submit"
            />
          </Row>
        </Form>
        <Separator />
        <Row alignItems="center">
          <AdvancedSearch
            currentFilters={advancedSearchFilters}
            isOpen={isAdvancedFiltersOpen}
            onRequestOpen={() => setAdvancedFiltersOpen(true)}
            onRequestClose={() => setAdvancedFiltersOpen(false)}
            onSubmit={(values) =>
              onChange({
                ...values,
                pricing_date:
                  values.pricing_date &&
                  values.pricing_date.map((date) =>
                    moment(date).format("YYYY-MM-DD")
                  ),
              })
            }
          >
            {({ getValue, setValue }) => (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, 300px)"
                gridGap={16}
              >
                <FormGroup label="Deal abbreviation">
                  <InputGroup
                    value={getValue("abbrv")}
                    onChange={(value) => setValue("abbrv", value)}
                  />
                </FormGroup>
                <FormGroup label="Issuer">
                  <InputGroup
                    value={getValue("issuer")}
                    onChange={(value) => setValue("issuer", value)}
                  />
                </FormGroup>
                <FormGroup label="Series">
                  <InputGroup
                    value={getValue("series")}
                    onChange={(value) => setValue("series", value)}
                    maxLength={50}
                  />
                </FormGroup>
                <FormGroup label="Deal type">
                  <SelectCMBSDealType
                    onSelect={(value) => setValue("deal_type", value)}
                    value={getValue("deal_type")}
                  />
                </FormGroup>
                <FormGroup label="Offering type">
                  <SelectOfferingTypeFilter
                    onSelect={(value) => setValue("offering_type", value)}
                    value={getValue("offering_type")}
                  />
                </FormGroup>
                <FormGroup label="Single borrower">
                  <Switch
                    value={getValue("multi_single")}
                    onChange={(value) =>
                      setValue("multi_single", !getValue("multi_single"))
                    }
                  />
                </FormGroup>
                <FormGroup label="Originated for securitization">
                  <Switch
                    value={getValue("ofs")}
                    onChange={(value) => setValue("ofs", !getValue("ofs"))}
                  />
                </FormGroup>
                <FormGroup label="Distribution region">
                  <SelectRegionCode
                    onSelect={(value) => setValue("distribution_region", value)}
                    value={getValue("distribution_region")}
                  />
                </FormGroup>
                <FormGroup label="Note rate">
                  <SelectRateType
                    onSelect={(value) => setValue("rate_notes", value)}
                    value={getValue("rate_notes")}
                  />
                </FormGroup>
                {/* <FormGroup label="Underwriter">
                  <SelectEntityMaster
                    onSelect={(value) =>
                      setValue("underwriter_entity_id", value)
                    }
                    value={getValue("underwriter_entity_id")}
                  />
                </FormGroup> */}
                <FormGroup label="Bookrunners only">
                  <Switch
                    value={getValue("only_book_runners_underwriter")}
                    onChange={(value) =>
                      setValue(
                        "only_book_runners_underwriter",
                        !getValue("only_book_runners_underwriter")
                      )
                    }
                  />
                </FormGroup>
                {/* <FormGroup label="Rating Agency">
                  <SelectEntityMaster
                    onSelect={(value) => setValue("rating_entity_id", value)}
                    value={getValue("rating_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Seller">
                  <SelectEntityMaster
                    onSelect={(value) => setValue("seller_entity_id", value)}
                    value={getValue("seller_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Master servicer">
                  <SelectEntityMaster
                    onSelect={(value) =>
                      setValue("master_servicer_entity_id", value)
                    }
                    value={getValue("master_servicer_entity_id")}
                    params={{ is_special: false }}
                  />
                </FormGroup>
                <FormGroup label="Special servicer">
                  <SelectEntityMaster
                    onSelect={(value) =>
                      setValue("special_servicer_entity_id", value)
                    }
                    value={getValue("special_servicer_entity_id")}
                    params={{ is_special: true }}
                  />
                </FormGroup>
                <FormGroup label="Trustee / certificate administrator">
                  <SelectEntityMaster
                    onSelect={(value) =>
                      setValue("trustee_certificate_admin_entity_id", value)
                    }
                    value={getValue("trustee_certificate_admin_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Legal counsel">
                  <SelectEntityMaster
                    onSelect={(value) => setValue("counsel_entity_id", value)}
                    value={getValue("counsel_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="B-piece buyer">
                  <SelectEntityMaster
                    onSelect={(value) => setValue("buyer_entity_id", value)}
                    value={getValue("buyer_entity_id")}
                    params={{ is_special: false }}
                  />
                </FormGroup> */}
                <FormGroup label="Pricing date">
                  <DateRange
                    onChange={(value) => setValue("pricing_date", value)}
                    value={getValue("pricing_date")}
                  />
                </FormGroup>
              </Box>
            )}
          </AdvancedSearch>
          <Separator />
          <Button
            icon="remove"
            minimal
            onClick={() => onChange({})}
            text="Clear filters"
          />
        </Row>
      </Row>
      <CurrentFiltersList
        filters={currentFilters}
        onRemove={(key) => {
          onChange({
            ...currentFilters,
            [key]: null,
          });
        }}
      />
    </React.Fragment>
  );
}

CMBSFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CMBSFilters;
