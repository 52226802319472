import React from "react";
import PropTypes from "prop-types";
import { Row } from "jsxstyle";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import DeleteABSGeographicDistribution from "./DeleteABSGeographicDistribution";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function CMBSUnderwritersTable({ data, pending, onReload, onRequestEdit }) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          data={data}
          pending={pending}
          rowKey="id"
          columns={[
            {
              title: "Country",
              dataIndex: "geo_country",
              key: "geo_country",
            },
            {
              title: "State",
              dataIndex: "geo_state",
              key: "geo_state",
            },
            {
              title: "Geo Percentage",
              dataIndex: "geo_percentage",
              key: "geo_percentage",
            },
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: (item) => (
                      <Row>
                        <Button
                          icon="edit"
                          onClick={() => onRequestEdit(item)}
                        />
                        <Separator />
                        <DeleteABSGeographicDistribution
                          geo={item}
                          onDelete={onReload}
                        />
                      </Row>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </PermissionValidator>
  );
}

CMBSUnderwritersTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onReload: PropTypes.func.isRequired,
  onRequestEdit: PropTypes.func.isRequired,
};

CMBSUnderwritersTable.defaultProps = {
  data: [],
  pending: false,
};

export default CMBSUnderwritersTable;
