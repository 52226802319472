import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteABSGeographicDistribution from "services/abs/deleteABSGeographicDistribution";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteABSGeographicDistribution({ geo, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteABSGeographicDistribution,
    onData: () => {
      showSuccess("Geographic Distribution deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Geographic Distribution");
    }
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ dealCode: geo.deal_code, id: geo.id })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Geographic Distribution{" "}
        <b>{geo.geo}</b>?
      </Alert>
    </>
  );
}

DeleteABSGeographicDistribution.propTypes = {
  geo: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteABSGeographicDistribution.defaultProps = {
  geo: {}
};

export default DeleteABSGeographicDistribution;
