import React from "react";
import PropTypes from "prop-types";
import { Row, Box } from "jsxstyle";
import { useRegionContext } from 'contexts/RegionContext';
import { TableWithFixedHeader } from "components/Table";
import history from "browserHistory";
import Separator from "components/Separator";
import DeleteREProp from "./DeleteREProp";
import REPropSaleTable from "./REPropSaleTable";
import columns from "./REPropTableColumns";
import EditButton from "components/EditButton";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function RETable({ data, pending, onSortChange, onReload, sortable }) {
  const { region, getRegionPath } = useRegionContext();
  const reTableColumns = JSON.parse(localStorage.getItem(`re/propColumns-${region}`));

  const visibleColumns = reTableColumns
    ? reTableColumns.map((column) =>
        columns.find((tableColumn) => tableColumn.title === column),
      )
    : columns;
  return (
    <React.Fragment>
      <TableWithFixedHeader
        pending={pending}
        rowKey="property_id"
        scroll={{
          x:
            visibleColumns.reduce((acc, column) => acc + column.width, 0) + 210,
        }}
        expandedRowRender={(item) => (
          <Box width="80%">
            <REPropSaleTable propertyId={item.property_id} />
          </Box>
        )}
        columns={[
          {
            title: "Property ID",
            dataIndex: "property_id",
            key: "property_id",
            width: 120,
            sortable: sortable !== false,
          },
          ...visibleColumns.map((column) => ({
            ...column,
            sortable: sortable !== false,
          })),
          {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            fixed: "right",
            width: 90,
            render: (item) => (
              <Row>
                <EditButton
                  onClick={() =>
                    history.push(getRegionPath(`/re/property/${item.property_id}`))
                  }
                />
                <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
                  <Separator size="xs" />
                  <DeleteREProp
                    propertyId={item.property_id}
                    onDelete={onReload}
                  />
                </PermissionValidator>
              </Row>
            ),
          },
        ]}
        data={data}
        onSortChange={onSortChange}
      />
    </React.Fragment>
  );
}

RETable.propTypes = {
  attribute: PropTypes.bool,
};

RETable.defaultProps = {
  sortable: true,
};

export default RETable;
