import React from "react";
import { withRouter } from "react-router-dom";
import Stepper from "components/Stepper";

const steps = [
  "ABS Deal",
  "Underwriters",
  "Rating Agencies",
  "Tranche Prices and Ratings",
  "Geographic Distribution"
];

function NewCMBSStepper({ match, children }) {
  const { currentView } = match.params;
  const currentStep =
    {
      underwriters: 1,
      "rating-agencies": 2,
      "tranche-prices-and-ratings": 3,
      "geographic-distribution": 4
    }[currentView] || 0;

  return (
    <Stepper steps={steps} currentStep={currentStep}>
      {children}
    </Stepper>
  );
}

NewCMBSStepper.propTypes = {};

NewCMBSStepper.defaultProps = {};

export default withRouter(NewCMBSStepper);
