import React from "react";
import PropTypes from "prop-types";
import { Row } from "jsxstyle";
import InputGroup from "components/InputGroup";
import Select from "components/Select";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Button from "components/Button";
import Separator from "components/Separator";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import saveAccountService from "services/account/saveAccountService";
import useForm from "hooks/useForm";
import isRequired from "validators/isRequired";
import isEmail from "validators/isEmail";

function AccountForm({ initialValues, onRequestClose, userName, onData }) {
  const { showSuccess, showError } = useToast();

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveAccountService,
    onData: () => {
      showSuccess(`Account ${userName ? "updated" : "created"}.`);
      onData();
      onRequestClose();
    },
    onError: error => {
      console.error(error);
      showError("An error happened.");
    }
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues,
    validations: {
      groupName: [isRequired],
      email: [isRequired, isEmail]
    },
    onSubmit: values => {
      fetch({ values, userName });
    }
  });

  return (
    <Form onSubmit={() => submit()}>
      <FormGroup label="First Name" error={getError("name")}>
        <InputGroup
          value={getValue("firstName")}
          onChange={value => {
            setValue("firstName", value);
          }}
          intent={getError("firstName") ? "danger" : null}
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup label="Last Name" error={getError("name")}>
        <InputGroup
          value={getValue("lastName")}
          onChange={value => {
            setValue("lastName", value);
          }}
          intent={getError("lastName") ? "danger" : null}
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup label="Email" error={getError("email")}>
        <InputGroup
          type="email"
          value={getValue("email")}
          onChange={value => {
            setValue("email", value);
          }}
          intent={getError("email") ? "danger" : null}
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup
        label="Role"
        disabled={isSubmitting}
        error={getError("groupName")}
      >
        <Select
          items={[
            {
              id: "Admin",
              label: "Admin"
            },
            {
              id: "Data-Team",
              label: "Data-Team"
            },
            {
              id: "Read-Only",
              label: "Read-Only"
            }
          ]}
          onSelect={value => setValue("groupName", value)}
          value={getValue("groupName")}
          error={getError("groupName")}
          disabled={isSubmitting}
        />
      </FormGroup>
      <Row flex={1} justifyContent="flex-end">
        <Button text="Cancel" onClick={onRequestClose} />
        <Separator />
        <Button type="submit" intent="success" text="Save" onClick={() => {}} />
      </Row>
    </Form>
  );
}

AccountForm.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default AccountForm;
