import React from "react";
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import Card from "components/Card";
import CustomTableForm from "components/custom-table/CustomTableForm";
import { useRegionContext } from "contexts/RegionContext";

function RESaleTableConfigurationScreen({ history }) {
  const { region, regionName } = useRegionContext();
  return (
    <React.Fragment>
      <PrivateLayout>
        <Heading size="md">{regionName} Sale - Table Configuration</Heading>
        <Separator />
        <Card>
          <CustomTableForm region={region} />
        </Card>
      </PrivateLayout>
    </React.Fragment>
  );
}

export default RESaleTableConfigurationScreen;
