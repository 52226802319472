import React from "react";
import { Row } from "jsxstyle";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import PermissionValidator from "components/PermissionValidator";
import DeleteFeeStructure from "./DeleteFeeStructure";

function HYFundFeeStructureTable({ data, pending, onReload, onRequestEdit }) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          data={data}
          onChange={(pagination, filter, sorter) => {}}
          pending={pending}
          rowKey="id"
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
            },
            {
              title: "Step",
              dataIndex: "step",
              key: "step",
            },
            {
              title: "GP Split",
              dataIndex: "gp_split",
              key: "gp_split",
            },
            {
              title: "Hurdle",
              dataIndex: "hurdle",
              key: "hurdle",
            },
            {
              title: "Hurdle Type",
              dataIndex: "hurdle_type.name",
              key: "hurdle_type.id",
            },
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: (item) => (
                      <Row>
                        <Button
                          icon="edit"
                          onClick={() => onRequestEdit(item)}
                        />
                        <Separator />
                        <DeleteFeeStructure
                          feeStructure={item}
                          onDelete={onReload}
                        />
                      </Row>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </PermissionValidator>
  );
}

HYFundFeeStructureTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onReload: PropTypes.func.isRequired,
  onRequestEdit: PropTypes.func.isRequired,
};

HYFundFeeStructureTable.defaultProps = {
  data: [],
  pending: false,
};

export default HYFundFeeStructureTable;
