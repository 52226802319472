import React from "react";
// import PropTypes from "prop-types";
import update from "immutability-helper";
import { Row, Col } from "jsxstyle";
import Heading from "components/Heading";
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Separator from "../Separator";
import Button from "../Button";
import FieldsTarget from "./FieldsTarget";
import VisibleColumnsTarget from "./VisibleColumnsTarget";
import history from "browserHistory";
import ABSColumns from "screens/abs/ABSTableColumns";
import CMBSColumns from "screens/cmbs/CMBSTableColumns";
import CLOColumns from "screens/clo/CLOTableColumns";
import SaleColumns from "screens/re/sale/RESaleTableColumns";
import PropertyColumns from "screens/re/prop/REPropTableColumns";
import ContactColumns from "screens/contact/ContactTableColumns";
import HYManagerColumns from "screens/hy/manager/HYManagerTableColumns";
import HYFundColumns from "screens/hy/fund/HYFundTableColumns";

function CustomTableForm({ region }) {
  const { pathname } = history.location;
  const currentTable = pathname.substring(1, pathname.lastIndexOf("/"));
  const localStoragePath = region 
    ? `${currentTable}Columns-${region}`
    : `${currentTable}Columns`;
  const currentColumns =
    JSON.parse(localStorage.getItem(localStoragePath)) || [];
  const columns = {
    abs: ABSColumns,
    cmbs: CMBSColumns,
    clo: CLOColumns,
    contacts: ContactColumns,
    "re/sale": SaleColumns,
    "re/prop": PropertyColumns,
    "hy/manager": HYManagerColumns,
    "hy/fund": HYFundColumns,
  }[currentTable];

  const [availableFields, setAvailableFields] = React.useState(
    columns
      .map((column, index) => ({ name: column.title, index }))
      .filter((field) => {
        return (
          !currentColumns ||
          !currentColumns.some((column) => column === field.name)
        );
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
  );

  const [currentItems, setCurrentItems] = React.useState(
    currentColumns.map((column, index) => ({ name: column, index })) || [],
  );

  const moveItem = React.useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = currentItems[dragIndex];
      setCurrentItems(
        update(currentItems, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [currentItems],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Col>
        <Row>
          <Col flexBasis="40%">
            <Heading size="xs">
              Available fields ({availableFields.length} fields available)
            </Heading>
            <Separator size="md" />
            <FieldsTarget items={availableFields} />
          </Col>
          <Separator size="lg" />
          <Col flexBasis="60%">
            <Heading size="xs">
              Table configuration ({currentItems.length} fields selected)
            </Heading>
            <Separator size="md" />
            <VisibleColumnsTarget
              items={currentItems}
              moveItem={moveItem}
              onDrag={(item) => {
                setCurrentItems(
                  currentItems.filter(
                    (currentItem) => currentItem.name !== item.name,
                  ),
                );
                setAvailableFields([...availableFields, item]);
              }}
              onDrop={(item) => {
                setCurrentItems([...currentItems, item]);
                setAvailableFields(
                  availableFields.filter((field) => field.index !== item.index),
                );
              }}
            />
          </Col>
        </Row>
        <Separator size="lg" />
        <Row>
          <Button
            intent="danger"
            text="Reset Defaults (show all fields)"
            onClick={() => {
              localStorage.removeItem(localStoragePath);
              history.goBack("");
            }}
          />
          <Separator size="md" />
          <Button
            minimal
            text="Cancel"
            onClick={() => {
              history.goBack("");
            }}
          />
          <Separator size="md" />
          <Button
            intent="primary"
            text="Save"
            onClick={() => {
              localStorage.setItem(
                localStoragePath,
                JSON.stringify(currentItems.map((field) => field.name)),
              );
              history.goBack();
            }}
          />
        </Row>
      </Col>
    </DndProvider>
  );
}

CustomTableForm.propTypes = {
  region: String,
};

CustomTableForm.defaultProps = {
  region: null,
};

export default CustomTableForm;
