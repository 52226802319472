import omit from "lodash/omit";
import saveRESaleService from "./saveRESaleService";
import deleteRESalePropService from "./deleteRESalePropService";
import saveRESalePropsService from "./saveRESalePropsService";

export default ({ values }) =>
  new Promise(async (resolve, reject) => {
    console.log({ values });
    try {
      if (values.sale_id) {
        await Promise.all([
          saveRESaleService({
            values: omit(values, ["property", "sale_id"]),
            saleId: values.sale_id,
          }),
          ...values.property.reduce((acc, property) => {
            if (property._deleted) {
              acc = [
                ...acc,
                deleteRESalePropService({
                  saleId: values.sale_id,
                  propertyId: property.property_id,
                }),
              ];
            } else if (!property._fromApi) {
              acc = [
                ...acc,
                saveRESalePropsService({
                  saleId: values.sale_id,
                  propertyId: property.property_id,
                }),
              ];
            }
            return acc;
          }, []),
        ]);
        resolve();
      } else {
        const sale = await saveRESaleService({
          values: omit(values, ["property"]),
        });
        await saveRESalePropsService({
          saleId: sale.sale_id,
          propertyId: values.property[0].property_id,
        });
      }

      resolve();
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
