/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Box } from "jsxstyle";
import history from "browserHistory";
import get from "lodash/get";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import useUrlParams from "hooks/useUrlParams";
import loadHYFundsService from "services/hy/loadHYFundsService";
import Button from "components/Button";
import AddButton from "components/AddButton";
import Pagination from "components/Pagination";
import InBetweenSpacing from "components/InBetweenSpacing";
import HYFundTable from "./HYFundTable";
import HYFundFilters from "./HYFundFilters";

const defaultParams = {
  page: 1,
  size: 20,
  order_by: "fund_id.entity_id",
  order_direction: "ASC",
};

function HYFundListScreen() {
  const { showError } = useToast();
  const [params, setParams] = useUrlParams(defaultParams);

  const [fetch, pending, data = {}] = useAsync({
    eager: true,
    params,
    promiseFn: loadHYFundsService,
    onError: (error) => {
      showError("Error loading HY Funds");
    },
  });

  return (
    <InBetweenSpacing>
      <Row alignItems="flex-start">
        <Box flex={1}>
          <HYFundFilters
            currentFilters={omit(params, ["page", "size"])}
            onChange={(newFilters) => {
              setParams({
                ...pickBy(newFilters),
                ...defaultParams,
              });
            }}
          />
        </Box>
        <Box flexBasis={150}>
          <Button
            icon="cog"
            text="Configure Table"
            onClick={() => history.push("/hy/fund/table-configuration")}
          />
        </Box>
      </Row>
      <Box>
        <AddButton
          text="Add Fund"
          onClick={() => history.push("/hy/fund/new")}
        />
      </Box>
      <HYFundTable
        onSortChange={(sortObject) =>
          setParams({
            ...params,
            ...pickBy(sortObject),
            page: 1,
          })
        }
        onReload={() => fetch({ params })}
        data={get(data, "data", [])}
        pending={pending}
      />
      {!pending && (
        <Row justifyContent="flex-end">
          <Pagination
            page={params.page}
            size={params.size}
            total={data.total}
            onChange={({ page }) => setParams({ ...params, page })}
          />
        </Row>
      )}
    </InBetweenSpacing>
  );
}

HYFundListScreen.propTypes = {};

HYFundListScreen.defaultProps = {};

export default HYFundListScreen;
