/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withRouter } from "react-router-dom";
import { Row } from "jsxstyle";
import PropTypes from "prop-types";
import browserHistory from "browserHistory";
import { Divider, Button } from "@blueprintjs/core";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import useAsync from "hooks/useAsync";
import loadHYFundPlacementAgentsByFundIdService from "services/hy/loadHYFundPlacementAgentsByFundIdService";
import Separator from "components/Separator";
import PermissionValidator from "components/PermissionValidator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import HYFundPlacementAgentForm from "./HYFundPlacementAgentForm";
import HYFundPlacementAgentTable from "./HYFundPlacementAgentTable";

function HYFundPlacementAgentTab({ match, isNew }) {
  const fundId = match.params.id;

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadHYFundPlacementAgentsByFundIdService,
  });

  React.useEffect(() => {
    fetch({ fundId });
  }, []);

  return (
    <React.Fragment>
      <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
        <TwoColumnFormLayout title="Add Placement Agent">
          <HYFundPlacementAgentForm
            fundId={fundId}
            onData={() => fetch({ fundId })}
          />
        </TwoColumnFormLayout>
        <Divider style={{ margin: "30px 0 30px 0" }} />
      </PermissionValidator>
      <TwoColumnFormLayout title="Placement Agents">
        <HYFundPlacementAgentTable
          data={data}
          pending={pending}
          onReload={() => fetch({ fundId })}
        />
      </TwoColumnFormLayout>
      {isNew && (
        <React.Fragment>
          <Separator size="lg" />
          <Row className="col-12" justifyContent="flex-end">
            <Button
              intent="primary"
              text="Finish"
              onClick={() => browserHistory.push("/hy/fund")}
            />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

HYFundPlacementAgentTab.propTypes = {
  isNew: PropTypes.bool,
};

HYFundPlacementAgentTab.defaultProps = {
  isNew: true,
};

export default withRouter(HYFundPlacementAgentTab);
