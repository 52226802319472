import React from "react";
import PropTypes from "prop-types";
import history from "browserHistory";
import orderBy from "lodash/orderBy";
import { Box, Row } from "jsxstyle";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import { TableWithFixedHeader } from "components/Table";
import Separator from "components/Separator";
import EditButton from "components/EditButton";
import PermissionValidator from "components/PermissionValidator";
import columns from "./HYFundTableColumns";
import DeleteHYFund from "./DeleteHYFund";
import HYManagerSubTable from "./HYManagerSubTable";

function HYFundTable({ data, pending, onSortChange, onReload, sortable }) {
  const fundColumns = JSON.parse(localStorage.getItem("hy/fundColumns"));

  const visibleColumns = fundColumns
    ? fundColumns.map((column) =>
        columns.find((tableColumn) => tableColumn.title === column),
      )
    : columns;

  return (
    <React.Fragment>
      <TableWithFixedHeader
        pending={pending}
        rowKey="fund_id.entity_id"
        scroll={{
          x:
            visibleColumns.reduce((acc, column) => acc + column.width, 0) + 210,
        }}
        expandedRowRender={({ fund_id: { entity_id } }) => (
          <Box maxWidth={1500}>
            <HYManagerSubTable fundId={entity_id} />
          </Box>
        )}
        columns={[
          {
            title: "Fund ID",
            dataIndex: "fund_id.entity_id",
            key: "fund_id.entity_id",
            width: 120,
            sortable: sortable !== false,
          },
          ...visibleColumns.map((column) => ({
            ...column,
            sortable: sortable !== false,
          })),
          {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            fixed: "right",
            width: 90,
            render: (item) => (
              <Row>
                <EditButton
                  onClick={() =>
                    history.push(`/hy/fund/edit/${item.fund_id.entity_id}`)
                  }
                />
                <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
                  <Separator size="xs" />
                  <DeleteHYFund fund={item} onDelete={onReload} />
                </PermissionValidator>
              </Row>
            ),
          },
        ]}
        data={orderBy(data, "fund_id", "desc")}
        onSortChange={onSortChange}
      />
    </React.Fragment>
  );
}

HYFundTable.propTypes = {
  attribute: PropTypes.bool,
};

HYFundTable.defaultProps = {
  sortable: true,
};

export default HYFundTable;
