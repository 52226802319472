import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteABSUnderwriter from "services/abs/deleteABSUnderwriter";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteABSUnderwriter({ underwriter, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteABSUnderwriter,
    onData: () => {
      showSuccess("Underwriter deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Underwriter");
    }
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() =>
          fetch({ dealCode: underwriter.deal_code, id: underwriter.id })
        }
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Underwriter{" "}
        <b>{underwriter.entity_id.long_name}</b>?
      </Alert>
    </>
  );
}

DeleteABSUnderwriter.propTypes = {
  underwriter: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteABSUnderwriter.defaultProps = {
  underwriter: {}
};

export default DeleteABSUnderwriter;
