import React from "react";
import isEmpty from "lodash/isEmpty";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import Form from "components/Form";
import Button from "components/Button";
import { REGION_CODES } from "consts";
import { useRegionContext } from "contexts/RegionContext";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import saveREPropService from "services/re/saveREPropService";
import REPropFields from "./REPropFields";
import history from "browserHistory";
import handleApiError from "utils/handleApiError";
import isRequired from "validators/isRequired";

function REPropForm({ initialValues }) {
  const { region, getRegionPath } = useRegionContext();
  const { showSuccess, showError } = useToast();
  const isUSA = region === REGION_CODES.USA
  const isCanada = region === REGION_CODES.CANADA

  const [fetch, pending] = useAsync({
    promiseFn: saveREPropService,
    onData: () => {
      showSuccess("Property saved.");
      history.push(getRegionPath("/re/property"));
    },
    onError: (error) => {
      showError(handleApiError(error));
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: isEmpty(initialValues)
      ? {
        measurement_unit: isUSA ? 'SF' : '',
        country_code: (
            isUSA ? 'USA'
            : isCanada ? 'CAN'
            : ''
          )
        }
      : {
          ...initialValues,
          long_lat:
            initialValues.long_lat && Object.values(initialValues.long_lat),
        },
    validations: {
      property_1: [isRequired],
      property_2: [isRequired],
      city: [isRequired],
      sector_id: [isRequired],
      ...(isUSA ? {
        state: [isRequired],
        market: [isRequired],
      } : {}),
    },
    onSubmit: ({ property_id, ...values }) =>
      fetch({ values, propertyId: property_id }),
  });

  return (
    <Form onSubmit={submit}>
      <REPropFields
        getValue={getValue}
        setValue={setValue}
        getError={getError}
        isSubmitting={pending}
        isNew={isEmpty(initialValues)}
        region={region}
      />

      <Row justifyContent="flex-end" className="col-12">
        <Button
          type="submit"
          text={"Save"}
          intent="primary"
          onClick={() => {}}
          disabled={pending}
          loading={pending}
        />
      </Row>
    </Form>
  );
}

export default REPropForm;
