import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { DateInput } from "@blueprintjs/datetime";

function DateInputGroup({
  disabled,
  intent,
  size,
  onChange,
  placeholder,
  rightElement,
  round,
  value,
  fullWidth,
  format,
  readOnly,
}) {
  const getMomentFormatter = (format) => ({
    formatDate: (date, locale) => moment(date).locale(locale).format(format),
    parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
    placeholder: format,
  });

  return (
    <DateInput
      disabled={disabled || readOnly}
      onChange={(date) => onChange(date)}
      placeholder={placeholder}
      rightElement={rightElement}
      {...getMomentFormatter(format)}
      locale="us"
      value={value ? moment(value).toDate() : null}
      inputProps={{
        intent,
        leftIcon: "calendar",
        width: "100%",
        round,
        large: size === "lg",
        small: size === "sm",
      }}
      minDate={new Date(1800, 1, 1)}
      maxDate={new Date(2100, 1, 1)}
      popoverProps={{ position: "bottom-left", fill: true }}
    />
  );
}

DateInputGroup.propTypes = {
  disabled: PropTypes.bool,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rightElement: PropTypes.node,
  round: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  fullWidth: PropTypes.bool,
  format: PropTypes.string,
};

DateInputGroup.defaultProps = {
  disabled: false,
  intent: "none",
  size: "md",
  leftIcon: null,
  onChange: () => null,
  placeholder: null,
  rightElement: null,
  round: false,
  fullWidth: false,
  format: "YYYY-MM-DD",
  value: null,
};

export default DateInputGroup;
