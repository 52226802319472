import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import DeleteProperty from "screens/re/selectProperty/DeleteProperty";
import EditPropertyForm from "./EditPropertyForm";
import { useRegionContext } from 'contexts/RegionContext';
import { REGION_CODES } from "consts";

function SalePortfolioPropertyTable({ data, saleId, pending, onChange }) {
  const { region } = useRegionContext();
  const isEurope = region === REGION_CODES.EUROPE;

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          data={data}
          pending={pending}
          expandedRowRender={(item) => (
            <EditPropertyForm
              saleId={Number(saleId)}
              propertyId={item.property_id}
              onEdit={onChange}
            />
          )}
          rowKey="property_id"
          columns={[
            {
              title: "Property Id",
              dataIndex: "property_id",
              key: "property_id",
            },
            {
              title: "Property 1",
              dataIndex: "property_1",
              key: "property_1",
            },
            {
              title: "Property 2",
              dataIndex: "property_2",
              key: "property_2",
            },
            {
              title: "City",
              dataIndex: "city",
              key: "city",
            },
            {
              title: isEurope ? "Country" : "State",
              dataIndex: isEurope ? "country_code" : "state",
              key: isEurope ? "country_code" : "state",
            },
            {
              title: "Prop type",
              dataIndex: "prop_type",
              key: "prop_type",
            },
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: (item) => (
                      <DeleteProperty
                        propertyId={item.property_id}
                        saleId={saleId}
                        onDelete={onChange}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </PermissionValidator>
  );
}

SalePortfolioPropertyTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  // onRequestDelete: PropTypes.func.isRequired,
};

SalePortfolioPropertyTable.defaultProps = {
  pending: false,
  data: [],
};

export default SalePortfolioPropertyTable;
