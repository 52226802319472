/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import history from "browserHistory";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import getHYFundByIdService from "services/hy/getHYFundByIdService";
import ScreenLoading from "components/ScreenLoading";
import NewHYFundLayout from "./NewHYFundLayout";
import EditHYFundLayout from "./EditHYFundLayout";

function HYFundFormScreen({ match }) {
  const { action, id } = match.params;
  const { showError } = useToast();
  const [fund, setFund] = React.useState();

  const [fetch, pending] = useAsync({
    promiseFn: getHYFundByIdService,
    onData: (data) => setFund(data),
    onError: (error) => {
      console.log(error);
      showError("An error happened.");
      history.push("/hy/fund");
    },
  });

  React.useEffect(() => {
    if (id) {
      fetch({ id });
    }
  }, []);

  if (id && (pending || !fund)) return <ScreenLoading />;
  if (action !== "new" && action !== "edit") return <></>;

  return {
    new: <NewHYFundLayout fund={fund} onFundChange={setFund} />,
    edit: <EditHYFundLayout fund={fund} onFundChange={setFund} />,
  }[action];
}

export default HYFundFormScreen;
