import React from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

function useURLSearchParam(defaultParams) {
  let history = useHistory();
  const params = React.useMemo(
    () =>
      history.location.search
        ? {
            ...defaultParams,
            ...qs.parse(history.location.search),
          }
        : defaultParams,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.location.search],
  );

  return [
    params || defaultParams,
    newParams => {
      history.push({
        search: qs.stringify({
          ...defaultParams,
          ...newParams,
        }),
      });
    },
  ];
}

export default useURLSearchParam;
