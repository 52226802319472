import React from "react";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import REListScreen from "screens/re/REListScreen";
import NewREPropScreen from "screens/re/prop/NewREPropScreen";
import EditREPropScreen from "screens/re/prop/EditREPropScreen";
import SalePropertyScreen from "screens/re/sale/property/SalePropertyScreen";
import NewSalePortfolioScreen from "screens/re/sale/portfolio/NewSalePortfolioScreen";
import EditSalePortfolioScreen from "screens/re/sale/portfolio/EditSalePortfolioScreen";
import RESaleTableConfigurationScreen from "screens/re/sale/RESaleTableConfigurationScreen";
import REPropTableConfigurationScreen from "screens/re/prop/REPropTableConfigurationScreen";

import { RegionContextProvider } from "contexts/RegionContext";

const RERoutes = () => {
  const { path } = useRouteMatch();
  return (
    <RegionContextProvider>
      <Switch>
        <Route
          exact
          path={`${path}/(sale)?/(property)?`}
          component={REListScreen}
        />
        <Route
          exact
          path={`${path}/sale/table-configuration`}
          component={RESaleTableConfigurationScreen}
        />
        <Route
          exact
          path={`${path}/prop/table-configuration`}
          component={REPropTableConfigurationScreen}
        />
        <Route 
          exact
          path={`${path}/property/new`}
          component={NewREPropScreen}
        />
        <Route
          exact
          path={`${path}/property/:propertyId`}
          component={EditREPropScreen}
        />
        <Route
          exact
          path={`${path}/sale/property/new`}
          component={SalePropertyScreen}
        />
        <Route 
          exact
          path={`${path}/sale/:saleId`}
          component={SalePropertyScreen}
        />
        <Route
          exact
          path={`${path}/sale/portfolio/new/:saleId?`}
          component={NewSalePortfolioScreen}
        />
        <Route
          exact
          path={`${path}/sale/portfolio/:saleId`}
          component={EditSalePortfolioScreen}
        />
      </Switch>
    </RegionContextProvider>
  )
}

export default RERoutes;