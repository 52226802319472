/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Table from "components/Table";
import Button from "components/Button";
import PermissionValidator from "components/PermissionValidator";

function HYVehicleTypeAssociationTable({ vehicleTypes, onRequestDelete }) {
  return vehicleTypes.length ? (
    <Table
      data={vehicleTypes}
      rowKey="id"
      columns={[
        {
          title: "Vehicle Type",
          dataIndex: "name",
          key: "id",
        },
        {
          title: "Actions",
          //dataIndex: "",
          //key: "",
          width: 100,
          render: (item) => (
            <PermissionValidator
              allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
              shouldHide
            >
              <Button
                size="sm"
                icon="trash"
                intent="none"
                onClick={() => onRequestDelete(item)}
              />
            </PermissionValidator>
          ),
        },
      ]}
    />
  ) : null;
}

HYVehicleTypeAssociationTable.propTypes = {
  vehicleTypes: PropTypes.arrayOf(PropTypes.number),
  onRequestDelete: PropTypes.func.isRequired,
};

HYVehicleTypeAssociationTable.defaultProps = {
  vehicleTypes: [],
};

export default HYVehicleTypeAssociationTable;
