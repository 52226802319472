/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable";
import { useMachine } from "@xstate/react";
import get from "lodash/get";
import debounceFetchMachine from "stateMachines/debounceFetchMachine";
import "./AsyncAutoComplete.css";

function AsyncCreatableAutoComplete({
  promiseFn,
  onSelect,
  onCreate,
  error,
  placeholder,
  value,
  disabled,
  params,
  filter,
  searchable,
  shouldFetchDefaultOptions,
  readOnly,
  itemRenderer,
  valueRenderer,
  createOptionRenderer,
  filterKey,
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(null);
  const searchMachine = debounceFetchMachine.withContext({
    promiseFn,
  });
  const [current, send] = useMachine(searchMachine);

  React.useEffect(() => {
    send({
      type: "UPDATE_PARAMS",
      payload: {
        params: {
          ...params,
          [filterKey]: shouldFetchDefaultOptions ? undefined : value,
          page: 1,
          size: 50,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    send({
      type: "UPDATE_PARAMS",
      payload: {
        params: {
          ...params,
          [filterKey]: inputValue,
          page: 1,
          size: 50,
        },
      },
    });
  }, [inputValue]);

  React.useEffect(() => {
    setSelectedValue(
      value
        ? {
            value,
            label: itemRenderer(value),
          }
        : null,
    );
  }, [value]);

  const options = get(current.context.data, "data", []);

  return (
    <Creatable
      styles={getStyle(error)}
      components={{
        SingleValue: ({ data: { value, label } = {} } = {}) =>
          valueRenderer({ value, label }),
      }}
      clear
      isClearable
      placeholder={placeholder}
      isLoading={current.matches("fetching")}
      isDisabled={disabled || readOnly}
      isSearchable={searchable}
      allowCreateWhileLoading
      value={selectedValue}
      inputValue={inputValue}
      options={options.map((option) => ({
        value: option,
        label: itemRenderer(option),
      }))}
      filterOption={({ value, data: { __isNew__ } }, filterValue) =>
        __isNew__ || filter({ filterValue, option: value })
      }
      onInputChange={setInputValue}
      onChange={(option) => {
        const { value, __isNew__ } = option || {};
        setSelectedValue(option);
        if (!option) {
          send({
            type: "UPDATE_PARAMS",
            payload: {
              params: {
                ...params,
                [filterKey]: undefined,
                page: 1,
                size: 50,
              },
            },
          });
          onSelect(null);
        } else if (__isNew__) {
          onCreate(value);
        } else {
          onSelect(value);
        }
      }}
      onCreateOption={onCreate}
      formatCreateLabel={createOptionRenderer}
      menuPortalTarget={document.getElementById("dropdown")}
    />
  );
}

AsyncCreatableAutoComplete.propTypes = {
  promiseFn: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  textPath: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  searchable: PropTypes.bool,
  createOptionRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  filterKey: PropTypes.string,
};

AsyncCreatableAutoComplete.defaultProps = {
  error: null,
  disabled: false,
  searchable: true,
  filterKey: "criteria",
  filter: (item) => true,
  itemRenderer: (item) => item,
  createOptionRenderer: (item) => undefined,
  valueRenderer: ({ label }) => label,
};

const getStyle = (error) => ({
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : null,
    background: state.isSelected ? "#137cbd" : null,
  }),
  control: (provided, state) => ({
    borderRadius: 3,
    background: state.isDisabled ? "#ced9e080" : "#ffffff",
    height: 30,
    padding: "0 10px",
    verticalAlign: "middle",
    display: "flex",
    fontSize: 14,
    boxShadow: error
      ? "0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)"
      : state.isFocused
      ? "0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2)"
      : "0 0 0 0 rgba(19, 124, 189, 0),0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)",
  }),
  singleValue: ({ transform, top, ...provided }) => ({
    ...provided,
  }),
  indicatorSeparator: () => ({}),
  menu: (provided) => ({ ...provided }),
});

export default AsyncCreatableAutoComplete;
