/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAsync from "hooks/useAsync";
import getCMBSDealByDealCodeService from "services/cmbs/getCMBSDealByDealCodeService";
import ScreenLoading from "components/ScreenLoading";
import NewCMBSLayout from "./NewCMBSLayout";
import EditCMBSLayout from "./EditCMBSLayout";

function CMBSFormScreen({ match }) {
  const { action, dealCode } = match.params;
  const [deal, setDeal] = React.useState();

  const [fetch, pending] = useAsync({
    promiseFn: getCMBSDealByDealCodeService,
    onData: data => setDeal(data)
  });

  React.useEffect(() => {
    if (dealCode) {
      fetch({ dealCode });
    }
  }, []);

  if (dealCode && (pending || !deal)) return <ScreenLoading />;

  if (action !== "new" && action !== "edit") return <></>;

  return {
    new: <NewCMBSLayout onDealChange={deal => setDeal(deal)} deal={deal} />,
    edit: <EditCMBSLayout deal={deal} onDealChange={deal => setDeal(deal)} />
  }[action];
}

export default CMBSFormScreen;
