/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAsync from "hooks/useAsync";
import getCLODealByDealCodeService from "services/clo/getCLODealByDealCodeService";
import ScreenLoading from "components/ScreenLoading";
import NewCLOLayout from "./NewCLOLayout";
import EditCLOLayout from "./EditCLOLayout";

function CLOFormScreen({ match }) {
  const { action, dealCode } = match.params;
  const [deal, setDeal] = React.useState();

  const [fetch, pending] = useAsync({
    promiseFn: getCLODealByDealCodeService,
    onData: (data) => setDeal(data),
  });

  React.useEffect(() => {
    if (dealCode) {
      fetch({ dealCode });
    }
  }, []);

  if (dealCode && (pending || !deal)) return <ScreenLoading />;

  if (action !== "new" && action !== "edit") return <></>;

  return {
    new: <NewCLOLayout onDealChange={(deal) => setDeal(deal)} deal={deal} />,
    edit: <EditCLOLayout deal={deal} onDealChange={(deal) => setDeal(deal)} />,
  }[action];
}

export default CLOFormScreen;
