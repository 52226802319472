import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@blueprintjs/core';

function GlobalSearchOmnibarItem({ onClick, children }) {
  return <MenuItem text={children} onClick={onClick} />;
}

GlobalSearchOmnibarItem.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
};

GlobalSearchOmnibarItem.defaultProps = {
  children: '',
  onClick: () => {},
};

export default GlobalSearchOmnibarItem;
