import React from "react";
import PropTypes from "prop-types";
import { Col } from "jsxstyle";
import PrivateNavbar from "../components/PrivateNavbar";
import InnerSpacing from "./InnerSpacing";

function PrivateLayout({ children }) {
  return (
    <Col flexGrow={1}>
      <PrivateNavbar />
      <Col flexGrow={1}>
        <InnerSpacing vertical="lg">{children}</InnerSpacing>
      </Col>
    </Col>
  );
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateLayout;
