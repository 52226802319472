import React from "react";
import PropTypes from "prop-types";
import { Tag as BluePringTag } from "@blueprintjs/core";

function Tag({
  children,
  icon,
  fill,
  intent,
  interactive,
  large,
  minimal,
  onClick,
  onRemove,
  rightIcon,
  round
}) {
  return (
    <BluePringTag
      icon={icon}
      fill={fill}
      intent={intent}
      interactive={interactive}
      large={large}
      minimal={minimal}
      onClick={onClick}
      onRemove={onRemove}
      rightIcon={rightIcon}
      round={round}
    >
      {children}
    </BluePringTag>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  fill: PropTypes.bool,
  intent: PropTypes.oneOf(["primary", "success", "warning", "danger"]),
  interactive: PropTypes.bool,
  large: PropTypes.bool,
  minimal: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  rightIcon: PropTypes.string,
  round: PropTypes.bool
};

export default Tag;
