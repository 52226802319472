import React from "react";
import { Row, Box } from "jsxstyle";
import get from "lodash/get";
import { useRegionContext } from 'contexts/RegionContext';
import Pagination from "components/Pagination";
import Button from "components/Button";
import useAsync from "hooks/useAsync";
import useUrlParams from "hooks/useUrlParams";
import useToast from "hooks/useToast";
import loadRESalesService from "services/re/loadRESalesService";
import RESaleFilters from "./RESaleFilters";
import RESaleTable from "./RESaleTable";
import AddButton from "components/AddButton";
import history from "browserHistory";
import InBetweenSpacing from "components/InBetweenSpacing";
import Separator from "components/Separator";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";

function RESaleListScreen() {
  const { region, getRegionPath }  = useRegionContext();
  const defaultParams = {
    page: 1,
    size: 20,
    order_by: "sale_date",
    order_direction: "DESC",
    region: region
  };
  const { showError } = useToast();
  const [params, setParams] = useUrlParams(defaultParams);

  const [fetch, pending, data = {}] = useAsync({
    promiseFn: loadRESalesService,
    params,
    eager: true,
    onError: (error) => {
      showError("Error loading RE");
    },
  });

  return (
    <InBetweenSpacing>
      <Row alignItems="flex-start">
        <Box flex={1}>
          <RESaleFilters
            currentFilters={omit(params, ["page", "size", "region"])}
            onChange={(newFilters) => {
              setParams({
                ...pickBy(newFilters),
                ...defaultParams,
              });
            }}
          />
        </Box>
        <Box flexBasis={150}>
          <Button
            icon="cog"
            text="Configure Table"
            onClick={() => history.push(getRegionPath("/re/sale/table-configuration"))}
          />
        </Box>
      </Row>
      <Row>
        <AddButton
          text="Add Property Sale"
          onClick={() => history.push(getRegionPath('/re/sale/property/new'))}
        />
        <Separator />
        <AddButton
          text="Add Portfolio Sale"
          onClick={() => history.push(getRegionPath('/re/sale/portfolio/new'))}
        />
      </Row>
      <RESaleTable
        onSortChange={(sortObject) =>
          setParams({
            ...params,
            ...pickBy(sortObject),
            page: 1,
          })
        }
        onReload={() => fetch({ params })}
        data={get(data, "data", [])}
        pending={pending}
      />
      {!pending && (
        <Row justifyContent="flex-end">
          <Pagination
            page={params.page}
            size={params.size}
            total={data.total}
            onChange={({ page }) => setParams({ ...params, page })}
          />
        </Row>
      )}
    </InBetweenSpacing>
  );
}

RESaleListScreen.propTypes = {};

RESaleListScreen.defaultProps = {};

export default RESaleListScreen;
