import React from "react";
// import PropTypes from "prop-types";
import get from "lodash/get";
import pick from "lodash/pick";
import PropertySelectTable from "screens/re/selectProperty/PropertySelectTable";
import Modal, { ModalContent } from "components/Modal";
import Stepper from "components/Stepper";
import Form from "components/Form";
import useAsync from "hooks/useAsync";
import useForm from "hooks/useForm";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import { Box, Row } from "jsxstyle";
import PropertyFields from "./PropertyFields";
import Button from "components/Button";
import Separator from "components/Separator";
import getRESaleById from "services/re/getRESaleById";
import saveRESalePropertyAndPortfolioService from "services/re/saveRESalePropertyAndPortfolioService";
import isRequired from 'validators/isRequired';
import SaleTable from "./SaleTable";
import { Divider } from "@blueprintjs/core";
import useToast from "hooks/useToast";
import { useRegionContext } from 'contexts/RegionContext';
import { REGION_CODES } from "consts";

function SalePortfolioPropertiesStepperModal({
  visible,
  onRequestClose,
  saleId,
  onData,
}) {
  const { showError, showSuccess } = useToast();

  const [selectedProperty, setSelectedProperty] = React.useState(null);

  const [currentStep, setCurrentStep] = React.useState(0);

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveRESalePropertyAndPortfolioService,
    onData: () => {
      showSuccess("Portfolio saved.");
      setSelectedProperty(null);
      onRequestClose();
      onData();
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });
  return (
    <Modal
      visible={visible}
      onRequestClose={() => {
        if (isSubmitting) return;
        setCurrentStep(0);
        onRequestClose();
      }}
    >
      <Box overflow="hidden" height="100%" width={1108}>
        <ModalContent>
          <Stepper
            steps={["Select a property", "Enter Portfolio data"]}
            currentStep={currentStep}
          >
            <SalePortfolioPropertySelect
              value={selectedProperty}
              onRequestClose={onRequestClose}
              onSelect={(selectedRows) => {
                if (selectedRows) {
                  setSelectedProperty(selectedRows[0]);
                  setCurrentStep(1);
                }
              }}
            />
            <PropertyForm
              saleId={saleId}
              onSubmit={(values) =>
                fetch({
                  saleId,
                  propertyId: selectedProperty.property_id,
                  values,
                })
              }
              isSubmitting={isSubmitting}
              onPreviousClick={() => setCurrentStep(0)}
            />
          </Stepper>
        </ModalContent>
      </Box>
    </Modal>
  );
}

SalePortfolioPropertiesStepperModal.propTypes = {};

SalePortfolioPropertiesStepperModal.defaultProps = {};

function SalePortfolioPropertySelect({ onRequestClose, onSelect, value }) {
  return (
    <PropertySelectTable
      value={[get(value, "property_id", null)]}
      confirmButton={{ title: "Next", intent: "primary" }}
      onSelect={(selectedRows) => {
        onSelect(selectedRows);
      }}
      onRequestModalClose={onRequestClose}
    />
  );
}

function PropertyForm({ saleId, onSubmit, isSubmitting, onPreviousClick }) {
  const { showError } = useToast();
  const { region } = useRegionContext();

  const [fetch, pending = true, data] = useAsync({
    promiseFn: getRESaleById,
    eager: true,
    onError: () => {
      showError("Error loading related sale");
    }
  });

  React.useEffect(() => {
    fetch({ saleId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleId]);

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: { 
      ...pick(data, ['stake', 'broker_1', 'broker_2']), 
      num_props: 1,
      currency_code: region === REGION_CODES.USA ? 'USD' : ''
    },
    validations: {
      num_props: [isRequired],
      price: [isRequired],
      price_accuracy: [isRequired],
      sale_date: [isRequired],
      stake: [isRequired],
    },
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Box flex={1}>
      <TwoColumnFormLayout title="Related Sale" size="sm">
        <SaleTable
          data={data}
          pending={pending}
        />
      </TwoColumnFormLayout>
      <Divider style={{ margin: "30px 0" }} />
      <TwoColumnFormLayout title="Property Sale Info" size="sm">
      <Form onSubmit={() => submit()}>
        <PropertyFields
          getValue={getValue}
          setValue={setValue}
          getError={getError}
          isSubmitting={isSubmitting}
        />
        <Row justifyContent="flex-end">
          <Button
            onClick={onPreviousClick}
            type="button"
            text="Previous"
            intent="none"
            disabled={isSubmitting}
          />
          <Separator />
          <Button
            text="Save"
            intent="primary"
            type="submit"
            onClick={() => {}}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Row>
      </Form>
    </TwoColumnFormLayout>
    </Box>
  );
}

export default SalePortfolioPropertiesStepperModal;
