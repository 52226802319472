import React from "react";
import PropTypes from "prop-types";
import { TableWithFixedHeader } from "components/Table";
import DeleteEntityMaster from "./DeleteEntityMaster";
import history from "browserHistory";
import Separator from "components/Separator";
import { Row } from "jsxstyle";
import EditButton from "components/EditButton";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import entityMasterTableColumns from "./EntityMasterTableColumns";

function EntityMasterTable({
  data,
  pending,
  onSortChange,
  onReload,
  sortable,
}) {
  return (
    <TableWithFixedHeader
      pending={pending}
      rowKey="entity_id"
      scroll={{
        x:
          entityMasterTableColumns.reduce(
            (acc, column) => acc + column.width,
            0,
          ) + 120,
      }}
      columns={[
        ...entityMasterTableColumns.map((column) => ({
          ...column,
          sortable: sortable !== false,
        })),
        {
          title: "Actions",
          dataIndex: "",
          key: "actions",
          fixed: "right",
          width: 120,
          render: (item) => (
            <Row>
              <EditButton
                onClick={() => history.push(`/entity-master/${item.entity_id}`)}
              />
              <PermissionValidator
                allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
                shouldHide
              >
                <Separator size="xs" />
                <DeleteEntityMaster entityMaster={item} onDelete={onReload} />
              </PermissionValidator>
            </Row>
          ),
        },
      ]}
      data={data}
      onSortChange={onSortChange}
    />
  );
}

EntityMasterTable.propTypes = {
  sortable: PropTypes.bool,
};

EntityMasterTable.defaultProps = {
  sortable: true,
};

export default EntityMasterTable;
