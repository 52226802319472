import React from "react";
import { Spinner } from "@blueprintjs/core";
import { Box } from "jsxstyle";

function ScreenLoading() {
  return (
    <Box
      width="100%"
      height="70vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  );
}

export default ScreenLoading;
