/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { pickBy } from "lodash";
import PropTypes from "prop-types";
import { Box, Row } from "jsxstyle";
import { Icon, Spinner } from "@blueprintjs/core";
import deleteEntityMasterService from "services/entityMaster/deleteEntityMasterService";
import getEntityMasterRelationshipsByIdService from "services/entityMaster/getEntityMasterRelationshipsByIdService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import isRequired from "validators/isRequired";
import Form from "components/Form";
import Modal from "components/Modal";
import Button from "components/Button";
import FormGroup from "components/FormGroup";
import Separator from "components/Separator";
import InputGroup from "components/InputGroup";
import DateInputGroup from "components/DateInputGroup";

function DeleteEntityMasterConfirmationModal({
  entityMaster,
  onDelete,
  onRequestClose,
  isVisible,
}) {
  const { showSuccess, showError } = useToast();

  const { getValue, setValue, getError, submit, reset } = useForm({
    initialValues: {...entityMaster, date_end: new Date().toISOString().split('T')[0]},
    onSubmit: ({ confirmation, date_end }) => {
      fetchDelete({ entityId: entityMaster.entity_id, dateEnd: date_end, confirmation });
    },
    validations: {
      confirmation: [
        isRequired,
        (value) =>
          value !== entityMaster.long_name &&
          "Confirmation should match entity's full name.",
      ],
      date_end: [isRequired]
    },
  });

  const [fetchRelationships, pendingRelationships, relationships] = useAsync({
    promiseFn: getEntityMasterRelationshipsByIdService,
    onError: (error) => {
      console.log(error);
      showError("Error getting Entity Master relationships");
    },
  });

  const [fetchDelete, deleting] = useAsync({
    promiseFn: deleteEntityMasterService,
    onError: ({data: { error, message }}) => {
      console.log(error);
      const errorMessage = message || "Error deleting Entity Master";
      showError(errorMessage);
    },
    onData: () => {
      showSuccess("Entity Master deleted successfully.");
      onDelete();
    },
  });

  React.useEffect(() => {
    if (isVisible) {
      fetchRelationships({ id: entityMaster.entity_id });
    }
  }, [isVisible]);

  const relationsKeys = Object.keys(pickBy(relationships));
  const relations = relationsKeys.filter(
    (_, index) => index < relationsKeys.length - 1,
  );

  const handleRequestClose = () => {
    reset();
    onRequestClose();
  };

  return (
    <Modal visible={isVisible} onRequestClose={handleRequestClose}>
      <Box padding={20}>
        <Row alignItems="center">
          <Icon icon="trash" iconSize={40} intent="danger" />
          <Separator />
          <Box>
            Are you sure you want to delete the Entity Master{" "}
            <b>{entityMaster.long_name}</b>?
          </Box>
        </Row>
        <Separator size="lg" />
        {pendingRelationships ? (
          <Spinner size={20} />
        ) : (
          <React.Fragment>
            {relations.length === 0 ? (
              "This Entity Master has no relationships and can be safely deleted."
            ) : (
              <React.Fragment>
                <Box>Existing Relationships</Box>
                <Separator size="xs" />
              </React.Fragment>
            )}
            {relations.map((key) => (
              <Row>
                <Box textTransform="uppercase">
                  <b>{key}</b>:
                </Box>
                <Separator size="xs" />
                <Box>{relationships[key]}</Box>
              </Row>
            ))}
          </React.Fragment>
        )}
        <Separator />
        <Form onSubmit={submit}>
          <FormGroup
            label={`Type '${entityMaster.long_name}' to confirm`}
            disabled={deleting}
            error={getError("confirmation")}
            required
          >
            <InputGroup
              intent={getError("confirmation") ? "danger" : null}
              value={getValue("confirmation")}
              onChange={(value) => setValue("confirmation", value)}
              disabled={deleting}
            />
          </FormGroup>
          <FormGroup
            label="Effective date"
            disabled={deleting}
            error={getError("date_end")}
            required
          >
            <DateInputGroup
              intent={getError("date_end") ? "danger" : null}
              value={getValue("date_end")}
              onChange={(value) => setValue("date_end", value)}
            />
          </FormGroup>
          <Row justifyContent="flex-end">
            <Button
              text="Cancel"
              onClick={handleRequestClose}
              disabled={deleting}
            />
            <Separator size="xs" />
            <Button
              type="submit"
              text="Delete"
              loading={deleting}
              intent="danger"
              disabled={deleting || pendingRelationships}
            />
          </Row>
        </Form>
      </Box>
    </Modal>
  );
}

DeleteEntityMasterConfirmationModal.propTypes = {
  entityMaster: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

DeleteEntityMasterConfirmationModal.defaultProps = {
  entityMaster: {},
};

export default DeleteEntityMasterConfirmationModal;
