import { Machine, assign } from "xstate";

const debounceFetchMachine = Machine(
  {
    id: "fetch",
    initial: "idle",
    context: {
      promiseFn: null,
      params: null,
      data: null,
      error: null
    },
    states: {
      idle: {},
      debouncing: {
        onEntry: "setParams",
        after: {
          300: "fetching"
        }
      },
      fetching: {
        invoke: {
          src: "fetch",
          onDone: {
            target: "success",
            actions: ["setData"]
          },
          onError: {
            target: "failure",
            actions: ["setError"]
          }
        }
      },
      success: {},
      failure: {},
    },
    on: {
      UPDATE_PARAMS: { target: ".debouncing", internal: false }
    }
  },
  {
    guards: {},
    actions: {
      setData: assign({ data: (_, event) => event.data }),
      setError: assign({ error: (_, event) => event.data }),
      setParams: assign({ params: (_, event) => event.payload })
    },
    services: {
      fetch: context => context.promiseFn(context.params)
    }
  }
);

export default debounceFetchMachine;
