import React from "react";
import { Box } from "jsxstyle";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Select from "components/Select";
import Switch from "components/Switch";
import FormGrid from "components/FormGrid";
import TextArea from "components/TextArea";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import DateInputGroup from "components/DateInputGroup";
import SelectRegionCode from "components/SelectRegionCode";
import IntegerInputGroup from "components/IntegerInputGroup";
import SelectCLODealType from "components/SelectCLODealType";
import SelectCountryCode from "components/SelectCountryCode";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import SelectCurrencyCode from "components/SelectCurrencyCode";
import PermissionValidator from "components/PermissionValidator";
import SelectCollateralCode from "components/SelectCollateralCode";
import SelectCreatableAbbrv from "components/SelectCreatableAbbrv";
import SelectCreatableIssuer from "components/SelectCreatableIssuer";

function CLOFields({ getValue, setValue, getError, isSubmitting, isNew }) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <FormGrid>
          <FormGrid.Item>
            <FormGroup
              label="Deal code"
              required
              disabled={isSubmitting}
              error={getError("deal_code")}
            >
              <IntegerInputGroup
                minLength={8}
                maxLength={8}
                intent={getError("deal_code") ? "danger" : null}
                value={getValue("deal_code")}
                onChange={(value) => setValue("deal_code", value)}
                buttonPosition="none"
                disabled={isSubmitting || !isNew}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Deal type"
              required
              disabled={isSubmitting}
              error={getError("deal_type")}
            >
              <SelectCLODealType
                onSelect={(value) => setValue("deal_type", value)}
                value={getValue("deal_type")}
                error={getError("deal_type")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Parent Code"
              disabled={isSubmitting}
              error={getError("parent_code")}
            >
              <IntegerInputGroup
                intent={getError("parent_code") ? "danger" : null}
                value={getValue("parent_code")}
                onChange={(value) => setValue("parent_code", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Pricing date *"
              disabled={isSubmitting}
              error={getError("pricing_date")}
            >
              <DateInputGroup
                intent={getError("pricing_date") ? "danger" : null}
                value={getValue("pricing_date")}
                onChange={(value) => setValue("pricing_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Closing date"
              disabled={isSubmitting}
              error={getError("closing_date")}
            >
              <DateInputGroup
                intent={getError("closing_date") ? "danger" : null}
                value={getValue("closing_date")}
                onChange={(value) => setValue("closing_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Abbreviation"
              disabled={isSubmitting}
              error={getError("abbrv")}
            >
              <SelectCreatableAbbrv
                variant="CLO"
                disabled={isSubmitting}
                error={getError("abbrv")}
                value={getValue("abbrv")}
                onCreate={(abbrv) => setValue("abbrv", abbrv)}
                onSelect={(value) => setValue("abbrv", value)}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Issuer *"
              disabled={isSubmitting}
              error={getError("issuer")}
            >
              <SelectCreatableIssuer
                variant="CLO"
                readOnly={!isAllowed}
                disabled={isSubmitting}
                error={getError("issuer")}
                value={getValue("issuer")}
                onCreate={(issuer) => setValue("issuer", issuer)}
                onSelect={(value) => setValue("issuer", value)}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Series"
              disabled={isSubmitting}
              error={getError("series")}
            >
              <InputGroup
                intent={getError("series") ? "danger" : null}
                value={getValue("series")}
                onChange={(value) => setValue("series", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                maxLength={50}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Deal amount *"
              disabled={isSubmitting}
              error={getError("deal_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("deal_amount") ? "danger" : null}
                value={getValue("deal_amount")}
                onChange={(value) => setValue("deal_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Retained amount"
              disabled={isSubmitting}
              error={getError("retained_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("retained_amount") ? "danger" : null}
                value={getValue("retained_amount")}
                onChange={(value) => setValue("retained_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Primary collateral type"
              disabled={isSubmitting}
              error={getError("collateral")}
            >
              <SelectCollateralCode
                onSelect={(value) => setValue("collateral", value)}
                value={getValue("collateral")}
                error={getError("collateral")}
                disabled={isSubmitting}
                params={{ is_CLO: true }}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Secondary collateral type"
              disabled={isSubmitting}
              error={getError("collateral_sub")}
            >
              <SelectCollateralCode
                onSelect={(value) => setValue("collateral_sub", value)}
                value={getValue("collateral_sub")}
                error={getError("collateral_sub")}
                disabled={isSubmitting}
                params={{ is_clo2: true }}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Non-call date"
              disabled={isSubmitting}
              error={getError("noncall_date")}
            >
              <DateInputGroup
                intent={getError("noncall_date") ? "danger" : null}
                value={getValue("noncall_date")}
                onChange={(value) => setValue("noncall_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="End date"
              disabled={isSubmitting}
              error={getError("end_date")}
            >
              <DateInputGroup
                intent={getError("end_date") ? "danger" : null}
                value={getValue("end_date")}
                onChange={(value) => setValue("end_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          {/* <FormGrid.Item>
            <FormGroup
              label="End Year"
              disabled={isSubmitting}
              error={getError("end_years")}
            >
              <Select
                items={range(2019, 2100).map(year => ({
                  id: year,
                  label: year
                }))}
                onSelect={value => setValue("end_years", value)}
                value={getValue("end_years")}
                error={getError("end_years")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item> */}
          <FormGrid.Item>
            <FormGroup
              label="Pay frequency"
              disabled={isSubmitting}
              error={getError("pay_frequency")}
            >
              <Select
                items={[
                  {
                    id: "M",
                    label: "Monthly",
                  },
                  {
                    id: "Q",
                    label: "Quarterly",
                  },
                  {
                    id: "S",
                    label: "Semi-annual",
                  },
                  {
                    id: "A",
                    label: "Annual",
                  },
                ]}
                onSelect={(value) => setValue("pay_frequency", value)}
                value={getValue("pay_frequency")}
                error={getError("pay_frequency")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Seller"
              disabled={isSubmitting}
              error={getError("seller")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("seller", value)}
                value={getValue("seller")}
                error={getError("seller")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Master servicer"
              disabled={isSubmitting}
              error={getError("master_servicer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("master_servicer", value)}
                value={getValue("master_servicer")}
                error={getError("master_servicer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Sponsor"
              disabled={isSubmitting}
              error={getError("sponsor")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("sponsor", value)}
                value={getValue("sponsor")}
                error={getError("sponsor")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Current manager"
              disabled={isSubmitting}
              error={getError("current_manager")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("current_manager", value)}
                value={getValue("current_manager")}
                error={getError("current_manager")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Transfer date"
              disabled={isSubmitting}
              error={getError("transfer_date")}
            >
              <DateInputGroup
                intent={getError("transfer_date") ? "danger" : null}
                value={getValue("transfer_date")}
                onChange={(value) => setValue("transfer_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Enhancement provider"
              disabled={isSubmitting}
              error={getError("enhancement")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("enhancement", value)}
                value={getValue("enhancement")}
                error={getError("enhancement")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Trustee"
              disabled={isSubmitting}
              error={getError("trustee")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("trustee", value)}
                value={getValue("trustee")}
                error={getError("trustee")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Underwriter counsel"
              disabled={isSubmitting}
              error={getError("counsel_uw")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("counsel_uw", value)}
                value={getValue("counsel_uw")}
                error={getError("counsel_uw")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Issuer counsel"
              disabled={isSubmitting}
              error={getError("counsel_issuer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("counsel_issuer", value)}
                value={getValue("counsel_issuer")}
                error={getError("counsel_issuer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination"
              disabled={isSubmitting}
              error={getError("denomination")}
            >
              <SelectCurrencyCode
                onSelect={(value) => setValue("denomination", value)}
                value={getValue("denomination")}
                error={getError("denomination")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination amount"
              disabled={isSubmitting}
              error={getError("denomination_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("denomination_amount") ? "danger" : null}
                value={getValue("denomination_amount")}
                onChange={(value) => setValue("denomination_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination retained"
              disabled={isSubmitting}
              error={getError("denomination_retained")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("denomination_retained") ? "danger" : null}
                value={getValue("denomination_retained")}
                onChange={(value) => setValue("denomination_retained", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Synthetic pool"
              disabled={isSubmitting}
              error={getError("synthetic_pool")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("synthetic_pool") ? "danger" : null}
                value={getValue("synthetic_pool")}
                onChange={(value) => setValue("synthetic_pool", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Country of collateral"
              disabled={isSubmitting}
              error={getError("col_country")}
            >
              <SelectCountryCode
                onSelect={(value) => {
                  setValue("col_country", value);
                }}
                value={getValue("col_country")}
                disabled={isSubmitting}
                error={getError("col_country")}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Region of collateral"
              disabled={isSubmitting}
              error={getError("col_region")}
            >
              <SelectRegionCode
                onSelect={(value) => setValue("col_region", value)}
                value={getValue("col_region")}
                error={getError("col_region")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Distribution region"
              disabled={isSubmitting}
              error={getError("distribution_region")}
            >
              <SelectRegionCode
                onSelect={(value) => setValue("distribution_region", value)}
                value={getValue("distribution_region")}
                error={getError("distribution_region")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Offer type"
              disabled={isSubmitting}
              error={getError("offer_type")}
              maxLength={3}
            >
              <Select
                items={[
                  {
                    id: "A",
                    label: "Rule 144A",
                  },
                  {
                    id: "I",
                    label: "Outside U.S",
                  },
                  {
                    id: "P",
                    label: "Private placement",
                  },
                  {
                    id: "S",
                    label: "SEC-registered",
                  },
                ]}
                onSelect={(value) => setValue("offer_type", value)}
                value={getValue("offer_type")}
                error={getError("offer_type")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Risk retention"
              disabled={isSubmitting}
              error={getError("risk_retention")}
            >
              <Select
                items={[
                  {
                    id: "H",
                    label: " Horizontal",
                  },
                  {
                    id: "V",
                    label: "Vertical",
                  },
                  {
                    id: "L",
                    label: "L-shaped",
                  },
                ]}
                onSelect={(value) => setValue("risk_retention", value)}
                value={getValue("risk_retention")}
                error={getError("risk_retention")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Self issuance"
              disabled={isSubmitting}
              error={getError("self_issuance")}
            >
              <InputGroup
                intent={getError("self_issuance") ? "danger" : null}
                value={getValue("self_issuance")}
                onChange={(value) => setValue("self_issuance", value)}
                disabled={isSubmitting}
                maxLength={3}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Government facility"
              disabled={isSubmitting}
              error={getError("govt")}
            >
              <Switch
                value={getValue("govt")}
                onChange={() => setValue("govt", !getValue("govt"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <Box className="col-12">
            <FormGroup
              label="Deal Profile"
              disabled={isSubmitting}
              error={getError("deal_profile")}
            >
              <TextArea
                intent={getError("deal_profile") ? "danger" : null}
                value={getValue("deal_profile")}
                onChange={(value) => setValue("deal_profile", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                fullWidth
              />
            </FormGroup>
          </Box>
          <Box className="col-12">
            <FormGroup
              label="Collateral Profile"
              disabled={isSubmitting}
              error={getError("collateral_profile")}
            >
              <TextArea
                intent={getError("collateral_profile") ? "danger" : null}
                value={getValue("collateral_profile")}
                onChange={(value) => setValue("collateral_profile", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                fullWidth
              />
            </FormGroup>
          </Box>
          <Box className="col-12">
            <FormGroup
              label="Risk retention profile"
              disabled={isSubmitting}
              error={getError("risk_retention_profile")}
            >
              <TextArea
                intent={getError("risk_retention_profile") ? "danger" : null}
                value={getValue("risk_retention_profile")}
                onChange={(value) => setValue("risk_retention_profile", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                fullWidth
                size="lg"
              />
            </FormGroup>
          </Box>
          <Box className="col-12">
            <FormGroup
              label="Notes"
              disabled={isSubmitting}
              error={getError("notes")}
            >
              <TextArea
                intent={getError("notes") ? "danger" : null}
                value={getValue("notes")}
                onChange={(value) => setValue("notes", value)}
                disabled={isSubmitting}
                fullWidth
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
        </FormGrid>
      )}
    </PermissionValidator>
  );
}

CLOFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isNew: PropTypes.bool,
};

CLOFields.defaultProps = {
  isSubmitting: false,
  isNew: true,
};

export default CLOFields;
