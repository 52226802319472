import React from 'react';
import PermissionValidator from 'components/PermissionValidator';
import NotAllowedScreen from 'screens/not-allowed/NotAllowedScreen';

function withPermission(WrappedComponent, allowedFor) {
  return props => (
    <PermissionValidator allowedFor={allowedFor} shouldHide={false}>
      {({ isAllowed }) =>
        isAllowed ? <WrappedComponent {...props} /> : <NotAllowedScreen />
      }
    </PermissionValidator>
  );
}

export default withPermission;
