/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CMBSUnderwritersForm from "./CMBSUnderwritersForm";
import CMBSUnderwritersTable from "./CMBSUnderwritersTable";
import useAsync from "hooks/useAsync";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadCMBSUnderwritersService from "services/cmbs/loadCMBSUnderwritersService";
import browserHistory from "browserHistory";

function CMBSUnderwritersTab({ deal = {}, isNew }) {
  const [underwriterToEdit, setUnderwriterToEdit] = React.useState(null);

  const dealCode = deal.deal_code;
  const dealSize = deal.deal_amount;

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadCMBSUnderwritersService,
  });

  React.useEffect(() => {
    if (dealCode) {
      fetch({ dealCode });
    }
  }, []);

  const bookRunnerCredit = data.reduce(
    (acc, underwriter) =>
      underwriter.entity_type === "B" ? (acc += underwriter.amount) : acc,
    0,
  );

  return (
    <React.Fragment>
      <Card>
        <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
          <TwoColumnFormLayout
            title={underwriterToEdit ? "Edit Underwriter" : "Add Underwriter"}
          >
            <CMBSUnderwritersForm
              key={underwriterToEdit && underwriterToEdit.id}
              onCancel={() => setUnderwriterToEdit(null)}
              dealSize={dealSize}
              bookRunnerCredit={bookRunnerCredit}
              onData={() => {
                setUnderwriterToEdit(null);
                fetch({ dealCode });
              }}
              initialValues={{
                dealCode,
                ...(underwriterToEdit
                  ? {
                      ...underwriterToEdit,
                      entity_id: underwriterToEdit.entity_id.entity_id,
                    }
                  : {}),
              }}
            />
          </TwoColumnFormLayout>
          <Divider style={{ margin: "30px 0 30px 0" }} />
        </PermissionValidator>
        <TwoColumnFormLayout title="Underwriters">
          <CMBSUnderwritersTable
            dealSize={dealSize}
            data={data}
            pending={pending}
            onReload={() => fetch({ dealCode })}
            onRequestEdit={(underwriter) => setUnderwriterToEdit(underwriter)}
          />
        </TwoColumnFormLayout>
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Next"
                onClick={() =>
                  browserHistory.push(
                    `/cmbs/new/${deal.deal_code}/rating-agencies`,
                  )
                }
              />
            </Row>
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
}

CMBSUnderwritersTab.propTypes = {
  isActive: PropTypes.bool,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool,
};

CMBSUnderwritersTab.defaultProps = {
  isActive: false,
  isNew: true,
};

export default CMBSUnderwritersTab;
