import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import InputGroup from "components/InputGroup";
import Form from "components/Form";
import Separator from "components/Separator";
import Button from "components/Button";
import { Row, Box } from "jsxstyle";
import FormGroup from "components/FormGroup";
import AdvancedSearch from "components/AdvancedSearch";
import moment from "moment";
import CurrentFiltersList from "components/CurrentFiltersList";
import Switch from "components/Switch";
import DateRange from "components/DateRange";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";
import SelectSector from "components/SelectSector";

function RESaleFilters({ onChange, currentFilters }) {
  const [isAdvancedFiltersOpen, setAdvancedFiltersOpen] = React.useState(false);
  const [saleIdValue, setSaleIdValue] = React.useState(currentFilters.sale_id);
  const advancedSearchFilters = omit(currentFilters, ["sale_id"]);
  React.useEffect(() => {
    if (!currentFilters.sale_id) {
      setSaleIdValue("");
    }
  }, [currentFilters.sale_id]);

  return (
    <>
      <Row alignItems="flex-start">
        <Form
          onSubmit={() => {
            onChange({ sale_id: saleIdValue });
          }}
        >
          <Row width={350} alignItems="flex-start">
            <Box flex={1}>
              <FormGroup>
                <InputGroup
                  leftIcon="search"
                  placeholder="Search by sale id"
                  value={saleIdValue}
                  onChange={(value) => setSaleIdValue(value)}
                  fullWidth
                />
              </FormGroup>
            </Box>
            <Separator />
            <Button
              intent="primary"
              icon="search"
              text="Search"
              type="submit"
            />
          </Row>
        </Form>
        <Separator />
        <Row alignItems="center">
          <AdvancedSearch
            currentFilters={advancedSearchFilters}
            isOpen={isAdvancedFiltersOpen}
            onRequestOpen={() => setAdvancedFiltersOpen(true)}
            onRequestClose={() => setAdvancedFiltersOpen(false)}
            onSubmit={(values) =>
              onChange({
                ...values,
                sale_date:
                  values.sale_date &&
                  values.sale_date.map((date) =>
                    moment(date).format("YYYY-MM-DD"),
                  ),
              })
            }
          >
            {({ getValue, setValue }) => (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, 300px)"
                gridGap={16}
              >
                <FormGroup label="Closed">
                  <Switch
                    value={getValue("closed")}
                    onChange={() => setValue("closed", !getValue("closed"))}
                  />
                </FormGroup>
                <FormGroup label="Portfolio">
                  <Switch
                    value={getValue("portfolio")}
                    onChange={(value) =>
                      setValue("portfolio", !getValue("portfolio"))
                    }
                  />
                </FormGroup>
                <FormGroup label="Property name / address">
                  <InputGroup
                    value={getValue("property")}
                    onChange={(value) => setValue("property", value)}
                  />
                </FormGroup>
                <FormGroup label="Portfolio name">
                  <InputGroup
                    value={getValue("portfolio_name")}
                    onChange={(value) => setValue("portfolio_name", value)}
                  />
                </FormGroup>
                <FormGroup label="Sector">
                  <SelectSector
                    onSelect={(value) => setValue("sector_id", value)}
                    value={getValue("sector_id")}
                  />
                </FormGroup>
                <FormGroup label="Price">
                  <Row>
                    <CurrencyInputGroup
                      leftIcon="dollar"
                      value={getValue("price_lower_bound")}
                      onChange={(value) => setValue("price_lower_bound", value)}
                      placeholder="Min"
                    />
                    <Box marginLeft={15}>
                      <CurrencyInputGroup
                        leftIcon="dollar"
                        value={getValue("price_upper_bound")}
                        onChange={(value) =>
                          setValue("price_upper_bound", value)
                        }
                        placeholder="Max"
                      />
                    </Box>
                  </Row>
                </FormGroup>
                <FormGroup label="City">
                  <InputGroup
                    value={getValue("property_city")}
                    onChange={(value) => setValue("property_city", value)}
                  />
                </FormGroup>
                <FormGroup label="State">
                  <SelectNorthAmericanState
                    value={getValue("property_state")}
                    onSelect={(value) => setValue("property_state", value)}
                  />
                </FormGroup>
                <FormGroup label="Market">
                  <InputGroup
                    value={getValue("property_market")}
                    onChange={(value) => setValue("property_market", value)}
                  />
                </FormGroup>
                <FormGroup label="Seller's broker">
                  <SelectEntityMaster
                    onSelect={(value) => setValue("broker_entity_id", value)}
                    value={getValue("broker_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Buyer">
                  <SelectEntityMaster
                    onSelect={(value) => setValue("buyer_entity_id", value)}
                    value={getValue("buyer_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Seller">
                  <SelectEntityMaster
                    onSelect={(value) => setValue("seller_entity_id", value)}
                    value={getValue("seller_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Sale date">
                  <DateRange
                    onChange={(value) => setValue("sale_date", value)}
                    value={getValue("sale_date")}
                  />
                </FormGroup>
              </Box>
            )}
          </AdvancedSearch>
          <Separator />
          <Button
            icon="remove"
            minimal
            onClick={() => onChange({})}
            text="Clear filters"
          />
        </Row>
      </Row>
      <CurrentFiltersList
        filters={currentFilters}
        onRemove={(key) => {
          onChange({
            ...currentFilters,
            [key]: null,
          });
        }}
      />
    </>
  );
}

RESaleFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default RESaleFilters;
