import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteABSService from "services/abs/deleteABSService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteABS({ abs, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteABSService,
    onError: error => {
      showError("Error deleting ABS");
    },
    onData: () => {
      showSuccess("ABS deleted successfully.");
      onDelete();
    }
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ dealCode: abs.deal_code })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the ABS <b>{abs.deal_code}</b>?
      </Alert>
    </>
  );
}

DeleteABS.propTypes = {
  abs: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteABS.defaultProps = {
  abs: {}
};

export default DeleteABS;
