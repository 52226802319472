import PropTypes from "prop-types";

function CurrencyFormat({ amount, maximumFractionDigits = 4}) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits,
  }).format(!amount ? 0 : amount);
}

CurrencyFormat.propTypes = {
  amount: PropTypes.number,
};

CurrencyFormat.defaultProps = {
  amount: 0,
};

export default CurrencyFormat;
