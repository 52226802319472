import React from "react";
import { Flex, Box } from "jsxstyle";
import Card from "components/Card";
import Logo from "components/Logo";
import Separator from "./Separator";

function PublicLayout({ children }) {
  return (
    <Flex height="100%" flexDirection="column">
      <Flex flexGrow={1} alignItems="center" justifyContent="center">
        <Flex flexDirection="column" marginTop={-80}>
          <Logo height={80} />
          <Separator size="lg" />
          <Card>
            <Box width={350}>{children}</Box>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default PublicLayout;
