export default [
  {
    title: "ID",
    dataIndex: "entity_id",
    key: "entity_id",
    width: 120,
    fixed: "left",
  },
  {
    title: "Editorial name",
    dataIndex: "editorial_name",
    key: "editorial_name",
    width: 240,
  },
  {
    title: "Full name",
    dataIndex: "long_name",
    key: "long_name",
    width: 240,
  },
  {
    title: "Editorial description",
    dataIndex: "editorial_description",
    key: "editorial_description",
    width: 240,
  },
  {
    title: "Entity type",
    dataIndex: "entity_type.long_name",
    key: "entity_type.long_name",
    width: 240,
  },
  {
    title: "Parent",
    dataIndex: "parent_entity.long_name",
    key: "parent_entity.long_name",
    width: 240,
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: 240,
  },
  {
    title: "Postal Code",
    dataIndex: "postal_code",
    key: "postal_code",
    width: 150,
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
    width: 240,
  },
  {
    title: "State",
    dataIndex: "state.state_name",
    key: "state.state_code",
    width: 240,
  },
  {
    title: "Country",
    dataIndex: "country_code.country_code",
    key: "country_code.country_code",
    width: 120,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width: 150,
  },
  {
    title: "Website",
    dataIndex: "website",
    key: "website",
    width: 240,
  },
  {
    title: "Year founded",
    dataIndex: "year_founded",
    key: "year_founded",
    width: 150,
  },
  {
    title: "Search term",
    dataIndex: "search_term",
    key: "search_term",
    width: 240,
  },
];
