import fetch from "../fetch";

export default ({ values, dealCode, trancheId }) =>
  fetch({
    path: `/abs/deals/${dealCode}/tranche-prices${
      trancheId ? `/${trancheId}` : ""
    }`,
    method: trancheId ? "PUT" : "POST",
    data: {
      tranche_class: values.tranche_class,
      class_amount: values.class_amount,
      notional_amount: values.notional_amount,
      denomination: values.denomination,
      denomination_amount: values.denomination_amount,
      subord_percent: values.subord_percent,
      coupon: values.coupon,
      price: values.price,
      yield: values.yield,
      maturity_date: values.maturity_date,
      wal: values.wal,
      spread: values.spread,
      benchmark: values.benchmark,
      rate: values.rate
    }
  });
