import React from "react";
import PropTypes from "prop-types";
import { Col } from "jsxstyle";

const getPaddingBySize = (size) =>
  ({
    xs: [10, 5],
    sm: [15, 10],
    md: [20, 15],
    lg: [25, 20],
    xl: [30, 25],
  }[size]);

const getPaddingByDirection = (direction, padding) =>
  ({
    vertical: `${padding}px 0`,
    horizontal: `0 ${padding}px`,
    default: padding,
  }[direction]);

const InnerSpacing = ({ size, children, direction }) => {
  return (
    <Col
      flexGrow={1}
      mediaQueries={{
        sm: "screen and (min-width: 360px) and (max-width: 991.98px)",
        lg: "screen and (min-width: 992px)",
      }}
      lgPadding={getPaddingByDirection(direction, getPaddingBySize(size)[0])}
      smPadding={getPaddingByDirection(direction, getPaddingBySize(size)[1])}
    >
      {children}
    </Col>
  );
};

InnerSpacing.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  direction: PropTypes.oneOf(["vertical", "horizontal", "default"]),
};

InnerSpacing.defaultProps = {
  size: "md",
  direction: "default",
};

export default InnerSpacing;
