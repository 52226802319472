import React from "react";
import queryString from "query-string";
import history from "browserHistory";

function useQueryStringFilters({ defaultFilters = {} } = {}) {
  const getQuery = () => queryString.parse(window.location.search);

  const [filters, setFilters] = React.useState({
    ...defaultFilters,
    ...getQuery()
  });

  React.useEffect(() => {
    setQuery(filters);
  }, [filters]);

  const setQuery = (query = {}) => {
    const search = queryString.stringify(query);
    history.push({ search });
  };

  const mergeQuery = (query = {}) => {
    const currentQuery = queryString.parse(window.location.search);
    const search = queryString.stringify({
      ...currentQuery,
      ...query
    });
    history.push({ search });
  };

  return { setQuery, mergeQuery, getQuery, filters, setFilters };
}

export default useQueryStringFilters;
