import React from "react";
import PropTypes from "prop-types";
import InputGroup from "../../components/InputGroup";
import Form from "components/Form";
import useQueryStringFilters from "hooks/useQueryStringFilters";
import SelectCountryCode from "components/SelectCountryCode";
import SelectEntityType from "components/SelectEntityType";
import SelectEntityMaster from "components/SelectEntityMaster";
import Separator from "components/Separator";
import Grid from "components/FormGrid";
import Button from "components/Button";
import FormGroup from "components/FormGroup";
import { Row } from "jsxstyle";
import useForm from "hooks/useForm";

function EntityMasterFilters({ onChange }) {
  const { getQuery } = useQueryStringFilters();
  const query = getQuery();
  const { getValue, setValue, clear, submit, clearCount } = useForm({
    initialValues: {
      criteria: query.criteria,
      country_code: query.country_code,
      parent_entity: query.parent_entity,
      entity_type: query.entity_type
    },
    onSubmit: onChange
  });

  React.useEffect(() => {
    if (clearCount) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount]);

  return (
    <Form onSubmit={submit}>
      <Grid>
        <Grid.Item>
          <FormGroup>
            <InputGroup
              leftIcon="search"
              placeholder="Search..."
              value={getValue("criteria")}
              onChange={value => setValue("criteria", value)}
              fullWidth
            />
          </FormGroup>
        </Grid.Item>
        <Grid.Item>
          <FormGroup>
            <SelectCountryCode
              placeholder="Search by Country Code"
              onSelect={value => setValue("country_code", value)}
              value={getValue("country_code")}
            />
          </FormGroup>
        </Grid.Item>
        <Grid.Item>
          <FormGroup>
            <SelectEntityType
              placeholder="Search by Entity Type"
              onSelect={value => setValue("entity_type", value)}
              value={getValue("entity_type")}
            />
          </FormGroup>
        </Grid.Item>
        <Grid.Item>
          <FormGroup>
            <SelectEntityMaster
              placeholder="Search by Parent Entity"
              onSelect={value => setValue("parent_entity", value)}
              value={getValue("parent_entity")}
            />
          </FormGroup>
        </Grid.Item>
        <Grid.Item>
          <Row>
            <Button
              intent="primary"
              icon="search"
              text="Search"
              type="submit"
            />
            <Separator />
            <Button intent="none" text="Clear filters" onClick={clear} />
          </Row>
        </Grid.Item>
      </Grid>
    </Form>
  );
}

EntityMasterFilters.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default EntityMasterFilters;
