import React from "react";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import Separator from "components/Separator";
import Form from "components/Form";
import Button from "components/Button";
import CLOFields from "./CLOFields";
import saveCLODealService from "services/clo/saveCLODealService";
import isRequired from "validators/isRequired";

function EditCLODealTab({ initialValues, onSubmit }) {
  const { showSuccess, showError } = useToast();

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveCLODealService,
    onData: (deal) => {
      showSuccess("CLO deal saved.");
      onSubmit(deal);
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues,
    validations: {
      deal_type: [isRequired],
      pricing_date: [isRequired],
      issuer: [isRequired],
      deal_amount: [isRequired],
    },
    onSubmit: (values) => fetch({ values, dealCode: values.deal_code }),
  });

  return (
    <Form onSubmit={() => submit()}>
      <TwoColumnFormLayout size="sm" title="General Information">
        <CLOFields
          getValue={getValue}
          setValue={setValue}
          getError={getError}
          isSubmitting={isSubmitting}
          isNew={false}
        />
      </TwoColumnFormLayout>
      <Separator size="xl" />
      <Row justifyContent="flex-end">
        <Button
          type="submit"
          text="Save"
          intent="primary"
          disabled={isSubmitting}
        />
      </Row>
    </Form>
  );
}

export default EditCLODealTab;
