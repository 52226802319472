import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import DateInputGroup from "components/DateInputGroup";
import Switch from "components/Switch";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import SelectEntityType from "components/SelectEntityType";
import SelectHYFundType from "components/SelectHYFundType";
import IntegerInputGroup from "components/IntegerInputGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import SelectCurrencyCode from "components/SelectCurrencyCode";
import SelectEntityMaster from "components/SelectEntityMaster";
import PermissionValidator from "components/PermissionValidator";
import SelectCreatableEntityMaster from "components/SelectCreatableEntityMaster";

function HYFundBasicInfoFields({
  getValue,
  setValue,
  getError,
  isSubmitting,
  isNew,
}) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <FormGrid>
          <FormGrid.Item>
            <FormGroup
              label="Fund ID"
              error={getError("fund_id")}
              disabled={isSubmitting || !isNew}
            >
              <SelectCreatableEntityMaster
                onSelect={(value) => {
                  setValue(
                    "fund_id",
                    value
                      ? {
                          entity_id: get(value, "entity_id"),
                          long_name: get(value, "long_name"),
                          editorial_name: get(value, "editorial_name"),
                          editorial_description: get(
                            value,
                            "editorial_description",
                          ),
                          address: get(value, "address"),
                          city: get(value, "city"),
                          postal_code: get(value, "postal_code"),
                          phone: get(value, "phone"),
                          website: get(value, "website"),
                          year_founded: get(value, "year_founded"),
                          search_term: get(value, "search_term"),
                          entity_type: get(value, "entity_type.entity_code"),
                          parent_entity: get(value, "parent_entity.entity_id"),
                          country_code: get(value, "country_code.country_code"),
                          state: get(value, "state.state_code"),
                          date_start: get(value, "date_start"),
                        }
                      : "",
                  );
                }}
                onCreate={(value) => {
                  setValue("fund_id", {
                    entity_id: value,
                  });
                }}
                value={getValue("fund_id")}
                error={getError("fund_id")}
                readOnly={!isAllowed}
                disabled={isSubmitting || !isNew}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Fund type"
              disabled={isSubmitting}
              error={getError("fund_type")}
            >
              <SelectHYFundType
                onSelect={(value) => setValue("fund_type", value)}
                value={getValue("fund_type")}
                error={getError("fund_type")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Full name"
              required
              error={getError("fund_id.long_name")}
              disabled={isSubmitting}
            >
              <InputGroup
                intent={getError("fund_id.long_name") ? "danger" : null}
                value={getValue("fund_id.long_name")}
                onChange={(value) => setValue("fund_id.long_name", value)}
                readOnly={!isAllowed}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Editorial name"
              required
              error={getError("fund_id.editorial_name")}
              readOnly={!isAllowed}
              disabled={isSubmitting}
            >
              <InputGroup
                intent={getError("fund_id.editorial_name") ? "danger" : null}
                value={getValue("fund_id.editorial_name")}
                onChange={(value) => setValue("fund_id.editorial_name", value)}
                readOnly={!isAllowed}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Editorial description"
              error={getError("fund_id.editorial_description")}
              disabled={isSubmitting}
            >
              <InputGroup
                intent={
                  getError("fund_id.editorial_description") ? "danger" : null
                }
                value={getValue("fund_id.editorial_description")}
                onChange={(value) =>
                  setValue("fund_id.editorial_description", value)
                }
                readOnly={!isAllowed}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Entity type"
              required
              error={getError("fund_id.entity_type")}
              disabled={isSubmitting}
            >
              <SelectEntityType
                onSelect={(value) => {
                  setValue("fund_id.entity_type", value);
                }}
                value={getValue("fund_id.entity_type")}
                error={getError("fund_id.entity_type")}
                readOnly={!isAllowed}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Parent Entity"
              error={getError("fund_id.parent_entity")}
              disabled={isSubmitting}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("fund_id.parent_entity", value)}
                value={getValue("fund_id.parent_entity")}
                error={getError("fund_id.parent_entity")}
                readOnly={!isAllowed}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Website"
              error={getError("fund_id.website")}
              disabled={isSubmitting}
            >
              <InputGroup
                intent={getError("fund_id.website") ? "danger" : null}
                value={getValue("fund_id.website")}
                onChange={(value) => setValue("fund_id.website", value)}
                maxLength={100}
                readOnly={!isAllowed}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Target Net Irr Low"
              disabled={isSubmitting}
              error={getError("target_net_irr_low")}
            >
              <IntegerInputGroup
                intent={getError("target_net_irr_low") ? "danger" : null}
                value={getValue("target_net_irr_low")}
                onChange={(value) => setValue("target_net_irr_low", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Target Net Irr High"
              disabled={isSubmitting}
              error={getError("target_net_irr_high")}
            >
              <IntegerInputGroup
                intent={getError("target_net_irr_high") ? "danger" : null}
                value={getValue("target_net_irr_high")}
                onChange={(value) => setValue("target_net_irr_high", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Active"
              disabled={isSubmitting}
              error={getError("active")}
            >
              <Switch
                value={getValue("active")}
                onChange={() => setValue("active", !getValue("active"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Closed Year"
              disabled={isSubmitting}
              error={getError("closed_year")}
            >
              <IntegerInputGroup
                intent={getError("closed_year") ? "danger" : null}
                value={getValue("closed_year")}
                onChange={(value) => setValue("closed_year", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination"
              disabled={isSubmitting}
              error={getError("denomination")}
            >
              <SelectCurrencyCode
                onSelect={(value) => setValue("denomination", value)}
                value={getValue("denomination")}
                error={getError("denomination")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Goal Amount Current"
              disabled={isSubmitting}
              error={getError("goal_amount_current")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("goal_amount_current") ? "danger" : null}
                value={getValue("goal_amount_current")}
                onChange={(value) => setValue("goal_amount_current", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Goal Amount Initial"
              disabled={isSubmitting}
              error={getError("goal_amount_initial")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("goal_amount_initial") ? "danger" : null}
                value={getValue("goal_amount_initial")}
                onChange={(value) => setValue("goal_amount_initial", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Raised Amount"
              disabled={isSubmitting}
              error={getError("raised_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("raised_amount") ? "danger" : null}
                value={getValue("raised_amount")}
                onChange={(value) => setValue("raised_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Invested Amount"
              disabled={isSubmitting}
              error={getError("invested_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("invested_amount") ? "danger" : null}
                value={getValue("invested_amount")}
                onChange={(value) => setValue("invested_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Released Amount"
              disabled={isSubmitting}
              error={getError("released_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("released_amount") ? "danger" : null}
                value={getValue("released_amount")}
                onChange={(value) => setValue("released_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Builder"
              disabled={isSubmitting}
              error={getError("builder")}
            >
              <Switch
                value={getValue("builder")}
                onChange={() => setValue("builder", !getValue("builder"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Debt Investor"
              disabled={isSubmitting}
              error={getError("debt_investor")}
            >
              <Switch
                value={getValue("debt_investor")}
                onChange={() =>
                  setValue("debt_investor", !getValue("debt_investor"))
                }
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
                <FormGroup
                  label="Effective date"
                  disabled={isSubmitting || !isAllowed}
                  error={getError("fund_id.date_start")}
                  required
                >
                  <DateInputGroup
                    intent={getError("fund_id.date_start") ? "danger" : null}
                    value={getValue("fund_id.date_start")}
                    onChange={(value) => setValue("fund_id.date_start", new Date(value).toISOString().split('T')[0])}
                  />
                </FormGroup>
              </FormGrid.Item>
        </FormGrid>
      )}
    </PermissionValidator>
  );
}

HYFundBasicInfoFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isNew: PropTypes.bool,
};

HYFundBasicInfoFields.defaultProps = {
  isSubmitting: false,
  isNew: true,
};

export default HYFundBasicInfoFields;
