import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteCMBSServicer from "services/cmbs/deleteCMBSServicer";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteCMBSServicer({ servicer, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteCMBSServicer,
    onData: () => {
      showSuccess("Servicer deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Servicer");
    }
  });

  return (
    <React.Fragment>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() =>
          fetch({ dealCode: servicer.deal_code, id: servicer.id })
        }
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Servicer{" "}
        <b>{servicer.servicer.long_name}</b>?
      </Alert>
    </React.Fragment>
  );
}

DeleteCMBSServicer.propTypes = {
  servicer: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteCMBSServicer.defaultProps = {
  servicer: {}
};

export default DeleteCMBSServicer;
