/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import history from "browserHistory";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import getHYManagerByIdService from "services/hy/getHYManagerByIdService";
import ScreenLoading from "components/ScreenLoading";
import HYManagerForm from "./HYManagerForm";

function HYManagerFormScreen({ match }) {
  const { action, id } = match.params;
  const { showError } = useToast();
  const [manager, setManager] = React.useState();

  const [fetch, pending] = useAsync({
    promiseFn: getHYManagerByIdService,
    onData: (data) => setManager(data),
    onError: (error) => {
      console.log(error);
      showError("An error happened.");
      history.push("/hy/manager");
    },
  });

  React.useEffect(() => {
    if (id) {
      fetch({ id });
    }
  }, []);

  if (id && (pending || !manager)) return <ScreenLoading />;
  if (action !== "new" && action !== "edit") return <></>;

  return <HYManagerForm initialValues={manager} />;
}

export default HYManagerFormScreen;
