import React from "react";
import PropTypes from "prop-types";
import { Box, Row, Col } from "jsxstyle";
import InputGroup from "components/InputGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import DecimalInputGroup from "components/DecimalInputGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import Switch from "components/Switch";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import SelectBenchmark from "components/SelectBenchmark";
import CurrencyFormat from "components/CurrencyFormat";

function CMBSLoanAndSecurityDetailsFields({
  getValue,
  setValue,
  getError,
  isSubmitting,
}) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Col>
          <FormGrid>
            <Box className="col-2">
              <FormGroup
                label="Government facility"
                disabled={isSubmitting}
                error={getError("govt_facility")}
              >
                <Switch
                  value={getValue("govt_facility")}
                  onChange={() =>
                    setValue("govt_facility", !getValue("govt_facility"))
                  }
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </Box>

            <Box className="col-2">
              <FormGroup
                label="EU risk retention"
                disabled={isSubmitting}
                error={getError("risk_retention_eu")}
              >
                <Switch
                  value={getValue("risk_retention_eu")}
                  onChange={() =>
                    setValue(
                      "risk_retention_eu",
                      !getValue("risk_retention_eu"),
                    )
                  }
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </Box>

            <FormGrid.Item>
              <FormGroup
                label="Offering types"
                disabled={isSubmitting}
                error={getError("offering_type_all")}
              >
                <InputGroup
                  intent={getError("offering_type_all") ? "danger" : null}
                  value={getValue("offering_type_all")}
                  onChange={(value) => setValue("offering_type_all", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                  maxLength={50}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Fixed loans amount"
                disabled={isSubmitting}
                error={getError("fixed_loans_amount")}
              >
                <CurrencyInputGroup
                  leftIcon="dollar"
                  intent={getError("fixed_loans_amount") ? "danger" : null}
                  value={getValue("fixed_loans_amount")}
                  onChange={(value) => setValue("fixed_loans_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Floating loans amount"
                disabled={isSubmitting}
                error={getError("floating_loans_amount")}
              >
                <CurrencyInputGroup
                  leftIcon="dollar"
                  intent={getError("floating_loans_amount") ? "danger" : null}
                  value={getValue("floating_loans_amount")}
                  onChange={(value) => setValue("floating_loans_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Fixed bonds amount"
                disabled={isSubmitting}
                error={getError("fixed_bonds_amount")}
              >
                <CurrencyInputGroup
                  leftIcon="dollar"
                  intent={getError("fixed_bonds_amount") ? "danger" : null}
                  value={getValue("fixed_bonds_amount")}
                  onChange={(value) => setValue("fixed_bonds_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Floating bonds amount"
                disabled={isSubmitting}
                error={getError("floating_bonds_amount")}
              >
                <CurrencyInputGroup
                  leftIcon="dollar"
                  intent={getError("floating_bonds_amount") ? "danger" : null}
                  value={getValue("floating_bonds_amount")}
                  onChange={(value) => setValue("floating_bonds_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Floating benchmark"
                disabled={isSubmitting}
                error={getError("floating_benchmark")}
              >
                <SelectBenchmark
                  onSelect={(value) => setValue("floating_benchmark", value)}
                  value={getValue("floating_benchmark")}
                  error={getError("floating_benchmark")}
                  params={{ type: "floating" }}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Floating margin"
                disabled={isSubmitting}
                error={getError("floating_margin")}
              >
                <DecimalInputGroup
                  intent={getError("floating_margin") ? "danger" : null}
                  value={getValue("floating_margin")}
                  onChange={(value) => setValue("floating_margin", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Weighted-average coupon"
                disabled={isSubmitting}
                error={getError("wac")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("wac") ? "danger" : null}
                  value={getValue("wac")}
                  onChange={(value) => setValue("wac", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Weighted-average remaining term to maturity"
                disabled={isSubmitting}
                error={getError("wam")}
              >
                <IntegerInputGroup
                  intent={getError("wam") ? "danger" : null}
                  value={getValue("wam")}
                  onChange={(value) => setValue("wam", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Weighted-average original term to maturity"
                disabled={isSubmitting}
                error={getError("wam_orig")}
              >
                <IntegerInputGroup
                  intent={getError("wam_orig") ? "danger" : null}
                  value={getValue("wam_orig")}
                  onChange={(value) => setValue("wam_orig", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Weighted-average remaining term to maturity (with extensions)"
                disabled={isSubmitting}
                error={getError("wam_ext")}
              >
                <IntegerInputGroup
                  intent={getError("wam_ext") ? "danger" : null}
                  value={getValue("wam_ext")}
                  onChange={(value) => setValue("wam_ext", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Weighted-average original term to maturity (with extensions)"
                disabled={isSubmitting}
                error={getError("wam_orig_ext")}
              >
                <IntegerInputGroup
                  intent={getError("wam_orig_ext") ? "danger" : null}
                  value={getValue("wam_orig_ext")}
                  onChange={(value) => setValue("wam_orig_ext", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Loan-to-value"
                disabled={isSubmitting}
                error={getError("ltv")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("ltv") ? "danger" : null}
                  value={getValue("ltv")}
                  onChange={(value) => setValue("ltv", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Debt-service coverage ratio, by net cash flow"
                disabled={isSubmitting}
                error={getError("dsc")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("dsc") ? "danger" : null}
                  value={getValue("dsc")}
                  onChange={(value) => setValue("dsc", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Debt-service coverage ratio, by net operating income"
                disabled={isSubmitting}
                error={getError("dsc_noi")}
              >
                <DecimalInputGroup
                  intent={getError("dsc_noi") ? "danger" : null}
                  value={getValue("dsc_noi")}
                  onChange={(value) => setValue("dsc_noi", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Debt yield, by net cash flow"
                disabled={isSubmitting}
                error={getError("dy_ncf")}
              >
                <DecimalInputGroup
                  intent={getError("dy_ncf") ? "danger" : null}
                  value={getValue("dy_ncf")}
                  onChange={(value) => setValue("dy_ncf", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Debt yield, by net operating income"
                disabled={isSubmitting}
                error={getError("dy_noi")}
              >
                <DecimalInputGroup
                  intent={getError("dy_noi") ? "danger" : null}
                  value={getValue("dy_noi")}
                  onChange={(value) => setValue("dy_noi", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Depositor"
                disabled={isSubmitting}
                error={getError("depositor")}
              >
                <SelectEntityMaster
                  onSelect={(value) => setValue("depositor", value)}
                  value={getValue("depositor")}
                  error={getError("depositor")}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Offered amount"
                disabled={isSubmitting}
                error={getError("offered_amount")}
              >
                <CurrencyInputGroup
                  leftIcon="dollar"
                  intent={getError("offered_amount") ? "danger" : null}
                  value={getValue("offered_amount")}
                  onChange={(value) => setValue("offered_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Nonoffered amount"
                disabled={isSubmitting}
                error={getError("nonoffered_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("nonoffered_amount") ? "danger" : null}
                  value={getValue("nonoffered_amount")}
                  onChange={(value) => setValue("nonoffered_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Risk retention amount"
                disabled={isSubmitting}
                error={getError("risk_retention_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("risk_retention_amount") ? "danger" : null}
                  value={getValue("risk_retention_amount")}
                  onChange={(value) => setValue("risk_retention_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Pooled amount"
                disabled={isSubmitting}
                error={getError("pooled_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("pooled_amount") ? "danger" : null}
                  value={getValue("pooled_amount")}
                  onChange={(value) => setValue("pooled_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Number of loan pools"
                disabled={isSubmitting}
                error={getError("pooled_num")}
              >
                <IntegerInputGroup
                  intent={getError("pooled_num") ? "danger" : null}
                  value={getValue("pooled_num")}
                  onChange={(value) => setValue("pooled_num", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Rake amount"
                disabled={isSubmitting}
                error={getError("rake_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("rake_amount") ? "danger" : null}
                  value={getValue("rake_amount")}
                  onChange={(value) => setValue("rake_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Number of rake portions"
                disabled={isSubmitting}
                error={getError("rake_num")}
              >
                <IntegerInputGroup
                  intent={getError("rake_num") ? "danger" : null}
                  value={getValue("rake_num")}
                  onChange={(value) => setValue("rake_num", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Refinance amount"
                disabled={isSubmitting}
                error={getError("refi_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("refi_amount") ? "danger" : null}
                  value={getValue("refi_amount")}
                  onChange={(value) => setValue("refi_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Acquisition amount"
                disabled={isSubmitting}
                error={getError("acquisition_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("acquisition_amount") ? "danger" : null}
                  value={getValue("acquisition_amount")}
                  onChange={(value) => setValue("acquisition_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Recapitalization amount"
                disabled={isSubmitting}
                error={getError("recap_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("recap_amount") ? "danger" : null}
                  value={getValue("recap_amount")}
                  onChange={(value) => setValue("recap_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Full IO amount"
                disabled={isSubmitting}
                error={getError("full_io_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("full_io_amount") ? "danger" : null}
                  value={getValue("full_io_amount")}
                  onChange={(value) => setValue("full_io_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Partial IO amount"
                disabled={isSubmitting}
                error={getError("partial_io_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("partial_io_amount") ? "danger" : null}
                  value={getValue("partial_io_amount")}
                  onChange={(value) => setValue("partial_io_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Amortizing amount"
                disabled={isSubmitting}
                error={getError("amortizing_amount")}
              >
                <CurrencyInputGroup
                  intent={getError("amortizing_amount") ? "danger" : null}
                  value={getValue("amortizing_amount")}
                  onChange={(value) => setValue("amortizing_amount", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
          </FormGrid>
          <Row justifyContent="flex-end" component="p" fontSize={16}>
            Deal amount:&nbsp;
            <Box component="strong">
              <CurrencyFormat amount={getValue("deal_amount")} />
            </Box>
          </Row>
        </Col>
      )}
    </PermissionValidator>
  );
}

CMBSLoanAndSecurityDetailsFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

CMBSLoanAndSecurityDetailsFields.defaultProps = {
  isSubmitting: false,
};

export default CMBSLoanAndSecurityDetailsFields;
