import React from "react";
import PropTypes from "prop-types";
import { Box } from "jsxstyle";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import Select from "components/Select";
import IntegerInputGroup from "components/IntegerInputGroup";
import DecimalInputGroup from "components/DecimalInputGroup";
import Switch from "components/Switch";
import SelectSector from "components/SelectSector";
import DateInputGroup from "components/DateInputGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import SelectCurrencyCode from "components/SelectCurrencyCode";

function PropertyFields({ getValue, setValue, getError, isSubmitting }) {
  return (
    <FormGrid>
      <FormGrid.Item>
        <FormGroup
          label="Sale date"
          disabled={isSubmitting}
          error={getError("sale_date")}
          required
        >
          <DateInputGroup
            intent={getError("sale_date") ? "danger" : null}
            value={getValue("sale_date")}
            onChange={(value) => setValue("sale_date", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Denomination"
          disabled={isSubmitting}
          error={getError("currency_code")}
        >
          <SelectCurrencyCode
            onSelect={(value) => setValue("currency_code", value)}
            value={getValue("currency_code")}
            error={getError("currency_code")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Property Price"
          disabled={isSubmitting}
          error={getError("price")}
          required
        >
          <CurrencyInputGroup
            leftIcon="dollar"
            intent={getError("price") ? "danger" : null}
            value={getValue("price")}
            onChange={(value) => setValue("price", value)}
            disabled={isSubmitting}
            decimalScale={6}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Price accuracy"
          disabled={isSubmitting}
          error={getError("price_accuracy")}
          required
        >
          <Select
            items={[
              { id: "Allocated", label: "Allocated" },
              { id: "Confirmed", label: "Confirmed" },
              { id: "Estimated", label: "Estimated" },
            ]}
            onSelect={(value) => setValue("price_accuracy", value)}
            value={getValue("price_accuracy")}
            error={getError("price_accuracy")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Number of Properties"
          disabled={isSubmitting}
          error={getError("num_props")}
          required
        >
          <IntegerInputGroup
            intent={getError("num_props") ? "danger" : null}
            value={getValue("num_props")}
            onChange={(value) => setValue("num_props", value)}
            disabled={isSubmitting}
            min={0}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Conversion type"
          disabled={isSubmitting}
          error={getError("conv")}
        >
          <Select
            items={[
              { id: "TD", label: "Tear-down conversion" },
              { id: "RD", label: "Redevelopment" },
            ]}
            onSelect={(value) => setValue("conv", value)}
            value={getValue("conv")}
            error={getError("conv")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Redevelopment Sector"
          disabled={isSubmitting}
          error={getError("sector_redev_id")}
        >
          <SelectSector
            onSelect={(value) => setValue("sector_redev_id", value)}
            value={getValue("sector_redev_id")}
            error={getError("sector_redev_id")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Stake sold"
          disabled={isSubmitting}
          error={getError("stake")}
          required
        >
          <IntegerInputGroup
            intent={getError("stake") ? "danger" : null}
            value={getValue("stake")}
            onChange={(value) => setValue("stake", value)}
            buttonPosition="none"
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Price per SF"
          disabled={isSubmitting}
          error={getError("sf_price")}
        >
          <CurrencyInputGroup
            intent={getError("sf_price") ? "danger" : null}
            value={getValue("sf_price")}
            onChange={(value) => setValue("sf_price", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Price per unit"
          disabled={isSubmitting}
          error={getError("unit_price")}
        >
          <CurrencyInputGroup
            intent={getError("unit_price") ? "danger" : null}
            value={getValue("unit_price")}
            onChange={(value) => setValue("unit_price", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <Box className="col-2">
        <FormGroup
          label="Sale-leaseback"
          disabled={isSubmitting}
          error={getError("sale_leaseback")}
        >
          <Switch
            value={getValue("sale_leaseback")}
            onChange={() =>
              setValue("sale_leaseback", !getValue("sale_leaseback"))
            }
            disabled={isSubmitting}
          />
        </FormGroup>
      </Box>
      <Box className="col-2">
        <FormGroup
          label="Leasehold"
          disabled={isSubmitting}
          error={getError("leasehold")}
        >
          <Switch
            value={getValue("leasehold")}
            onChange={() => setValue("leasehold", !getValue("leasehold"))}
            disabled={isSubmitting}
          />
        </FormGroup>
      </Box>
      <FormGrid.Item>
        <FormGroup
          label="Distressed sale type"
          disabled={isSubmitting}
          error={getError("distressed")}
        >
          <Select
            items={[
              { id: "Debt purchase", label: "Debt purchase" },
              { id: "Deed-in-lieu", label: "Deed-in-lieu" },
              { id: "Distressed", label: "Distressed" },
              { id: "Foreclosure auction", label: "Foreclosure auction" },
            ]}
            onSelect={(value) => setValue("distressed", value)}
            value={getValue("distressed")}
            error={getError("distressed")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Capitalization rate"
          disabled={isSubmitting}
          error={getError("cap_rate")}
        >
          <DecimalInputGroup
            intent={getError("cap_rate") ? "danger" : null}
            value={getValue("cap_rate")}
            onChange={(value) => setValue("cap_rate", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Occupancy %"
          disabled={isSubmitting}
          error={getError("occupancy")}
        >
          <DecimalInputGroup
            intent={getError("occupancy") ? "danger" : null}
            value={getValue("occupancy")}
            onChange={(value) => setValue("occupancy", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Broker 1"
          disabled={isSubmitting}
          error={getError("broker_1")}
        >
          <SelectEntityMaster
            onSelect={(value) => setValue("broker_1", value)}
            value={getValue("broker_1")}
            error={getError("broker_1")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Broker 2"
          disabled={isSubmitting}
          error={getError("broker_2")}
        >
          <SelectEntityMaster
            onSelect={(value) => setValue("broker_2", value)}
            value={getValue("broker_2")}
            error={getError("broker_2")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <Box className="col-2">
        <FormGroup
          label="Confidential Price"
          disabled={isSubmitting}
          error={getError("flag_confidential_price")}
        >
          <Switch
            value={getValue("flag_confidential_price")}
            onChange={() =>
              setValue(
                "flag_confidential_price",
                Number(!getValue("flag_confidential_price"))
              )
            }
            disabled={isSubmitting}
          />
        </FormGroup>
      </Box>
      <Box className="col-2">
        <FormGroup
          label="Confidential Cap Rate"
          disabled={isSubmitting}
          error={getError("flag_confidential_cap_rate")}
        >
          <Switch
            value={getValue("flag_confidential_cap_rate")}
            onChange={() =>
              setValue(
                "flag_confidential_cap_rate",
                Number(!getValue("flag_confidential_cap_rate"))
              )
            }
            disabled={isSubmitting}
          />
        </FormGroup>
      </Box>
    </FormGrid>
  );
}

PropertyFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default PropertyFields;
