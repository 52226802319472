/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { pickBy } from "lodash";
import PropTypes from "prop-types";
import { Box, Row } from "jsxstyle";
import { Icon, Spinner } from "@blueprintjs/core";
import deleteREPropService from "services/re/deleteREPropService";
import getREPropertyRelationshipsByIdService from "services/re/getREPropertyRelationshipsByIdService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import Modal from "components/Modal";
import Button from "components/Button";
import Separator from "components/Separator";

function DeleteREProp({ propertyId, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetchRelationships, pendingRelationships, relationships] = useAsync({
    promiseFn: getREPropertyRelationshipsByIdService,
    onError: (error) => {
      console.log(error);
      showError("Error getting Property relationships");
    },
  });

  const [fetchDelete, deleting] = useAsync({
    promiseFn: deleteREPropService,
    onError: (error) => {
      showError("Error deleting Property");
    },
    onData: () => {
      showSuccess("Property deleted successfully.");
      onDelete();
    },
  });

  React.useEffect(() => {
    if (alertVisible) {
      fetchRelationships({ id: propertyId });
    }
  }, [alertVisible]);

  const relationsKeys = Object.keys(pickBy(relationships));
  const relations = relationsKeys.filter(
    (_, index) => index < relationsKeys.length - 1,
  );

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={deleting}
      />

      <Modal
        visible={alertVisible}
        onRequestClose={() => setAlertVisible(false)}
      >
        <Box padding={20}>
          <Row alignItems="center">
            <Icon icon="trash" iconSize={40} intent="danger" />
            <Separator />
            <Box>
              Are you sure you want to delete the Property <b>{propertyId}</b>
            </Box>
          </Row>
          <Separator size="lg" />
          {pendingRelationships ? (
            <Spinner size={20} />
          ) : (
            <React.Fragment>
              {relations.length === 0 ? (
                "The Property has no relationships and can be safely deleted."
              ) : (
                <React.Fragment>
                  <Box>Existing Relationships</Box>
                  <Separator size="xs" />
                </React.Fragment>
              )}
              {relations.map((key) => (
                <Row>
                  <Box textTransform="uppercase">
                    <b>{key}</b>:
                  </Box>
                  <Separator size="xs" />
                  <Box>{relationships[key]}</Box>
                </Row>
              ))}
            </React.Fragment>
          )}
          <Separator />
          <Row justifyContent="flex-end">
            <Button
              text="Cancel"
              onClick={() => setAlertVisible(false)}
              disabled={deleting}
            />
            <Separator size="xs" />
            <Button
              onClick={() => fetchDelete({ propertyId })}
              text="Delete"
              intent="danger"
              disabled={deleting || pendingRelationships}
            />
          </Row>
        </Box>
      </Modal>
    </>
  );
}

DeleteREProp.propTypes = {
  re: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

DeleteREProp.defaultProps = {
  re: {},
};

export default DeleteREProp;
