import fetch from "../fetch";

export default ({ id, fund_id, step, gp_split, hurdle, hurdle_type }) =>
  fetch({
    path: `/hy/funds/${fund_id}/performance-fees${id ? `/${id}` : ""}`,
    method: id ? "PUT" : "POST",
    data: {
      step,
      gp_split,
      hurdle,
      hurdle_type,
    },
  });
