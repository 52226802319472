import React from "react";
import CurrencyFormat from "components/CurrencyFormat";

export default [
  {
    title: "Offering type",
    dataIndex: "offering_type.offering_desc",
    key: "offering_type",
    sortable: true,
    width: 240,
  },
  {
    title: "Deal type",
    dataIndex: "deal_type.deal_type_desc",
    key: "deal_type",
    sortable: true,
    width: 240,
  },
  {
    title: "Abbreviation",
    dataIndex: "abbrv",
    key: "abbrv",
    width: 200,
  },
  {
    title: "Issuer",
    dataIndex: "issuer",
    key: "issuer",
    width: 240,
  },
  {
    title: "Series",
    dataIndex: "series",
    key: "series",
    width: 150,
  },
  {
    title: "Deal amount",
    dataIndex: "deal_amount",
    key: "deal_amount",
    width: 200,
    render: (deal_amount) => <CurrencyFormat amount={deal_amount || 0} />,
  },
  {
    title: "Pricing date",
    dataIndex: "pricing_date",
    key: "pricing_date",
    width: 150,
  },
  {
    title: "Operating Advisor",
    dataIndex: "operating_advisor.long_name",
    key: "operating_advisor.long_name",
    width: 240,
  },
  {
    title: "Asset reviewer",
    dataIndex: "asset_reviewer.long_name",
    key: "asset_reviewer.long_name",
    width: 240,
  },
  {
    title: "Trustee",
    dataIndex: "trustee.long_name",
    key: "trustee.long_name",
    width: 240,
  },
  {
    title: "Certificate admin",
    dataIndex: "certificate_admin.long_name",
    key: "certificate_admin.long_name",
    width: 240,
  },
  {
    title: "Underwriter counsel",
    dataIndex: "counsel_uw.long_name",
    key: "counsel_uw.long_name",
    width: 240,
  },
  {
    title: "Issuer counsel",
    dataIndex: "counsel_issuer.long_name",
    key: "counsel_issuer.long_name",
    width: 240,
  },
  {
    title: "Denomination",
    dataIndex: "denomination.editorial_curr_name",
    key: "denomination.editorial_curr_name",
    width: 240,
  },
  {
    title: "Denomination amount",
    dataIndex: "denomination_amount",
    key: "denomination_amount",
    width: 200,
  },
  {
    title: "Synthetic pool",
    dataIndex: "synthetic_pool",
    key: "synthetic_pool",
    width: 150,
  },
  {
    title: "Distribution region",
    dataIndex: "distribution_region",
    key: "distribution_region",
    width: 200,
  },
  {
    title: "Pay frequency",
    dataIndex: "pay_frequency",
    key: "pay_frequency",
    width: 150,
  },
  {
    title: "Tax treatment",
    dataIndex: "tax_treatment",
    key: "tax_treatment",
    width: 150,
  },
];
