import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteCMBSSeller from "services/cmbs/deleteCMBSSeller";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteCMBSSeller({ seller, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteCMBSSeller,
    onData: () => {
      showSuccess("Seller deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Seller");
    }
  });

  return (
    <React.Fragment>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ dealCode: seller.deal_code, id: seller.id })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Seller{" "}
        <b>{seller.seller_entity_id.long_name}</b>?
      </Alert>
    </React.Fragment>
  );
}

DeleteCMBSSeller.propTypes = {
  seller: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteCMBSSeller.defaultProps = {
  seller: {}
};

export default DeleteCMBSSeller;
