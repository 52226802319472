import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import loadRESalePropsService from "services/re/loadRESalePropsService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import REPropTableColumns from "../prop/REPropTableColumns";

function RESalePropropertyTable({ saleId }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    promiseFn: loadRESalePropsService,
    eager: true,
    onError: error => {
      showError("Error loading Sale Props");
    }
  });

  React.useEffect(() => {
    fetch({ saleId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = React.useMemo(
    () =>
      REPropTableColumns.filter(column =>
        ["property_1", "property_2", "city", "state", "prop_type"].some(
          index => index === column.dataIndex
        )
      ),
    []
  );

  return (
    <Table
      pending={pending}
      bordered={false}
      data={data}
      rowKey="property_id"
      columns={columns}
    />
  );
}

RESalePropropertyTable.propTypes = {
  sale_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default RESalePropropertyTable;
