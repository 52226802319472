import React from "react";
import { Row } from "jsxstyle";
import history from "browserHistory";
import { Button } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import isEmpty from "lodash/isEmpty";
import useForm from "hooks/useForm";
import Form from "components/Form";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import PermissionValidator from "components/PermissionValidator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import TextArea from "components/TextArea";
import Switch from "components/Switch";
import DateInputGroup from "components/DateInputGroup";
import DecimalInputGroup from "components/DecimalInputGroup";
import InputGroup from "components/InputGroup";
import saveHYFundService from "services/hy/saveHYFundService";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import Select from "components/Select";

function HYFundManagementSponsorsTab({
  match,
  onSubmit,
  initialValues,
  isNew = true,
}) {
  const { showError, showSuccess } = useToast();
  const id = match.params.id;

  const [fetch, pending] = useAsync({
    promiseFn: saveHYFundService,
    onData: (fund) => {
      showSuccess("HY Fund saved.");
      onSubmit(fund);
      if (isNew) {
        history.push(`/hy/fund/new/${id}/fee-structure`);
      }
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: isEmpty(initialValues) ? {} : initialValues,
    onSubmit: (values) => fetch({ id, values }),
    validations: {},
  });

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <TwoColumnFormLayout title="Management and Sponsors" size="sm">
          <Form onSubmit={submit}>
            <FormGrid>
              <FormGrid.Item>
                <FormGroup
                  label="Sponsor Contrib"
                  error={getError("sponsor_contrib")}
                >
                  <CurrencyInputGroup
                    leftIcon="dollar"
                    intent={getError("sponsor_contrib") ? "danger" : null}
                    value={getValue("sponsor_contrib")}
                    onChange={(value) => setValue("sponsor_contrib", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Management Fee Committed"
                  error={getError("management_fee_committed")}
                >
                  <CurrencyInputGroup
                    leftIcon="percentage"
                    intent={
                      getError("management_fee_committed") ? "danger" : null
                    }
                    value={getValue("management_fee_committed")}
                    onChange={(value) =>
                      setValue("management_fee_committed", value)
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Management Fee Invested"
                  error={getError("management_fee_invested")}
                >
                  <CurrencyInputGroup
                    leftIcon="percentage"
                    intent={
                      getError("management_fee_invested") ? "danger" : null
                    }
                    value={getValue("management_fee_invested")}
                    onChange={(value) =>
                      setValue("management_fee_invested", value)
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Preferred Return"
                  error={getError("preferred_return")}
                >
                  <CurrencyInputGroup
                    leftIcon="dollar"
                    intent={getError("preferred_return") ? "danger" : null}
                    value={getValue("preferred_return")}
                    onChange={(value) => setValue("preferred_return", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Leverage Percent %"
                  error={getError("leverage_percent")}
                >
                  <DecimalInputGroup
                    intent={getError("leverage_percent") ? "danger" : null}
                    value={getValue("leverage_percent")}
                    onChange={(value) => setValue("leverage_percent", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Suspended" error={getError("suspended")}>
                  <Select
                    items={[
                      {
                        id: "On Hold",
                        label: "On Hold",
                      },
                      {
                        id: "Canceled",
                        label: "Canceled",
                      },
                    ]}
                    value={getValue("suspended")}
                    onSelect={(value) => setValue("suspended", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Suspended Date Start"
                  error={getError("suspended_date_start")}
                >
                  <DateInputGroup
                    intent={getError("suspended_date_start") ? "danger" : null}
                    value={getValue("suspended_date_start")}
                    onChange={(value) =>
                      setValue("suspended_date_start", value)
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Suspended Date End"
                  error={getError("suspended_date_end")}
                >
                  <DateInputGroup
                    intent={getError("suspended_date_end") ? "danger" : null}
                    value={getValue("suspended_date_end")}
                    onChange={(value) => setValue("suspended_date_end", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Exclude" error={getError("exclude")}>
                  <Switch
                    value={getValue("exclude")}
                    onChange={() => setValue("exclude", !getValue("exclude"))}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Exclude Reason"
                  error={getError("exclude_reason")}
                >
                  <InputGroup
                    maxLength={100}
                    value={getValue("exclude_reason")}
                    onChange={(value) => {
                      setValue("exclude_reason", value);
                    }}
                    intent={getError("exclude_reason") ? "danger" : null}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Added Date" error={getError("added_date")}>
                  <DateInputGroup
                    intent={getError("added_date") ? "danger" : null}
                    value={getValue("added_date")}
                    onChange={(value) => setValue("added_date", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Active Date" error={getError("active_date")}>
                  <DateInputGroup
                    intent={getError("active_date") ? "danger" : null}
                    value={getValue("active_date")}
                    onChange={(value) => setValue("active_date", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Inactive Date"
                  error={getError("inactive_date")}
                >
                  <DateInputGroup
                    intent={getError("inactive_date") ? "danger" : null}
                    value={getValue("inactive_date")}
                    onChange={(value) => setValue("inactive_date", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Update Date" error={getError("update_date")}>
                  <DateInputGroup
                    intent={getError("update_date") ? "danger" : null}
                    value={getValue("update_date")}
                    onChange={(value) => setValue("update_date", value)}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Fee Note" error={getError("fee_note")}>
                  <TextArea
                    intent={getError("fee_note") ? "danger" : null}
                    value={getValue("fee_note")}
                    onChange={(value) => setValue("fee_note", value)}
                    readOnly={!isAllowed}
                    fullWidth
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Source" error={getError("source")}>
                  <TextArea
                    intent={getError("source") ? "danger" : null}
                    value={getValue("source")}
                    onChange={(value) => setValue("source", value)}
                    readOnly={!isAllowed}
                    fullWidth
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Public Note" error={getError("public_note")}>
                  <TextArea
                    intent={getError("public_note") ? "danger" : null}
                    value={getValue("public_note")}
                    onChange={(value) => setValue("public_note", value)}
                    readOnly={!isAllowed}
                    fullWidth
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Private Note"
                  error={getError("private_note")}
                >
                  <TextArea
                    intent={getError("private_note") ? "danger" : null}
                    value={getValue("private_note")}
                    onChange={(value) => setValue("private_note", value)}
                    readOnly={!isAllowed}
                    fullWidth
                  />
                </FormGroup>
              </FormGrid.Item>
            </FormGrid>
            <Row justifyContent="flex-end">
              <Button
                disabled={pending}
                loading={pending}
                type="submit"
                intent="primary"
                text={isNew ? "Next" : "Save"}
              />
            </Row>
          </Form>
        </TwoColumnFormLayout>
      )}
    </PermissionValidator>
  );
}

export default withRouter(HYFundManagementSponsorsTab);
