import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadHYFundsService from "services/hy/loadHYFundsService";

function SelectHYFund({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
  params,
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadHYFundsService}
      onSelect={onSelect}
      textPath="fund_id.long_name"
      labelPath="fund_id.entity_id"
      idPath="fund_id.entity_id"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      params={params}
    />
  );
}

SelectHYFund.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  params: PropTypes.object,
};

SelectHYFund.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  params: undefined,
};

export default SelectHYFund;
