/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import { Row } from "jsxstyle";
import PropTypes from "prop-types";
import browserHistory from "browserHistory";
import { Divider, Button } from "@blueprintjs/core";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import useAsync from "hooks/useAsync";
import loadHYFundFeeStructuresByFundIdService from "services/hy/loadHYFundFeeStructuresByFundIdService";
import Separator from "components/Separator";
import PermissionValidator from "components/PermissionValidator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import HYFundFeeStructureForm from "./HYFundFeeStructureForm";
import HYFundFeeStructureTable from "./HYFundFeeStructureTable";

function HYFundFeeStructureTab({ match, isNew }) {
  const fundId = match.params.id;
  const [feeStructureToEdit, setFeeStructureToEdit] = React.useState();

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadHYFundFeeStructuresByFundIdService,
  });

  React.useEffect(() => {
    fetch({ fundId });
  }, []);

  return (
    <React.Fragment>
      <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
        <TwoColumnFormLayout
          title={
            feeStructureToEdit ? "Edit Fee Structure" : "Add Fee Structure"
          }
        >
          <HYFundFeeStructureForm
            key={feeStructureToEdit && feeStructureToEdit.id}
            fundId={fundId}
            onCancel={() => setFeeStructureToEdit(undefined)}
            onData={() => {
              setFeeStructureToEdit(undefined);
              fetch({ fundId });
            }}
            initialValues={{
              ...feeStructureToEdit,
              hurdle_type: get(feeStructureToEdit, "hurdle_type.id"),
            }}
          />
        </TwoColumnFormLayout>
        <Divider style={{ margin: "30px 0 30px 0" }} />
      </PermissionValidator>
      <TwoColumnFormLayout title="Fee Structures">
        <HYFundFeeStructureTable
          data={data}
          pending={pending}
          onReload={() => fetch({ fundId })}
          onRequestEdit={setFeeStructureToEdit}
        />
      </TwoColumnFormLayout>
      <Separator size="lg" />
      {isNew && (
        <React.Fragment>
          <Separator size="lg" />
          <Row className="col-12" justifyContent="flex-end">
            <Button
              intent="primary"
              text="Next"
              onClick={() =>
                browserHistory.push(`/hy/fund/new/${fundId}/placement-agent`)
              }
            />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

HYFundFeeStructureTab.propTypes = {
  isNew: PropTypes.bool,
};

HYFundFeeStructureTab.defaultProps = {
  isNew: true,
};

export default withRouter(HYFundFeeStructureTab);
