import React from "react";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "components/Separator";
import PrivateLayout from "components/PrivateLayout";
import CustomTableForm from "components/custom-table/CustomTableForm";

function HYFundTableConfigurationScreen() {
  return (
    <React.Fragment>
      <PrivateLayout>
        <Heading size="md">HY Manager - Table Configuration</Heading>
        <Separator />
        <Card>
          <CustomTableForm />
        </Card>
      </PrivateLayout>
    </React.Fragment>
  );
}

export default HYFundTableConfigurationScreen;
