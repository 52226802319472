import React from "react";
import PropTypes from "prop-types";
import { Box } from "jsxstyle";

function FormGrid({ children }) {
  return <Box className="row">{children}</Box>;
}

FormGrid.Item = ({ children }) => (
  <Box className="col-xl-4 col-lg-6 col-md-12">{children}</Box>
);

FormGrid.propTypes = { children: PropTypes.arrayOf(PropTypes.node) };

export default FormGrid;
