import fetch from "../fetch";

export default ({ values, dealCode, buyerId }) =>
  fetch({
    path: `/cmbs/deals/${dealCode}/b-piece-buyers${
      buyerId ? `/${buyerId}` : ""
    }`,
    method: buyerId ? "PUT" : "POST",
    data: {
      buyer_entity_id: values.buyer_entity_id,
      b_piece_amount: values.b_piece_amount
    }
  });
