import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CMBSBPieceBuyersForm from "./CMBSBPieceBuyersForm";
import CMBSBPieceBuyersTable from "./CMBSBPieceBuyersTable";
import useAsync from "hooks/useAsync";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadCMBSBPieceBuyersService from "services/cmbs/loadCMBSBPieceBuyersService";
import browserHistory from "browserHistory";

function CMBSBPieceBuyersTab({ deal, isNew }) {
  const [buyerToEdit, setBuyerToEdit] = React.useState(null);

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadCMBSBPieceBuyersService,
  });

  const dealCode = deal.deal_code;
  const dealSize = deal.deal_amount;

  React.useEffect(() => {
    fetch({ dealCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bPieceBuyerCredit = data.reduce(
    (acc, bPieceBuyer) => acc + bPieceBuyer.b_piece_amount,
    0,
  );

  return (
    <React.Fragment>
      <Card>
        <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
          <TwoColumnFormLayout title={buyerToEdit ? "Edit Buyer" : "Add Buyer"}>
            <CMBSBPieceBuyersForm
              key={buyerToEdit && buyerToEdit.id}
              onCancel={() => setBuyerToEdit(null)}
              dealSize={dealSize}
              bPieceBuyerCredit={bPieceBuyerCredit}
              onData={() => {
                setBuyerToEdit(null);
                fetch({ dealCode });
              }}
              initialValues={{
                dealCode,
                ...(buyerToEdit
                  ? {
                      ...buyerToEdit,
                      buyer_entity_id: buyerToEdit.buyer_entity_id.entity_id,
                    }
                  : {}),
              }}
            />
          </TwoColumnFormLayout>
          <Divider style={{ margin: "30px 0 30px 0" }} />
        </PermissionValidator>
        <TwoColumnFormLayout title="Buyers">
          <CMBSBPieceBuyersTable
            dealSize={dealSize}
            data={data}
            pending={pending}
            onReload={() => fetch({ dealCode })}
            onRequestEdit={(buyer) => setBuyerToEdit(buyer)}
          />
        </TwoColumnFormLayout>
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Next"
                onClick={() =>
                  browserHistory.push(
                    `/cmbs/new/${deal.deal_code}/tranche-prices-and-ratings`,
                  )
                }
              ></Button>
            </Row>
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
}

CMBSBPieceBuyersTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool,
};

CMBSBPieceBuyersTab.defaultProps = {
  isActive: false,
  isNew: true,
};

export default CMBSBPieceBuyersTab;
