import React from "react";
import PropTypes from "prop-types";
import { NumericInput as BluePrintNumericInput } from "@blueprintjs/core";

function IntegerInputGroup({
  disabled,
  intent,
  size,
  leftIcon,
  onChange,
  placeholder,
  rightElement,
  round,
  value,
  fullWidth,
  max,
  min,
  buttonPosition,
  maxLength,
  minLength,
  readOnly
}) {
  const getMaxSafeIntegerValue = value =>
    value <= Number.MAX_SAFE_INTEGER ? value : Number.MAX_SAFE_INTEGER;
  return (
    <BluePrintNumericInput
      buttonPosition={buttonPosition}
      disabled={disabled}
      intent={intent}
      large={size === "lg"}
      small={size === "sm"}
      leftIcon={leftIcon}
      onChange={e => {
        onChange(getMaxSafeIntegerValue(e.target.value));
      }}
      onValueChange={value => onChange(getMaxSafeIntegerValue(value))}
      placeholder={placeholder}
      rightElement={rightElement}
      round={round}
      value={value ? value : undefined}
      fill={fullWidth}
      max={max}
      min={min}
      maxLength={maxLength}
      minLength={minLength}
      readOnly={readOnly}
    />
  );
}

IntegerInputGroup.propTypes = {
  disabled: PropTypes.bool,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  leftIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rightElement: PropTypes.node,
  round: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number
};

IntegerInputGroup.defaultProps = {
  value: null,
  disabled: false,
  intent: "none",
  size: "md",
  leftIcon: null,
  onChange: () => null,
  placeholder: null,
  rightElement: null,
  round: false,
  fullWidth: true,
  max: null,
  min: null,
  buttonPosition: "right",
  maxLength: null,
  minLength: null
};

export default IntegerInputGroup;
