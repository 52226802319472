import React from "react";
import { Row, Box } from "jsxstyle";
import get from "lodash/get";
import pickBy from "lodash/pickBy";
import omit from "lodash/omit";
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import Pagination from "components/Pagination";
import Button from "components/Button";
import Card from "components/Card";
import AddButton from "components/AddButton";
import useAsync from "hooks/useAsync";
import useUrlParams from "hooks/useUrlParams";
import useToast from "hooks/useToast";
import loadCMBSService from "services/cmbs/loadCMBSService";
import CMBSFilters from "./CMBSFilters";
import CMBSTable from "./CMBSTable";

const defaultParams = {
  page: 1,
  size: 20,
  order_by: "pricing_date",
  order_direction: "DESC",
};

function CMBSListScreen({ history }) {
  const { showError } = useToast();

  const [params, setParams] = useUrlParams(defaultParams);

  const [fetch, pending, data = {}, error] = useAsync({
    promiseFn: loadCMBSService,
    eager: true,
    params,
  });

  React.useEffect(() => {
    if (error) {
      showError("Error loading CMBS");
    }
  }, [error, showError]);

  return (
    <PrivateLayout>
      <Row alignItems="center">
        <Heading size="md">CMBS</Heading>
        <Separator />
        <AddButton onClick={() => history.push("/cmbs/new")} />
      </Row>
      <Separator />
      <Card style={{ flexGrow: 1 }}>
        <Row
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          <Box flex={1}>
            <CMBSFilters
              currentFilters={omit(params, ["page", "size"])}
              onChange={(newFilters) => {
                setParams({
                  ...pickBy(newFilters),
                  ...defaultParams,
                });
              }}
            />
          </Box>
          <Box flexBasis={150}>
            <Button
              icon="cog"
              text="Configure Table"
              onClick={() => history.push("/cmbs/table-configuration")}
            />
          </Box>
        </Row>
        <Separator />
        <CMBSTable
          onSortChange={(sortObject) =>
            setParams({
              ...pickBy(sortObject),
              page: 1,
            })
          }
          onReload={() => fetch()}
          data={get(data, "data", [])}
          pending={pending}
        />
        <Separator />
        {!pending && (
          <Row justifyContent="flex-end">
            <Pagination
              page={params.page}
              size={params.size}
              total={data.total}
              onChange={({ page }) => setParams({ ...params, page })}
            />
          </Row>
        )}
      </Card>
    </PrivateLayout>
  );
}

export default CMBSListScreen;
