/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { Row } from "jsxstyle";
import { Divider } from "@blueprintjs/core";
import Card from "components/Card";
import Separator from "components/Separator";
import Button from "components/Button";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import useAsync from "hooks/useAsync";
import GeographicDistributionForm from "./GeographicDistributionForm";
import GeographicDistributionTable from "./GeographicDistributionTable";
import history from "browserHistory";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadABSGeographicDistributionsService from "services/abs/loadABSGeographicDistributionsService";

function GeographicDistributionTab({ deal, isNew }) {
  const [
    geographicDistributionToEdit,
    setGeographicDistributionToEdit
  ] = React.useState(null);

  const dealCode = deal.deal_code;

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadABSGeographicDistributionsService
  });

  React.useEffect(() => {
    fetch({ dealCode });
  }, []);

  const totalGeographicPercentage = data.reduce(
    (acc, geo) => acc + geo.geo_percentage,
    0
  );

  return (
    <Card>
      <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
        <TwoColumnFormLayout
          title={
            isEmpty(geographicDistributionToEdit)
              ? "Add Geographic Distribution"
              : "Edit Geographic Distribution"
          }
        >
          <GeographicDistributionForm
            key={
              geographicDistributionToEdit && geographicDistributionToEdit.id
            }
            onData={() => {
              setGeographicDistributionToEdit(null);
              fetch({ dealCode });
            }}
            initialValues={{
              dealCode,
              ...(geographicDistributionToEdit
                ? geographicDistributionToEdit
                : {})
            }}
            onCancel={() => setGeographicDistributionToEdit(null)}
            totalGeographicPercentage={totalGeographicPercentage}
          />
        </TwoColumnFormLayout>
        <Divider style={{ margin: "30px 0 30px 0" }} />
      </PermissionValidator>
      <TwoColumnFormLayout title="Geographic Distributions">
        <GeographicDistributionTable
          data={data}
          pending={pending}
          onReload={() => fetch({ dealCode })}
          onRequestEdit={geographicDistribution =>
            setGeographicDistributionToEdit(geographicDistribution)
          }
        />
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Finish"
                onClick={() => history.push("/abs")}
              />
            </Row>
          </React.Fragment>
        )}
      </TwoColumnFormLayout>
    </Card>
  );
}

GeographicDistributionTab.propTypes = {
  isActive: PropTypes.bool,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool
};
GeographicDistributionTab.defaultProps = {
  isActive: false,
  isNew: true
};
export default GeographicDistributionTab;
