/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import ABSUnderwritersForm from "./ABSUnderwritersForm";
import ABSUnderwritersTable from "./ABSUnderwritersTable";
import useAsync from "hooks/useAsync";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadABSUnderwritersService from "services/abs/loadABSUnderwritersService";
import browserHistory from "browserHistory";

function ABSUnderwritersTab({ deal, isNew }) {
  const [underwriterToEdit, setUnderwriterToEdit] = React.useState(null);

  const dealCode = deal.deal_code;
  const dealSize = deal.deal_amount;

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadABSUnderwritersService,
  });

  React.useEffect(() => {
    fetch({ dealCode });
  }, []);

  const bookRunnerCredit = data.reduce(
    (acc, underwriter) =>
      underwriter.entity_type === "B" ? (acc += underwriter.amount) : acc,
    0,
  );

  return (
    <Card>
      <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
        <TwoColumnFormLayout
          title={underwriterToEdit ? "Edit Underwriter" : "Add Underwriter"}
        >
          <ABSUnderwritersForm
            key={underwriterToEdit && underwriterToEdit.id}
            onData={() => {
              setUnderwriterToEdit(null);
              fetch({ dealCode });
            }}
            initialValues={{
              dealCode,
              ...(underwriterToEdit
                ? {
                    ...underwriterToEdit,
                    entity_id: underwriterToEdit.entity_id.entity_id,
                  }
                : {}),
            }}
            onCancel={() => setUnderwriterToEdit(null)}
            dealSize={dealSize}
            bookRunnerCredit={bookRunnerCredit}
          />
        </TwoColumnFormLayout>
        <Divider style={{ margin: "30px 0 30px 0" }} />
      </PermissionValidator>
      <TwoColumnFormLayout title="Underwriters">
        <ABSUnderwritersTable
          dealSize={dealSize}
          data={data}
          pending={pending}
          onReload={() => fetch({ dealCode })}
          onRequestEdit={(item) => setUnderwriterToEdit(item)}
        />
      </TwoColumnFormLayout>
      {isNew && (
        <React.Fragment>
          <Separator size="lg" />
          <Row className="col-12" justifyContent="flex-end">
            <Button
              intent="primary"
              text="Next"
              onClick={() =>
                browserHistory.push(
                  `/abs/new/${deal.deal_code}/rating-agencies`,
                )
              }
            ></Button>
          </Row>
        </React.Fragment>
      )}
    </Card>
  );
}

ABSUnderwritersTab.propTypes = {
  isActive: PropTypes.bool,
  dealCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isNew: PropTypes.bool,
};

ABSUnderwritersTab.defaultProps = {
  isActive: false,
  isNew: true,
};

export default ABSUnderwritersTab;
