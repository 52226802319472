import React from "react";
import PropTypes from "prop-types";
import { ButtonGroup as BluePrintButtonGroup } from "@blueprintjs/core";
import Button from "./Button";

function ButtonGroup({ buttons }) {
  return (
    <BluePrintButtonGroup>
      {buttons.map(
        (button, index) => button.render || <Button {...button} key={index} />
      )}
    </BluePrintButtonGroup>
  );
}

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ButtonGroup;
