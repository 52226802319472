import React from "react";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import { Row } from "jsxstyle";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import CurrencyFormat from "components/CurrencyFormat";
import DeleteCMBSUnderwriter from "./DeleteCMBSUnderwriter";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import PercentFormat from "components/PercentFormat";

function CMBSUnderwritersTable({
  data,
  dealSize,
  pending,
  onReload,
  onRequestEdit,
}) {
  const handleOnChange = (pagination, filter, sorter) => {};

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          data={orderBy(data, ["entity_type", "amount"], ["asc", "desc"])}
          onChange={handleOnChange}
          pending={pending}
          rowKey="id"
          columns={[
            {
              title: "Underwriter",
              dataIndex: "entity_id.long_name",
              key: "entity_id.long_name",
            },
            {
              title: "Type",
              dataIndex: "entity_type",
              key: "entity_type",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              render: (amount) => <CurrencyFormat amount={amount || 0} />,
            },
            {
              title: "Amount(%)",
              dataIndex: "amount",
              key: "amount",
              render: (amount) => (
                <PercentFormat
                  amount={(((amount || 0) / dealSize) * 100).toFixed(4)}
                />
              ),
            },
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: (item) => (
                      <Row>
                        <Button
                          icon="edit"
                          onClick={() => onRequestEdit(item)}
                        />
                        <Separator />
                        <DeleteCMBSUnderwriter
                          underwriter={item}
                          onDelete={onReload}
                        />
                      </Row>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </PermissionValidator>
  );
}

CMBSUnderwritersTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onReload: PropTypes.func.isRequired,
  onRequestEdit: PropTypes.func.isRequired,
};

CMBSUnderwritersTable.defaultProps = {
  data: [],
  pending: false,
};

export default CMBSUnderwritersTable;
