import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from 'query-string';
import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { REGION_CODES, REGION_NAMES_MAP } from "consts";

const MenuRERegions = () => {
  const history = useHistory();
  const location = useLocation();
  const menu = (
    <Menu>
      {Object.values(REGION_CODES).map(region => (
        <MenuItem
          key={region}
          text={REGION_NAMES_MAP[region]}
          onClick={() => history.push(`/re?region=${region}`)}
          active={qs.parse(location.search).region === region}
        />
      ))}
    </Menu>
  )
  return (
    <Popover content={menu} position={Position.BOTTOM} >
      <Button
        className="bp3-minimal"
        text="RE"
        active={location.pathname.includes("/re")}
      />
    </Popover>
  )
}

MenuRERegions.propTypes = {};

MenuRERegions.defaultProps = {};

export default MenuRERegions;
