import React from "react";
import pickBy from "lodash/pickBy";
import omit from "lodash/omit";
import get from "lodash/get";
import { Row, Box } from "jsxstyle";
import { useRegionContext } from 'contexts/RegionContext';
import Pagination from "components/Pagination";
import Button from "components/Button";
import useAsync from "hooks/useAsync";
import useUrlParams from "hooks/useUrlParams";
import useToast from "hooks/useToast";
import REPropFilters from "./REPropFilters";
import REPropTable from "./REPropTable";
import AddButton from "components/AddButton";
import InBetweenSpacing from "components/InBetweenSpacing";
import loadREPropService from "services/re/loadREPropService";
import history from "browserHistory";

function RESaleListScreen() {
  const { region, getRegionPath } = useRegionContext();
  const defaultParams = {
    page: 1,
    size: 20,
    region: region
  };
  const { showError } = useToast();
  const [params, setParams] = useUrlParams(defaultParams);

  const [fetch, pending, data = {}] = useAsync({
    promiseFn: loadREPropService,
    eager: true,
    params,
    onError: (error) => {
      showError("Error loading RE");
    },
  });

  return (
    <InBetweenSpacing>
      <Row alignItems="flex-start" justifyContent="space-between">
        <Box flex="1">
          <REPropFilters
            currentFilters={omit(params, ["page", "size", "region"])}
            onChange={(newFilters) => {
              setParams({
                ...pickBy(newFilters),
                ...defaultParams,
              });
            }}
          />
        </Box>
        <Box flexBasis={150}>
          <Button
            icon="cog"
            text="Configure Table"
            onClick={() => history.push(getRegionPath("/re/prop/table-configuration"))}
          />
        </Box>
      </Row>
      <Box>
        <AddButton
          text="Add Property"
          onClick={() => history.push(getRegionPath('/re/property/new'))}
        />
      </Box>
      <REPropTable
        onSortChange={(sortObject) =>
          setParams({
            ...params,
            ...pickBy(sortObject),
            page: 1,
          })
        }
        onReload={() => fetch({ params })}
        data={get(data, "data", [])}
        pending={pending}
      />
      {!pending && (
        <Row justifyContent="flex-end">
          <Pagination
            page={params.page}
            size={params.size}
            total={data.total}
            onChange={({ page }) => setParams({ ...params, page })}
          />
        </Row>
      )}
    </InBetweenSpacing>
  );
}

RESaleListScreen.propTypes = {};

RESaleListScreen.defaultProps = {};

export default RESaleListScreen;
