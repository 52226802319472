import React from "react";
import Heading from "components/Heading";
import Separator from "components/Separator";
import PrivateLayout from "components/PrivateLayout";
import NewContactStepper from "./NewContactStepper";
import ContactBasicInfoTab from "./basic-info/ContactBasicInfoTab";
import ContactFundTab from "./funds/ContactFundTab";
import Card from "components/Card";

function NewContactLayout({ contact, onContactChange }) {
  return (
    <PrivateLayout>
      <Heading size="md">New Contact</Heading>
      <Separator />
      <NewContactStepper>
        <Card>
          <ContactBasicInfoTab
            initialValues={contact}
            onSubmit={onContactChange}
          />
        </Card>
        <Card>
          <ContactFundTab contact={contact} isNew />
        </Card>
      </NewContactStepper>
    </PrivateLayout>
  );
}

export default NewContactLayout;
