import PropTypes from "prop-types";

function PercentFormat({ amount }) {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2
  }).format(amount / 100);
}

PercentFormat.propTypes = {
  amount: PropTypes.number
};

PercentFormat.defaultProps = {
  amount: 0
};

export default PercentFormat;
