import React from "react";
import {
  Navbar as BluePrintNavbar,
  Alignment,
  Tabs,
  Tab
} from "@blueprintjs/core";
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Card from "components/Card";
import EditCLODealTab from "./deal/EditCLODealTab";
import CLOUnderwritersTab from "./underwriters/CLOUnderwritersTab";
import CLORatingAgenciesTab from "./ratingAgencies/CLORatingAgenciesTab";
import CLOTranchePricesTab from "./tranchePrices/CLOTranchePricesTab";

function EditCLOLayout({ deal, onDealChange }) {
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <PrivateLayout>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Heading size="md">
            {isAllowed ? "Edit" : "View"} CLO Deal - {deal.deal_code}
          </Heading>
        )}
      </PermissionValidator>
      <Separator />
      <React.Fragment>
        <BluePrintNavbar>
          <BluePrintNavbar.Group align={Alignment.LEFT}>
            <Tabs
              large={true}
              onChange={nextTabId => setCurrentTab(nextTabId)}
              selectedTabId={currentTab}
            >
              <Tab id={0} title="ABS Deal" />
              <Tab id={1} title="Underwriters" />
              <Tab id={2} title="Rating Agencies" />
              <Tab id={3} title="Tranche Prices and Ratings" />
            </Tabs>
          </BluePrintNavbar.Group>
        </BluePrintNavbar>
        <Card>
          {
            {
              0: (
                <EditCLODealTab onSubmit={onDealChange} initialValues={deal} />
              ),
              1: <CLOUnderwritersTab deal={deal} isNew={false} />,
              2: <CLORatingAgenciesTab deal={deal} isNew={false} />,
              3: <CLOTranchePricesTab deal={deal} isNew={false} />
            }[currentTab]
          }
        </Card>
      </React.Fragment>
    </PrivateLayout>
  );
}

export default EditCLOLayout;
