import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import Button from "components/Button";
import getEntityMasterByIdService from "services/entityMaster/getEntityMasterByIdService";
import useAsync from "hooks/useAsync";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function REBuyersTable({ entities, onRequestDelete }) {
  const [fetch, pending, data] = useAsync({
    promiseFn: values =>
      new Promise(async (resolve, reject) => {
        try {
          const data = await Promise.all(
            values.entities.map(seller =>
              getEntityMasterByIdService({ id: seller })
            )
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      })
  });

  React.useEffect(() => {
    if (entities.length) {
      fetch({ entities });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  return entities.length ? (
    <Table
      pending={pending}
      data={data}
      rowKey="entity_id"
      columns={[
        {
          title: "Buyer",
          dataIndex: "long_name",
          key: "long_name"
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "",
          width: 100,
          render: item => (
            <PermissionValidator
              allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
              shouldHide
            >
              <Button
                size="sm"
                icon="trash"
                intent="none"
                onClick={() => onRequestDelete(item)}
              />
            </PermissionValidator>
          )
        }
      ]}
    />
  ) : null;
}

REBuyersTable.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.number),
  onRequestDelete: PropTypes.func.isRequired
};

REBuyersTable.defaultProps = {
  entities: []
};

export default REBuyersTable;
