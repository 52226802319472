import React from "react";
import { Box } from "jsxstyle";
import useForm from "hooks/useForm";
import Form from "components/Form";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectHYFund from "components/SelectHYFund";
import SelectHYContactType from "components/SelectHYContactType";
import useAsync from "hooks/useAsync";
import saveContactFundService from "services/contact/saveContactFundService";
import useToast from "hooks/useToast";
import required from "validators/isRequired";

function ContactFundForm({ contact, onData, initialValues }) {
  const { showError, showSuccess } = useToast();
  const { contact_id: contactId } = contact;

  const { getValue, setValue, getError, clear, reset, submit } = useForm({
    initialValues,
    onSubmit: ({ fund_id, contact_type }) => {
      fetch({ contactId, fundId: fund_id, contact_type });
    },
    validations: {
      fund_id: [required],
      contact_type: [required],
    },
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveContactFundService,
    onData: (data) => {
      showSuccess("Fund saved.");
      reset();
      clear();
      onData(data);
    },
    onError: ({ data: { error } = {} }) => {
      const errorMessage =
        {
          duplicated_key: "Duplicate Fund",
        }[error] || "An error happened.";
      showError(errorMessage);
    },
  });

  return (
    <Form onSubmit={submit}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Fund"
            disabled={isSubmitting}
            error={getError("fund_id")}
            required
          >
            <SelectHYFund
              onSelect={(value) => setValue("fund_id", value)}
              value={getValue("fund_id")}
              error={getError("fund_id")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Contact Type"
            disabled={isSubmitting}
            error={getError("contact_type")}
            required
          >
            <SelectHYContactType
              onSelect={(value) => setValue("contact_type", value)}
              value={getValue("contact_type")}
              error={getError("contact_type")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <Box marginTop={22}>
            <Button
              type="submit"
              text="Add"
              intent="success"
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Box>
        </FormGrid.Item>
      </FormGrid>
    </Form>
  );
}

ContactFundForm.propTypes = {};

ContactFundForm.defaultProps = {};

export default ContactFundForm;
