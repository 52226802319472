import React from "react";
import PropTypes from "prop-types";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import deleteContactService from "services/contact/deleteContactService";
import Alert from "components/Alert";
import Button from "components/Button";

function DeleteContact({ contact, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteContactService,
    onError: (error) => {
      showError("Error deleting Contact");
    },
    onData: () => {
      showSuccess("Contact deleted successfully.");
      onDelete();
    },
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ id: contact.contact_id })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Contact <b>{contact.first_name}</b>?
      </Alert>
    </>
  );
}

DeleteContact.propTypes = {
  contact: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

DeleteContact.defaultProps = {
  contact: {},
};

export default DeleteContact;
