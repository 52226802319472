import fetch from "../fetch";

export default ({ id, values, dealCode, trancheId }) =>
  fetch({
    path: `/cmbs/deals/${dealCode}/tranche-prices/${trancheId}/tranche-ratings${
      id ? `/${id}` : ""
    }`,
    method: id ? "PUT" : "POST",
    data: values,
  });
