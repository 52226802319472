import React from "react";
import { Row } from "jsxstyle";
import get from "lodash/get";
import pickBy from "lodash/pickBy";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import useUrlParams from "hooks/useUrlParams";
import PrivateLayout from "components/PrivateLayout";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "components/Separator";
import Button from "components/Button";
import Pagination from "components/Pagination";
import EntityMasterTable from "./EntityMasterTable";
import EntityMasterFilters from "./EntityMasterFilters";
import loadEntityMasterService from "services/entityMaster/loadEntityMasterService";

const defaultParams = {
  page: 1,
  size: 20,
  order_by: "entity_id",
  order_direction: "DESC",
};

function EntityMasterListScreen({ history }) {
  const [params, setParams] = useUrlParams(defaultParams);

  const { showError } = useToast();

  const [fetch, pending, data = {}] = useAsync({
    promiseFn: loadEntityMasterService,
    params,
    eager: true,
    onError: (error) => {
      showError("Error loading Entity Master");
    },
  });

  return (
    <PrivateLayout>
      <Row alignItems="center">
        <Heading size="md">Entity Master</Heading>
        <Separator />
        <Button
          intent="primary"
          icon="add"
          text="Add"
          onClick={() => history.push("/entity-master/new")}
        />
      </Row>
      <Separator />
      <Card style={{ flexGrow: 1 }}>
        <EntityMasterFilters
          onChange={(newFilters) =>
            setParams({
              ...pickBy(newFilters),
              ...defaultParams,
            })
          }
        />
        <Separator />
        <EntityMasterTable
          onSortChange={(sortObject) =>
            setParams({
              ...params,
              ...pickBy(sortObject),
              page: 1,
            })
          }
          onReload={() => fetch({ params })}
          data={get(data, "data", [])}
          pending={pending}
        />
        <Separator />
        {!pending && (
          <Row justifyContent="flex-end">
            <Pagination
              page={params.page}
              size={params.size}
              total={data.total}
              onChange={({ page }) => setParams({ ...params, page })}
            />
          </Row>
        )}
      </Card>
    </PrivateLayout>
  );
}

export default EntityMasterListScreen;
