import React from "react";
import PropTypes from "prop-types";
import AsyncCreatableAutoComplete from "./AsyncCreatableAutoComplete";
import loadEntityMasterService from "services/entityMaster/loadEntityMasterService";
import { Row, Box } from "jsxstyle";

const maxLength = 6;
function SelectCreatableEntityMaster({
  onSelect,
  onCreate,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
  params,
}) {
  const handleCreate = (value = "") => {
    if (onCreate) {
      onCreate(value.substring(0, maxLength).trim());
    }
  };

  return (
    <AsyncCreatableAutoComplete
      promiseFn={loadEntityMasterService}
      onSelect={onSelect}
      onCreate={handleCreate}
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      filter={({
        filterValue,
        option: { entity_id = "", long_name = "" } = {},
      }) =>
        entity_id.toLowerCase().includes(filterValue.toLowerCase()) ||
        long_name.toLowerCase().includes(filterValue.toLowerCase())
      }
      valueRenderer={({ value: { entity_id } = {} } = {}) => (
        <Box>{entity_id}</Box>
      )}
      itemRenderer={(entityMaster) => {
        return (
          <Row>
            <Box minWidth={60}>{entityMaster.entity_id}</Box>
            <Box>{entityMaster.long_name}</Box>
          </Row>
        );
      }}
      createOptionRenderer={(filterValue = "") => (
        <React.Fragment>{`Create new "${filterValue
          .substring(0, maxLength)
          .trim()}"`}</React.Fragment>
      )}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      params={params}
    />
  );
}

SelectCreatableEntityMaster.propTypes = {
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  params: PropTypes.object,
};

SelectCreatableEntityMaster.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  params: undefined,
};

export default SelectCreatableEntityMaster;
