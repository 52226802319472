import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CMBSServicersForm from "./CMBSServicersForm";
import CMBSServicersTable from "./CMBSServicersTable";
import useAsync from "hooks/useAsync";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadCMBSServicersService from "services/cmbs/loadCMBSServicersService";
import browserHistory from "browserHistory";

function CMBSServicersTab({ deal, isNew }) {
  const [servicerToEdit, setServicerToEdit] = React.useState(null);

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadCMBSServicersService,
  });

  const dealCode = deal.deal_code;
  const dealSize = deal.deal_amount;

  React.useEffect(() => {
    fetch({ dealCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const masterServicerCredit = data.reduce(
    (acc, servicer) => (!servicer.is_special ? acc + servicer.amount : acc),
    0,
  );

  const specialServicerCredit = data.reduce(
    (acc, servicer) => (servicer.is_special ? acc + servicer.amount : acc),
    0,
  );

  return (
    <React.Fragment>
      <Card>
        <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
          <TwoColumnFormLayout
            title={servicerToEdit ? "Edit Servicer" : "Add Servicer"}
          >
            <CMBSServicersForm
              key={servicerToEdit && servicerToEdit.id}
              onCancel={() => setServicerToEdit(null)}
              dealSize={dealSize}
              masterServicerCredit={masterServicerCredit}
              specialServicerCredit={specialServicerCredit}
              onData={() => {
                setServicerToEdit(null);
                fetch({ dealCode });
              }}
              initialValues={{
                dealCode,
                ...(servicerToEdit
                  ? {
                      ...servicerToEdit,
                      servicer: servicerToEdit.servicer.entity_id,
                    }
                  : {}),
              }}
            />
          </TwoColumnFormLayout>
          <Divider style={{ margin: "30px 0 30px 0" }} />
        </PermissionValidator>
        <TwoColumnFormLayout title="Special Servicers">
          <CMBSServicersTable
            dealSize={dealSize}
            data={data.filter((services) => Boolean(services.is_special))}
            pending={pending}
            onReload={() => fetch({ dealCode })}
            onRequestEdit={(servicer) => setServicerToEdit(servicer)}
          />
        </TwoColumnFormLayout>
        <Divider style={{ margin: "30px 0 30px 0" }} />
        <TwoColumnFormLayout title="Servicers">
          <CMBSServicersTable
            dealSize={dealSize}
            data={data.filter((services) => !Boolean(services.is_special))}
            pending={pending}
            onReload={() => fetch({ dealCode })}
            onRequestEdit={(servicer) => setServicerToEdit(servicer)}
          />
        </TwoColumnFormLayout>
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Next"
                onClick={() =>
                  browserHistory.push(
                    `/cmbs/new/${deal.deal_code}/b-piece-buyers`,
                  )
                }
              ></Button>
            </Row>
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
}

CMBSServicersTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool,
};

CMBSServicersTab.defaultProps = {
  isActive: false,
  isNew: true,
};

export default CMBSServicersTab;
