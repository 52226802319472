import React from "react";
import CurrencyFormat from "components/CurrencyFormat";
import PercentFormat from "components/PercentFormat";

export default [
  {
    title: "Deal type",
    dataIndex: "deal_type.deal_type_desc",
    key: "deal_type.deal_type_desc",
    width: 180,
  },
  {
    title: "Pricing date",
    dataIndex: "pricing_date",
    key: "pricing_date",
    width: 150,
  },
  {
    title: "Closing date",
    dataIndex: "closing_date",
    key: "closing_date",
    width: 150,
  },
  { title: "Abbrv", dataIndex: "abbrv", key: "abbrv", width: 150 },
  { title: "Issuer", dataIndex: "issuer", key: "issuer", width: 150 },
  { title: "Series", dataIndex: "series", key: "series", width: 150 },
  {
    title: "Deal amount",
    dataIndex: "deal_amount",
    key: "deal_amount",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Retained amount",
    dataIndex: "retained_amount",
    key: "retained_amount",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Upsized",
    dataIndex: "upsized",
    key: "upsized",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Seller",
    dataIndex: "seller.long_name",
    key: "seller",
    width: 150,
  },
  {
    title: "Sponsor",
    dataIndex: "sponsor.long_name",
    key: "sponsor.long_name",
    width: 150,
  },
  {
    title: "Master servicer",
    dataIndex: "master_servicer.long_name",
    key: "master_servicer",
    width: 150,
  },
  {
    title: "Primary servicer",
    dataIndex: "primary_servicer.long_name",
    key: "primary_servicer",
    width: 150,
  },
  {
    title: "Special servicer",
    dataIndex: "special_servicer.long_name",
    key: "special_servicer",
    width: 150,
  },
  {
    title: "Enhancement",
    dataIndex: "enhancement.long_name",
    key: "enhancement",
    width: 150,
  },
  {
    title: "Trustee",
    dataIndex: "trustee.long_name",
    key: "trustee.long_name",
    width: 150,
  },
  {
    title: "Counsel underwriter",
    dataIndex: "counsel_uw.long_name",
    key: "counsel_uw",
    width: 240,
  },
  {
    title: "Denomination",
    dataIndex: "denomination.currency_name",
    key: "denomination",
    width: 200,
  },
  {
    title: "Denomination retained",
    dataIndex: "denomination_retained",
    key: "denomination_retained",
    width: 240,
  },
  {
    title: "Synthetic pool",
    dataIndex: "synthetic_pool",
    key: "synthetic_pool",
    width: 150,
  },
  {
    title: "Pay frequency",
    dataIndex: "pay_frequency",
    key: "pay_frequency",
    width: 150,
  },
  {
    title: "Number of loans",
    dataIndex: "num_of_loans",
    key: "num_of_loans",
    width: 150,
  },
  {
    title: "Avg loan amount",
    dataIndex: "avg_loan_amount",
    key: "avg_loan_amount",
    width: 180,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  { title: "Fico avg", dataIndex: "fico_avg", key: "fico_avg", width: 150 },
  {
    title: "Country code",
    dataIndex: "country_code.country_name",
    key: "country_code",
    width: 240,
  },
  {
    title: "Region code",
    dataIndex: "region_code.region_name",
    key: "region_code",
    width: 240,
  },
  {
    title: "Distribution region",
    dataIndex: "distribution_region.region_name",
    key: "distribution_region",
    width: 240,
  },
  {
    title: "Offering type",
    dataIndex: "offering_type",
    key: "offering_type",
    width: 150,
  },
  { title: "Club", dataIndex: "club", key: "club", width: 150 },
  {
    title: "Overcol percent",
    dataIndex: "overcol_percent",
    key: "overcol_percent",
    width: 150,
  },
  {
    title: "Reserve amount",
    dataIndex: "reserve_amount",
    key: "reserve_amount",
    width: 150,
    render: (amount) => <PercentFormat amount={amount || 0} />,
  },
  {
    title: "Excess spread percent",
    dataIndex: "excess_spread_percent",
    key: "excess_spread_percent",
    width: 240,
  },
  {
    title: "Risk retention",
    dataIndex: "risk_retention",
    key: "risk_retention",
    width: 150,
  },
  {
    title: "Asset reviewer",
    dataIndex: "asset_reviewer",
    key: "asset_reviewer",
    width: 150,
  },
  {
    title: "Self issuance",
    dataIndex: "self_issuance",
    key: "self_issuance",
    width: 150,
  },
  {
    title: "Govt facility",
    dataIndex: "govt_facility",
    key: "govt_facility",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Counsel issuer",
    dataIndex: "counsel_issuer.long_name",
    key: "counsel_issuer",
    width: 240,
  },
  {
    title: "Collateral",
    dataIndex: "collateral.long_name",
    key: "collateral",
    width: 240,
  },
  {
    title: "Collateral sub",
    dataIndex: "collateral_sub.long_name",
    key: "collateral_sub",
    width: 240,
  },
  {
    title: "Collateral sub 2",
    dataIndex: "collateral_sub2.long_name",
    key: "collateral_sub2",
    width: 240,
  },
];
