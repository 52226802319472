import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'jsxstyle';
import { Colors } from '@blueprintjs/core';

function Badge({ children }) {
  return (
    <Flex
      height={20}
      width={20}
      borderRadius="50%"
      backgroundColor={Colors.BLUE1}
      alignItems="center"
      justifyContent="center"
      color="#fff"
      fontSize={12}
    >
      {children}
    </Flex>
  );
}

Badge.propTypes = { children: PropTypes.node.isRequired };

export default Badge;
