/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Row } from "jsxstyle";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import saveHYFundFeeStructureService from "services/hy/saveHYFundFeeStructureService";
import Form from "components/Form";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import Separator from "components/Separator";
import IntegerInputGroup from "components/IntegerInputGroup";
import SelectHYHurdleType from "components/SelectHYHurdleType";

function HYFundFeeStructureForm({ fundId, onData, initialValues, onCancel }) {
  const { showSuccess, showError } = useToast();

  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: ({ id, step, gp_split, hurdle, hurdle_type }) =>
      fetch({
        id,
        fund_id: fundId,
        step,
        gp_split,
        hurdle,
        hurdle_type,
      }),
    initialValues,
    validations: {},
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveHYFundFeeStructureService,
    onData: () => {
      showSuccess("Fee Structure saved.");
      reset();
      onData();
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const handleCancel = React.useCallback(() => {
    onCancel();
    reset();
  }, []);

  const isEditing = Boolean(getValue("id"));

  return (
    <Form onSubmit={submit}>
      <FormGrid>
        <Box className="col-6">
          <FormGroup
            label="Step"
            error={getError("step")}
            disabled={isSubmitting}
          >
            <IntegerInputGroup
              intent={getError("step") ? "danger" : null}
              value={getValue("step")}
              onChange={(value) => setValue("step", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
        <Box className="col-6">
          <FormGroup
            label="GP Split"
            error={getError("gp_split")}
            disabled={isSubmitting}
          >
            <IntegerInputGroup
              intent={getError("gp_split") ? "danger" : null}
              value={getValue("gp_split")}
              onChange={(value) => setValue("gp_split", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
        <Box className="col-6">
          <FormGroup
            label="Hurdle"
            error={getError("hurdle")}
            disabled={isSubmitting}
          >
            <IntegerInputGroup
              intent={getError("hurdle") ? "danger" : null}
              value={getValue("hurdle")}
              onChange={(value) => setValue("hurdle", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
        <Box className="col-6">
          <FormGroup
            label="Hurdle Type"
            error={getError("hurdle_type")}
            disabled={isSubmitting}
          >
            <SelectHYHurdleType
              onSelect={(value) => setValue("hurdle_type", value)}
              value={getValue("hurdle_type")}
              error={getError("hurdle_type")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
      </FormGrid>
      <Row alignItems="flex-start">
        {isEditing && (
          <React.Fragment>
            <Button
              text="Cancel"
              onClick={handleCancel}
              disabled={isSubmitting}
            />
            <Separator />
          </React.Fragment>
        )}
        <Button
          type="submit"
          text={isEditing ? "Save" : "Add"}
          intent="success"
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </Row>
    </Form>
  );
}

HYFundFeeStructureForm.propTypes = {};

HYFundFeeStructureForm.defaultProps = {};

export default HYFundFeeStructureForm;
