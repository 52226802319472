import React from "react";
import { Row } from "jsxstyle";
import {
  Navbar as BluePrintNavbar,
  Alignment,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "components/Separator";
import PrivateLayout from "components/PrivateLayout";
import HYFundListScreen from "./fund/HYFundListScreen";
import HYManagerListScreen from "./manager/HYManagerListScreen";
import browserHistory from "browserHistory";

const routeMap = {
  0: "/hy/fund",
  1: "/hy/manager",
};

function HYListScreen({ location }) {
  const [currentTab, setCurrentTab] = React.useState(
    location.pathname.includes("manager") ? 1 : 0,
  );

  return (
    <PrivateLayout>
      <Row alignItems="center">
        <Heading size="md">High Yield</Heading>
      </Row>
      <Separator />
      <React.Fragment>
        <BluePrintNavbar
          style={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
        >
          <BluePrintNavbar.Group align={Alignment.LEFT}>
            <Tabs
              large={true}
              onChange={(nextTabId) => {
                browserHistory.push(routeMap[nextTabId]);
                setCurrentTab(nextTabId);
              }}
              selectedTabId={currentTab}
            >
              <Tab id={0} title="Funds" />
              <Tab id={1} title="Managers" />
            </Tabs>
          </BluePrintNavbar.Group>
        </BluePrintNavbar>
        <Card style={{ flexGrow: 1 }}>
          {
            {
              0: <HYFundListScreen />,
              1: <HYManagerListScreen />,
            }[currentTab]
          }
        </Card>
      </React.Fragment>
    </PrivateLayout>
  );
}

export default HYListScreen;
