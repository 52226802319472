import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@blueprintjs/core';
import { Box } from 'jsxstyle';

function GlobalSearchOmnibarSectionHeader({ children }) {
  return (
    <Box
      background={Colors.DARK_GRAY5}
      fontSize={14}
      color="white"
      padding={8}
      margin="0 -5px"
      fontWeight="bold"
    >
      {children}
    </Box>
  );
}

GlobalSearchOmnibarSectionHeader.propTypes = {
  children: PropTypes.any,
};

GlobalSearchOmnibarSectionHeader.defaultProps = {
  children: '',
};

export default GlobalSearchOmnibarSectionHeader;
