/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { Row, Box } from "jsxstyle";
import { Navbar as BluePrintNavbar, Alignment } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import useToast from "hooks/useToast";
import Card from "components/Card";
import Form from "components/Form";
import Button from "components/Button";
import Separator from "components/Separator";
import CMBSGeneralInformationFields from "./CMBSGeneralInformationFields";
import CMBSPercentageBreakdownsFields from "./CMBSPercentageBreakdownsFields";
import CMBSLoanAndSecurityDetailsFields from "./CMBSLoanAndSecurityDetailsFields";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import isRequired from "validators/isRequired";
import minLength from "validators/minLength";
import saveCMBSDealService from "services/cmbs/saveCMBSDealService";
import CurrencyFormat from "components/CurrencyFormat";

const GENERAL_INFORMATION_TAB = 0;
const PERCENTAGE_BREAKDOWNS_TAB = 1;
const LOAN_AND_SECUTIRY_DETAILS_TAB = 2;

function CMBSNewDealTab({ history, match, nextStep, onSubmit, goToStep }) {
  const { showSuccess, showError } = useToast();
  const [currentTab, setCurrentTab] = React.useState(GENERAL_INFORMATION_TAB);

  const [fetch, pending] = useAsync({
    promiseFn: saveCMBSDealService,
    onData: (deal) => {
      showSuccess("CMBS deal saved.");
      onSubmit(deal);
      history.push(`/cmbs/new/${deal.deal_code}/underwriters`);
    },
    onError: ({ data: { error } = {} }) => {
      console.error(error);
      const errorMessage =
        {
          duplicated_key: "Duplicate Deal Code",
        }[error] || "An error happened.";
      showError(errorMessage);
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: {},
    validations: {
      deal_code: [isRequired, (value) => minLength(value, 6)],
      offering_type: [isRequired],
      deal_type: [isRequired],
      pricing_date: [isRequired],
      issuer: [isRequired],
      deal_amount: [isRequired],
    },
    onSubmit: (values) => {
      if (currentTab === LOAN_AND_SECUTIRY_DETAILS_TAB) {
        fetch({ values });
      } else {
        setCurrentTab(
          currentTab === GENERAL_INFORMATION_TAB
            ? PERCENTAGE_BREAKDOWNS_TAB
            : LOAN_AND_SECUTIRY_DETAILS_TAB,
        );
      }
    },
  });

  return (
    <React.Fragment>
      <BluePrintNavbar>
        <BluePrintNavbar.Group align={Alignment.LEFT}>
          <Tabs large selectedTabId={currentTab}>
            <Tab id={GENERAL_INFORMATION_TAB} title="General Information" />
            <Tab id={PERCENTAGE_BREAKDOWNS_TAB} title="Percentage Breakdowns" />
            <Tab
              id={LOAN_AND_SECUTIRY_DETAILS_TAB}
              title="Loan and security details"
            />
          </Tabs>
        </BluePrintNavbar.Group>
      </BluePrintNavbar>
      <Card>
        <Form onSubmit={submit}>
          {
            {
              [GENERAL_INFORMATION_TAB]: (
                <CMBSGeneralInformationFields
                  getValue={getValue}
                  setValue={setValue}
                  getError={getError}
                  isSubmitting={pending}
                />
              ),
              [PERCENTAGE_BREAKDOWNS_TAB]: (
                <CMBSPercentageBreakdownsFields
                  getValue={getValue}
                  setValue={setValue}
                  getError={getError}
                  isSubmitting={pending}
                  onBack={() => setCurrentTab(GENERAL_INFORMATION_TAB)}
                />
              ),
              [LOAN_AND_SECUTIRY_DETAILS_TAB]: (
                <React.Fragment>
                  <Row justifyContent="flex-end" component="p" fontSize={16}>
                    Deal amount:&nbsp;
                    <Box component="strong">
                      <CurrencyFormat amount={getValue("deal_amount")} />
                    </Box>
                  </Row>
                  <CMBSLoanAndSecurityDetailsFields
                    onBack={() => setCurrentTab(PERCENTAGE_BREAKDOWNS_TAB)}
                    getValue={getValue}
                    setValue={setValue}
                    getError={getError}
                    isSubmitting={pending}
                  />
                </React.Fragment>
              ),
            }[currentTab]
          }

          <Row justifyContent="flex-end">
            {currentTab > 0 && (
              <React.Fragment>
                <Button
                  minimal
                  text="Previous"
                  onClick={() => setCurrentTab(currentTab - 1)}
                  disabled={pending}
                />
                <Separator size="lg" />
              </React.Fragment>
            )}

            <Button
              type="submit"
              text="Next"
              intent="primary"
              disabled={pending}
            />
          </Row>
        </Form>
      </Card>
    </React.Fragment>
  );
}

export default withRouter(CMBSNewDealTab);
