/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// import PropTypes from "prop-types";
import { Row, Box } from "jsxstyle";
import PrivateLayout from "components/PrivateLayout";
import useAsync from "hooks/useAsync";
import Heading from "components/Heading";
import Separator from "components/Separator";
import Button from "components/Button";
import Card from "components/Card";
import AccountsFilters from "./AccountsFilters";
import useUrlParams from "hooks/useUrlParams";
import useToast from "hooks/useToast";
import AccountTable from "./AccountTable";
import AccountForm from "./AccountForm";
import loadAccounts from "services/account/loadAccountsService";
import Modal, { ModalContent } from "components/Modal";
import pickBy from "lodash/pickBy";
import { ADMIN_ROLE } from "consts";
import withPermission from "hocs/withPermission";

const defaultParams = {
  page: 1,
  size: 20,
};
const MODAL_VISIBLE = "visible";
const MODAL_HIDDEN = "hidden";

function AccountListScreen() {
  const [modalVisibility, setModalVisibility] = React.useState(MODAL_HIDDEN);
  const [editingUser, setEditingUser] = React.useState(null);

  const [params, setParams] = useUrlParams(defaultParams);

  const { showError } = useToast();

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadAccounts,
    eager: true,
    params,
    onError: (error) => {
      showError("Error loading Accounts");
    },
  });

  return (
    <PrivateLayout>
      <Row alignItems="center">
        <Heading size="md">Accounts</Heading>
        <Separator />
        <Button
          intent="primary"
          icon="add"
          text="Add"
          onClick={() => setModalVisibility(MODAL_VISIBLE)}
        />
      </Row>
      <Separator />
      <Card style={{ flexGrow: 1 }}>
        <AccountsFilters
          onChange={(newFilters) =>
            setParams({
              ...pickBy(newFilters),
              ...defaultParams,
            })
          }
        />
        <Separator />
        <AccountTable
          onReload={() => fetch({ params })}
          data={data}
          pending={pending}
          onRequestEdit={(user) => setEditingUser(user)}
        />
      </Card>
      <Modal
        visible={modalVisibility === MODAL_VISIBLE || !!editingUser}
        onRequestClose={() => {
          setModalVisibility(MODAL_HIDDEN);
          setEditingUser(null);
        }}
      >
        <ModalContent>
          <Heading size="sm">New Account</Heading>
          <Separator />
          <Box width={450}>
            <AccountForm
              onData={() => fetch({ params })}
              userName={editingUser && editingUser.userName}
              initialValues={
                editingUser
                  ? {
                      firstName: editingUser.firstName,
                      lastName: editingUser.lastName,
                      groupName: editingUser.groupName,
                      email: editingUser.email,
                    }
                  : {}
              }
              onRequestClose={() => {
                setModalVisibility(MODAL_HIDDEN);
                setEditingUser(null);
              }}
            />
          </Box>
        </ModalContent>
      </Modal>
    </PrivateLayout>
  );
}

AccountListScreen.propTypes = {};

AccountListScreen.defaultProps = {};

export default withPermission(AccountListScreen, [ADMIN_ROLE]);
