import React from "react";
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import NewCLODealTab from "./deal/NewCLODealTab";
import CLOUnderwritersTab from "./underwriters/CLOUnderwritersTab";
import CLORatingAgenciesTab from "./ratingAgencies/CLORatingAgenciesTab";
import CLOTranchePricesTab from "./tranchePrices/CLOTranchePricesTab";
import NewCLOStepper from "./NewCLOStepper";

function CLOFormScreen({ deal, onDealChange }) {
  return (
    <PrivateLayout>
      <Heading size="md">New CLO Deal</Heading>
      <Separator />
      <NewCLOStepper>
        <NewCLODealTab onSubmit={onDealChange} />
        <CLOUnderwritersTab deal={deal} />
        <CLORatingAgenciesTab deal={deal} />
        <CLOTranchePricesTab deal={deal} />
      </NewCLOStepper>
    </PrivateLayout>
  );
}

export default CLOFormScreen;
