import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadHYManagersService from "services/hy/loadHYManagersService";

function SelectHYManager({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
  params,
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadHYManagersService}
      onSelect={onSelect}
      textPath="manager_id.long_name"
      labelPath="manager_id.entity_id"
      idPath="manager_id.entity_id"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      params={params}
    />
  );
}

SelectHYManager.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  params: PropTypes.object,
};

SelectHYManager.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  params: undefined,
};

export default SelectHYManager;
