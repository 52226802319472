import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteABSRatingService from "services/abs/deleteABSRatingService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteABSRatingAgency({ rating, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteABSRatingService,
    onData: () => {
      showSuccess("Rating deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Rating");
    }
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ dealCode: rating.deal_code, id: rating.id })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Rating{" "}
        <b>{rating.entity_id.long_name}</b>?
      </Alert>
    </>
  );
}

DeleteABSRatingAgency.propTypes = {
  rating: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteABSRatingAgency.defaultProps = {
  rating: {}
};

export default DeleteABSRatingAgency;
