/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import ABSTranchePricesForm from "./ABSTranchePricesForm";
import ABSTranchePricesTable from "./ABSTranchePricesTable";
import useAsync from "hooks/useAsync";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import loadABSTranchePricesService from "services/abs/loadABSTranchePricesService";
import browserHistory from "browserHistory";

function ABSTranchePricesTab({ deal, isNew }) {
  const [editingItem, setEditingItem] = React.useState(null);

  const dealCode = deal.deal_code;
  const dealSize = deal.deal_amount;

  const [fetch, pending, data = []] = useAsync({
    promiseFn: loadABSTranchePricesService
  });

  React.useEffect(() => {
    fetch({ dealCode });
  }, []);

  const allocated = data.reduce(
    (acc, tranche) => acc + tranche.class_amount,
    0
  );

  return (
    <React.Fragment>
      <Card>
        <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
          <TwoColumnFormLayout
            title={editingItem ? "Edit Tranche" : "Add Tranche"}
          >
            <ABSTranchePricesForm
              key={editingItem && editingItem.id}
              onCancel={() => setEditingItem(null)}
              dealSize={dealSize}
              allocated={allocated}
              onData={() => {
                fetch({ dealCode });
                setEditingItem(null);
              }}
              initialValues={{
                dealCode,
                ...editingItem
              }}
            />
          </TwoColumnFormLayout>
          <Divider style={{ margin: "30px 0 30px 0" }} />
        </PermissionValidator>
        <TwoColumnFormLayout title="Tranches">
          <ABSTranchePricesTable
            dealCode={dealCode}
            data={data}
            pending={pending}
            onReload={() => fetch({ dealCode })}
            onRequestEdit={item => setEditingItem(item)}
          />
        </TwoColumnFormLayout>
        {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Next"
                onClick={() =>
                  browserHistory.push(
                    `/abs/new/${deal.deal_code}/geographic-distribution`
                  )
                }
              ></Button>
            </Row>
          </React.Fragment>
        )}
        {/* {isNew && (
          <React.Fragment>
            <Separator size="lg" />
            <Row className="col-12" justifyContent="flex-end">
              <Button
                intent="primary"
                text="Next"
                onClick={() => history.push("/abs")}
              />
            </Row>
          </React.Fragment>
        )} */}
      </Card>
    </React.Fragment>
  );
}

ABSTranchePricesTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool
};

ABSTranchePricesTab.defaultProps = {
  isActive: false,
  isNew: true
};

export default ABSTranchePricesTab;
