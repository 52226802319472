import React from "react";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import {
  Navbar as BluePrintNavbar,
  Alignment,
  Tabs,
  Tab,
} from "@blueprintjs/core";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "components/Separator";
import PrivateLayout from "components/PrivateLayout";
import PermissionValidator from "components/PermissionValidator";
import HYFundBasicInfoTab from "./basic-info/HYFundBasicInfoTab";
import HYFundFeeStructureTab from "./fee-structure/HYFundFeeStructureTab";
import HYFundTargetAssetsTab from "./target-assets/HYFundTargetAssetsTab";
import HYFundTargetRegionsTab from "./target-regions/HYFundTargetRegionsTab";
import HYFundPlacementAgentTab from "./placement-agent/HYFundPlacementAgentTab";
import HYFundManagementSponsorsTab from "./management-sponsors/HYFundManagementSponsorsTab";

function EditHYFundLayout({ fund, onFundChange }) {
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <PrivateLayout>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Heading size="md">
            {isAllowed ? "Edit" : "View"} HY Fund - {fund.fund_id.entity_id}
          </Heading>
        )}
      </PermissionValidator>
      <Separator />
      <React.Fragment>
        <BluePrintNavbar>
          <BluePrintNavbar.Group align={Alignment.LEFT}>
            <Tabs
              large={true}
              onChange={(nextTabId) => setCurrentTab(nextTabId)}
              selectedTabId={currentTab}
            >
              <Tab id={0} title="Basic Info" />
              <Tab id={1} title="Target Assets" />
              <Tab id={2} title="Target Regions" />
              <Tab id={3} title="Management and Sponsors" />
              <Tab id={4} title="Fee Structure" />
              <Tab id={5} title="Placement Agent" />
            </Tabs>
          </BluePrintNavbar.Group>
        </BluePrintNavbar>
        <Card>
          {
            {
              0: (
                <HYFundBasicInfoTab
                  initialValues={fund}
                  isNew={false}
                  onSubmit={onFundChange}
                />
              ),
              1: (
                <HYFundTargetAssetsTab
                  initialValues={fund}
                  isNew={false}
                  onSubmit={onFundChange}
                />
              ),
              2: (
                <HYFundTargetRegionsTab
                  initialValues={fund}
                  isNew={false}
                  onSubmit={onFundChange}
                />
              ),
              3: (
                <HYFundManagementSponsorsTab
                  initialValues={fund}
                  isNew={false}
                  onSubmit={onFundChange}
                />
              ),
              4: <HYFundFeeStructureTab isNew={false} />,
              5: <HYFundPlacementAgentTab isNew={false} />,
            }[currentTab]
          }
        </Card>
      </React.Fragment>
    </PrivateLayout>
  );
}

export default EditHYFundLayout;
