import React from "react";
import { Row } from "jsxstyle";
import { Tag } from "@blueprintjs/core";
import { TableWithFixedHeader } from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import DeleteAccount from "./DeleteAccount";

function AccountTable({
  data,
  pending,
  onSortChange,
  onReload,
  onRequestEdit,
}) {
  return (
    <TableWithFixedHeader
      pending={pending}
      rowKey="userName"
      columns={[
        {
          title: "Name",
          render: (user) => `${user.firstName} ${user.lastName}`,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Role",
          render: (user) => (
            <Row>
              <Tag intent="primary" key={user.groupName}>
                {user.groupName}
              </Tag>
            </Row>
          ),
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "actions",
          fixed: "right",
          width: 90,
          render: (item) => (
            <Row>
              <Button
                size="sm"
                icon="edit"
                onClick={() => onRequestEdit(item)}
              />
              <Separator />
              <DeleteAccount onDelete={onReload} userName={item.userName} />
            </Row>
          ),
        },
      ]}
      data={data || []}
      onSortChange={onSortChange}
    />
  );
}

export default AccountTable;
