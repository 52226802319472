import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import useAsync from "hooks/useAsync";
import loadCLOTranchePriceRatingsService from "services/clo/loadCLOTranchePriceRatingsService";

function CLOTranchePricesSubTable({ dealCode, trancheId }) {
  const [fetch, pending, data] = useAsync({
    promiseFn: loadCLOTranchePriceRatingsService,
  });

  React.useEffect(() => {
    fetch({ dealCode, trancheId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealCode, trancheId]);

  return (
    <Table
      bordered={false}
      rowKey="id"
      pending={pending}
      columns={[
        {
          title: "Agency",
          dataIndex: "entity_id.long_name",
          key: "entity_id.long_name",
        },
        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
        },
      ]}
      data={data}
    />
  );
}

CLOTranchePricesSubTable.propTypes = {
  dealCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  trancheId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default CLOTranchePricesSubTable;
