import React from "react";
import PropTypes from "prop-types";
import { Col } from "jsxstyle";
import AntDesignTable from "antd/es/table";
import useQueryStringFilters from "hooks/useQueryStringFilters";
import "./Table.css";
import "antd/es/table/style/css";

export default function Table({
  data,
  columns,
  expandedRowRender,
  onExpand,
  onSortChange,
  pending,
  rowKey,
  bordered,
  scroll,
  rowSelection,
}) {
  const { getQuery } = useQueryStringFilters();
  const { order_by, order_direction } = getQuery();

  const handleChange = (pagination, filters, sorter) => {
    const order = {
      ascend: "ASC",
      descend: "DESC",
    }[sorter.order];

    onSortChange({
      order_by: order ? sorter.field : undefined,
      order_direction: order,
    });
  };

  return (
    <Col backgroundColor="white">
      <AntDesignTable
        bordered={bordered}
        columns={columns.map((column) => ({
          ...column,
          sorter: column.sortable,
          sortOrder:
            order_by === column.dataIndex &&
            {
              ASC: "ascend",
              DESC: "descend",
            }[order_direction],
          sortDirections: ["ascend", "descend", "ascend"],
        }))}
        expandedRowRender={expandedRowRender}
        onExpand={onExpand}
        dataSource={data}
        loading={pending}
        onChange={handleChange}
        size="small"
        pagination={false}
        rowKey={rowKey}
        scroll={scroll}
        rowSelection={rowSelection}
      />
    </Col>
  );
}

Table.propTypes = {
  bordered: PropTypes.bool,
  size: PropTypes.oneOf(["default", "middle", "small"]),
};

Table.defaultProps = {
  bordered: true,
};

export const TableWithFixedHeader = ({
  data,
  columns,
  expandedRowRender,
  onExpand,
  onSortChange,
  pending,
  rowKey,
  bordered,
  scroll,
  rowSelection,
  height,
}) => {
  const wrapperRef = React.useRef(null);
  const [wrapperHeight, setWrapperHeight] = React.useState(
    height === "auto" ? 0 : height,
  );

  React.useLayoutEffect(() => {
    if (
      height === "auto" &&
      wrapperRef.current &&
      wrapperRef.current.offsetHeight
    ) {
      setWrapperHeight(wrapperRef.current.offsetHeight - 74); //table heading title should be <= 2 rows of break words
    }
  }, [height]);

  return (
    <Col props={{ ref: wrapperRef }} position="relative" flexGrow={1}>
      <Table
        data={data}
        columns={columns}
        expandedRowRender={expandedRowRender}
        onExpand={onExpand}
        onSortChange={onSortChange}
        pending={pending}
        rowKey={rowKey}
        bordered={bordered}
        scroll={{ ...(scroll || {}), y: wrapperHeight }}
        rowSelection={rowSelection}
      />
    </Col>
  );
};

TableWithFixedHeader.propTypes = {
  bordered: PropTypes.bool,
  size: PropTypes.oneOf(["default", "middle", "small"]),
  height: PropTypes.oneOf([PropTypes.number, "auto"]).isRequired,
};

TableWithFixedHeader.defaultProps = {
  bordered: true,
  height: "auto",
};
