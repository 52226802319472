import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import CurrencyFormat from "components/CurrencyFormat";

function SaleTable({ data, pending }) {
  return (
    <Table
      data={data ? [data] : []}
      pending={pending}
      rowKey="property_id"
      columns={[
        {
          title: "Portfolio name",
          dataIndex: "portfolio_name",
          key: "portfolio_name"
        },
        {
          title: "Sale price",
          dataIndex: "price",
          key: "price",
          render: price => <CurrencyFormat amount={price} />
        },
        {
          title: "Sf sold",
          dataIndex: "sf_sold",
          key: "sf_sold"
        },
        {
          title: "Sf total",
          dataIndex: "sf_total",
          key: "sf_total"
        },
        {
          title: "Units",
          dataIndex: "units",
          key: "units"
        },
        {
          title: "Sf price",
          dataIndex: "sf_price",
          key: "sf_price"
        }
      ]}
    />
  );
}

SaleTable.propTypes = {
  data: PropTypes.object,
  pending: PropTypes.bool,
};

SaleTable.defaultProps = {
  pending: false,
  data: []
};

export default SaleTable;
