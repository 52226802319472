import React from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { Navbar as BluePrintNavbar, Alignment } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import Card from "components/Card";
import Separator from "components/Separator";
import CMBSEditDealTab from "./deal/CMBSEditDealTab";
import CMBSUnderwritersTab from "./underwriters/CMBSUnderwritersTab";
import CMBSSellersTab from "./sellers/CMBSSellersTab";
import CMBSServicersTab from "./servicers/CMBSServicersTab";
import CMBSBPieceBuyersTab from "./bPieceBuyers/CMBSBPieceBuyersTab";
import CMBSTranchePricesTab from "./tranchePrices/CMBSTranchePricesTab";
import PrivateLayout from "components/PrivateLayout";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Heading from "components/Heading";
import CMBSRatingAgenciesTab from "./ratingAgencies/CMBSRatingAgenciesTab";
import CMBSLoanAndSecurityDetailsTab from "./deal/CMBSLoanAndSecurityDetailsTab";
import CMBSPercentageBreakdownsTab from "./deal/CMBSPercentageBreakdownsTab";
import CMBSBorrowersTab from "./borrowers/CMBSBorrowersTab";

function EditCMBSScreen({ deal, onDealChange }) {
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <PrivateLayout>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Heading size="md">
            {isAllowed ? "Edit" : "View"} CMBS Deal - {deal.deal_code}
          </Heading>
        )}
      </PermissionValidator>
      <Separator />
      <React.Fragment>
        <BluePrintNavbar>
          <BluePrintNavbar.Group align={Alignment.LEFT}>
            <Tabs
              large={true}
              onChange={(nextTabId) => {
                setCurrentTab(nextTabId);
              }}
              selectedTabId={currentTab}
            >
              <Tab id={0} title="CMBS Deal" />
              <Tab id={1} title="Percentage Breakdowns" />
              <Tab id={2} title="Loan and security details" />
              <Tab id={3} title="Underwriters" />
              <Tab id={4} title="Rating Agencies" />
              <Tab id={5} title="Borrowers" />
              <Tab id={6} title="Sellers" />
              <Tab id={7} title="Servicers" />
              <Tab id={8} title="B Piece Buyers" />
              <Tab id={9} title="Tranche Prices and Ratings" />
            </Tabs>
          </BluePrintNavbar.Group>
        </BluePrintNavbar>
        <Card>
          {
            {
              0: <CMBSEditDealTab onData={onDealChange} initialValues={deal} />,
              1: (
                <CMBSPercentageBreakdownsTab
                  onData={onDealChange}
                  initialValues={deal}
                />
              ),
              2: (
                <CMBSLoanAndSecurityDetailsTab
                  onData={onDealChange}
                  initialValues={deal}
                />
              ),
              3: <CMBSUnderwritersTab deal={deal} isNew={false} />,
              4: <CMBSRatingAgenciesTab deal={deal} isNew={false} />,
              5: <CMBSBorrowersTab deal={deal} isNew={false} />,
              6: <CMBSSellersTab deal={deal} isNew={false} />,
              7: <CMBSServicersTab deal={deal} isNew={false} />,
              8: <CMBSBPieceBuyersTab deal={deal} isNew={false} />,
              9: <CMBSTranchePricesTab deal={deal} isNew={false} />,
            }[currentTab]
          }
        </Card>
        {/* <Alert
            isOpen={alertVisibility === "visible"}
            onConfirm={() => {
              reset();
              setCurrentTab(desiredTab);
            }}
            intent="warning"
            icon="warning-sign"
            onCancel={hideAlert}
          >
            You haven't saved your changes.
            <br />
            Are you sure you want to leave?
          </Alert> */}
      </React.Fragment>
    </PrivateLayout>
  );
}

export default withRouter(EditCMBSScreen);
