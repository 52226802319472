import React from "react";
import { useDrag } from "react-dnd";
import { Box } from "jsxstyle";

const FieldItem = ({ name, index }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: "fieldItem", index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <Box
      opacity={opacity}
      padding="5px"
      cursor="move"
      border="1px dashed grey"
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      props={{ ref: drag }}
    >
      {name}
    </Box>
  );
};

FieldItem.propTypes = {};

FieldItem.defaultProps = {};

export default FieldItem;
