import fetch from "../fetch";

export default ({ fundId, placementAgentId, note }) =>
  fetch({
    path: `/hy/funds/${fundId}/placement-agents/${placementAgentId}`,
    method: "PUT",
    data: {
      note,
    },
  });
