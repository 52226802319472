import React from "react";
import { Box } from "jsxstyle";
import PropTypes from "prop-types";
import loadABSService from "services/abs/loadABSService";
import loadCLOService from "services/clo/loadCLOService";
import loadCMBSService from "services/cmbs/loadCMBSService";
import AsyncCreatableAutoComplete from "./AsyncCreatableAutoComplete";
import "./AsyncAutoComplete.css";

const promiseFnMap = {
  ABS: loadABSService,
  CLO: loadCLOService,
  CMBS: loadCMBSService,
};

function SelectCreatableAbbrv({
  variant,
  onSelect,
  onCreate,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
  maxLength,
}) {
  const handleCreate = (value = "") => {
    if (onCreate) {
      onCreate(value.trim());
    }
  };

  return (
    <AsyncCreatableAutoComplete
      filterKey="abbrv"
      promiseFn={promiseFnMap[variant]}
      onSelect={(value) => onSelect(value ? value.abbrv : null)}
      onCreate={(value) => handleCreate(value.substring(0, maxLength))}
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      filter={({ filterValue = "", option: { abbrv = "" } = {} }) =>
        abbrv && abbrv.toLowerCase().includes(filterValue.toLowerCase())
      }
      valueRenderer={({ value: { abbrv } = {} } = {}) => <Box>{abbrv}</Box>}
      itemRenderer={(entity) => entity.abbrv}
      createOptionRenderer={(filterValue = "") => (
        <React.Fragment>{`Create new "${filterValue
          .substring(0, maxLength)
          .trim()}"`}</React.Fragment>
      )}
      placeholder={placeholder}
      value={typeof value === "string" ? { abbrv: value } : value}
      readOnly={readOnly}
    />
  );
}

SelectCreatableAbbrv.propTypes = {
  variant: PropTypes.oneOf(["ABS", "CLO", "CMBS"]).isRequired,
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  params: PropTypes.object,
  maxLength: PropTypes.number,
};

SelectCreatableAbbrv.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  params: undefined,
  maxLength: 25,
};

export default SelectCreatableAbbrv;
