import React from "react";
import PropTypes from "prop-types";
import { FormGroup as BluePrintFormGroup } from "@blueprintjs/core";

function FormGroup({
  disabled,
  inline,
  intent,
  helperText,
  label,
  labelFor,
  labelInfo,
  children,
  required,
  error
}) {
  return (
    <BluePrintFormGroup
      disabled={disabled}
      inline={inline}
      intent={error ? "danger" : intent}
      helperText={error || helperText}
      label={label}
      labelFor={labelFor}
      labelInfo={required ? "*" : labelInfo}
    >
      {children}
    </BluePrintFormGroup>
  );
}

FormGroup.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  helperText: PropTypes.string,
  label: PropTypes.string,
  labelFor: PropTypes.string,
  labelInfo: PropTypes.string,
  children: PropTypes.node.isRequired,
  error: PropTypes.string
};

FormGroup.defaultProps = {
  required: false,
  disabled: false,
  inline: false,
  intent: "none",
  helperText: null,
  label: null,
  labelFor: null,
  labelInfo: null,
  children: null,
  error: null
};

export default FormGroup;
