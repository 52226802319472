import React from "react";
import PropTypes from "prop-types";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import Button from "components/Button";
import useForm from "hooks/useForm";
import Callout from "components/Callout";
import Form from "components/Form";
import isRequired from "validators/isRequired";
import isEmail from "validators/isEmail";
import Separator from "components/Separator";
import useAsync from "hooks/useAsync";
import forgotPasswordService from "services/authentication/forgotPasswordService";
import useQueryStringFilters from "hooks/useQueryStringFilters";

function ForgotPasswordForm({ onComplete }) {
  const { setQuery } = useQueryStringFilters();
  const [fetch, pending, , error] = useAsync({
    promiseFn: forgotPasswordService,
    onData: onComplete,
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      email: [isRequired, isEmail],
    },
    onSubmit: (values) => {
      fetch(values);
      setQuery({ email: values.email });
    },
  });

  return (
    <>
      <Callout intent="primary">
        You will receive instructions on your email.
      </Callout>
      <Separator />
      <Form onSubmit={submit}>
        <FormGroup
          label="E-mail"
          onChange={(value) => setValue("email", value)}
          error={getError("email")}
        >
          <InputGroup
            type="email"
            leftIcon="envelope"
            size="lg"
            value={getValue("email")}
            onChange={(value) => setValue("email", value)}
            error={getError("email")}
            disabled={pending}
            placeholder="Enter your e-mail"
          />
        </FormGroup>
        <Button
          intent="primary"
          text="Request password change"
          icon="reset"
          size="lg"
          type="submit"
          fullWidth
          loading={pending}
        />
      </Form>
      <Separator />
      {error && (
        <Callout intent="danger">
          {error.message ? error.message : "An error happened"}
        </Callout>
      )}
    </>
  );
}

ForgotPasswordForm.propTypes = { onComplete: PropTypes.func.isRequired };

ForgotPasswordForm.defaultProps = {};

export default ForgotPasswordForm;
