import React from "react";
import { Route, Switch } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import GlobalSearchResultScreen from "screens/global-search/GlobalSearchResultScreen";
import EntityMasterListScreen from "./screens/entity-master/EntityMasterListScreen";
import EntityMasterFormScreen from "./screens/entity-master/EntityMasterFormScreen";
import Auth from "components/Auth";
import RERoutes from "routes/RERoutes";
import CMBSListScreen from "./screens/cmbs/CMBSListScreen";
import CMBSFormScreen from "./screens/cmbs/CMBSFormScreen";
import CMBSTableConfigurationScreen from "screens/cmbs/CMBSTableConfigurationScreen";
import ABSListScreen from "./screens/abs/ABSListScreen";
import ABSFormScreen from "./screens/abs/ABSFormScreen";
import ABSTableConfigurationScreen from "./screens/abs/ABSTableConfigurationScreen";
import CLOListScreen from "./screens/clo/CLOListScreen";
import CLOFormScreen from "./screens/clo/CLOFormScreen";
import CLOTableConfigurationScreen from "screens/clo/CLOTableConfigurationScreen";
import AccountListScreen from "./screens/accounts/AccountListScreen";
import ContactListScreen from "screens/contact/ContactListScreen";
import ContactTableConfigurationScreen from "screens/contact/ContactTableConfigurationScreen";
import ContactFormScreen from "screens/contact/ContactFormScreen";
import NotFoundScreen from "screens/not-found/NotFoundScreen";
import HYListScreen from "screens/hy/HYListScreen";
import HYFundFormScreen from "screens/hy/fund/HYFundFormScreen";
import HYManagerFormScreen from "screens/hy/manager/HYManagerFormScreen";
import HYFundTableConfigurationScreen from "screens/hy/fund/HYFundTableConfigurationScreen";
import HYManagerTableConfigurationScreen from "screens/hy/manager/HYManagerTableConfigurationScreen";

function App() {
  return (
    <Auth>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route
          exact
          path="/global-search"
          component={GlobalSearchResultScreen}
        />
        <Route
          exact
          path="/entity-master"
          component={EntityMasterListScreen}
        />
        <Route
          exact
          path="/entity-master/new"
          component={EntityMasterFormScreen}
        />
        <Route
          exact
          path="/entity-master/:id"
          component={EntityMasterFormScreen}
        />

        <Route
          exact
          path="/cmbs/table-configuration"
          component={CMBSTableConfigurationScreen}
        />
        <Route exact path="/cmbs" component={CMBSListScreen} />
        <Route
          exact
          path="/cmbs/:action/:dealCode?/:currentView?"
          component={CMBSFormScreen}
        />

        <Route exact path="/abs" component={ABSListScreen} />
        <Route
          exact
          path="/abs/table-configuration"
          component={ABSTableConfigurationScreen}
        />
        <Route
          exact
          path="/abs/:action/:dealCode?/:currentView?"
          component={ABSFormScreen}
        />
        <Route
          exact
          path="/clo/table-configuration"
          component={CLOTableConfigurationScreen}
        />
        <Route exact path="/clo" component={CLOListScreen} />
        <Route
          exact
          path="/clo/:action/:dealCode?/:currentView?"
          component={CLOFormScreen}
        />
        {/* RE ROUTES */}
        <Route
          path="/re"
          component={RERoutes}
        />
        <Route exact path="/contacts" component={ContactListScreen} />
        <Route
          exact
          path="/contacts/table-configuration"
          component={ContactTableConfigurationScreen}
        />
        <Route
          exact
          path="/contacts/:action/:id?/:currentView?"
          component={ContactFormScreen}
        />

        <Route exact path="/hy/(fund)?/(manager)?" component={HYListScreen} />
        <Route
          exact
          path="/hy/fund/table-configuration"
          component={HYFundTableConfigurationScreen}
        />
        <Route
          exact
          path="/hy/fund/:action/:id?/:currentView?"
          component={HYFundFormScreen}
        />
        <Route
          exact
          path="/hy/manager/table-configuration"
          component={HYManagerTableConfigurationScreen}
        />
        <Route
          exact
          path="/hy/manager/:action/:id?/:currentView?"
          component={HYManagerFormScreen}
        />

        <Route exact path="/accounts" component={AccountListScreen} />
        <Route path="*" component={NotFoundScreen} />
      </Switch>
    </Auth>
  );
}

export default App;
