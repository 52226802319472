import React from "react";
import { withRouter } from "react-router-dom";
import Stepper from "components/Stepper";

const steps = [
  "CLO Deal",
  "Underwriters",
  "Rating Agencies",
  "Tranche Prices and Ratings"
];

function NewCLOStepper({ match, children }) {
  const { currentView } = match.params;
  const currentStep =
    {
      underwriters: 1,
      "rating-agencies": 2,
      "tranche-prices-and-ratings": 3
    }[currentView] || 0;

  return (
    <Stepper steps={steps} currentStep={currentStep}>
      {children}
    </Stepper>
  );
}

NewCLOStepper.propTypes = {};

NewCLOStepper.defaultProps = {};

export default withRouter(NewCLOStepper);
