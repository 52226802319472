import React from "react";
// import PropTypes from "prop-types";
import { Col, Box } from "jsxstyle";
import get from "lodash/get";
import Card from "components/Card";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import Button from "components/Button";
import InBetweenSpacing from "components/InBetweenSpacing";
import SalePortfolioPropertiesStepperModal from "./SalePortfolioPropertiesStepperModal";
import useQueryStringFilters from "hooks/useQueryStringFilters";
import useAsync from "hooks/useAsync";
import loadRESalePropsWithPricesService from "services/re/loadRESalePropsWithPricesService";
import useToast from "hooks/useToast";
import SalePortfolioPropertyTable from "./SalePortfolioPropertyTable";
import history from "browserHistory";
import PermissionValidator from "components/PermissionValidator";
import { useRegionContext } from "contexts/RegionContext";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import CurrencyFormat from "components/CurrencyFormat";

const MODAL_VISIBLE = "visible";
const MODAL_HIDDEN = "hidden";

function SalePortfolioProperties({ isActive, saleId, sale }) {
  const [modalVisibility, setModalVibility] = React.useState(MODAL_HIDDEN);
  const { showError } = useToast();
  const { setQuery, getQuery } = useQueryStringFilters();
  const query = getQuery();
  const { getRegionPath } = useRegionContext();

  const [fetch, pending, data] = useAsync({
    promiseFn: loadRESalePropsWithPricesService,
    eager: true,
    onError: error => {
      showError("Error loading Sale Props");
    }
  });

  React.useEffect(() => {
    handleLoadProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isActive) {
      setQuery({ ...query, step: "properties" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const handleLoadProperties = () => fetch({ saleId });

  return (
    <Card>
      <TwoColumnFormLayout title="Properties" size="sm">
        <InBetweenSpacing>
          <SalePortfolioPropertyTable
            data={get(data, "properties", [])}
            pending={pending}
            saleId={saleId}
            onChange={handleLoadProperties}
          />
          {!pending && (
            <Col alignItems="flex-end">
              <Box component="p">
                Portfolio price:&nbsp;
                <Box component="strong">
                  <CurrencyFormat
                    amount={get(data, "portfolioPrice", 0)}
                    maximumFractionDigits={6}
                  />
                </Box>
              </Box>
              <Box component="p">
                Remaining to allocate:&nbsp;
                <Box component="strong">
                  <CurrencyFormat
                    amount={
                      get(data, "portfolioPrice", 0) -
                      get(data, "totalPropertiesPrice", 0)
                    }
                    maximumFractionDigits={6}
                  />
                </Box>
              </Box>
            </Col>
          )}
          <PermissionValidator
            allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
            shouldHide
          >
            <Col alignItems="flex-end" justifyContent="flex-end">
              <InBetweenSpacing>
                <Button
                  onClick={() => setModalVibility(MODAL_VISIBLE)}
                  text="Add property"
                  icon="add"
                  intent="primary"
                />
                <Button
                  onClick={() => history.push(getRegionPath("/re/sale"))}
                  text="Finish"
                  intent="primary"
                />
              </InBetweenSpacing>
            </Col>
          </PermissionValidator>
        </InBetweenSpacing>
      </TwoColumnFormLayout>

      <SalePortfolioPropertiesStepperModal
        saleId={saleId}
        visible={modalVisibility === MODAL_VISIBLE}
        onRequestClose={() => setModalVibility(MODAL_HIDDEN)}
        onData={handleLoadProperties}
      />
    </Card>
  );
}

SalePortfolioProperties.propTypes = {};

SalePortfolioProperties.defaultProps = {};

export default SalePortfolioProperties;
