/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useMachine } from '@xstate/react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import debounceFetchMachine from '../stateMachines/debounceFetchMachine';
import useMounted from './useMounted';

export default function({
  promiseFn = () => {
    throw new Error('useAsync expects a promiseFn');
  },
  params = null,
  onData = () => {},
  onError = () => {},
}) {
  const mounted = useMounted();

  const [current, send] = useMachine(debounceFetchMachine, {
    context: { promiseFn },
  });

  const { data, error } = current.context;

  React.useEffect(() => {
    if (current.matches('failure')) {
      onError(error, params);
    }
  }, [current.value]);

  React.useEffect(() => {
    if (current.matches('success')) {
      onData(data, params);
    }
  }, [current.value]);

  useDeepCompareEffect(() => {
    if (mounted) {
      fetch(params);
    }
  }, [params]);

  const fetch = React.useCallback(
    (args) =>
      send({
        type: 'UPDATE_PARAMS',
        payload: args,
      }),
    [],
  );

  return { pending: current.matches('fetching'), debouncing: current.matches('debouncing'), data, error };
}