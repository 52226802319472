import React from 'react';
import PrivateLayout from 'components/PrivateLayout';
import { Link } from 'react-router-dom';
import { NonIdealState } from '@blueprintjs/core';

function NotFoundScreen() {
  return (
    <PrivateLayout>
      <NonIdealState
        icon="help"
        title="Page not found"
        description="The page you are trying to reach does not exists."
        action={<Link to="/">Back to home</Link>}
      />
    </PrivateLayout>
  );
}

export default NotFoundScreen;
