import axios from "axios";
import { Auth } from "aws-amplify";

const fetch = ({
  method = "GET",
  path,
  fullUrl,
  params,
  headers,
  responseType,
  data
}) => {
  return new Promise(async (resolve, reject) => {
    const auth = await Auth.currentSession();

    axios({
      headers: {
        ...headers,
        Authorization: auth.idToken.jwtToken
      },
      url: fullUrl || `${process.env.REACT_APP_API_URL}${path}`,
      method: method || "GET",
      params: params,
      responseType: responseType,
      data: data
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.response);
      });
  });
};

export default fetch;
