/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import InputGroup from "../../components/InputGroup";
import Form from "components/Form";
import useQueryStringFilters from "hooks/useQueryStringFilters";
import Grid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import useForm from "hooks/useForm";

function AccountsFilters({ onChange }) {
  const { getQuery } = useQueryStringFilters();
  const { criteria, offering_type, deal_type } = getQuery();
  const { getValue, setValue, clear, submit, clearCount } = useForm({
    initialValues: {
      criteria,
      offering_type,
      deal_type
    },
    onSubmit: onChange
  });

  React.useEffect(() => {
    if (clearCount) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount]);

  return (
    <Form onSubmit={submit}>
      <Grid>
        <Grid.Item>
          <FormGroup>
            <InputGroup
              leftIcon="search"
              placeholder="Search..."
              value={getValue("criteria")}
              onChange={value => setValue("criteria", value)}
              fullWidth
            />
          </FormGroup>
        </Grid.Item>
      </Grid>
    </Form>
  );
}

AccountsFilters.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default AccountsFilters;
