import React from "react";
import { Box } from "jsxstyle";
import useForm from "hooks/useForm";
import Form from "components/Form";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectHYFund from "components/SelectHYFund";
import required from "validators/isRequired";
import useData from "hocs/useData";
import loadHYFundsService from "services/hy/loadHYFundsService";

function HYFundAssociationForm({ onSubmit, isSubmitting }) {
  const {
    data: { data: funds },
  } = useData({
    initialParams: { size: 99999 },
    promiseFn: loadHYFundsService,
  });

  const { getValue, setValue, getError, submit } = useForm({
    onSubmit: ({ fund_id }, { reset, clear }) => {
      onSubmit(
        funds.find(({ fund_id: { entity_id } }) => entity_id === fund_id),
      );
      reset();
      clear();
    },
    validations: {
      fund_id: [required],
    },
  });

  return (
    <Form onSubmit={submit}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Fund"
            error={getError("fund_id")}
            disabled={isSubmitting}
            required
          >
            <SelectHYFund
              onSelect={(value) => setValue("fund_id", value)}
              value={getValue("fund_id")}
              error={getError("fund_id")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <Box marginTop={22}>
            <Button
              type="submit"
              text="Add"
              intent="success"
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Box>
        </FormGrid.Item>
      </FormGrid>
    </Form>
  );
}

HYFundAssociationForm.propTypes = {};

HYFundAssociationForm.defaultProps = {};

export default HYFundAssociationForm;
