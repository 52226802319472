import React from "react";
import CurrencyFormat from "components/CurrencyFormat";
import PercentFormat from "components/PercentFormat";

export default [
  {
    title: "Full name",
    dataIndex: "fund_id.long_name",
    key: "fund_id.long_name",
    width: 240,
  },
  {
    title: "Editorial name",
    dataIndex: "fund_id.editorial_name",
    key: "fund_id.editorial_name",
    width: 240,
  },
  {
    title: "Editorial description",
    dataIndex: "fund_id.editorial_description",
    key: "fund_id.editorial_description",
    width: 240,
  },
  {
    title: "Entity type",
    dataIndex: "fund_id.entity_type.long_name",
    key: "fund_id.entity_type.long_name",
    width: 240,
  },
  {
    title: "Parent",
    dataIndex: "fund_id.parent_entity.long_name",
    key: "fund_id.parent_entity.long_name",
    width: 240,
  },
  {
    title: "Fund Type",
    dataIndex: "fund_type.name",
    key: "fund_type.name",
    width: 150,
  },
  {
    title: "Address",
    dataIndex: "fund_id.address",
    key: "fund_id.address",
    width: 240,
  },
  {
    title: "Postal Code",
    dataIndex: "fund_id.postal_code",
    key: "fund_id.postal_code",
    width: 150,
  },
  {
    title: "City",
    dataIndex: "fund_id.city",
    key: "fund_id.city",
    width: 240,
  },
  {
    title: "State",
    dataIndex: "fund_id.state.state_name",
    key: "fund_id.state.state_name",
    width: 240,
  },
  {
    title: "Country",
    dataIndex: "fund_id.country_code.country_code",
    key: "fund_id.country_code.country_code",
    width: 150,
  },
  {
    title: "Target Net Irr Low",
    dataIndex: "target_net_irr_low",
    key: "target_net_irr_low",
    width: 200,
  },
  {
    title: "Target Net Irr High",
    dataIndex: "target_net_irr_high",
    key: "target_net_irr_high",
    width: 200,
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Closed Year",
    dataIndex: "closed_year",
    key: "closed_year",
    width: 150,
  },
  {
    title: "Denomination",
    dataIndex: "denomination.currency_name",
    key: "denomination.currency_name",
    width: 240,
  },
  {
    title: "Goal Amount Current",
    dataIndex: "goal_amount_current",
    key: "goal_amount_current",
    width: 240,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Goal Amount Initial",
    dataIndex: "goal_amount_initial",
    key: "goal_amount_initial",
    width: 240,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Raised Amount",
    dataIndex: "raised_amount",
    key: "raised_amount",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Invested Amount",
    dataIndex: "invested_amount",
    key: "invested_amount",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Released Amount",
    dataIndex: "released_amount",
    key: "released_amount",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Builder",
    dataIndex: "builder",
    key: "builder",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Debt Investor",
    dataIndex: "debt_investor",
    key: "debt_investor",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },

  //target assets
  {
    title: "Target Assets Description",
    dataIndex: "target_assets_description",
    key: "target_assets_description",
    width: 240,
  },
  {
    title: "Target Office",
    dataIndex: "target_office",
    key: "target_office",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Mf",
    dataIndex: "target_mf",
    key: "target_mf",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Retail",
    dataIndex: "target_retail",
    key: "target_retail",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Industrial",
    dataIndex: "target_industrial",
    key: "target_industrial",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Hotel",
    dataIndex: "target_hotel",
    key: "target_hotel",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Data Center",
    dataIndex: "target_data_center",
    key: "target_data_center",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Medical Office",
    dataIndex: "target_medical_office",
    key: "target_medical_office",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Senior Housing",
    dataIndex: "target_senior_housing",
    key: "target_senior_housing",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Student Housing",
    dataIndex: "target_student_housing",
    key: "target_student_housing",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Distressed Debt",
    dataIndex: "target_distressed_debt",
    key: "target_distressed_debt",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Originations",
    dataIndex: "target_originations",
    key: "target_originations",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Pe Mezz",
    dataIndex: "target_pe_mezz",
    key: "target_pe_mezz",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Cmbs",
    dataIndex: "target_cmbs",
    key: "target_cmbs",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Diverse",
    dataIndex: "target_diverse",
    key: "target_diverse",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Land",
    dataIndex: "target_land",
    key: "target_land",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Other",
    dataIndex: "target_other",
    key: "target_other",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Opportunity Zone",
    dataIndex: "target_opportunity_zone",
    key: "target_opportunity_zone",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },

  //target region
  {
    title: "Target Geography Description",
    dataIndex: "target_geography_description",
    key: "target_geography_description",
    width: 240,
  },
  {
    title: "Target Us",
    dataIndex: "target_us",
    key: "target_us",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Non Us",
    dataIndex: "target_non_us",
    key: "target_non_us",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Europe",
    dataIndex: "target_europe",
    key: "target_europe",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Asia",
    dataIndex: "target_asia",
    key: "target_asia",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Global",
    dataIndex: "target_global",
    key: "target_global",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Other Region",
    dataIndex: "target_other_region",
    key: "target_other_region",
    width: 240,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Northeast",
    dataIndex: "target_northeast",
    key: "target_northeast",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Metro Ny",
    dataIndex: "target_metro_ny",
    key: "target_metro_ny",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Midatlantic",
    dataIndex: "target_midatlantic",
    key: "target_midatlantic",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Southeast",
    dataIndex: "target_southeast",
    key: "target_southeast",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Midwest",
    dataIndex: "target_midwest",
    key: "target_midwest",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target South",
    dataIndex: "target_south",
    key: "target_south",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Southwest",
    dataIndex: "target_southwest",
    key: "target_southwest",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target Northwest",
    dataIndex: "target_northwest",
    key: "target_northwest",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Target California",
    dataIndex: "target_california",
    key: "target_california",
    width: 200,
    render: (value) => (value ? "Yes" : "No"),
  },

  //management and sponsors
  {
    title: "Sponsor Contrib",
    dataIndex: "sponsor_contrib",
    key: "sponsor_contrib",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Management Fee Committed",
    dataIndex: "management_fee_committed",
    key: "management_fee_committed",
    width: 240,
    render: (amount) => <PercentFormat amount={amount || 0} />,
  },
  {
    title: "Management Fee Invested",
    dataIndex: "management_fee_invested",
    key: "management_fee_invested",
    width: 240,
    render: (amount) => <PercentFormat amount={amount || 0} />,
  },
  {
    title: "Preferred Return",
    dataIndex: "preferred_return",
    key: "preferred_return",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Fee Note",
    dataIndex: "fee_note",
    key: "fee_note",
    width: 400,
  },
  {
    title: "Leverage Percent",
    dataIndex: "leverage_percent",
    key: "leverage_percent",
    width: 150,
    render: (amount) => <PercentFormat amount={amount || 0} />,
  },
  {
    title: "Suspended",
    dataIndex: "suspended",
    key: "suspended",
    width: 150,
  },
  {
    title: "Suspended Date Start",
    dataIndex: "suspended_date_start",
    key: "suspended_date_start",
    width: 240,
  },
  {
    title: "Suspended Date End",
    dataIndex: "suspended_date_end",
    key: "suspended_date_end",
    width: 240,
  },
  {
    title: "Exclude",
    dataIndex: "exclude",
    key: "exclude",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Exclude Reason",
    dataIndex: "exclude_reason",
    key: "exclude_reason",
    width: 150,
  },
  {
    title: "Added Date",
    dataIndex: "added_date",
    key: "added_date",
    width: 150,
  },
  {
    title: "Active Date",
    dataIndex: "active_date",
    key: "active_date",
    width: 150,
  },
  {
    title: "Inactive Date",
    dataIndex: "inactive_date",
    key: "inactive_date",
    width: 150,
  },
  {
    title: "Update Date",
    dataIndex: "update_date",
    key: "update_date",
    width: 150,
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    width: 150,
  },
  {
    title: "Public Note",
    dataIndex: "public_note",
    key: "public_note",
    width: 400,
  },
  {
    title: "Private Note",
    dataIndex: "private_note",
    key: "private_note",
    width: 400,
  },
];
