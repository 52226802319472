import React from "react";
import PropTypes from "prop-types";
import { Callout as BluePrintCallout } from "@blueprintjs/core";

function Callout({ intent, children, title }) {
  return (
    <BluePrintCallout intent={intent} title={title}>
      {children}
    </BluePrintCallout>
  );
}

Callout.propTypes = {
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  children: PropTypes.node
};

Callout.defaultProps = {
  intent: "primary",
  children: null,
  title: null
};

export default Callout;
