import fetch from "../fetch";

export default ({ values, dealCode, borrowerId }) =>
  fetch({
    method: borrowerId ? "PUT" : "POST",
    path: `/cmbs/deals/${dealCode}/borrowers${
      borrowerId ? `/${borrowerId}` : ""
    }`,
    data: {
      entity_id: values.entity_id,
    },
  });
