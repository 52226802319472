import React from "react";
import PropTypes from "prop-types";
import { InputGroup as BluePrintInputGroup } from "@blueprintjs/core";

function InputGroup({
  label,
  disabled,
  intent,
  size,
  leftIcon,
  onChange,
  placeholder,
  rightElement,
  round,
  type,
  value,
  fullWidth,
  maxLength,
  inputRef,
  readOnly,
}) {
  return (
    <BluePrintInputGroup
      disabled={disabled}
      intent={intent}
      large={size === "lg"}
      small={size === "sm"}
      leftIcon={leftIcon}
      onChange={(e) => onChange(e.target.value || null)}
      placeholder={placeholder}
      rightElement={rightElement}
      round={round}
      type={type}
      value={value || ""}
      fill={fullWidth}
      maxLength={maxLength}
      inputRef={inputRef}
      readOnly={readOnly}
    />
  );
}

InputGroup.propTypes = {
  disabled: PropTypes.bool,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  leftIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rightElement: PropTypes.node,
  round: PropTypes.bool,
  type: PropTypes.oneOf(["text", "password", "email", "numeric"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
};

InputGroup.defaultProps = {
  value: undefined,
  disabled: false,
  intent: "none",
  size: "md",
  leftIcon: null,
  onChange: () => null,
  placeholder: null,
  rightElement: null,
  round: false,
  type: "text",
  fullWidth: false,
  maxLength: null,
  readOnly: false,
};

export default InputGroup;
