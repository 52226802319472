import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadREAttributesService from "services/re/loadREAttributesService";

function SelectAttribute({
  disabled,
  error,
  onSelect,
  placeholder,
  subSectorId,
  readOnly,
  value,
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadREAttributesService}
      onSelect={onSelect}
      idPath="attribute_id"
      disabled={!subSectorId || disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      textPath="attribute"
      value={value}
      readOnly={readOnly}
      params={{ sub_sector_id: subSectorId }}
    />
  );
}

SelectAttribute.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  subSectorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SelectAttribute.defaultProps = {
  disabled: false,
  error: null,
  placeholder: "Search...",
  subSectorId: null,
  readOnly: false,
  value: undefined,
};

export default SelectAttribute;
