import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import moment from "moment";
import { Row, Box } from "jsxstyle";
import InputGroup from "components/InputGroup";
import Form from "components/Form";
import Separator from "components/Separator";
import Button from "components/Button";
import FormGroup from "components/FormGroup";
import CurrentFiltersList from "components/CurrentFiltersList";
import AdvancedSearch from "components/AdvancedSearch";
import SelectCLODealType from "components/SelectCLODealType";
import SelectCollateralCode from "components/SelectCollateralCode";
import DateRange from "components/DateRange";
import SelectRegionCode from "components/SelectRegionCode";
import SelectEntityMaster from "components/SelectEntityMaster";
import Switch from "components/Switch";

function CLOFilters({ onChange, currentFilters }) {
  const [isAdvancedFiltersOpen, setAdvancedFiltersOpen] = React.useState(false);
  const [dealCodeValue, setDealCodeValue] = React.useState(
    currentFilters.deal_code
  );
  const advancedSearchFilters = omit(currentFilters, ["deal_code"]);
  React.useEffect(() => {
    if (!currentFilters.deal_code) {
      setDealCodeValue("");
    }
  }, [currentFilters.deal_code]);

  return (
    <>
      <Row alignItems="flex-start">
        <Form
          onSubmit={() => {
            onChange({ deal_code: dealCodeValue });
          }}
        >
          <Row width={350} alignItems="flex-start">
            <Box flex={1}>
              <FormGroup>
                <InputGroup
                  leftIcon="search"
                  placeholder="Search by deal code"
                  value={dealCodeValue}
                  onChange={value => setDealCodeValue(value)}
                  fullWidth
                />
              </FormGroup>
            </Box>
            <Separator />
            <Button
              intent="primary"
              icon="search"
              text="Search"
              type="submit"
            />
          </Row>
        </Form>
        <Separator />
        <Row alignItems="center">
          <AdvancedSearch
            currentFilters={advancedSearchFilters}
            isOpen={isAdvancedFiltersOpen}
            onRequestOpen={() => setAdvancedFiltersOpen(true)}
            onRequestClose={() => setAdvancedFiltersOpen(false)}
            onSubmit={values =>
              onChange({
                ...values,
                pricing_date:
                  values.pricing_date &&
                  values.pricing_date.map(date =>
                    moment(date).format("YYYY-MM-DD")
                  )
              })
            }
          >
            {({ getValue, setValue }) => (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, 300px)"
                gridGap={16}
              >
                <FormGroup label="Deal abbreviation">
                  <InputGroup
                    value={getValue("abbrv")}
                    onChange={value => setValue("abbrv", value)}
                  />
                </FormGroup>
                <FormGroup label="Issuer">
                  <InputGroup
                    value={getValue("issuer")}
                    onChange={value => setValue("issuer", value)}
                  />
                </FormGroup>
                <FormGroup label="Series">
                  <InputGroup
                    value={getValue("series")}
                    onChange={value => setValue("series", value)}
                    maxLength={50}
                  />
                </FormGroup>
                <FormGroup label="Deal type" required>
                  <SelectCLODealType
                    onSelect={value => setValue("deal_type", value)}
                    value={getValue("deal_type")}
                  />
                </FormGroup>
                <FormGroup label="Collateral type">
                  <SelectCollateralCode
                    onSelect={value => setValue("collateral", value)}
                    value={getValue("collateral")}
                    params={{ is_col: true }}
                  />
                </FormGroup>
                <FormGroup label="Secondary collateral type">
                  <SelectCollateralCode
                    onSelect={value => setValue("collateral_sub", value)}
                    value={getValue("collateral_sub")}
                    params={{ is_col2: true }}
                  />
                </FormGroup>
                <FormGroup label="Region of distribution">
                  <SelectRegionCode
                    onSelect={value => setValue("distribution_region", value)}
                    value={getValue("distribution_region")}
                  />
                </FormGroup>
                <FormGroup label="Underwriter">
                  <SelectEntityMaster
                    onSelect={value => setValue("underwriter_entity_id", value)}
                    value={getValue("underwriter_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Bookrunners only">
                  <Switch
                    value={getValue("only_book_runners_underwriter")}
                    onChange={value =>
                      setValue(
                        "only_book_runners_underwriter",
                        !getValue("only_book_runners_underwriter")
                      )
                    }
                  />
                </FormGroup>
                <FormGroup label="Rating Agency">
                  <SelectEntityMaster
                    onSelect={value => setValue("rating_entity_id", value)}
                    value={getValue("rating_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Seller">
                  <SelectEntityMaster
                    onSelect={value => setValue("seller", value)}
                    value={getValue("seller")}
                  />
                </FormGroup>
                <FormGroup label="Sponsor">
                  <SelectEntityMaster
                    onSelect={value => setValue("sponsor", value)}
                    value={getValue("sponsor")}
                  />
                </FormGroup>
                <FormGroup label="Trustee">
                  <SelectEntityMaster
                    onSelect={value => setValue("trustee", value)}
                    value={getValue("trustee")}
                  />
                </FormGroup>
                <FormGroup label="Legal counsel">
                  <SelectEntityMaster
                    onSelect={value => setValue("counsel_entity_id", value)}
                    value={getValue("counsel_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Pricing date">
                  <DateRange
                    onChange={value => setValue("pricing_date", value)}
                    value={getValue("pricing_date")}
                  />
                </FormGroup>
              </Box>
            )}
          </AdvancedSearch>
          <Separator />
          <Button
            icon="remove"
            minimal
            onClick={() => onChange({})}
            text="Clear filters"
          />
        </Row>
      </Row>
      <CurrentFiltersList
        filters={currentFilters}
        onRemove={key => {
          onChange({
            ...currentFilters,
            [key]: null
          });
        }}
      />
    </>
  );
}

CLOFilters.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default CLOFilters;
