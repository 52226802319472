import fetch from "../fetch";
import get from "lodash/get";

export default ({ id, values }) => {
  return fetch({
    path: id ? `/hy/funds/${id}` : "/hy/funds",
    method: id ? "PUT" : "POST",
    data: {
      ...values,
      managers: get(values, "managers", []).map(
        ({ manager_id: { entity_id } }) => entity_id,
      ),
    },
  });
};
