import React from "react";
import { Divider } from "@blueprintjs/core";
import { useRegionContext } from 'contexts/RegionContext';
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import getREPropById from "services/re/getREPropById";
import REPropForm from "./REPropForm";
import ScreenLoading from "components/ScreenLoading";
import REPropSaleTable from "./REPropSaleTable";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import Card from "components/Card";

function EditREPropScreen({ match }) {
  const { regionName } = useRegionContext();
  const { propertyId } = match.params;
  const { showError } = useToast();

  // eslint-disable-next-line no-unused-vars
  const [fetch, pending, data] = useAsync({
    promiseFn: getREPropById,
    onError: () => showError("Error loading Property")
  });

  React.useEffect(() => {
    if (propertyId) {
      fetch({ propertyId });
    }
  }, [propertyId, fetch]);

  return (
    <PrivateLayout>
      <Heading size="md">{`Edit ${regionName} Property - ${propertyId}`}</Heading>
      <Separator />
      {pending ? (
        <ScreenLoading />
      ) : (
        <Card>
          <TwoColumnFormLayout size="sm" title="Property">
            <REPropForm initialValues={data} />
          </TwoColumnFormLayout>
          <Divider style={{ margin: "30px 0 30px 0" }} />
          <TwoColumnFormLayout size="sm" title="Related Sales">
            <REPropSaleTable bordered propertyId={propertyId} />
          </TwoColumnFormLayout>
        </Card>
      )}
    </PrivateLayout>
  );
}

export default EditREPropScreen;
