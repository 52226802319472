import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { REGION_CODES, REGION_NAMES_MAP } from 'consts';
import NotFoundScreen from "screens/not-found/NotFoundScreen";

const RegionContext = React.createContext();

const RegionContextProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search);
  let region = search.region;
  // default region to U.S. if no region is in query string
  if (!region) {
    region = REGION_CODES.USA;
    const queryString = `?${qs.stringify({ ...search, region })}`;
    history.push({ search: queryString});
  }
  if (!Object.values(REGION_CODES).includes(region)) return <NotFoundScreen/>;

  return (
    <RegionContext.Provider value={region}>
        {children}
    </RegionContext.Provider>
  );
}

const useRegionContext = () => {
  const region = React.useContext(RegionContext);
  if (region === undefined) {
    throw new Error('useRegionContext must be used within a RegionProvider')
  }
  const getRegionPath = (path) => {
    if (!path || typeof path !== 'string') 
      throw Error('Must provide a string path to getRegionPath');
    return `${path}?region=${region}`;
  }
  const regionName = REGION_NAMES_MAP[region];

  return {
    getRegionPath,
    region,
    regionName,
  };
}

export {
  RegionContextProvider, 
  useRegionContext,
};