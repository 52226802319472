import PropTypes from "prop-types";

function PermissionValidator({ children, allowedFor, shouldHide }) {
  const isAllowed =
    !allowedFor ||
    allowedFor.some(role => role === sessionStorage.getItem("role"));
  if (!isAllowed && shouldHide) {
    return null;
  }
  return typeof children === "function" ? children({ isAllowed }) : children;
}

PermissionValidator.propTypes = {
  allowedFor: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  shouldHide: PropTypes.bool
};

PermissionValidator.defaultProps = {
  allowedFor: null,
  shouldHide: true
};

export default PermissionValidator;
