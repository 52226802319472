import React from "react";
import PropTypes from "prop-types";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import Button from "components/Button";
import useForm from "hooks/useForm";
import Callout from "components/Callout";
import Form from "components/Form";
import isRequired from "validators/isRequired";
import isEmail from "validators/isEmail";
import Separator from "components/Separator";

function LoginForm({ onSubmit, pending, error }) {
  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      email: [isRequired, isEmail],
      password: [isRequired]
    },
    onSubmit
  });

  return (
    <>
      <Form onSubmit={submit}>
        <FormGroup label="Email" error={getError("email")}>
          <InputGroup
            type="email"
            leftIcon="user"
            size="lg"
            value={getValue("email")}
            onChange={value => setValue("email", value)}
            error={getError("email")}
            disabled={pending}
          />
        </FormGroup>
        <FormGroup
          label="Password"
          onChange={value => setValue("password", value)}
          error={getError("password")}
        >
          <InputGroup
            type="password"
            leftIcon="lock"
            size="lg"
            value={getValue("password")}
            onChange={value => setValue("password", value)}
            error={getError("password")}
            disabled={pending}
          />
        </FormGroup>
        <Button
          intent="primary"
          text="Login"
          icon="log-in"
          size="lg"
          type="submit"
          fullWidth
          loading={pending}
        />
      </Form>
      <Separator />
      {error && <Callout intent="danger">An error happened</Callout>}
    </>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  error: PropTypes.string
};

LoginForm.defaultProps = {
  pending: false,
  error: null
};

export default LoginForm;
