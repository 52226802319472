import React from "react";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import SelectCountryCode from "components/SelectCountryCode";
import SelectEntityMaster from "components/SelectEntityMaster";
import PermissionValidator from "components/PermissionValidator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import Separator from "components/Separator";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";

function ContactBasicInfoFields({
  getValue,
  setValue,
  getError,
  isSubmitting,
}) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <>
          <TwoColumnFormLayout title="Basic info" size="sm">
            <FormGrid>
              <FormGrid.Item>
                <FormGroup
                  label="First name"
                  error={getError("first_name")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={100}
                    intent={getError("first_name") ? "danger" : null}
                    value={getValue("first_name")}
                    onChange={(value) => setValue("first_name", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Last name"
                  error={getError("last_name")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={100}
                    intent={getError("last_name") ? "danger" : null}
                    value={getValue("last_name")}
                    onChange={(value) => setValue("last_name", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Job title"
                  error={getError("job_title")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={100}
                    intent={getError("job_title") ? "danger" : null}
                    value={getValue("job_title")}
                    onChange={(value) => setValue("job_title", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Company"
                  error={getError("company_id")}
                  disabled={isSubmitting}
                >
                  <SelectEntityMaster
                    onSelect={(value) => setValue("company_id", value)}
                    value={getValue("company_id")}
                    error={getError("company_id")}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Phone"
                  disabled={isSubmitting}
                  error={getError("phone")}
                >
                  <InputGroup
                    maxLength={20}
                    intent={getError("phone") ? "danger" : null}
                    value={getValue("phone")}
                    onChange={(value) => setValue("phone", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Phone ext"
                  disabled={isSubmitting}
                  error={getError("phone_ext")}
                >
                  <InputGroup
                    maxLength={8}
                    intent={getError("phone_ext") ? "danger" : null}
                    value={getValue("phone_ext")}
                    onChange={(value) => setValue("phone_ext", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Cell"
                  error={getError("cell")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={20}
                    intent={getError("cell") ? "danger" : null}
                    value={getValue("cell")}
                    onChange={(value) => setValue("cell", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Fax"
                  error={getError("fax")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={20}
                    intent={getError("fax") ? "danger" : null}
                    value={getValue("fax")}
                    onChange={(value) => setValue("fax", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="E-mail"
                  error={getError("email")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={100}
                    intent={getError("email") ? "danger" : null}
                    value={getValue("email")}
                    onChange={(value) => setValue("email", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
            </FormGrid>
          </TwoColumnFormLayout>
          <Separator />
          <TwoColumnFormLayout title="Address info" size="sm">
            <FormGrid>
              <FormGrid.Item>
                <FormGroup
                  label="Address"
                  error={getError("mailing_address")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={100}
                    intent={getError("mailing_address") ? "danger" : null}
                    value={getValue("mailing_address")}
                    onChange={(value) => setValue("mailing_address", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="City"
                  error={getError("mailing_city")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={100}
                    intent={getError("mailing_city") ? "danger" : null}
                    value={getValue("mailing_city")}
                    onChange={(value) => setValue("mailing_city", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="State"
                  error={getError("mailing_state")}
                  disabled={isSubmitting}
                >
                  <SelectNorthAmericanState
                    onSelect={(value) => {
                      setValue("mailing_state", value);
                    }}
                    value={getValue("mailing_state")}
                    error={getError("mailing_state")}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Postal code"
                  error={getError("mailing_postal_code")}
                  disabled={isSubmitting}
                >
                  <InputGroup
                    maxLength={10}
                    intent={getError("mailing_postal_code") ? "danger" : null}
                    value={getValue("mailing_postal_code")}
                    onChange={(value) => setValue("mailing_postal_code", value)}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Country"
                  error={getError("mailing_country")}
                  disabled={isSubmitting}
                >
                  <SelectCountryCode
                    onSelect={(value) => setValue("mailing_country", value)}
                    value={getValue("mailing_country")}
                    error={getError("mailing_country")}
                    readOnly={!isAllowed}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
            </FormGrid>
          </TwoColumnFormLayout>
        </>
      )}
    </PermissionValidator>
  );
}

ContactBasicInfoFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

ContactBasicInfoFields.defaultProps = {
  isSubmitting: false,
};

export default ContactBasicInfoFields;
