import React from "react";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import {
  Navbar as BluePrintNavbar,
  Alignment,
  Tabs,
  Tab,
} from "@blueprintjs/core";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "components/Separator";
import PrivateLayout from "components/PrivateLayout";
import PermissionValidator from "components/PermissionValidator";
import ContactFundTab from "./funds/ContactFundTab";
import ContactBasicInfoTab from "./basic-info/ContactBasicInfoTab";

function EditContactLayout({ contact, onContactChange }) {
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <PrivateLayout>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Heading size="md">
            {isAllowed ? "Edit" : "View"} Contact - {contact.contact_id}
          </Heading>
        )}
      </PermissionValidator>
      <Separator />
      <React.Fragment>
        <BluePrintNavbar>
          <BluePrintNavbar.Group align={Alignment.LEFT}>
            <Tabs
              large={true}
              onChange={(nextTabId) => setCurrentTab(nextTabId)}
              selectedTabId={currentTab}
            >
              <Tab id={0} title="Basic Info" />
              <Tab id={1} title="Funds" />
            </Tabs>
          </BluePrintNavbar.Group>
        </BluePrintNavbar>
        <Card>
          {
            {
              0: (
                <ContactBasicInfoTab
                  onSubmit={onContactChange}
                  initialValues={contact}
                />
              ),
              1: <ContactFundTab contact={contact} />,
            }[currentTab]
          }
        </Card>
      </React.Fragment>
    </PrivateLayout>
  );
}

export default EditContactLayout;
