import React from "react";
import PropTypes from "prop-types";

const getHeightBySize = size =>
  ({
    xs: 10,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30
  }[size]);

const InBetweenSpacing = ({ children, size }) => {
  return Array.isArray(children)
    ? React.Children.map(children, (child, index) => (
        <React.Fragment key={index}>
          {child}
          <div
            style={{
              height: getHeightBySize(size),
              width: getHeightBySize(size)
            }}
          />
        </React.Fragment>
      ))
    : children;
};

InBetweenSpacing.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

InBetweenSpacing.defaultProps = { size: "md" };

export default InBetweenSpacing;
