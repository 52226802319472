import React from "react";
import { Box } from "jsxstyle";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import Form from "components/Form";
import useForm from "hooks/useForm";

function RESellersForm({ onSubmit, initialValues, isSubmitting }) {
  const { getValue, setValue, getError, submit } = useForm({
    onSubmit: (values, { reset, clear }) => {
      onSubmit(values.entity_id);
      reset();
      clear();
    },
    initialValues
  });

  return (
    <Form onSubmit={() => submit()}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Seller"
            disabled={isSubmitting}
            error={getError("entity_id")}
          >
            <SelectEntityMaster
              onSelect={value => setValue("entity_id", value)}
              value={getValue("entity_id")}
              error={getError("entity_id")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <Box marginTop={22}>
            <Button
              type="submit"
              text="Add"
              intent="success"
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Box>
        </FormGrid.Item>
      </FormGrid>
    </Form>
  );
}

RESellersForm.propTypes = {};

RESellersForm.defaultProps = {};

export default RESellersForm;
