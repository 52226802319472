import fetch from "../fetch";

export default ({ entityId, confirmation, dateEnd }) =>
  fetch({
    method: "DELETE",
    path: `/em/entities/${entityId}`,
    data: {
      long_name: confirmation,
      date_end: dateEnd,
      entity_code: entityId
    },
  });
