import React from "react";
import { Row } from "jsxstyle";
import history from "browserHistory";
import { withRouter } from "react-router-dom";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import isRequired from "validators/isRequired";
import saveHYFundService from "services/hy/saveHYFundService";
import Form from "components/Form";
import Button from "components/Button";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import HYFundBasicInfoFields from "./HYFundBasicInfoFields";
import HYManagerAssociationForm from "./HYManagerAssociationForm";
import HYManagerAssociationTable from "./HYManagerAssociationTable";
import get from "lodash/get";

function HYFundBasicInfoTab({ onSubmit, initialValues, isNew = true }) {
  const { showSuccess, showError } = useToast();

  const [fetch, pending] = useAsync({
    promiseFn: saveHYFundService,
    onData: (fund) => {
      showSuccess("HY Fund saved.");
      onSubmit(fund);
      if (isNew) {
        history.push(`/hy/fund/new/${fund.fund_id.entity_id}/target-assets`);
      }
    },
    onError: ({ data: { error, message } = {} }) => {
      const errorMessage =
        {
          fund_id_exists: "Existing Fund ID",
        }[error] || "An error happened.";
      showError(errorMessage);
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: {
      ...initialValues,
      fund_id: {
        ...get(initialValues, "fund_id"),
        entity_type: get(initialValues, "fund_id.entity_type", "refund"),
      },
    },
    onSubmit: (values) =>
      fetch({ id: isNew ? undefined : values.fund_id.entity_id, values }),
    validations: {
      fund_id: [isRequired],
      "fund_id.long_name": [isRequired],
      "fund_id.entity_type": [isRequired],
      "fund_id.date_start": [isRequired],
      "fund_id.editorial_name": [isRequired],
    },
  });

  return (
    <Form onSubmit={submit}>
      <TwoColumnFormLayout title="HY Fund" size="sm">
        <HYFundBasicInfoFields
          key={getValue("fund_id.entity_id")}
          getValue={getValue}
          setValue={setValue}
          getError={getError}
          isSubmitting={pending}
          isNew={isNew}
        />
      </TwoColumnFormLayout>
      <Separator size="xl" />
      <TwoColumnFormLayout title="HY Managers" size="sm">
        <HYManagerAssociationForm
          onSubmit={(manager) => {
            if (
              !(getValue("managers") || []).find(
                ({ manager_id: { entity_id } }) =>
                  entity_id === manager.manager_id.entity_id,
              )
            ) {
              setValue("managers", [...getValue("managers"), manager]);
            }
          }}
        />
        <Separator />
        <HYManagerAssociationTable
          managers={getValue("managers", [])}
          onRequestDelete={({ manager_id: { entity_id: deleteId } }) =>
            setValue(
              "managers",
              getValue("managers").filter(
                (manager) => manager.manager_id.entity_id !== deleteId,
              ),
            )
          }
        />
      </TwoColumnFormLayout>
      <Separator size="xl" />
      <Row justifyContent="flex-end">
        <Button
          type="submit"
          text={isNew ? "Next" : "Save"}
          intent="primary"
          disabled={pending}
          loading={pending}
        />
      </Row>
    </Form>
  );
}

export default withRouter(HYFundBasicInfoTab);
