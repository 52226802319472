import React from "react";

export default [
  { title: "City", dataIndex: "city", key: "city", width: 240 },
  { title: "Country", dataIndex: "country_code", key: "country_code", width: 240 },
  {
    title: "Geocode accuracy",
    dataIndex: "geocode_accuracy",
    key: "geocode_accuracy",
    width: 200,
  },
  {
    title: "Geocode address",
    dataIndex: "geocode_address",
    key: "geocode_address",
    width: 240,
  },
  {
    title: "Long lat",
    dataIndex: "long_lat",
    key: "long_lat",
    width: 240,
    render: (long_lat) =>
      long_lat && <span>{`${long_lat.x}, ${long_lat.y}`}</span>,
  },
  { title: "Market", dataIndex: "market", key: "market", width: 240 },
  {
    title: "Mixed use",
    dataIndex: "mixed_use",
    key: "mixed_use",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Opportunity zone",
    dataIndex: "opportunity_zone",
    key: "opportunity_zone",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Prop sub type",
    dataIndex: "prop_sub_type",
    key: "prop_sub_type",
    width: 150,
  },
  { title: "Prop type", dataIndex: "prop_type", key: "prop_type", width: 150 },
  {
    title: "Property 1",
    dataIndex: "property_1",
    key: "property_1",
    width: 240,
  },
  {
    title: "Property 2",
    dataIndex: "property_2",
    key: "property_2",
    width: 240,
  },
  {
    title: "Property longform",
    dataIndex: "property_longform",
    key: "property_longform",
    width: 240,
  },
  { title: "Sf sold", dataIndex: "sf_sold", key: "sf_sold", width: 150 },
  { title: "Sf total", dataIndex: "sf_total", key: "sf_total", width: 150 },
  { title: "Units", dataIndex: "units", key: "units", width: 150 },
  { title: "State", dataIndex: "state", key: "state", width: 150 },
  {
    title: "Sector",
    dataIndex: "sector_id.sector",
    key: "sector_id.sector",
    width: 240,
  },
  {
    title: "Sub Sector",
    dataIndex: "sub_sector_id.sector",
    key: "sub_sector_id.sector",
    width: 240,
  },
  {
    title: "Attribute",
    dataIndex: "attribute_id.attribute",
    key: "attribute_id.attribute",
    width: 240,
  },
];
