import React from "react";
import { Box } from "jsxstyle";
import { useDrop } from "react-dnd";
import FieldItem from "./FieldItem";

const FieldsTarget = ({ items }) => {
  const [{ isOver }, drop] = useDrop({
    accept: "visibleColumnItem",
    drop: () => ({ name: "fieldsTarget" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <Box
      props={{ ref: drop }}
      border={isOver ? "1px dashed #000" : "1px solid transparent"}
    >
      {items.map((item, index) => {
        return <FieldItem key={index} index={item.index} name={item.name} />;
      })}
    </Box>
  );
};

FieldsTarget.propTypes = {};

FieldsTarget.defaultProps = {};

export default FieldsTarget;
