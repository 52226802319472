import React from "react";
import PropTypes from "prop-types";
import range from "lodash/range";
import {
  ControlGroup,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
import { Row, Box } from "jsxstyle";

function Pagination({ page, size, total, onChange }) {
  const totalPages = Math.ceil(
    Number(String(total || 0)) / Number(String(size || 1)),
  );
  const [pageInputValue, setPageInputValue] = React.useState(
    Number(String(page)),
  );
  React.useEffect(() => {
    setPageInputValue(Number(String(page)));
  }, [page]);

  const disabled = totalPages <= 1;

  return (
    <Row alignItems="center" justifyContent="flex-end" width={450}>
      <Box width={150} marginLeft={10}>
        <small>
          Showing page {page} of {totalPages}
        </small>
      </Box>
      <ControlGroup fill={true} vertical={false} disabled={totalPages === 1}>
        <ButtonGroup
          buttons={[
            {
              icon: "double-chevron-left",
              onClick: () => onChange({ page: 1 }),
              disabled: disabled,
            },
            {
              icon: "chevron-left",
              onClick: () => onChange({ page: Number(String(page)) - 1 }),
              disabled: disabled,
            },
          ]}
        />
        <InputGroup
          //allowNumericCharactersOnly={true}
          type="number"
          min={1}
          max={totalPages}
          disabled={disabled}
          placeholder="Page"
          rightElement={
            <Button
              disabled={disabled}
              icon="arrow-right"
              onClick={() => onChange({ page: pageInputValue })}
            />
          }
          value={pageInputValue}
          onChange={(e) => setPageInputValue(Number(String(e.target.value)))}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onChange({ page: pageInputValue });
            }
          }}
        />
        <ButtonGroup
          buttons={[
            {
              render: (
                <Popover
                  key="popover"
                  disabled={disabled}
                  content={
                    <Menu>
                      {range(1, totalPages + 1).map((page) => (
                        <MenuItem
                          key={page}
                          text={page}
                          onClick={() => onChange({ page })}
                        />
                      ))}
                    </Menu>
                  }
                  position={Position.RIGHT_TOP}
                >
                  <Button icon="caret-down" disabled={disabled} />
                </Popover>
              ),
            },
            {
              icon: "chevron-right",
              onClick: () => onChange({ page: Number(String(page)) + 1 }),
              disabled: disabled,
            },
            {
              icon: "double-chevron-right",
              onClick: () => onChange({ page: totalPages }),
              disabled: disabled,
            },
          ]}
        />
      </ControlGroup>
    </Row>
  );
}

Pagination.propTypes = { onChange: PropTypes.func.isRequired };

Pagination.defaultProps = {};

export default Pagination;
