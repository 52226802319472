import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import deleteRESalePropService from "services/re/deleteRESalePropService";

function DeleteProperty({ propertyId, onDelete, saleId }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteRESalePropService,
    onError: error => {
      showError("Error deleting Property");
    },
    onData: () => {
      showSuccess("Property deleted successfully.");
      onDelete();
    }
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ saleId, propertyId })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete this Property <b>{propertyId}</b>?
      </Alert>
    </>
  );
}

DeleteProperty.propTypes = {
  propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onDelete: PropTypes.func.isRequired
};

DeleteProperty.defaultProps = {};

export default DeleteProperty;
