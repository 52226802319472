import React from "react";
import PropTypes from "prop-types";
import { TextArea as BluePrintTextArea } from "@blueprintjs/core";
import "./TextArea.css";
function TextArea({
  disabled,
  intent,
  size,
  leftIcon,
  onChange,
  placeholder,
  rightElement,
  round,
  type,
  value,
  fullWidth,
  readOnly,
}) {
  return (
    <BluePrintTextArea
      disabled={disabled}
      intent={intent}
      large={size === "lg"}
      small={size === "sm"}
      leftIcon={leftIcon}
      onChange={(e) => onChange(e.target.value || null)}
      placeholder={placeholder}
      rightElement={rightElement}
      type={type}
      value={value || ""}
      fill={fullWidth}
      readOnly={readOnly}
      className="taller-area"
    />
  );
}

TextArea.propTypes = {
  disabled: PropTypes.bool,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  leftIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rightElement: PropTypes.node,
  type: PropTypes.oneOf(["text", "password", "email", "numeric"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
};

TextArea.defaultProps = {
  disabled: false,
  intent: "none",
  size: "md",
  leftIcon: null,
  onChange: () => null,
  placeholder: null,
  rightElement: null,
  type: "text",
  fullWidth: false,
  value: undefined,
};

export default TextArea;
