import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Callout from "components/Callout";
import Separator from "components/Separator";
import ForgotPasswordForm from "./ForgotPasswordForm";
import EnterCodeForm from "./EnterCodeForm";

function ForgotPasswordScreen({ backToLogin }) {
  const [viewState, setViewState] = React.useState("FORGOT_PASSWORD");

  if (viewState === "FORGOT_PASSWORD") {
    return (
      <>
        <ForgotPasswordForm
          onComplete={email => {
            setViewState("ENTER_CODE");
          }}
        />
        <Separator />
        <Button
          text="Back to Login"
          small
          onClick={backToLogin}
        />
      </>
    );
  }

  if (viewState === "ENTER_CODE") {
    return (
      <EnterCodeForm
        onComplete={() => setViewState("PASSWORD_RESET_SUCCESSFULLY")}
      />
    );
  }
  return (
    <>
      <Callout intent="success">Password reseted successfully.</Callout>
      <Separator />
      <Button
        intent="primary"
        text="Go to login"
        fullWidth
        onClick={backToLogin}
      />
    </>
  );
}

ForgotPasswordScreen.propTypes = {
  backToLogin: PropTypes.func.isRequired
};

export default ForgotPasswordScreen;
