import React from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { Navbar as BluePrintNavbar, Alignment } from "@blueprintjs/core";
import Card from "components/Card";
import Separator from "components/Separator";
import ABSEditDealTab from "./deal/ABSEditDealTab";
import ABSUnderwritersTab from "./underwriters/ABSUnderwritersTab";
import ABSTranchePricesTab from "./tranchePrices/ABSTranchePricesTab";
import GeographicDistributionTab from "./geographicDistribution/GeographicDistributionTab";
import PrivateLayout from "components/PrivateLayout";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Heading from "components/Heading";
import ABSRatingAgenciesTab from "./ratingAgency/ABSRatingAgenciesTab";

function EditABSScreen({ deal, onDealChange }) {
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <PrivateLayout>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Heading size="md">
            {isAllowed ? "Edit" : "View"} ABS Deal - {deal.deal_code}
          </Heading>
        )}
      </PermissionValidator>
      <Separator />
      <React.Fragment>
        <BluePrintNavbar>
          <BluePrintNavbar.Group align={Alignment.LEFT}>
            <Tabs
              large={true}
              onChange={nextTabId => setCurrentTab(nextTabId)}
              selectedTabId={currentTab}
            >
              <Tab id={0} title="ABS Deal" />
              <Tab id={1} title="Underwriters" />
              <Tab id={2} title="Rating Agencies" />
              <Tab id={3} title="Tranche Prices and Ratings" />
              <Tab id={4} title="Geographic Distribution" />
            </Tabs>
          </BluePrintNavbar.Group>
        </BluePrintNavbar>
        <Card>
          {
            {
              0: (
                <ABSEditDealTab onSubmit={onDealChange} initialValues={deal} />
              ),
              1: <ABSUnderwritersTab deal={deal} isNew={false} />,
              2: <ABSRatingAgenciesTab deal={deal} isNew={false} />,
              3: <ABSTranchePricesTab deal={deal} isNew={false} />,
              4: <GeographicDistributionTab deal={deal} isNew={false} />
            }[currentTab]
          }
        </Card>
      </React.Fragment>
    </PrivateLayout>
  );
}

export default EditABSScreen;
