import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import get from "lodash/get";
import { Row, Box } from "jsxstyle";
import InBetweenSpacing from "components/InBetweenSpacing";
import Heading from "components/Heading";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import Button from "components/Button";
import Separator from "components/Separator";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import saveCMBSDealService from "services/cmbs/saveCMBSDealService";
import history from "browserHistory";

function DuplicateCMBS({
  onRequestClose,
  initialValues,
  validations,
  onDuplicate,
}) {
  const { getValue, setValue, getError, submit } = useForm({
    onSubmit: (values) =>
      fetch({
        values: {
          ...values,
          denomination: get(values, "denomination.currency_code", null),
          operating_advisor: get(values, "operating_advisor.entity_id", null),
          offering_type: get(values, "offering_type.offering_code", null),
          deal_type: get(values, "deal_type.deal_code", null),
          asset_reviewer: get(values, "asset_reviewer.entity_id", null),
          counsel_issuer: get(values, "counsel_issuer.entity_id", null),
          certificate_admin: get(values, "certificate_admin.entity_id", null),
          counsel_uw: get(values, "counsel_uw.entity_id", null),
          trustee: get(values, "trustee.entity_id", null),
          country: get(values, "country.country_code", null),
          depositor: get(values, "depositor.entity_id", null),
          rate_loans: get(values, "rate_loans.rate_code", null),
          rate_notes: get(values, "rate_notes.rate_code", null),
          region: get(values, "region.region_code", null),
        },
      }),
    initialValues: omit(initialValues, "deal_code"),
    validations,
  });

  const { showError, showSuccess } = useToast();

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveCMBSDealService,
    onData: ({ deal_code }) => {
      showSuccess("CMBS deal saved.");
      onDuplicate();
      onRequestClose();
      history.push(`/cmbs/edit/${deal_code}`);
    },
    onError: ({ data: { error } = {} }) => {
      console.error(error);
      const errorMessage =
        {
          duplicated_key: "Duplicate Deal Code",
        }[error] || "An error happened.";
      showError(errorMessage);
    },
  });

  return (
    <InBetweenSpacing size="lg">
      <Box>
        <Heading size="sm">New Deal Code</Heading>
        <Box color="#5f646d">
          Please enter a deal code in order to duplicate the selected deal
        </Box>
      </Box>
      <Form onSubmit={() => submit()}>
        <FormGroup
          label="Deal code"
          required
          disabled={isSubmitting}
          error={getError("deal_code")}
        >
          <IntegerInputGroup
            minLength={8}
            maxLength={8}
            intent={getError("deal_code") ? "danger" : null}
            value={getValue("deal_code")}
            onChange={(value) => setValue("deal_code", value)}
            buttonPosition="none"
            disabled={isSubmitting}
          />
        </FormGroup>

        <Row justifyContent="flex-end">
          <Button
            minimal
            text="Cancel"
            onClick={onRequestClose}
            disabled={isSubmitting}
          />
          <Separator size="md" />
          <Button
            intent="primary"
            text="Save"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={() => {}}
          />
        </Row>
      </Form>
    </InBetweenSpacing>
  );
}

DuplicateCMBS.propTypes = {
  onDuplicate: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
};

DuplicateCMBS.defaultProps = {
  isSubmitting: false,
};

export default DuplicateCMBS;
