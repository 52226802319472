import fetch from "../fetch";

export default ({ values, dealCode, servicerId }) =>
  fetch({
    path: `/cmbs/deals/${dealCode}/servicers${
      servicerId ? `/${servicerId}` : ""
    }`,
    method: servicerId ? "PUT" : "POST",
    data: {
      servicer: values.servicer,
      amount: values.amount,
      is_special: Boolean(values.is_special)
    }
  });
