/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import isEqual from 'lodash/isEqual';

const initialValues = {
  data: [],
  pending: true,
  error: null,
  params: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    case 'FETCHING': {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }
    case 'SUCCESS': {
      return {
        ...state,
        data: action.payload,
        pending: false,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default ({
  promiseFn = () => {
    throw new Error('useAsync expects a promiseFn');
  },
  params,
  eager = false,
  onData,
  onError,
}) => {
  const initialState = React.useMemo(
    () => ({
      ...initialValues,
      pending: eager,
    }),
    [],
  );
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useDeepCompareEffectNoCheck(() => {
    dispatch({ type: 'SET_PARAMS', payload: params });
    if (eager && !isEqual(params, state.params)) {
      fetch({ params });
    }
  }, [params]);

  const fetch = React.useCallback(
    async (params = {}) => {
      try {
        dispatch({ type: 'FETCHING' });
        const payload = await promiseFn({
          params: state.params,
          ...params,
        });
        dispatch({ type: 'SUCCESS', payload });
        if (onData) {
          onData(payload, params);
        }
      } catch (error) {
        dispatch({ type: 'ERROR', payload: error });
        if (onError) {
          onError(error, params);
        }
      }
    },
    [state.params],
  );

  return [fetch, state.pending, state.data, state.error];
};
