export default [
  {
    title: "First name",
    dataIndex: "first_name",
    key: "first_name",
    width: 240,
  },
  {
    title: "Last name",
    dataIndex: "last_name",
    key: "last_name",
    width: 240,
  },
  {
    title: "Job",
    dataIndex: "job_title",
    key: "job_title",
    width: 240,
  },
  {
    title: "Company",
    dataIndex: "company_id.long_name",
    key: "company",
    width: 240,
  },
  {
    title: "Mail",
    dataIndex: "mailing_address",
    key: "mailing_address",
    width: 240,
  },
  {
    title: "City",
    dataIndex: "mailing_city",
    key: "mailing_city",
    width: 240,
  },
  {
    title: "State",
    dataIndex: "mailing_state.state_name",
    key: "mailing_state",
    width: 240,
  },
  {
    title: "Postal code",
    dataIndex: "mailing_postal_code",
    key: "mailing_postal_code",
    width: 150,
  },
  {
    title: "Country",
    dataIndex: "mailing_country.country_code",
    key: "mailing_country",
    width: 150,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width: 150,
  },
  {
    title: "Phone ext",
    dataIndex: "phone_ext",
    key: "phone_ext",
    width: 150,
  },
  {
    title: "Cell",
    dataIndex: "cell",
    key: "cell",
    width: 150,
  },
  {
    title: "Fax",
    dataIndex: "fax",
    key: "fax",
    width: 150,
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
    width: 240,
  },
];
