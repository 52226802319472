/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Table from "components/Table";
import Button from "components/Button";
import PermissionValidator from "components/PermissionValidator";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import deleteContactFundService from "services/contact/deleteContactFundService";

function ContactFundTable({ data, pending, onReload }) {
  const { showError } = useToast();

  const [fetch, deleting] = useAsync({
    promiseFn: deleteContactFundService,
    onData: (data) => {
      onReload();
    },
    onError: () => showError("An error happened."),
  });

  const handleDelete = ({ id, fund }) => {
    fetch({ contactFundId: id, fundId: fund.fund_id.entity_id });
  };

  return (
    <Table
      data={data}
      pending={pending || deleting}
      rowKey="fund.fund_id.entity_id"
      columns={[
        {
          title: "Fund",
          dataIndex: "fund.fund_id.long_name",
          key: "fund.fund_id.entity_id",
        },
        {
          title: "Contact Type",
          dataIndex: "contact_type.name",
          key: "contact_type.id",
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "",
          width: 80,
          render: (item) => (
            <PermissionValidator
              allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
              shouldHide
            >
              <Button
                size="sm"
                icon="trash"
                intent="none"
                onClick={() => handleDelete(item)}
              />
            </PermissionValidator>
          ),
        },
      ]}
    />
  );
}

ContactFundTable.propTypes = {
  onReload: PropTypes.func.isRequired,
};

ContactFundTable.defaultProps = {};

export default ContactFundTable;
