/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
// import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useMachine } from "@xstate/react";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import debounceFetchMachine from "stateMachines/debounceFetchMachine";
import "./AsyncAutoComplete.css";

function AsyncAutoComplete({
  promiseFn,
  onSelect,
  textPath,
  idPath,
  error,
  placeholder,
  value,
  disabled,
  params,
  filter,
  searchable,
  shouldFetchDefaultOptions,
  readOnly,
  itemRenderer,
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(null);
  const paramsRef = React.useRef(params);
  const valueRef = React.useRef(value);
  const searchMachine = debounceFetchMachine.withContext({
    promiseFn,
  });
  const [current, send] = useMachine(searchMachine);

  React.useEffect(() => {
    send({
      type: "UPDATE_PARAMS",
      payload: {
        params: {
          ...params,
          criteria: shouldFetchDefaultOptions ? undefined : value,
          page: 1,
          size: 50,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    if (value !== valueRef.current) {
      valueRef.current = value;
      if (!value) {
        setSelectedValue(null);
      }
      send({
        type: "UPDATE_PARAMS",
        payload: {
          params: {
            ...params,
            criteria: value,
            page: 1,
            size: 50,
          },
        },
      });
    }
  }, [value]);

  React.useEffect(() => {
    if (isEqual(paramsRef.current, params)) return;
    paramsRef.current = params;
    send({
      type: "UPDATE_PARAMS",
      payload: {
        params: {
          ...params,
          criteria: undefined,
          page: 1,
          size: 50,
        },
      },
    });
  }, [params]);

  React.useEffect(() => {
    if (inputValue) {
      send({
        type: "UPDATE_PARAMS",
        payload: {
          params: {
            ...params,
            criteria: inputValue,
            page: 1,
            size: 50,
          },
        },
      });
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (current.context.data && current.context.data.data.length && value) {
      const currentValue = current.context.data.data.find(
        (option) =>
          get(option, idPath, "").toString() === (value || "").toString(),
      );
      if (currentValue) {
        setSelectedValue({
          ...currentValue,
          label: itemRenderer
            ? itemRenderer(currentValue)
            : get(currentValue, textPath),
          value: get(currentValue, idPath),
        });
      } else {
        setSelectedValue(null);
        onSelect(null);
      }
    }
  }, [current]);

  const options = get(current.context.data, "data", []).map((item) => ({
    ...item,
    value: get(item, idPath),
    label: itemRenderer ? itemRenderer(item) : get(item, textPath),
  }));
  return (
    <Select
      styles={getStyle(error)}
      options={filter ? options.filter(filter) : options}
      placeholder={placeholder}
      inputValue={inputValue}
      isClearable
      onInputChange={(value) => setInputValue(value)}
      value={selectedValue}
      clear
      onChange={(option) => {
        if (!option) {
          send({
            type: "UPDATE_PARAMS",
            payload: {
              params: {
                ...params,
                criteria: undefined,
                page: 1,
                size: 50,
              },
            },
          });
        }
        setSelectedValue(option);
        onSelect(option ? option.value : null);
      }}
      isLoading={current.matches("fetching")}
      isDisabled={disabled || readOnly}
      isSearchable={searchable}
      menuPortalTarget={document.getElementById("dropdown")}
    />
  );
}

AsyncAutoComplete.propTypes = {
  promiseFn: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  textPath: PropTypes.string.isRequired,
  error: PropTypes.string,
  initialQuery: PropTypes.string,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  searchable: PropTypes.bool,
};

AsyncAutoComplete.defaultProps = {
  error: null,
  initialQuery: null,
  disabled: false,
  filter: (item) => true,
  searchable: true,
};

const getStyle = (error) => ({
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : null,
    background: state.isSelected ? "#137cbd" : null,
  }),
  control: (provided, state) => ({
    borderRadius: 3,
    background: state.isDisabled ? "#ced9e080" : "#ffffff",
    height: 30,
    padding: "0 10px",
    verticalAlign: "middle",
    display: "flex",
    fontSize: 14,
    boxShadow: error
      ? "0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)"
      : state.isFocused
      ? "0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2)"
      : "0 0 0 0 rgba(19, 124, 189, 0),0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)",
  }),
  singleValue: ({ transform, top, ...provided }) => ({
    ...provided,
  }),
  indicatorSeparator: () => ({}),
  menu: (provided) => ({ ...provided }),
});

export default AsyncAutoComplete;
