import React from "react";
import PropTypes from "prop-types";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import Button from "components/Button";
import useForm from "hooks/useForm";
import Callout from "components/Callout";
import Form from "components/Form";
import isRequired from "validators/isRequired";
import passwordStrength from "validators/passwordStrength";
import Separator from "components/Separator";
import useAsync from "hooks/useAsync";
import resetPasswordService from "services/authentication/resetPasswordService";
import useQueryStringFilters from "hooks/useQueryStringFilters";

function EnterCodeForm({ onComplete }) {
  const { getQuery } = useQueryStringFilters();
  const query = getQuery();

  const [fetch, pending, , error] = useAsync({
    promiseFn: resetPasswordService,
    onData: () => {
      onComplete();
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      code: [isRequired],
      password: [isRequired, passwordStrength],
    },
    onSubmit: (values) => fetch({ ...values, email: query.email }),
  });

  return (
    <>
      <Callout intent="primary">
        Enter the code you received in your email and select a new password.
      </Callout>
      <Separator />
      <Form onSubmit={submit}>
        <FormGroup
          label="Code"
          onChange={(value) => setValue("code", value)}
          error={getError("code")}
        >
          <InputGroup
            size="lg"
            value={getValue("code")}
            onChange={(value) => setValue("code", value)}
            error={getError("code")}
            disabled={pending}
            placeholder="Enter the code you received in your email"
          />
        </FormGroup>
        <FormGroup
          label="New Password"
          onChange={(value) => setValue("password", value)}
          error={getError("password")}
        >
          <InputGroup
            type="password"
            leftIcon="lock"
            size="lg"
            value={getValue("password")}
            onChange={(value) => setValue("password", value)}
            error={getError("password")}
            disabled={pending}
          />
        </FormGroup>
        <Button
          intent="primary"
          text="Confirm"
          icon="confirm"
          size="lg"
          type="submit"
          fullWidth
          loading={pending}
        />
      </Form>
      <Separator />
      {error && (
        <Callout intent="danger">
          {error.message ? error.message : "An error happened"}
        </Callout>
      )}
    </>
  );
}

EnterCodeForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

EnterCodeForm.defaultProps = {};

export default EnterCodeForm;
