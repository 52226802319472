/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAsync from "hooks/useAsync";
import getABSDealByDealCodeService from "services/abs/getABSDealByDealCodeService";
import ScreenLoading from "components/ScreenLoading";
import NewABSLayout from "./NewABSLayout";
import EditABSLayout from "./EditABSLayout";

function ABSFormScreen({ match }) {
  const { action, dealCode } = match.params;
  const [deal, setDeal] = React.useState();

  const [fetch, pending] = useAsync({
    promiseFn: getABSDealByDealCodeService,
    onData: data => setDeal(data)
  });

  React.useEffect(() => {
    if (dealCode) {
      fetch({ dealCode });
    }
  }, []);

  if (dealCode && (pending || !deal)) return <ScreenLoading />;

  if (action !== "new" && action !== "edit") return <></>;

  return {
    new: <NewABSLayout onDealChange={deal => setDeal(deal)} deal={deal} />,
    edit: <EditABSLayout deal={deal} onDealChange={deal => setDeal(deal)} />
  }[action];
}

export default ABSFormScreen;
