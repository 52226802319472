import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import Form from "components/Form";
import Button from "components/Button";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import RESaleFields from "../RESaleFields";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import PropertySelectModal from "screens/re/selectProperty/PropertySelectModal";
import history from "browserHistory";
import PropertyTable from "screens/re/selectProperty/PropertyTable";
import Separator from "components/Separator";
import saveSaleService from "services/re/saveSaleService";
import RESellersForm from "../sellers/RESellersForm";
import RESellersTable from "../sellers/RESellersTable";
import REBuyersForm from "../buyers/REBuyersForm";
import REBuyersTable from "../buyers/REBuyersTable";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import handleApiError from "utils/handleApiError";
import isRequired from "validators/isRequired";
import { useRegionContext } from 'contexts/RegionContext';
import { REGION_CODES } from "consts";

const MODAL_VISIBLE = "visible";
const MODAL_HIDDEN = "hidden";

function SalePropertyForm({ initialValues }) {
  const { showSuccess, showError } = useToast();
  const [modalVisibility, setModalVisibility] = React.useState(MODAL_HIDDEN);
  const { region, getRegionPath } = useRegionContext();
  const isUSA = region === REGION_CODES.USA
  const isCanada = region === REGION_CODES.CANADA

  // eslint-disable-next-line no-unused-vars
  const [fetch, pending] = useAsync({
    promiseFn: saveSaleService,
    onData: () => {
      showSuccess("Sale saved.");
      history.push(getRegionPath("/re/sale"));
    },
    onError: (error) => {
      showError(handleApiError(error));
    },
  });

  const {
    getValue,
    setValue,
    getValues,
    setValues,
    getError,
    submit,
  } = useForm({
    onSubmit: (values) => fetch({ values }),
    initialValues: isEmpty(initialValues)
      ? {
          portfolio: false,
          num_props: 1,
          currency_code: region === REGION_CODES.USA ? 'USD' : '',
          country_code: (
            isUSA ? 'USA' 
            : isCanada ? 'CAN'
            : ''
          )
        }
      : pickBy({
          ...initialValues,
          sellers: initialValues.sellers.map((seller) => seller.entity_id),
          buyers: initialValues.buyers.map((buyer) => buyer.entity_id),
          sector_id: get(initialValues, 'property[0].sector_id'),
          sub_sector_id: get(initialValues, 'property[0].sub_sector_id'),
          attribute_id: get(initialValues, 'property[0].attribute_id'),
        }),
    validations: {
      country_code: [isRequired],
      sale_date: [isRequired],
      price_accuracy: [isRequired],
      stake: [isRequired],
      updated: [isRequired],
      source: [isRequired],
      sector_id: [isRequired],
      // only require price for USA sales
      ...(isUSA ? { 
        price: [isRequired],
      } : {}),
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sellers = React.useMemo(() => getValue("sellers"), [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getValue("sellers").length,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const buyers = React.useMemo(() => getValue("buyers"), [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getValue("buyers").length,
  ]);

  const hasProperty =
    getValue("property") &&
    Boolean(getValue("property").find((property) => !property._deleted));

  return (
    <Form onSubmit={submit}>
      <TwoColumnFormLayout title="Property" size="sm">
        {hasProperty ? (
          <PropertyTable
            saleId={getValue("sale_id")}
            data={getValue("property").filter((property) => !property._deleted)}
            pending={false}
            onRequestDelete={(item) => {
              if (item._fromApi) {
                setValue(
                  "property",
                  getValue("property").map((property) =>
                    property.property_id === item.property_id
                      ? { ...property, _deleted: true }
                      : property,
                  ),
                );
              } else {
                setValue(
                  "property",
                  getValue("property").filter(
                    (property) => property.property_id !== item.property_id,
                  ),
                );
              }
              setValue('sector_id', null);
              setValue('sub_sector_id', null);
              setValue('attribute_id', null);
            }}
          />
        ) : (
          <PermissionValidator
            allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
            shouldHide
          >
            <React.Fragment>
              <Button
                intent="primary"
                text="Select Property"
                icon="search"
                onClick={() => setModalVisibility(MODAL_VISIBLE)}
              />
              <PropertySelectModal
                onSelect={(property) => {
                  setValue(
                    "property",
                    getValue("property")
                      ? [...getValue("property"), property[0]]
                      : property,
                  );

                  setValue('sector_id', get(property, '[0].sector_id.sector_id'));
                  setValue('sub_sector_id', get(property, '[0].sub_sector_id.sector_id'));
                  setValue('attribute_id', get(property, '[0].attribute_id.attribute_id'));
                }}
                onRequestClose={() => setModalVisibility(MODAL_HIDDEN)}
                visible={modalVisibility === MODAL_VISIBLE}
              />
            </React.Fragment>
          </PermissionValidator>
        )}
      </TwoColumnFormLayout>
      <Separator size="xl" />
      <TwoColumnFormLayout title="Property Sale" size="sm">
        <RESaleFields
          getValue={getValue}
          setValue={setValue}
          getValues={getValues}
          setValues={setValues}
          getError={getError}
          isSubmitting={pending}
        />
      </TwoColumnFormLayout>
      <Separator />
      <TwoColumnFormLayout title="Sellers" size="sm">
        <PermissionValidator
          allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
          shouldHide
        >
          <RESellersForm
            onSubmit={(seller) => {
              setValue("sellers", [...getValue("sellers"), seller]);
            }}
          />
          <Separator />
        </PermissionValidator>
        <RESellersTable
          entities={sellers}
          onRequestDelete={(seller) =>
            setValue(
              "sellers",
              getValue("sellers").filter(
                (entity_id) => entity_id !== seller.entity_id,
              ),
            )
          }
        />
      </TwoColumnFormLayout>
      <Separator />
      <TwoColumnFormLayout title="Buyers" size="sm">
        <PermissionValidator
          allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
          shouldHide
        >
          <REBuyersForm
            onSubmit={(buyer) => {
              setValue("buyers", [...getValue("buyers"), buyer]);
            }}
          />
          <Separator />
        </PermissionValidator>
        <REBuyersTable
          entities={buyers}
          onRequestDelete={(buyer) =>
            setValue(
              "buyers",
              getValue("buyers").filter(
                (entity_id) => entity_id !== buyer.entity_id,
              ),
            )
          }
        />
      </TwoColumnFormLayout>
      <Separator />
      <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]} shouldHide>
        <Row justifyContent="flex-end" className="col-12">
          <Button
            type="submit"
            text={"Save"}
            intent="primary"
            onClick={() => {}}
            disabled={pending || !hasProperty}
            loading={pending}
          />
        </Row>
      </PermissionValidator>
    </Form>
  );
}

export default SalePropertyForm;
