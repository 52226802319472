import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import deleteHYFundService from "services/hy/deleteHYFundService";
import Alert from "components/Alert";
import Button from "components/Button";

function DeleteHYFund({ fund, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteHYFundService,
    onError: (error) => {
      showError("Error deleting Fund");
    },
    onData: () => {
      showSuccess("Fund deleted successfully.");
      onDelete();
    },
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ id: fund.fund_id.entity_id })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Fund{" "}
        <b>{get(fund, "fund_id.long_name", "")}</b>?
      </Alert>
    </>
  );
}

DeleteHYFund.propTypes = {
  fund: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

DeleteHYFund.defaultProps = {
  fund: {},
};

export default DeleteHYFund;
