import React from "react";
import PropTypes from "prop-types";

function Form({ onSubmit, children }) {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit();
      }}
    >
      {children}
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default Form;
