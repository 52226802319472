import React from "react";
import { withRouter } from "react-router-dom";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import isRequired from "validators/isRequired";
import saveEntityMasterService from "services/entityMaster/saveEntityMasterService";
import getEntityMasterByIdCaseInsensitiveService from "services/entityMaster/getEntityMasterByIdCaseInsensitiveService";
import Form from "components/Form";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import Separator from "components/Separator";
import InputGroup from "components/InputGroup";
import DateInputGroup from "components/DateInputGroup";
import SelectEntityType from "components/SelectEntityType";
import SelectCountryCode from "components/SelectCountryCode";
import SelectEntityMaster from "components/SelectEntityMaster";
import SelectMultiEntityRoles from "components/SelectMultiEntityRoles";
import IntegerInputGroup from "components/IntegerInputGroup";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import PermissionValidator from "components/PermissionValidator";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";
import useUrlParams from "hooks/useUrlParams";
import { pickBy } from "lodash";

function EntityMasterForm({ history, initialValues, isNew }) {
  const { showSuccess, showError } = useToast();
  const [params] = useUrlParams();
  const allowList = ['long_name', 'address'];

  const [fetch, pending] = useAsync({
    promiseFn: saveEntityMasterService,
    onData: () => {
      showSuccess("Entity master saved.");
      history.push("/entity-master");
    },
    onError: ({ data: { error, message } = {} }) => {
      console.error(error);
      const errorMessage =
        {
          duplicated_key: "Duplicate Entity ID",
        }[error] || message ||  "An error happened.";
      showError(errorMessage);
    },
  });

  const defaultValues = {...initialValues, date_start: initialValues.date_start || new Date().toISOString().split('T')[0]};

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: isNew 
                    ? {...defaultValues, ...pickBy(params, (value, key) => allowList.includes(key))} 
                    : defaultValues,
    validations: {
      editorial_name: [isRequired],
      entity_id: [isRequired],
      entity_type: [isRequired],
      long_name: [isRequired],
      date_start: [isRequired]
    },
    onSubmit: (values) => fetch({ data: values, isNew }),
  });

  const handleSubmit = async () => {
    if (!isNew) {
      submit()
      return
    }

    try {
      const entityData = await getEntityMasterByIdCaseInsensitiveService({ id: getValue("entity_id") })
      if (!entityData.entity_id) {
        submit()
      } else {
        showError(`${entityData.entity_id} already exists, please use a different value for Entity ID`)
      }
      return
    } catch(error) {
      console.log(error)
      // Entity not found
      submit()
    }
  }

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Form onSubmit={handleSubmit}>
          <TwoColumnFormLayout title="Basic Info" size="sm">
            <FormGrid>
              <FormGrid.Item>
                <FormGroup
                  label="Entity ID"
                  required
                  disabled={pending || !isNew || !isAllowed}
                  error={getError("entity_id")}
                >
                  <InputGroup
                    intent={getError("entity_id") ? "danger" : null}
                    value={getValue("entity_id")}
                    onChange={(value) => setValue("entity_id", value ? value.toUpperCase() : value)}
                    disabled={!isNew || !isAllowed}
                    maxLength={6}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Full name"
                  required
                  disabled={pending || !isAllowed}
                  error={getError("long_name")}
                >
                  <InputGroup
                    intent={getError("long_name") ? "danger" : null}
                    value={getValue("long_name")}
                    onChange={(value) => setValue("long_name", value)}
                    disabled={pending || !isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Editorial name"
                  disabled={pending || !isAllowed}
                  error={getError("editorial_name")}
                  required
                >
                  <InputGroup
                    intent={getError("editorial_name") ? "danger" : null}
                    value={getValue("editorial_name")}
                    onChange={(value) => setValue("editorial_name", value)}
                    disabled={pending || !isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Editorial description"
                  disabled={pending || !isAllowed}
                  error={getError("editorial_description")}
                >
                  <InputGroup
                    intent={getError("editorial_description") ? "danger" : null}
                    value={getValue("editorial_description")}
                    onChange={(value) =>
                      setValue("editorial_description", value)
                    }
                    disabled={pending || !isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Entity type"
                  required
                  disabled={pending || !isAllowed}
                >
                  <SelectEntityType
                    onSelect={(value) => {
                      setValue("entity_type", value);
                    }}
                    value={getValue("entity_type")}
                    disabled={pending || !isAllowed}
                    error={getError("entity_type")}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Parent Entity"
                  disabled={pending || !isAllowed}
                >
                  <SelectEntityMaster
                    onSelect={(value) => setValue("parent_entity", value)}
                    value={getValue("parent_entity")}
                    error={getError("parent_entity")}
                    disabled={pending || !isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Phone"
                  disabled={pending || !isAllowed}
                  error={getError("phone")}
                >
                  <InputGroup
                    intent={getError("phone") ? "danger" : null}
                    value={getValue("phone")}
                    onChange={(value) => setValue("phone", value)}
                    maxLength={15}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Website"
                  disabled={pending || !isAllowed}
                  error={getError("website")}
                >
                  <InputGroup
                    intent={getError("website") ? "danger" : null}
                    value={getValue("website")}
                    onChange={(value) => setValue("website", value)}
                    maxLength={100}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Year founded"
                  disabled={pending || !isAllowed}
                  error={getError("year_founded")}
                >
                  <IntegerInputGroup
                    intent={getError("year_founded") ? "danger" : null}
                    value={getValue("year_founded")}
                    onChange={(value) => setValue("year_founded", value)}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Search term"
                  disabled={pending || !isAllowed}
                  error={getError("search_term")}
                >
                  <InputGroup
                    intent={getError("search_term") ? "danger" : null}
                    value={getValue("search_term")}
                    onChange={(value) => setValue("search_term", value)}
                    maxLength={100}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Effective date"
                  disabled={pending || !isAllowed}
                  error={getError("date_start")}
                  required
                >
                  <DateInputGroup
                    intent={getError("date_start") ? "danger" : null}
                    value={getValue("date_start")}
                    onChange={(value) => setValue("date_start", new Date(value).toISOString().split('T')[0])}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Entity Roles"
                  disabled={pending || !isAllowed}
                  error={getError("entity_role_ids")}
                >
                  <SelectMultiEntityRoles
                    intent={getError("entity_role_ids") ? "danger" : null}
                    value={getValue("entity_role_ids")}
                    onSelect={values => setValue("entity_role_ids", values)}
                  />
                </FormGroup>
              </FormGrid.Item>
            </FormGrid>
          </TwoColumnFormLayout>
          <TwoColumnFormLayout title="Address Info" size="sm">
            <FormGrid>
              <FormGrid.Item>
                <FormGroup
                  label="Address"
                  disabled={pending || !isAllowed}
                  error={getError("address")}
                >
                  <InputGroup
                    intent={getError("address") ? "danger" : null}
                    value={getValue("address")}
                    onChange={(value) => setValue("address", value)}
                    maxLength={100}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="City"
                  disabled={pending || !isAllowed}
                  error={getError("city")}
                >
                  <InputGroup
                    intent={getError("city") ? "danger" : null}
                    value={getValue("city")}
                    onChange={(value) => setValue("city", value)}
                    maxLength={100}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="State"
                  disabled={pending || !isAllowed}
                  error={getError("state")}
                >
                  <SelectNorthAmericanState
                    onSelect={(value) => {
                      setValue("state", value);
                    }}
                    value={getValue("state")}
                    disabled={pending || !isAllowed}
                    error={getError("state")}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Postal code"
                  disabled={pending || !isAllowed}
                  error={getError("postal_code")}
                >
                  <InputGroup
                    intent={getError("postal_code") ? "danger" : null}
                    value={getValue("postal_code")}
                    onChange={(value) => setValue("postal_code", value)}
                    maxLength={9}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Country"
                  error={getError("country_code")}
                  disabled={pending || !isAllowed}
                >
                  <SelectCountryCode
                    onSelect={(value) => setValue("country_code", value)}
                    value={getValue("country_code")}
                    error={getError("country_code")}
                  />
                </FormGroup>
              </FormGrid.Item>
            </FormGrid>
          </TwoColumnFormLayout>
          <TwoColumnFormLayout size="sm">
            <Row alignItems="center">
              <Button
                icon="chevron-left"
                text="Back"
                onClick={() => history.push("/entity-master")}
                disabled={pending || !isAllowed}
              />
              <Separator />
              {isAllowed && (
                <Button
                  intent="success"
                  icon="floppy-disk"
                  text="Save"
                  type="button"
                  onClick={handleSubmit}
                  loading={pending}
                />
              )}
            </Row>
            <Separator />
            <small> * Required fields</small>
          </TwoColumnFormLayout>
        </Form>
      )}
    </PermissionValidator>
  );
}

export default withRouter(EntityMasterForm);
