import React from "react";
import { Row } from "jsxstyle";
import history from "browserHistory";
import { withRouter } from "react-router-dom";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import saveHYManagerService from "services/hy/saveHYManagerService";
import Form from "components/Form";
import Button from "components/Button";
import required from "validators/isRequired";
import Separator from "components/Separator";
import PrivateLayout from "components/PrivateLayout";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import HYManagerFormFields from "./HYManagerFormFields";
import HYFundAssociationForm from "./HYFundAssociationForm";
import HYFundAssociationTable from "./HYFundAssociationTable";
import Card from "components/Card";
import Heading from "components/Heading";
import get from "lodash/get";
import HYVehicleTypeAssociationForm from "./HYVehicleTypeAssociationForm";
import HYVehicleTypeAssociationTable from "./HYVehicleTypeAssociationTable";

function HYManagerForm({ match, initialValues }) {
  const { action } = match.params;
  const { showSuccess, showError } = useToast();
  const isNew = action === "new";

  const [fetch, pending] = useAsync({
    promiseFn: saveHYManagerService,
    onData: (manager) => {
      showSuccess("Manager saved.");
      history.push("/hy/manager");
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues,
    onSubmit: (values) => fetch({ data: values, isNew }),
    validations: {
      manager_id: [required],
      "manager_id.long_name": [required],
      "manager_id.entity_type": [required],
      "manager_id.date_start": [required],
      "manager_id.editorial_name": [required],
    },
  });

  return (
    <PrivateLayout>
      <Heading size="md">
        {isNew
          ? "New Manager"
          : `Edit Manager - ${get(initialValues, "manager_id.entity_id")}`}
      </Heading>
      <Separator />
      <Card>
        <Form onSubmit={submit}>
          <TwoColumnFormLayout title="Manager info" size="sm">
            <HYManagerFormFields
              key={getValue("manager_id.entity_id")}
              getValue={getValue}
              setValue={setValue}
              getError={getError}
              isSubmitting={pending}
              isNew={isNew}
            />
          </TwoColumnFormLayout>
          <Separator size="xl" />
          <TwoColumnFormLayout title="HY Fund" size="sm">
            <HYFundAssociationForm
              onSubmit={(fund) => {
                if (
                  !(getValue("funds") || []).find(
                    ({ fund_id: { entity_id } }) =>
                      entity_id === fund.fund_id.entity_id,
                  )
                ) {
                  setValue("funds", [...getValue("funds"), fund]);
                }
              }}
            />
            <Separator />
            <HYFundAssociationTable
              funds={getValue("funds")}
              onRequestDelete={({ fund_id: { entity_id: deleteId } }) =>
                setValue(
                  "funds",
                  getValue("funds").filter(
                    (fund) => fund.fund_id.entity_id !== deleteId,
                  ),
                )
              }
            />
          </TwoColumnFormLayout>
          <Separator size="xl" />
          <TwoColumnFormLayout title="HY Vehicle Type" size="sm">
            <HYVehicleTypeAssociationForm
              onSubmit={(vehicle_type) => {
                if (
                  !(getValue("vehicle_types") || []).find(
                    ({ id }) => id === vehicle_type.id,
                  )
                ) {
                  setValue("vehicle_types", [
                    ...getValue("vehicle_types"),
                    vehicle_type,
                  ]);
                }
              }}
            />
            <Separator />
            <HYVehicleTypeAssociationTable
              vehicleTypes={getValue("vehicle_types")}
              onRequestDelete={({ id: deletedId }) =>
                setValue(
                  "vehicle_types",
                  getValue("vehicle_types").filter(
                    ({ id }) => id !== deletedId,
                  ),
                )
              }
            />
          </TwoColumnFormLayout>
          <Separator size="xl" />
          <Row justifyContent="flex-end">
            <Button
              type="submit"
              text={isNew ? "Next" : "Save"}
              intent="primary"
              disabled={pending}
              loading={pending}
            />
          </Row>
        </Form>
      </Card>
    </PrivateLayout>
  );
}

export default withRouter(HYManagerForm);
