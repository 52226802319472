import fetch from "../fetch";

export default ({ values, dealCode, ratingId }) =>
  fetch({
    path: `/cmbs/deals/${dealCode}/ratings${ratingId ? `/${ratingId}` : ""}`,
    method: ratingId ? "PUT" : "POST",
    data: {
      entity_id: values.entity_id
    }
  });
