/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Toaster } from "@blueprintjs/core";

const toast = Toaster.create();

function useToast() {
  const getIconByIntent = intent =>
    ({
      success: "tick",
      danger: "error"
    }[intent]);

  const show = ({ intent, message }) => {
    toast.show({
      intent,
      message,
      icon: getIconByIntent(intent)
    });
  };

  const showError = React.useCallback(
    message => show({ intent: "danger", message }),
    []
  );

  const showSuccess = React.useCallback(
    message => show({ intent: "success", message }),
    []
  );

  return { show, showError, showSuccess };
}

export default useToast;
