import React from "react";
import { Row } from "jsxstyle";
import PropTypes from "prop-types";
import useForm from "hooks/useForm";
import useQueryStringFilters from "hooks/useQueryStringFilters";
import Form from "components/Form";
import Button from "components/Button";
import Separator from "components/Separator";
import InputGroup from "components/InputGroup";

function PropertySelectFilters({ onChange }) {
  const { getQuery } = useQueryStringFilters();
  const { property } = getQuery();
  const { getValue, setValue, clear, submit, clearCount } = useForm({
    initialValues: {
      property,
    },
    onSubmit: onChange,
  });

  React.useEffect(() => {
    if (clearCount) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount]);

  return (
    <Form onSubmit={submit}>
      <Row alignItems="flex-start">
        <InputGroup
          leftIcon="search"
          placeholder="Search..."
          value={getValue("property")}
          onChange={(value) => setValue("property", value)}
        />
        <Separator />
        <Button intent="primary" icon="search" text="Search" type="submit" />
        <Separator />
        <Button intent="none" text="Clear filters" onClick={clear} />
      </Row>
    </Form>
  );
}

PropertySelectFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PropertySelectFilters;
