import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteCMBSTranchePrice from "services/cmbs/deleteCMBSTranchePrice";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteCMBSTranchePrice({ tranchePrice, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteCMBSTranchePrice,
    onData: () => {
      showSuccess("Tranche Price deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Tranche Price");
    }
  });

  return (
    <React.Fragment>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() =>
          fetch({ dealCode: tranchePrice.deal_code, id: tranchePrice.id })
        }
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete this Tranche Price?
      </Alert>
    </React.Fragment>
  );
}

DeleteCMBSTranchePrice.propTypes = {
  tranchePrice: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteCMBSTranchePrice.defaultProps = {
  underwriter: {}
};

export default DeleteCMBSTranchePrice;
