/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import DeleteEntityMasterConfirmationModal from "./DeleteEntityMasterConfirmationModal";
import Button from "components/Button";

function DeleteEntityMaster({ entityMaster, onDelete }) {
  const [alertVisible, setAlertVisible] = React.useState(false);

  return (
    <>
      <Button size="sm" icon="trash" onClick={() => setAlertVisible(true)} />
      <DeleteEntityMasterConfirmationModal
        isVisible={alertVisible}
        entityMaster={entityMaster}
        onRequestClose={() => setAlertVisible(false)}
        onDelete={onDelete}
      />
    </>
  );
}

DeleteEntityMaster.propTypes = {
  entityMaster: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

DeleteEntityMaster.defaultProps = {
  entityMaster: {},
};

export default DeleteEntityMaster;
