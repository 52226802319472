import React from "react";
import PropTypes from "prop-types";
import { Row } from "jsxstyle";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import DeleteABSDealLevelRating from "./DeleteABSDealLevelRating";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function ABSRatingAgenciesTable({ data, pending, onReload, onRequestEdit }) {
  const handleOnChange = (pagination, filter, sorter) => {};

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          data={data}
          onChange={handleOnChange}
          pending={pending}
          rowKey="id"
          columns={[
            {
              title: "Agency",
              dataIndex: "entity_id.long_name",
              key: "entity_id"
            },
            // CLORatingAgenciesTable
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: item => (
                      <Row>
                        <Button
                          icon="edit"
                          onClick={() => onRequestEdit(item)}
                        />
                        <Separator />
                        <DeleteABSDealLevelRating
                          rating={item}
                          onDelete={onReload}
                        />
                      </Row>
                    )
                  }
                ]
              : [])
          ]}
        />
      )}
    </PermissionValidator>
  );
}

ABSRatingAgenciesTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onReload: PropTypes.func.isRequired,
  onRequestEdit: PropTypes.func.isRequired
};

ABSRatingAgenciesTable.defaultProps = {
  data: [],
  pending: false
};

export default ABSRatingAgenciesTable;
