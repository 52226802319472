import React from "react";
import CurrencyFormat from "components/CurrencyFormat";

export default [
  {
    title: "Sale Date",
    dataIndex: "sale_date",
    key: "sale_date",
    width: 150,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: 200,
    render: (amount) => amount ? <CurrencyFormat amount={amount} maximumFractionDigits={6}/> : 'N/A',
  },
  {
    title: "Price Accuracy",
    dataIndex: "price_accuracy",
    key: "price_accuracy",
    width: 150,
  },
  {
    title: "Conv",
    dataIndex: "conv",
    key: "conv",
    width: 150,
  },
  {
    title: "Dev",
    dataIndex: "dev",
    key: "dev",
    width: 150,
  },
  {
    title: "Stake",
    dataIndex: "stake",
    key: "stake",
    width: 150,
  },
  {
    title: "Sf Price",
    dataIndex: "sf_price",
    key: "sf_price",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Unit Price",
    dataIndex: "unit_price",
    key: "unit_price",
    width: 150,
    render: (amount) => <CurrencyFormat amount={amount || 0} />,
  },
  {
    title: "Broker 1",
    dataIndex: "broker_1.long_name",
    key: "broker_1.long_name",
    width: 240,
  },
  {
    title: "Broker 2",
    dataIndex: "broker_2.long_name",
    key: "broker_2.long_name",
    width: 240,
  },

  {
    title: "Sale Leaseback",
    dataIndex: "sale_leaseback",
    key: "sale_leaseback",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Portfolio",
    dataIndex: "portfolio",
    key: "portfolio",
    width: 150,
    render: (portfolio) => (portfolio ? "Yes" : "No"),
  },
  {
    title: "Closed",
    dataIndex: "closed",
    key: "closed",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Leasehold",
    dataIndex: "leasehold",
    key: "leasehold",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Distressed",
    dataIndex: "distressed",
    key: "distressed",
    width: 150,
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Cap Rate",
    dataIndex: "cap_rate",
    key: "cap_rate",
    width: 150,
  },
  {
    title: "Occupancy",
    dataIndex: "occupancy",
    key: "occupancy",
    width: 150,
  },
  {
    title: "Buyer Broker",
    dataIndex: "buyer_broker.long_name",
    key: "buyer_broker.long_name",
    width: 240,
  },
  {
    title: "Vehicle Buyer",
    dataIndex: "vehicle_buyer.long_name",
    key: "vehicle_buyer.long_name",
    width: 240,
  },
  {
    title: "Vehicle Seller",
    dataIndex: "vehicle_seller.long_name",
    key: "vehicle_seller.long_name",
    width: 240,
  },
  {
    title: "Flag",
    dataIndex: "flag",
    key: "flag",
    width: 150,
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    width: 240,
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    width: 400,
  },
  {
    title: "Sector",
    dataIndex: "sector_id.sector",
    key: "sector_id.sector",
    width: 240,
  },
  {
    title: "Sub Sector",
    dataIndex: "sub_sector_id.sector",
    key: "sub_sector_id.sector",
    width: 240,
  },
  {
    title: "Attribute",
    dataIndex: "attribute_id.attribute",
    key: "attribute_id.attribute",
    width: 240,
  },
];
