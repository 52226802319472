/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import getHYFundByIdService from "services/hy/getHYFundByIdService";
import HYManagerTableColumns from "../manager/HYManagerTableColumns";

function HYManagerSubTable({ fundId, bordered }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    eager: true,
    promiseFn: getHYFundByIdService,
    onError: (error) => {
      showError("Error loading Subtable HY Managers");
    },
  });

  React.useEffect(() => {
    fetch({ id: fundId });
  }, []);

  const columns = HYManagerTableColumns.slice(0, 8).map((column) => column);

  return (
    <Table
      rowKey="manager_id.entity_id"
      pending={pending}
      bordered={bordered}
      data={data.managers}
      columns={columns}
    />
  );
}

HYManagerSubTable.propTypes = {
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bordered: PropTypes.bool,
};
HYManagerSubTable.defaultProps = {
  bordered: false,
};

export default HYManagerSubTable;
