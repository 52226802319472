import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadEntityMasterService from "services/entityMaster/loadEntityMasterService";

function SelectEntityMaster({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly,
  params,
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadEntityMasterService}
      onSelect={onSelect}
      textPath="long_name"
      labelPath="entity_id"
      idPath="entity_id"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      params={{ order_by: "long_name", order_direction: "ASC", ...params }}
    />
  );
}

SelectEntityMaster.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  params: PropTypes.object,
};

SelectEntityMaster.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  params: undefined,
};

export default SelectEntityMaster;
