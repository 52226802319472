import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteCLOService from "services/clo/deleteCLOService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteCLO({ clo, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteCLOService,
    onError: error => {
      showError("Error deleting CLO");
    },
    onData: () => {
      showSuccess("CLO deleted successfully.");
      onDelete();
    }
  });

  return (
    <React.Fragment>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ dealCode: clo.deal_code })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the CLO <b>{clo.deal_code}</b>?
      </Alert>
    </React.Fragment>
  );
}

DeleteCLO.propTypes = {
  clo: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteCLO.defaultProps = {
  clo: {}
};

export default DeleteCLO;
