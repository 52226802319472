import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import loadABSTranchePriceRatingsService from "services/abs/loadABSTranchePriceRatingsService";
import useAsync from "hooks/useAsync";

function ABSRatingsTable({ dealCode, trancheId }) {
  const [fetch, pending, data] = useAsync({
    promiseFn: loadABSTranchePriceRatingsService,
  });

  React.useEffect(() => {
    fetch({
      dealCode: dealCode,
      trancheId: trancheId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealCode, trancheId]);

  return (
    <Table
      bordered={false}
      pending={pending}
      rowKey="id"
      columns={[
        {
          title: "Agency",
          dataIndex: "entity_id.long_name",
          key: "entity_id.long_name",
        },
        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
        },
      ]}
      data={data}
    />
  );
}

ABSRatingsTable.propTypes = {
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  trancheId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default ABSRatingsTable;
