import React from "react";
import { Row } from "jsxstyle";
import history from "browserHistory";
import { Button } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import isEmpty from "lodash/isEmpty";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import saveHYFundService from "services/hy/saveHYFundService";
import Form from "components/Form";
import Switch from "components/Switch";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import PermissionValidator from "components/PermissionValidator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";

function HYFundTargetRegionsTab({
  match,
  onSubmit,
  initialValues,
  isNew = true,
}) {
  const { showError, showSuccess } = useToast();
  const id = match.params.id;

  const [fetch, pending] = useAsync({
    promiseFn: saveHYFundService,
    onData: (fund) => {
      showSuccess("HY Fund saved.");
      onSubmit(fund);
      if (isNew) {
        history.push(`/hy/fund/new/${id}/management-and-sponsors`);
      }
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: isEmpty(initialValues) ? {} : initialValues,
    onSubmit: (values) => fetch({ id, values }),
    validations: {},
  });

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <TwoColumnFormLayout title="Target Regions" size="sm">
          <Form onSubmit={submit}>
            <FormGrid>
              <FormGrid.Item>
                <FormGroup
                  label="Target Geography Description"
                  error={getError("target_geography_description")}
                  disabled={pending}
                >
                  <InputGroup
                    intent={
                      getError("target_geography_description") ? "danger" : null
                    }
                    value={getValue("target_geography_description")}
                    onChange={(value) =>
                      setValue("target_geography_description", value)
                    }
                    readOnly={!isAllowed}
                    disabled={pending}
                  />
                </FormGroup>
              </FormGrid.Item>

              <FormGrid.Item>
                <FormGroup label="Target Us" error={getError("target_us")}>
                  <Switch
                    value={getValue("target_us")}
                    onChange={() =>
                      setValue("target_us", !getValue("target_us"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Non Us"
                  error={getError("target_non_us")}
                >
                  <Switch
                    value={getValue("target_non_us")}
                    onChange={() =>
                      setValue("target_non_us", !getValue("target_non_us"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Europe"
                  error={getError("target_europe")}
                >
                  <Switch
                    value={getValue("target_europe")}
                    onChange={() =>
                      setValue("target_europe", !getValue("target_europe"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Target Asia" error={getError("target_asia")}>
                  <Switch
                    value={getValue("target_asia")}
                    onChange={() =>
                      setValue("target_asia", !getValue("target_asia"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Global"
                  error={getError("target_global")}
                >
                  <Switch
                    value={getValue("target_global")}
                    onChange={() =>
                      setValue("target_global", !getValue("target_global"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Other Region"
                  error={getError("target_other_region")}
                >
                  <Switch
                    value={getValue("target_other_region")}
                    onChange={() =>
                      setValue(
                        "target_other_region",
                        !getValue("target_other_region"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Northeast"
                  error={getError("target_northeast")}
                >
                  <Switch
                    value={getValue("target_northeast")}
                    onChange={() =>
                      setValue(
                        "target_northeast",
                        !getValue("target_northeast"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Metro Ny"
                  error={getError("target_metro_ny")}
                >
                  <Switch
                    value={getValue("target_metro_ny")}
                    onChange={() =>
                      setValue("target_metro_ny", !getValue("target_metro_ny"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Midatlantic"
                  error={getError("target_midatlantic")}
                >
                  <Switch
                    value={getValue("target_midatlantic")}
                    onChange={() =>
                      setValue(
                        "target_midatlantic",
                        !getValue("target_midatlantic"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Southeast"
                  error={getError("target_southeast")}
                >
                  <Switch
                    value={getValue("target_southeast")}
                    onChange={() =>
                      setValue(
                        "target_southeast",
                        !getValue("target_southeast"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Midwest"
                  error={getError("target_midwest")}
                >
                  <Switch
                    value={getValue("target_midwest")}
                    onChange={() =>
                      setValue("target_midwest", !getValue("target_midwest"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target South"
                  error={getError("target_south")}
                >
                  <Switch
                    value={getValue("target_south")}
                    onChange={() =>
                      setValue("target_south", !getValue("target_south"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Southwest"
                  error={getError("target_southwest")}
                >
                  <Switch
                    value={getValue("target_southwest")}
                    onChange={() =>
                      setValue(
                        "target_southwest",
                        !getValue("target_southwest"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Northwest"
                  error={getError("target_northwest")}
                >
                  <Switch
                    value={getValue("target_northwest")}
                    onChange={() =>
                      setValue(
                        "target_northwest",
                        !getValue("target_northwest"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target California"
                  error={getError("target_california")}
                >
                  <Switch
                    value={getValue("target_california")}
                    onChange={() =>
                      setValue(
                        "target_california",
                        !getValue("target_california"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
            </FormGrid>
            <Row justifyContent="flex-end">
              <Button
                disabled={pending}
                loading={pending}
                type="submit"
                intent="primary"
                text={isNew ? "Next" : "Save"}
              />
            </Row>
          </Form>
        </TwoColumnFormLayout>
      )}
    </PermissionValidator>
  );
}

export default withRouter(HYFundTargetRegionsTab);
