import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadRESectorsService from "services/re/loadRESectorsService";

function SelectSector({
  disabled,
  error,
  onSelect,
  placeholder,
  readOnly,
  value,
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadRESectorsService}
      onSelect={onSelect}
      idPath="sector_id"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      textPath="sector_publish"
      value={value}
      readOnly={readOnly}
    />
  );
}

SelectSector.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SelectSector.defaultProps = {
  disabled: false,
  error: null,
  placeholder: "Search...",
  readOnly: false,
  value: undefined,
};

export default SelectSector;