import React from "react";
import { Box } from "jsxstyle";
import useData from "hocs/useData";
import useForm from "hooks/useForm";
import required from "validators/isRequired";
import loadHYManagersService from "services/hy/loadHYManagersService";
import Form from "components/Form";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectHYManager from "components/SelectHYManager";

function HYManagerAssociationForm({ onSubmit, isSubmitting }) {
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    data: { data: funds = [] },
  } = useData({
    initialParams: { size: 100 },
    /**
     * The following is a bit of a hack. It taps into the intent of
     * `promiseFn` (see the `useAsync` hook) so that it can recursively
     * call the paginated GET /hy/managers endpoint to get all results.
     */
    promiseFn: async args => {
      setIsLoading(true);
      const response = await loadHYManagersService(args);
      while (response.data.length < response.total) {
        args.params.page++;
        response.data = response.data.concat((await loadHYManagersService(args)).data);
      }
      setIsLoading(false);
      return response;
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    onSubmit: ({ manager_id }, { reset, clear }) => {
      onSubmit(funds.find(({ manager_id: { entity_id } }) => entity_id === manager_id));
      reset();
      clear();
    },
    validations: {
      manager_id: [required],
    },
  });

  return (
    <Form onSubmit={submit}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Fund manager"
            error={getError("manager_id")}
            disabled={isSubmitting}
            required
          >
            <SelectHYManager
              onSelect={(value) => setValue("manager_id", value)}
              value={getValue("manager_id")}
              error={getError("manager_id")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <Box marginTop={22}>
            <Button
              type="submit"
              text="Add"
              intent="success"
              disabled={isSubmitting}
              loading={isLoading}
            />
          </Box>
        </FormGrid.Item>
      </FormGrid>
    </Form>
  );
}

HYManagerAssociationForm.propTypes = {};

HYManagerAssociationForm.defaultProps = {};

export default HYManagerAssociationForm;
