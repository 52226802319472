import React from "react";
import { Box } from "jsxstyle";
import { useDrag, useDrop } from "react-dnd";

const VisibleColumnItem = ({
  name,
  index,
  sortableIndex,
  moveItem,
  onDrag
}) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: "visibleColumnItem",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.sortableIndex;
      const hoverIndex = sortableIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
      const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
      const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;

      if (upwards && (leftwards || rightwards)) {
        return;
      }

      if (downwards && (leftwards || rightwards)) {
        return;
      }
      moveItem(dragIndex, hoverIndex);

      item.sortableIndex = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: "visibleColumnItem", index, sortableIndex },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        if (dropResult.name === "fieldsTarget") {
          onDrag(item);
        }
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box
      opacity={opacity}
      display="flex"
      justifyContent="center"
      alignItems="center"
      props={{ ref }}
      border="1px dashed gray"
      padding={5}
      backgroundColor="white"
      cursor="move"
    >
      {name}
    </Box>
  );
};

VisibleColumnItem.propTypes = {};

VisibleColumnItem.defaultProps = {};

export default VisibleColumnItem;
