import React from "react";
import PropTypes from "prop-types";
import { Box, Row } from "jsxstyle";
import range from "lodash/range";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import InputGroup from "components/InputGroup";
import Switch from "components/Switch";
import Select from "components/Select";
import SelectAttribute from "components/SelectAttribute";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";
import SelectSubSector from "components/SelectSubSector";
import SelectSector from "components/SelectSector";
import SelectREMarket from "components/SelectREMarket";
import SelectUnitOfMeasure from "components/SelectUnitOfMeasure";
import Separator from "components/Separator";
import { REGION_CODES } from "consts";
import { Tooltip, Icon } from "@blueprintjs/core";
import SelectCountryCode from "components/SelectCountryCode";

function RESalePropFields({ isSubmitting, getValue, setValue, getError, region }) {
  const isEurope = region === REGION_CODES.EUROPE;
  const isCanada = region === REGION_CODES.CANADA;
  const isUSA = region === REGION_CODES.USA;
  return (
    <FormGrid>
      <FormGrid.Item>
        <FormGroup
          label="Property Id"
          required
          disabled={isSubmitting}
          error={getError("property_id")}
        >
          <IntegerInputGroup
            minLength={6}
            maxLength={6}
            intent={getError("property_id") ? "danger" : null}
            value={getValue("property_id")}
            onChange={(value) => setValue("property_id", value)}
            buttonPosition="none"
            disabled
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Property 1"
          required
          disabled={isSubmitting}
          error={getError("property_1")}
        >
          <InputGroup
            intent={getError("property_1") ? "danger" : null}
            value={getValue("property_1")}
            onChange={(value) => setValue("property_1", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Property 2"
          required
          disabled={isSubmitting}
          error={getError("property_2")}
        >
          <InputGroup
            intent={getError("property_2") ? "danger" : null}
            value={getValue("property_2")}
            onChange={(value) => setValue("property_2", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      {isUSA && (
        <FormGrid.Item>
          <FormGroup
            label="State"
            required
            disabled={isSubmitting}
            error={getError("state")}
          >
            <SelectNorthAmericanState
              onSelect={(value) => setValue("state", value)}
              value={getValue("state")}
              error={getError("state")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
      )}
      <FormGrid.Item>
        <FormGroup
          label="City"
          required
          disabled={isSubmitting}
          error={getError("city")}
        >
          <InputGroup
            intent={getError("city") ? "danger" : null}
            value={getValue("city")}
            onChange={(value) => setValue("city", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Zip Code / Postal Code"
          disabled={isSubmitting}
          error={getError("postal_code")}
        >
          <InputGroup
            maxLength={9}
            intent={getError("postal_code") ? "danger" : null}
            value={getValue("postal_code")}
            onChange={(value) => setValue("postal_code", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Country"
          disabled={isSubmitting}
          error={getError("country_code")}
        >
          <SelectCountryCode
              onSelect={(value) => setValue("country_code", value)}
              value={getValue("country_code")}
              error={getError("country_code")}
              disabled={isSubmitting}
              region={region}
        />
        </FormGroup>
      </FormGrid.Item>
      {isUSA && (
        <FormGrid.Item>
          <FormGroup
            label={
              <Row alignItems="center">
                Market *
                <Separator size="xs" />
                <Tooltip
                  content="Select a State or City to enable this field"
                  position="bottom"
                >
                  <Icon icon="help" iconSize={16} color="#394b59" />
                </Tooltip>
              </Row>
            }
            disabled={isSubmitting}
            error={getError("market")}
          >
            <SelectREMarket
              key={`${getValue("city")}-${getValue("state")}`}
              city={getValue("city")}
              state={getValue("state")}
              intent={getError("market") ? "danger" : null}
              value={getValue("market")}
              onSelect={(value) => setValue("market", value)}
              disabled={isSubmitting || (!getValue("state") && !getValue("city"))}
            />
          </FormGroup>
        </FormGrid.Item>
      )}
      <FormGrid.Item>
        <FormGroup
          label="Sector"
          required
          disabled={isSubmitting}
          error={getError("sector_id")}
        >
          <SelectSector
            onSelect={(value) => setValue("sector_id", value)}
            value={getValue("sector_id")}
            error={getError("sector_id")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label={
            <Row alignItems="center">
              Sub Sector
            </Row>
          }
          disabled={isSubmitting}
          error={getError("sub_sector_id")}
        >
          <SelectSubSector
            sectorId={getValue("sector_id")}
            onSelect={(value) => setValue("sub_sector_id", value)}
            value={getValue("sub_sector_id")}
            error={getError("sub_sector_id")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Attribute"
          disabled={isSubmitting}
          error={getError("attribute_id")}
        >
          <SelectAttribute
            subSectorId={getValue("sub_sector_id") || getValue("sector_id")}
            onSelect={(value) => setValue("attribute_id", value)}
            value={getValue("attribute_id")}
            error={getError("attribute_id")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Sf Sold"
          disabled={isSubmitting}
          error={getError("sf_sold")}
        >
          <CurrencyInputGroup
            intent={getError("sf_sold") ? "danger" : null}
            value={getValue("sf_sold")}
            onChange={(value) => setValue("sf_sold", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Sf Total"
          disabled={isSubmitting}
          error={getError("sf_total")}
        >
          <CurrencyInputGroup
            intent={getError("sf_total") ? "danger" : null}
            value={getValue("sf_total")}
            onChange={(value) => setValue("sf_total", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Unit of Measure"
          disabled={isSubmitting}
          error={getError("measurement_unit")}
        >
          <SelectUnitOfMeasure
            onSelect={(value) => setValue("measurement_unit", value)}
            value={getValue("measurement_unit")}
            error={getError("measurement_unit")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      {isUSA && (
        <Box className="col-2">
          <FormGroup
            label="Opportunity Zone"
            disabled={isSubmitting}
            error={getError("opportunity_zone")}
          >
            <Switch
              value={getValue("opportunity_zone")}
              onChange={() =>
                setValue("opportunity_zone", !getValue("opportunity_zone"))
              }
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
      )}
      <Box className={isUSA ? 'col-2' : 'col-4'}>
        <FormGroup
          label="Mixed Use"
          disabled={isSubmitting}
          error={getError("mixed_use")}
        >
          <Switch
            value={getValue("mixed_use")}
            onChange={() => setValue("mixed_use", !getValue("mixed_use"))}
            disabled={isSubmitting}
          />
        </FormGroup>
      </Box>
      <FormGrid.Item>
        <FormGroup
          label="Units"
          disabled={isSubmitting}
          error={getError("units")}
        >
          <IntegerInputGroup
            intent={getError("units") ? "danger" : null}
            value={getValue("units")}
            onChange={(value) => setValue("units", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      {(isEurope || isCanada) && (
        <>
          <FormGrid.Item>
            <FormGroup
              label="Local Property Name"
              disabled={isSubmitting}
              error={getError("property1_local")}
            >
              <InputGroup
                intent={getError("property1_local") ? "danger" : null}
                value={getValue("property1_local")}
                onChange={(value) => setValue("property1_local", value)}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Local Address Field"
              disabled={isSubmitting}
              error={getError("property2_local")}
            >
              <InputGroup
                intent={getError("property2_local") ? "danger" : null}
                value={getValue("property2_local")}
                onChange={(value) => setValue("property2_local", value)}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
        </>
      )}
      <FormGrid.Item>
        <FormGroup
          label="Number of Buildings"
          disabled={isSubmitting}
          error={getError("number_buildings")}
        >
          <IntegerInputGroup
            intent={getError("number_buildings") ? "danger" : null}
            value={getValue("number_buildings")}
            onChange={(value) => setValue("number_buildings", value)}
            disabled={isSubmitting}
            min={0}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Number of Floors"
          disabled={isSubmitting}
          error={getError("floors")}
        >
          <IntegerInputGroup
            intent={getError("floors") ? "danger" : null}
            value={getValue("floors")}
            onChange={(value) => setValue("floors", value)}
            disabled={isSubmitting}
            min={0}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Year Built"
          disabled={isSubmitting}
          error={getError("year_built")}
        >
          <Select
            intent={getError("year_built") ? "danger" : null}
            value={getValue("year_built")}
            onSelect={(value) => setValue("year_built", value)}
            disabled={isSubmitting}
            items={range(1700, 2100).map(year => ({ label: year, id: year }))}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Effective Year Built"
          disabled={isSubmitting}
          error={getError("year_built_effective")}
        >
          <Select
            intent={getError("year_built_effective") ? "danger" : null}
            value={getValue("year_built_effective")}
            onSelect={(value) => setValue("year_built_effective", value)}
            disabled={isSubmitting}
            items={range(1700, 2100).map(year => ({ label: year, id: year }))}
          />
        </FormGroup>
      </FormGrid.Item>
      <FormGrid.Item>
        <FormGroup
          label="Land Use"
          disabled={isSubmitting}
          error={getError("land_use")}
        >
          <InputGroup
            maxLength={10}
            intent={getError("land_use") ? "danger" : null}
            value={getValue("land_use")}
            onChange={(value) => setValue("land_use", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item>
      <Box className="col-xl-2 col-lg-6 col-md-12">
        <FormGroup
          label="Long"
          disabled={isSubmitting}
          error={getError("long_lat")}
        >
          <InputGroup
            intent={getError("long_lat") ? "danger" : null}
            value={getValue("long_lat[0]")}
            onChange={(value) => setValue("long_lat[0]", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </Box>
      <Box className="col-xl-2 col-lg-6 col-md-12">
        <FormGroup
          label="Lat"
          disabled={isSubmitting}
          error={getError("long_lat")}
        >
          <InputGroup
            intent={getError("long_lat") ? "danger" : null}
            value={getValue("long_lat[1]")}
            onChange={(value) => setValue("long_lat[1]", value)}
            disabled={isSubmitting}
          />
        </FormGroup>
      </Box>
    </FormGrid>
  );
}

RESalePropFields.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

RESalePropFields.defaultProps = {
  isNew: true,
};

export default RESalePropFields;
