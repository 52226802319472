/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Card from "components/Card";
import Separator from "components/Separator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CMBSBorrowersForm from "./CMBSBorrowersForm";
import CMBSBorrowersTable from "./CMBSBorrowersTable";
import useAsync from "hooks/useAsync";
import loadCMBSBorrowersService from "services/cmbs/loadCMBSBorrowersService";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import browserHistory from "browserHistory";

function CMBSBorrowersTab({ deal, isNew }) {
  const [borrowerToEdit, setBorrowerToEdit] = React.useState(null);

  const dealCode = deal.deal_code;

  const [fetch, pending, data] = useAsync({
    promiseFn: loadCMBSBorrowersService,
  });

  React.useEffect(() => {
    fetch({ dealCode });
  }, []);

  return (
    <Card>
      <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
        <TwoColumnFormLayout
          title={borrowerToEdit ? "Edit Borrower" : "Add Borrower"}
        >
          <CMBSBorrowersForm
            key={borrowerToEdit && borrowerToEdit.id}
            onCancel={() => setBorrowerToEdit(null)}
            onData={() => {
              setBorrowerToEdit(null);
              fetch({ dealCode });
            }}
            initialValues={{
              dealCode,
              ...(borrowerToEdit
                ? {
                    ...borrowerToEdit,
                    entity_id: borrowerToEdit.entity_id.entity_id,
                  }
                : {}),
            }}
          />
        </TwoColumnFormLayout>
        <Divider style={{ margin: "30px 0 30px 0" }} />
      </PermissionValidator>
      <TwoColumnFormLayout title="Borrowers">
        <CMBSBorrowersTable
          data={data}
          pending={pending}
          onReload={() => fetch({ dealCode })}
          onRequestEdit={(agency) => setBorrowerToEdit(agency)}
        />
      </TwoColumnFormLayout>
      {isNew && (
        <React.Fragment>
          <Separator size="lg" />
          <Row className="col-12" justifyContent="flex-end">
            <Button
              intent="primary"
              text="Next"
              onClick={() =>
                browserHistory.push(`/cmbs/new/${deal.deal_code}/sellers`)
              }
            />
          </Row>
        </React.Fragment>
      )}
    </Card>
  );
}

CMBSBorrowersTab.propTypes = {
  isActive: PropTypes.bool,
  dealCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool,
};

CMBSBorrowersTab.defaultProps = {
  isActive: false,
  isNew: true,
};

export default CMBSBorrowersTab;
