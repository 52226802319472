import React from "react";
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import ABSNewDealTab from "./deal/ABSNewDealTab";
import ABSUnderwritersTab from "./underwriters/ABSUnderwritersTab";
import ABSTranchePricesTab from "./tranchePrices/ABSTranchePricesTab";
import GeographicDistributionTab from "./geographicDistribution/GeographicDistributionTab";
import ABSRatingAgenciesTab from "./ratingAgency/ABSRatingAgenciesTab";
import NewABSStepper from "./NewABSStepper";

function NewABSLayout({ deal, onDealChange }) {
  return (
    <PrivateLayout>
      <Heading size="md">New ABS Deal</Heading>
      <Separator />
      <NewABSStepper>
        <ABSNewDealTab onSubmit={onDealChange} />
        <ABSUnderwritersTab deal={deal} />
        <ABSRatingAgenciesTab deal={deal} />
        <ABSTranchePricesTab deal={deal} />
        <GeographicDistributionTab deal={deal} />
      </NewABSStepper>
    </PrivateLayout>
  );
}

export default NewABSLayout;
