export const ADMIN_ROLE = "Admin";
export const DATA_TEAM_ROLE = "Data-Team";
export const READ_ONLY_ROLE = "Read-Only";
export const REGION_CODES = {
  CANADA: 'C',
  EUROPE: 'E',
  USA: 'U',
};
export const REGION_NAMES_MAP = {
  C: 'Canada',
  E: 'Europe',
  U: 'U.S.',
};