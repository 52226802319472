import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { InputGroup } from "@blueprintjs/core";
import isNil from "lodash/isNil";

function CurrencyInputGroup({
  disabled,
  intent,
  size,
  leftIcon,
  onChange,
  placeholder,
  rightElement,
  round,
  value,
  fullWidth,
  readOnly,
  decimalScale,
  max,
  allowNegative
}) {
  return (
    <NumberFormat
      value={value}
      thousandSeparator={true}
      decimalScale={decimalScale}
      customInput={InputGroup}
      disabled={disabled}
      readOnly={readOnly}
      intent={intent}
      large={size === "lg"}
      small={size === "sm"}
      leftIcon={leftIcon}
      placeholder={placeholder}
      rightElement={rightElement}
      round={round}
      fill={fullWidth}
      allowNegative={allowNegative}
      isAllowed={({ floatValue }) => floatValue <= max || !floatValue}
      onValueChange={({ floatValue }) => onChange(
        isNil(floatValue) ? null : floatValue,
      )}
    />
  );
}

CurrencyInputGroup.propTypes = {
  disabled: PropTypes.bool,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  leftIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rightElement: PropTypes.node,
  round: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  decimalScale: PropTypes.number,
  max: PropTypes.number,
  allowNegative: PropTypes.bool
};

CurrencyInputGroup.defaultProps = {
  value: undefined,
  disabled: false,
  intent: "none",
  size: "md",
  leftIcon: null,
  onChange: () => null,
  placeholder: null,
  rightElement: null,
  round: false,
  fullWidth: false,
  decimalScale: 4,
  max: 999.9999,
  allowNegative: true
};

export default CurrencyInputGroup;
