import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const SelectUnitOfMeasure = ({ onSelect, value, error, disabled, readOnly }) => (
  <Select
    items={[
      { id: "SF", label: "Square Feet" },
      { id: "SM", label: "Square Meters" },
    ]}
    onSelect={onSelect}
    value={value}
    error={error}
    disabled={disabled}
    readOnly={readOnly}
  />
);

SelectUnitOfMeasure.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

SelectUnitOfMeasure.defaultProps = {
  value: undefined,
  error: undefined,
  disabled: false,
  readOnly: false,
};

export default SelectUnitOfMeasure;