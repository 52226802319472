import React from "react";
import { Box } from "jsxstyle";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Table from "components/Table";
import PermissionValidator from "components/PermissionValidator";
import DeletePlacementAgent from "./DeletePlacementAgent";

function HYFundPlacementAgentTable({ data, pending, onReload }) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          data={data}
          onChange={(pagination, filter, sorter) => {}}
          pending={pending}
          rowKey="id"
          columns={[
            {
              title: "Placement Agent",
              dataIndex: "placement_agent_id.long_name",
              key: "placement_agent_id.entity_id",
            },
            {
              title: "Note",
              dataIndex: "note",
              key: "note",
            },
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: (item) => (
                      <Box>
                        <DeletePlacementAgent
                          placementAgent={item}
                          onDelete={onReload}
                        />
                      </Box>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </PermissionValidator>
  );
}

HYFundPlacementAgentTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onReload: PropTypes.func.isRequired,
  onRequestEdit: PropTypes.func.isRequired,
};

HYFundPlacementAgentTable.defaultProps = {
  data: [],
  pending: false,
};

export default HYFundPlacementAgentTable;
