import fetch from "../fetch";

export default ({ values, dealCode, underwriterId }) =>
  fetch({
    path: `/clo/deals/${dealCode}/underwriters${
      underwriterId ? `/${underwriterId}` : ""
    }`,
    method: underwriterId ? "PUT" : "POST",
    data: {
      entity_id: values.entity_id,
      entity_type: values.entity_type,
      amount: values.amount
    }
  });
