import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadABSCollateralService from "services/common/loadABSCollateralService";

function SelectCollateralCode({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  filter,
  readOnly,
  params
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadABSCollateralService}
      onSelect={onSelect}
      textPath="long_name"
      labelPath="collateral_code"
      idPath="collateral_code"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      filter={filter}
      readOnly={readOnly}
      params={params}
    />
  );
}

SelectCollateralCode.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filter: PropTypes.func
};

SelectCollateralCode.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search...",
  filter: null
};

export default SelectCollateralCode;
