import React from "react";
import PropTypes from "prop-types";
import InputGroup from "components/InputGroup";
import Form from "components/Form";
import Separator from "components/Separator";
import Button from "components/Button";
import { Row, Box } from "jsxstyle";
import omit from "lodash/omit";
import FormGroup from "components/FormGroup";
import AdvancedSearch from "components/AdvancedSearch";
import CurrentFiltersList from "components/CurrentFiltersList";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";
import SelectSector from "components/SelectSector";

function REPropFilters({ onChange, currentFilters }) {
  const [isAdvancedFiltersOpen, setAdvancedFiltersOpen] = React.useState(false);
  const [propertyIdValue, setPropertyIdValue] = React.useState(
    currentFilters.property_id,
  );
  const advancedSearchFilters = omit(currentFilters, ["property_id"]);
  React.useEffect(() => {
    if (!currentFilters.property_id) {
      setPropertyIdValue("");
    }
  }, [currentFilters.property_id]);

  return (
    <>
      <Row alignItems="flex-start">
        <Form
          onSubmit={() => {
            onChange({ property_id: propertyIdValue });
          }}
        >
          <Row width={350} alignItems="flex-start">
            <Box flex={1}>
              <FormGroup>
                <InputGroup
                  leftIcon="search"
                  placeholder="Search by property id"
                  value={propertyIdValue}
                  onChange={(value) => setPropertyIdValue(value)}
                  fullWidth
                />
              </FormGroup>
            </Box>
            <Separator />
            <Button
              intent="primary"
              icon="search"
              text="Search"
              type="submit"
            />
          </Row>
        </Form>
        <Separator />
        <Row alignItems="center">
          <AdvancedSearch
            currentFilters={advancedSearchFilters}
            isOpen={isAdvancedFiltersOpen}
            onRequestOpen={() => setAdvancedFiltersOpen(true)}
            onRequestClose={() => setAdvancedFiltersOpen(false)}
            onSubmit={onChange}
          >
            {({ getValue, setValue }) => (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, 300px)"
                gridGap={16}
              >
                <FormGroup label="Property name / address">
                  <InputGroup
                    value={getValue("property")}
                    onChange={(value) => setValue("property", value)}
                  />
                </FormGroup>
                <FormGroup label="Sector">
                  <SelectSector
                    onSelect={(value) => setValue("sector_id", value)}
                    value={getValue("sector_id")}
                  />
                </FormGroup>
                <FormGroup label="City">
                  <InputGroup
                    value={getValue("city")}
                    onChange={(value) => setValue("city", value)}
                  />
                </FormGroup>
                <FormGroup label="State">
                  <SelectNorthAmericanState
                    value={getValue("state")}
                    onSelect={(value) => setValue("state", value)}
                  />
                </FormGroup>
                <FormGroup label="Market">
                  <InputGroup
                    value={getValue("market")}
                    onChange={(value) => setValue("market", value)}
                  />
                </FormGroup>
              </Box>
            )}
          </AdvancedSearch>
          <Separator />
          <Button
            icon="remove"
            minimal
            onClick={() => onChange({})}
            text="Clear filters"
          />
        </Row>
      </Row>
      <CurrentFiltersList
        filters={currentFilters}
        onRemove={(key) => {
          onChange({
            ...currentFilters,
            [key]: null,
          });
        }}
      />
    </>
  );
}

REPropFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default REPropFilters;
