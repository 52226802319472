import React from "react";
import isEmpty from "lodash/isEmpty";
import { Row } from "jsxstyle";
import { withRouter } from "react-router-dom";
import useToast from "hooks/useToast";
import Card from "components/Card";
import Form from "components/Form";
import Button from "components/Button";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import isRequired from "validators/isRequired";
import minLength from "validators/minLength";
import saveCLODealService from "services/clo/saveCLODealService";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import CLOFields from "./CLOFields";
import history from "browserHistory";

function NewCLODealTab({ initialValues, isNew = true, onSubmit }) {
  const { showSuccess, showError } = useToast();

  const [fetch, pending] = useAsync({
    promiseFn: saveCLODealService,
    onData: (deal) => {
      showSuccess("CLO deal saved.");
      onSubmit(deal);
      history.push(`/clo/new/${deal.deal_code}/underwriters`);
    },
    onError: ({ data: { error } = {} }) => {
      console.error(error);
      const errorMessage =
        {
          duplicated_key: "Duplicate Deal Code",
        }[error] || "An error happened.";
      showError(errorMessage);
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: isEmpty(initialValues) ? {} : initialValues,
    validations: {
      deal_code: [isRequired, (value) => minLength(value, 6)],
      deal_type: [isRequired],
      pricing_date: [isRequired],
      issuer: [isRequired],
      deal_amount: [isRequired],
    },
    onSubmit: (values) => fetch({ values }),
  });

  return (
    <Card>
      <TwoColumnFormLayout title="CLO Deal" size="sm">
        <Form onSubmit={submit}>
          <CLOFields
            getValue={getValue}
            setValue={setValue}
            getError={getError}
            isSubmitting={pending}
          />
          <Row justifyContent="flex-end">
            <Button
              type="submit"
              text={"Next"}
              intent="primary"
              onClick={() => {}}
              disabled={pending}
              loading={pending}
            />
          </Row>
        </Form>
      </TwoColumnFormLayout>
    </Card>
  );
}

export default withRouter(NewCLODealTab);
