import React from "react";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import Button from "components/Button";
import useForm from "hooks/useForm";
import Callout from "components/Callout";
import Form from "components/Form";
import isRequired from "validators/isRequired";
import passwordStrength from "validators/passwordStrength";
import Separator from "components/Separator";

function ChangePasswordForm({ pending, onSubmit, error }) {
  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      password: [isRequired, passwordStrength]
    },
    onSubmit
  });

  return (
    <>
      <Form onSubmit={submit}>
        <FormGroup
          label="New Password"
          onChange={value => setValue("password", value)}
          error={getError("password")}
        >
          <InputGroup
            type="password"
            leftIcon="lock"
            size="lg"
            value={getValue("password")}
            onChange={value => setValue("password", value)}
            error={getError("password")}
            disabled={pending}
          />
        </FormGroup>
        <Button
          intent="primary"
          text="Login"
          icon="log-in"
          size="lg"
          type="submit"
          fullWidth
          loading={pending}
        />
      </Form>
      <Separator />
      {error ? (
        <Callout intent="danger">An error happened</Callout>
      ) : (
        <Callout intent="primary">
          Since it's your first access, please change your password.
        </Callout>
      )}
    </>
  );
}

ChangePasswordForm.propTypes = {};

ChangePasswordForm.defaultProps = {};

export default ChangePasswordForm;
