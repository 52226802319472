import React from "react";
import PropTypes from "prop-types";
import { H1, H2, H3, H4, H5 } from "@blueprintjs/core";

function Heading({ size, children }) {
  const Element = {
    xs: H5,
    sm: H4,
    md: H3,
    lg: H2,
    xl: H1
  }[size];
  return <Element intent="primary">{children}</Element>;
}

Heading.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  children: PropTypes.node.isRequired
};

Heading.defaultProps = { size: "xl" };

export default Heading;
