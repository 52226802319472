import React from "react";
import PropTypes from "prop-types";
import PermissionValidator from "./PermissionValidator";
import Button from "./Button";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function EditButton({ onClick }) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Button
          size="sm"
          icon={isAllowed ? "edit" : "eye-open"}
          onClick={onClick}
        />
      )}
    </PermissionValidator>
  );
}

EditButton.propTypes = { onClick: PropTypes.func.isRequired };

EditButton.defaultProps = {};

export default EditButton;
