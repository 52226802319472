import React from "react";
import PropTypes from "prop-types";
import { Row, Box } from "jsxstyle";
import { useRegionContext } from 'contexts/RegionContext';
import { TableWithFixedHeader } from "components/Table";
import history from "browserHistory";
import Separator from "components/Separator";
import DeleteRESale from "./DeleteRESale";
import DuplicateRESale from "./DuplicateRESale";
import RESalePropropertyTable from "./RESalePropropertyTable";
import columns from "./RESaleTableColumns";
import EditButton from "components/EditButton";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function RESaleTable({ data, pending, onSortChange, onReload, sortable }) {
  const { region, getRegionPath } = useRegionContext();
  const reTableColumns = JSON.parse(localStorage.getItem(`re/saleColumns-${region}`));

  const visibleColumns = reTableColumns
    ? reTableColumns.map((column) =>
        columns.find((tableColumn) => tableColumn.title === column),
      )
    : columns;
  return (
    <React.Fragment>
      <TableWithFixedHeader
        pending={pending}
        rowKey="sale_id"
        scroll={{
          x:
            visibleColumns.reduce((acc, column) => acc + column.width, 0) + 480,
        }}
        expandedRowRender={(item) => (
          <Box width="30%">
            <RESalePropropertyTable saleId={item.sale_id} />
          </Box>
        )}
        columns={[
          {
            title: "Sale ID",
            dataIndex: "sale_id",
            key: "sale_id",
            width: 120,
            sortable: sortable !== false,
          },
          {
            title: "Property Type",
            dataIndex: "portfolio",
            key: "property_type",
            width: 240,
            sortable: sortable !== false,
            render: (portfolio) => (portfolio ? "Portfolio" : "Property"),
          },
          //prop 'sortable=false' overrides column defined sortable value
          ...visibleColumns.map((column) => ({
            ...column,
            sortable: sortable !== false,
          })),
          {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            fixed: "right",
            width: 120,
            render: (item) => (
              <Row>
                <EditButton
                  onClick={() =>
                    item.portfolio
                      ? history.push(getRegionPath(`/re/sale/portfolio/${item.sale_id}`))
                      : history.push(getRegionPath(`/re/sale/${item.sale_id}`))
                  }
                />
                <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
                  <Separator size="xs" />
                  <DeleteRESale re={item} onDelete={onReload} />
                  <Separator size="xs" />
                  <DuplicateRESale
                    onDuplicate={onReload}
                    initialValues={item}
                  />
                </PermissionValidator>
              </Row>
            ),
          },
        ]}
        data={data}
        onSortChange={onSortChange}
      />
    </React.Fragment>
  );
}

RESaleTable.propTypes = {
  attribute: PropTypes.bool,
};

RESaleTable.defaultProps = {
  sortable: true,
};

export default RESaleTable;
