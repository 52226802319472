import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteCMBSBPieceBuyer from "services/cmbs/deleteCMBSBPieceBuyer";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteCMBSBPieceBuyer({ buyer, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteCMBSBPieceBuyer,
    onData: () => {
      showSuccess("Buyer deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Buyer");
    }
  });

  return (
    <React.Fragment>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ dealCode: buyer.deal_code, id: buyer.id })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Buyer{" "}
        <b>{buyer.buyer_entity_id.long_name}</b>?
      </Alert>
    </React.Fragment>
  );
}

DeleteCMBSBPieceBuyer.propTypes = {
  buyer: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteCMBSBPieceBuyer.defaultProps = {
  buyer: {}
};

export default DeleteCMBSBPieceBuyer;
