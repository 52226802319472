import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteCMBSService from "services/cmbs/deleteCMBSService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteCMBS({ cmbs, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteCMBSService,
    onError: error => {
      showError("Error deleting CMBS");
    },
    onData: () => {
      showSuccess("CMBS deleted successfully.");
      onDelete();
    }
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ dealCode: cmbs.deal_code })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the CMBS <b>{cmbs.deal_code}</b>?
      </Alert>
    </>
  );
}

DeleteCMBS.propTypes = {
  cmbs: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteCMBS.defaultProps = {
  cmbs: {}
};

export default DeleteCMBS;
