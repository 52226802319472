import React from "react";
import { Button as BluePrintButton } from "@blueprintjs/core";
import PropTypes from "prop-types";

function Button({
  text,
  icon,
  rightIcon,
  intent,
  size,
  active,
  loading,
  minimal,
  disabled,
  type,
  alignText,
  onClick,
  fullWidth
}) {
  return (
    <BluePrintButton
      text={text}
      icon={icon}
      rightIcon={rightIcon}
      intent={intent}
      size={size}
      active={active}
      loading={loading}
      minimal={minimal}
      disabled={disabled}
      type={type}
      alignText={alignText}
      onClick={onClick}
      large={size === "lg"}
      small={size === "sm"}
      fill={fullWidth}
    />
  );
}

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  rightIcon: PropTypes.string,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  active: PropTypes.bool,
  loading: PropTypes.bool,
  minimal: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "reset", "button"]),
  alignText: PropTypes.oneOf(["center", "left", "right"]),
  onClick: PropTypes.func
};

Button.defaultProps = {
  text: null,
  icon: null,
  rightIcon: null,
  intent: "none",
  size: "md",
  active: false,
  loading: false,
  minimal: false,
  disabled: false,
  type: "button",
  alignText: "center",
  onClick: () => null,
  fullWidth: false
};

export default Button;
