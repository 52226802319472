export default [
  {
    title: "Full name",
    dataIndex: "manager_id.long_name",
    key: "manager_id.long_name",
    width: 240,
  },
  {
    title: "Editorial name",
    dataIndex: "manager_id.editorial_name",
    key: "manager_id.editorial_name",
    width: 240,
  },
  {
    title: "Editorial description",
    dataIndex: "manager_id.editorial_description",
    key: "manager_id.editorial_description",
    width: 240,
  },
  {
    title: "Entity type",
    dataIndex: "manager_id.entity_type.long_name",
    key: "manager_id.entity_type.long_name",
    width: 240,
  },
  {
    title: "Parent",
    dataIndex: "manager_id.parent_entity.long_name",
    key: "manager_id.parent_entity.long_name",
    width: 240,
  },
  {
    title: "Address",
    dataIndex: "manager_id.address",
    key: "manager_id.address",
    width: 240,
  },
  {
    title: "Postal Code",
    dataIndex: "manager_id.postal_code",
    key: "manager_id.postal_code",
    width: 150,
  },
  {
    title: "City",
    dataIndex: "manager_id.city",
    key: "manager_id.city",
    width: 240,
  },
  {
    title: "State",
    dataIndex: "manager_id.state.state_name",
    key: "manager_id.state.state_name",
    width: 240,
  },
  {
    title: "Country",
    dataIndex: "manager_id.country_code.country_code",
    key: "manager_id.country_code.country_code",
    width: 150,
  },
  {
    title: "Phone",
    dataIndex: "manager_id.phone",
    key: "manager_id.phone",
    width: 150,
  },
  {
    title: "Website",
    dataIndex: "manager_id.website",
    key: "manager_id.website",
    width: 240,
  },
  {
    title: "Year Founded",
    dataIndex: "manager_id.year_founded",
    key: "manager_id.year_founded",
    width: 150,
  },
  {
    title: "Search Terms",
    dataIndex: "manager_id.search_term",
    key: "manager_id.search_term",
    width: 240,
  },
  {
    title: "Public Note",
    dataIndex: "public_note",
    key: "public_note",
    width: 400,
  },
  {
    title: "Private Note",
    dataIndex: "private_note",
    key: "private_note",
    width: 400,
  },
];
