import React from "react";
import PropTypes from "prop-types";
import { Box, Row } from "jsxstyle";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import DateInputGroup from "components/DateInputGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import InputGroup from "components/InputGroup";
import DecimalInputGroup from "components/DecimalInputGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import TextArea from "components/TextArea";
import Select from "components/Select";
import Switch from "components/Switch";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";
import SelectAttribute from "components/SelectAttribute";
import SelectSubSector from "components/SelectSubSector";
import SelectSector from "components/SelectSector";
import SelectREMarket from "components/SelectREMarket";
import SelectUnitOfMeasure from "components/SelectUnitOfMeasure";
import { Tooltip, Icon } from "@blueprintjs/core";
import Separator from "components/Separator";
import SelectCurrencyCode from "components/SelectCurrencyCode";
import SelectCountryCode from "components/SelectCountryCode";
import { REGION_CODES } from "consts";

function RESaleFields({
  getValue,
  setValue,
  getValues,
  setValues,
  getError,
  isSubmitting,
  portfolio,
  region
}) {
  const isUSA = region === REGION_CODES.USA;
  const disabledForPropertyType = (types) =>
    types.some((type) => {
      if (portfolio) {
        return type === getValue("prop_type");
      }
      return type === getValue("dev");
    });

  const sfPriceIsDisabled = disabledForPropertyType(["M", "L", "H"]);
  const unitPriceIsDisabled = disabledForPropertyType([
    "F",
    "OFS",
    "R",
    "W",
    "MU",
    "MP",
  ]);

  React.useEffect(() => {
    if (sfPriceIsDisabled) {
      setValue("sf_price", null);
    }

    if (unitPriceIsDisabled) {
      setValue("unit_price", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sfPriceIsDisabled, unitPriceIsDisabled]);

  const sfPrice =
    (getValue("price") * 1000) /
    (getValue("sf_sold") * (getValue("stake") / 100));

  const unitPrice =
    (getValue("price") * 1000000) /
    (getValue("units") * (getValue("stake") / 100));

  React.useEffect(() => {
    if (typeof sfPrice === "number") {
      if (!sfPriceIsDisabled) {
        setValue("sf_price", sfPrice);
      }
    }
    if (typeof unitPrice === "number") {
      if (!unitPriceIsDisabled) {
        setValue("unit_price", unitPrice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sfPrice, unitPrice]);

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <FormGrid>
          {portfolio && (
            <React.Fragment>
              <FormGrid.Item>
                <FormGroup
                  label="Portfolio name"
                  disabled={isSubmitting}
                  error={getError("portfolio_name")}
                  required
                >
                  <InputGroup
                    label="portfolio-test"
                    intent={getError("portfolio_name") ? "danger" : null}
                    value={getValue("portfolio_name")}
                    onChange={(value) => setValue("portfolio_name", value)}
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Portfolio type"
                  disabled={isSubmitting}
                  error={getError("portfolio_type")}
                >
                  <Select
                    items={[
                      { id: "FP", label: "Office park" },
                      { id: "WP", label: "Industrial park" },
                    ]}
                    onSelect={(value) => setValue("portfolio_type", value)}
                    value={getValue("portfolio_type")}
                    error={getError("portfolio_type")}
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Number of Properties"
                  disabled={isSubmitting}
                  error={getError("num_props")}
                  required
                >
                  <IntegerInputGroup
                    intent={getError("num_props") ? "danger" : null}
                    value={getValue("num_props")}
                    onChange={(value) => setValue("num_props", value)}
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="State"
                  disabled={isSubmitting}
                  error={getError("state")}
                >
                  <SelectNorthAmericanState
                    onSelect={(value) =>
                      setValues({ ...getValues(), state: value, market: null })
                    }
                    value={getValue("state")}
                    error={getError("state")}
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="City"
                  disabled={isSubmitting}
                  error={getError("city")}
                >
                  <InputGroup
                    intent={getError("city") ? "danger" : null}
                    value={getValue("city")}
                    onChange={(value) =>
                      setValues({ ...getValues(), city: value, market: null })
                    }
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Zip Code / Postal Code"
                  disabled={isSubmitting}
                  error={getError("postal_code")}
                >
                  <InputGroup
                    maxLength={9}
                    intent={getError("postal_code") ? "danger" : null}
                    value={getValue("postal_code")}
                    onChange={(value) => setValue("postal_code", value)}
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
            </React.Fragment>
          )}
          <FormGrid.Item>
            <FormGroup
              label="Country"
              disabled={isSubmitting}
              error={getError("country_code")}
              required
            >
              <SelectCountryCode
                      onSelect={(value) => setValue("country_code", value)}
                  value={getValue("country_code")}
                  error={getError("country_code")}
                  disabled={isSubmitting}
                  region={region}
                    />
            </FormGroup>
          </FormGrid.Item>
          {portfolio && (
            <React.Fragment>
              <FormGrid.Item>
                <FormGroup
                  label={
                    <Row alignItems="center">
                      Market
                      <Separator size="xs" />
                      <Tooltip
                        content="Select a State or City to enable this field"
                        position="bottom"
                      >
                        <Icon icon="help" iconSize={16} color="#394b59" />
                      </Tooltip>
                    </Row>
                  }
                  disabled={isSubmitting}
                  error={getError("market")}
                >
                  <SelectREMarket
                    key={`${getValue("city")}-${getValue("state")}`}
                    city={getValue("city")}
                    state={getValue("state")}
                    intent={getError("market") ? "danger" : null}
                    value={getValue("market")}
                    onSelect={(value) => setValue("market", value)}
                    disabled={
                      isSubmitting || (!getValue("state") && !getValue("city"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
            </React.Fragment>
          )}
          <FormGrid.Item>
            <FormGroup
              label="Sale date"
              disabled={isSubmitting}
              error={getError("sale_date")}
              required
            >
              <DateInputGroup
                intent={getError("sale_date") ? "danger" : null}
                value={getValue("sale_date")}
                onChange={(value) => setValue("sale_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination"
              disabled={isSubmitting}
              error={getError("currency_code")}
            >
              <SelectCurrencyCode
                onSelect={(value) => setValue("currency_code", value)}
                value={getValue("currency_code")}
                error={getError("currency_code")}
                disabled={isSubmitting}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Sale Price"
              disabled={isSubmitting}
              error={getError("price")}
              required={isUSA}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("price") ? "danger" : null}
                value={getValue("price")}
                onChange={(value) => setValue("price", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                decimalScale={6}
              />
            </FormGroup>
          </FormGrid.Item>
          {portfolio && (
            <>
              <FormGrid.Item>
                <FormGroup
                  label="Sector"
                  required
                  disabled={isSubmitting}
                  error={getError("sector_id")}
                >
                  <SelectSector
                    onSelect={(value) => setValues((currentValue) => ({
                      ...currentValue,
                      sector_id: value,
                      sub_sector_id: null,
                      attribute_id: null,
                    }))}
                    value={getValue("sector_id")}
                    error={getError("sector_id")}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label={
                    <Row alignItems="center">
                      Sub Sector
                    </Row>
                  }
                  disabled={isSubmitting}
                  error={getError("sub_sector_id")}
                >
                  <SelectSubSector
                    sectorId={getValue("sector_id")}
                    onSelect={(value) => setValues((currentValue) => ({
                      ...currentValue,
                      sub_sector_id: value,
                      attribute_id: null,
                    }))}
                    value={getValue("sub_sector_id")}
                    error={getError("sub_sector_id")}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Attribute"
                  disabled={isSubmitting}
                  error={getError("attribute_id")}
                >
                  <SelectAttribute
                    subSectorId={getValue("sub_sector_id") || getValue("sector_id")}
                    onSelect={(value) => setValue("attribute_id", value)}
                    value={getValue("attribute_id")}
                    error={getError("attribute_id")}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </FormGrid.Item>
            </>
          )}
          <FormGrid.Item>
            <FormGroup
              label={
                <Row alignItems="center">
                  SF Sold
                  <Separator size="xs" />
                  <Tooltip
                    content="Only applicable for retail sales"
                    position="bottom"
                  >
                    <Icon icon="help" iconSize={16} color="#394b59" />
                  </Tooltip>
                </Row>
              }
              disabled={isSubmitting}
              error={getError("sf_sold")}
            >
              <CurrencyInputGroup
                intent={getError("sf_sold") ? "danger" : null}
                value={getValue("sf_sold")}
                onChange={(value) => setValue("sf_sold", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          {portfolio && (
            <React.Fragment>
              <FormGrid.Item>
                <FormGroup
                  label="Total SF"
                  disabled={isSubmitting}
                  error={getError("sf_total")}
                >
                  <CurrencyInputGroup
                    intent={getError("sf_total") ? "danger" : null}
                    value={getValue("sf_total")}
                    onChange={(value) => setValue("sf_total", value)}
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Unit of Measure"
                  disabled={isSubmitting}
                  error={getError("measurement_unit")}
                >
                  <SelectUnitOfMeasure
                    onSelect={(value) => setValue("measurement_unit", value)}
                    value={getValue("measurement_unit")}
                    error={getError("measurement_unit")}
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Number of units"
                  disabled={isSubmitting}
                  error={getError("units")}
                >
                  <IntegerInputGroup
                    intent={getError("units") ? "danger" : null}
                    value={getValue("units")}
                    onChange={(value) => setValue("units", value)}
                    buttonPosition="none"
                    disabled={isSubmitting}
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
            </React.Fragment>
          )}
          <FormGrid.Item>
            <FormGroup
              label="Price accuracy"
              disabled={isSubmitting}
              error={getError("price_accuracy")}
              required
            >
              <Select
                items={[
                  { id: "Allocated", label: "Allocated" },
                  { id: "Confirmed", label: "Confirmed" },
                  { id: "Estimated", label: "Estimated" },
                ]}
                onSelect={(value) => setValue("price_accuracy", value)}
                value={getValue("price_accuracy")}
                error={getError("price_accuracy")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Conversion type"
              disabled={isSubmitting}
              error={getError("conv")}
            >
              <Select
                items={[
                  { id: "TD", label: "Tear-down conversion" },
                  { id: "RD", label: "Redevelopment" },
                ]}
                onSelect={(value) => setValue("conv", value)}
                value={getValue("conv")}
                error={getError("conv")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Redevelopment Sector"
              disabled={isSubmitting}
              error={getError("sector_redev_id")}
            >
              <SelectSector
                onSelect={(value) => setValue("sector_redev_id", value)}
                value={getValue("sector_redev_id")}
                error={getError("sector_redev_id")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Stake sold"
              disabled={isSubmitting}
              error={getError("stake")}
              required
            >
              <IntegerInputGroup
                intent={getError("stake") ? "danger" : null}
                value={getValue("stake")}
                onChange={(value) => setValue("stake", value)}
                buttonPosition="none"
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Price per SF"
              disabled={isSubmitting}
              error={getError("sf_price")}
            >
              <CurrencyInputGroup
                intent={getError("sf_price") ? "danger" : null}
                value={getValue("sf_price")}
                onChange={(value) => setValue("sf_price", value)}
                disabled={isSubmitting || sfPriceIsDisabled}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Price per unit"
              disabled={isSubmitting}
              error={getError("unit_price")}
            >
              <CurrencyInputGroup
                intent={getError("unit_price") ? "danger" : null}
                value={getValue("unit_price")}
                onChange={(value) => setValue("unit_price", value)}
                disabled={isSubmitting || unitPriceIsDisabled}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Broker 1"
              disabled={isSubmitting}
              error={getError("broker_1")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("broker_1", value)}
                value={getValue("broker_1")}
                error={getError("broker_1")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Broker 2"
              disabled={isSubmitting}
              error={getError("broker_2")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("broker_2", value)}
                value={getValue("broker_2")}
                error={getError("broker_2")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <Box className="col-2">
            <FormGroup
              label="Sale-leaseback"
              disabled={isSubmitting}
              error={getError("sale_leaseback")}
            >
              <Switch
                value={getValue("sale_leaseback")}
                onChange={() =>
                  setValue("sale_leaseback", !getValue("sale_leaseback"))
                }
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
          <Box className="col-2">
            <FormGroup
              label="Closed"
              disabled={isSubmitting}
              error={getError("closed")}
            >
              <Switch
                value={getValue("closed")}
                onChange={() => setValue("closed", !getValue("closed"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
          <FormGrid.Item>
            <FormGroup
              label="Leasehold"
              disabled={isSubmitting}
              error={getError("leasehold")}
            >
              <Switch
                value={getValue("leasehold")}
                onChange={() => setValue("leasehold", !getValue("leasehold"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          {/* <FormGrid.Item>
            <FormGroup
              label="Portfolio code"
              disabled={isSubmitting}
              error={getError("portfolio_code")}
            >
              <IntegerInputGroup
                intent={getError("portfolio_code") ? "danger" : null}
                value={getValue("portfolio_code")}
                onChange={value => setValue("portfolio_code", value)}
                buttonPosition="none"
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item> */}
          <FormGrid.Item>
            <FormGroup
              label="Distressed sale type"
              disabled={isSubmitting}
              error={getError("distressed")}
            >
              <Select
                items={[
                  { id: "Debt purchase", label: "Debt purchase" },
                  { id: "Deed-in-lieu", label: "Deed-in-lieu" },
                  { id: "Distressed", label: "Distressed" },
                  { id: "Foreclosure auction", label: "Foreclosure auction" },
                ]}
                onSelect={(value) => setValue("distressed", value)}
                value={getValue("distressed")}
                error={getError("distressed")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          {/* <FormGrid.Item>
        <FormGroup
          label="Distressed"
          disabled={isSubmitting}
          error={getError("distressed")}
        >
          <Select
            items={[
              { id: "foreclosure auction", label: "foreclosure auction" },
              { id: "debt sale", label: "debt sale" },
              { id: "deed in lieu", label: "deed in lieu" },
              { id: "distressed", label: "distressed" }
            ]}
            onSelect={value => setValue("distressed", value)}
            value={getValue("distressed")}
            error={getError("distressed")}
            disabled={isSubmitting}
          />
        </FormGroup>
      </FormGrid.Item> */}
          <FormGrid.Item>
            <FormGroup
              label="Capitalization rate"
              disabled={isSubmitting}
              error={getError("cap_rate")}
            >
              <DecimalInputGroup
                intent={getError("cap_rate") ? "danger" : null}
                value={getValue("cap_rate")}
                onChange={(value) => setValue("cap_rate", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Occupancy %"
              disabled={isSubmitting}
              error={getError("occupancy")}
            >
              <DecimalInputGroup
                intent={getError("occupancy") ? "danger" : null}
                value={getValue("occupancy")}
                onChange={(value) => setValue("occupancy", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Buyer broker"
              disabled={isSubmitting}
              error={getError("buyer_broker")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("buyer_broker", value)}
                value={getValue("buyer_broker")}
                error={getError("buyer_broker")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Vehicle buyer"
              disabled={isSubmitting}
              error={getError("vehicle_buyer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("vehicle_buyer", value)}
                value={getValue("vehicle_buyer")}
                error={getError("vehicle_buyer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Vehicle seller"
              disabled={isSubmitting}
              error={getError("vehicle_seller")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("vehicle_seller", value)}
                value={getValue("vehicle_seller")}
                error={getError("vehicle_seller")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Lender Entity"
              disabled={isSubmitting}
              error={getError("lender_id")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("lender_id", value)}
                value={getValue("lender_id")}
                error={getError("lender_id")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Loan Amount"
              disabled={isSubmitting}
              error={getError("amount_loan")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("amount_loan") ? "danger" : null}
                value={getValue("amount_loan")}
                onChange={(value) => setValue("amount_loan", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                decimalScale={6}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Updated"
              disabled={isSubmitting}
              error={getError("updated")}
              required
            >
              <DateInputGroup
                intent={getError("updated") ? "danger" : null}
                value={getValue("updated")}
                onChange={(value) => setValue("updated", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Flag"
              disabled={isSubmitting}
              error={getError("flag")}
            >
              <InputGroup
                intent={getError("flag") ? "danger" : null}
                value={getValue("flag")}
                onChange={(value) => setValue("flag", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Source"
              disabled={isSubmitting}
              error={getError("source")}
              required
            >
              <InputGroup
                intent={getError("source") ? "danger" : null}
                value={getValue("source")}
                onChange={(value) => setValue("source", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Source Owner"
              disabled={isSubmitting}
              error={getError("source_owner")}
            >
              <InputGroup
                intent={getError("source_owner") ? "danger" : null}
                value={getValue("source_owner")}
                onChange={(value) => setValue("source_owner", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Source Seller"
              disabled={isSubmitting}
              error={getError("source_seller")}
            >
              <InputGroup
                intent={getError("source_seller") ? "danger" : null}
                value={getValue("source_seller")}
                onChange={(value) => setValue("source_seller", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Source Id"
              disabled={isSubmitting}
              error={getError("source_id")}
            >
              <IntegerInputGroup
                intent={getError("source_id") ? "danger" : null}
                value={getValue("source_id")}
                onChange={(value) => setValue("source_id", value)}
                buttonPosition="none"
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Notes"
              disabled={isSubmitting}
              error={getError("notes")}
            >
              <TextArea
                intent={getError("notes") ? "danger" : null}
                value={getValue("notes")}
                onChange={(value) => setValue("notes", value)}
                disabled={isSubmitting}
                fullWidth
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <Box className="col-2">
            <FormGroup
              label="Confidential Transaction"
              disabled={isSubmitting}
              error={getError("flag_confidential_transaction")}
            >
              <Switch
                value={getValue("flag_confidential_transaction")}
                onChange={() =>
                  setValue(
                    "flag_confidential_transaction",
                    Number(!getValue("flag_confidential_transaction"))
                  )
                }
                disabled={isSubmitting}
              />
            </FormGroup>
          </Box>
          <Box className="col-2">
            <FormGroup
              label="Confidential Price"
              disabled={isSubmitting}
              error={getError("flag_confidential_price")}
            >
              <Switch
                value={getValue("flag_confidential_price")}
                onChange={() =>
                  setValue(
                    "flag_confidential_price",
                    Number(!getValue("flag_confidential_price"))
                  )
                }
                disabled={isSubmitting}
              />
            </FormGroup>
          </Box>
          <Box className="col-2">
            <FormGroup
              label="Confidential Seller"
              disabled={isSubmitting}
              error={getError("flag_confidential_seller")}
            >
              <Switch
                value={getValue("flag_confidential_seller")}
                onChange={() =>
                  setValue(
                    "flag_confidential_seller",
                    Number(!getValue("flag_confidential_seller"))
                  )
                }
                disabled={isSubmitting}
              />
            </FormGroup>
          </Box>
          <Box className="col-2">
            <FormGroup
              label="Confidential Buyer"
              disabled={isSubmitting}
              error={getError("flag_confidential_buyer")}
            >
              <Switch
                value={getValue("flag_confidential_buyer")}
                onChange={() =>
                  setValue(
                    "flag_confidential_buyer",
                    Number(!getValue("flag_confidential_buyer"))
                  )
                }
                disabled={isSubmitting}
              />
            </FormGroup>
          </Box>
          <Box className="col-4">
            <FormGroup
              label="Confidential Cap Rate"
              disabled={isSubmitting}
              error={getError("flag_confidential_cap_rate")}
            >
              <Switch
                value={getValue("flag_confidential_cap_rate")}
                onChange={() =>
                  setValue(
                    "flag_confidential_cap_rate",
                    Number(!getValue("flag_confidential_cap_rate"))
                  )
                }
                disabled={isSubmitting}
              />
            </FormGroup>
          </Box>
        </FormGrid>
      )}
    </PermissionValidator>
  );
}

RESaleFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  portfolio: PropTypes.bool
};

RESaleFields.defaultProps = {
  portfolio: false,
};

export default RESaleFields;
