import React from "react";
import { withRouter } from "react-router-dom";
import Stepper from "components/Stepper";

const steps = [
  "Basic Info",
  "Target Assets",
  "Target Regions",
  "Management and Sponsors",
  "Fee structure",
  "Placement Agent",
];

function NewHYFundStepper({ match, children }) {
  const { currentView } = match.params;
  const currentStep =
    {
      "target-assets": 1,
      "target-regions": 2,
      "management-and-sponsors": 3,
      "fee-structure": 4,
      "placement-agent": 5,
    }[currentView] || 0;
  return (
    <Stepper steps={steps} currentStep={currentStep}>
      {children}
    </Stepper>
  );
}

NewHYFundStepper.propTypes = {};

NewHYFundStepper.defaultProps = {};

export default withRouter(NewHYFundStepper);
