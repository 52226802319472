import React from "react";
import PropTypes from "prop-types";
import PermissionValidator from "./PermissionValidator";
import Button from "./Button";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function AddButton({ onClick, text }) {
  return (
    <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]} shouldHide>
      <Button
        intent="primary"
        icon="add"
        text={text || "Add"}
        onClick={onClick}
      />
    </PermissionValidator>
  );
}

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string
};

AddButton.defaultProps = {
  text: undefined
};

export default AddButton;
