import React from "react";
import PropTypes from "prop-types";
import { Row } from "jsxstyle";
import { TableWithFixedHeader } from "components/Table";
import Button from "components/Button";
import history from "browserHistory";
import Separator from "components/Separator";
import DeleteABS from "./DeleteABS";
import DuplicateABSForm from "./DuplicateABSForm";
import columns from "./ABSTableColumns";
import EditButton from "components/EditButton";
import PermissionValidator from "components/PermissionValidator";
import Modal, { ModalContent } from "components/Modal";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import useQueryStringFilters from "hooks/useQueryStringFilters";

function ABSTable({ data, pending, onSortChange, onReload, sortable }) {
  const { getQuery } = useQueryStringFilters();
  const { order_by, order_direction } = getQuery();

  const [ABSToDuplicate, setABSToDuplicate] = React.useState(null);
  const absTableColumns = JSON.parse(localStorage.getItem("absColumns"));

  const visibleColumns = absTableColumns
    ? absTableColumns.map((column) =>
        columns.find((tableColumn) => tableColumn.title === column),
      )
    : columns;

  return (
    <React.Fragment>
      <TableWithFixedHeader
        pending={pending}
        rowKey="deal_code"
        scroll={{
          x:
            visibleColumns.reduce((acc, column) => acc + column.width, 0) + 240,
        }}
        columns={[
          {
            title: "Deal code",
            dataIndex: "deal_code",
            key: "deal_code",
            width: 120,
            fixed: "left",
            sortable: sortable !== false,
            sortOrder:
              order_by === "deal_code" &&
              {
                ASC: "ascend",
                DESC: "descend",
              }[order_direction],
          },
          ...visibleColumns.map((column) => ({
            ...column,
            sortable: sortable !== false,
          })),
          {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            fixed: "right",
            width: 120,
            render: (item) => (
              <Row>
                <EditButton
                  onClick={() => history.push(`/abs/edit/${item.deal_code}`)}
                />
                <PermissionValidator
                  allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
                  shouldHide
                >
                  <Separator size="xs" />
                  <DeleteABS abs={item} onDelete={onReload} />
                  <Separator size="xs" />
                  <Button
                    size="sm"
                    icon="duplicate"
                    onClick={() => setABSToDuplicate(item)}
                  />
                </PermissionValidator>
              </Row>
            ),
          },
        ]}
        data={data}
        onSortChange={onSortChange}
      />
      <Modal
        visible={ABSToDuplicate}
        onRequestClose={() => setABSToDuplicate(null)}
        width={450}
      >
        <ModalContent>
          <DuplicateABSForm
            onRequestClose={() => setABSToDuplicate(null)}
            onDuplicate={onReload}
            initialValues={ABSToDuplicate}
          />
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}

ABSTable.propTypes = {
  attribute: PropTypes.bool,
};

ABSTable.defaultProps = {
  sortable: true,
};

export default ABSTable;
