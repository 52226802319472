import React from "react";
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import CMBSNewDealTab from "./deal/CMBSNewDealTab";
import CMBSUnderwritersTab from "./underwriters/CMBSUnderwritersTab";
import CMBSSellersTab from "./sellers/CMBSSellersTab";
import CMBSServicersTab from "./servicers/CMBSServicersTab";
import CMBSBPieceBuyersTab from "./bPieceBuyers/CMBSBPieceBuyersTab";
import CMBSTranchePricesTab from "./tranchePrices/CMBSTranchePricesTab";
import CMBSRatingAgenciesTab from "./ratingAgencies/CMBSRatingAgenciesTab";
import NewCMBSStepper from "./NewCMBSStepper";
import CMBSBorrowersTab from "./borrowers/CMBSBorrowersTab";

function NewCMBSLayout({ deal, onDealChange }) {
  return (
    <PrivateLayout>
      <Heading size="md">New CMBS Deal</Heading>
      <Separator />
      <NewCMBSStepper>
        <CMBSNewDealTab onSubmit={onDealChange} />
        <CMBSUnderwritersTab deal={deal} />
        <CMBSRatingAgenciesTab deal={deal} />
        <CMBSBorrowersTab deal={deal} />
        <CMBSSellersTab deal={deal} />
        <CMBSServicersTab deal={deal} />
        <CMBSBPieceBuyersTab deal={deal} />
        <CMBSTranchePricesTab deal={deal} />
      </NewCMBSStepper>
    </PrivateLayout>
  );
}

export default NewCMBSLayout;
