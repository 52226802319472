import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadCMBSRateTypesService from "services/cmbs/loadCMBSRateTypesService";

function SelectRateType({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadCMBSRateTypesService}
      onSelect={onSelect}
      textPath="rate_type_desc"
      labelPath="rate_code"
      idPath="rate_code"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
    />
  );
}

SelectRateType.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string
};

SelectRateType.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search..."
};

export default SelectRateType;
