import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadRatingsService from "services/common/loadRatingsService";

function SelectRateType({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  entity_id,
  readOnly
}) {
  React.useEffect(() => {
    if (!entity_id) {
      onSelect(null);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity_id]);
  return (
    <AsyncAutoComplete
      key={entity_id}
      searchable={false}
      params={{ entity_id }}
      promiseFn={loadRatingsService}
      onSelect={onSelect}
      textPath="rating"
      idPath="rating"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      shouldFetchDefaultOptions
      readOnly={readOnly}
    />
  );
}

SelectRateType.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string
};

SelectRateType.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search..."
};

export default SelectRateType;
