import React from "react";
import PropTypes from "prop-types";
import { Row, Box } from "jsxstyle";
import history from "browserHistory";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import orderBy from "lodash/orderBy";
import { TableWithFixedHeader } from "components/Table";
import Separator from "components/Separator";
import EditButton from "components/EditButton";
import PermissionValidator from "components/PermissionValidator";
import columns from "./HYManagerTableColumns";
import HYFundSubTable from "./HYFundSubTable";
import DeleteHYManager from "./DeleteHYManager";

function HYManagerTable({ data, pending, onSortChange, onReload, sortable }) {
  const managerColumns = JSON.parse(localStorage.getItem("hy/managerColumns"));

  const visibleColumns = managerColumns
    ? managerColumns.map((column) =>
        columns.find((tableColumn) => tableColumn.title === column),
      )
    : columns;

  return (
    <React.Fragment>
      <TableWithFixedHeader
        pending={pending}
        rowKey="manager_id.entity_id"
        scroll={{
          x:
            visibleColumns.reduce((acc, column) => acc + column.width, 0) + 210,
        }}
        expandedRowRender={({ manager_id: { entity_id } }) => (
          <Box>
            <HYFundSubTable managerId={entity_id} />
          </Box>
        )}
        columns={[
          {
            title: "Manager ID",
            dataIndex: "manager_id.entity_id",
            key: "manager_id.entity_id",
            width: 120,
            sortable: true,
          },
          ...visibleColumns.map((column) => ({
            ...column,
            sortable: true,
          })),
          {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            fixed: "right",
            width: 90,
            render: (item) => (
              <Row>
                <EditButton
                  onClick={() =>
                    history.push(
                      `/hy/manager/edit/${item.manager_id.entity_id}`,
                    )
                  }
                />
                <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
                  <Separator size="xs" />
                  <DeleteHYManager manager={item} onDelete={onReload} />
                </PermissionValidator>
              </Row>
            ),
          },
        ]}
        data={orderBy(data, "manager_id", "desc")}
        onSortChange={onSortChange}
      />
    </React.Fragment>
  );
}

HYManagerTable.propTypes = {
  attribute: PropTypes.bool,
};

HYManagerTable.defaultProps = {
  sortable: true,
};

export default HYManagerTable;
