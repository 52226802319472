import React from "react";
import PropTypes from "prop-types";
import { Row, Box } from "jsxstyle";
import orderBy from "lodash/orderBy";
import Table from "components/Table";
import Button from "components/Button";
import CLORatingsFormModal from "./CLORatingsFormModal";
import DeleteCLOTranchePrice from "./DeleteCLOTranchePrice";
import Separator from "components/Separator";
import CLORatingsTable from "./CLORatingsTable";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import CurrencyFormat from "components/CurrencyFormat";
import PercentFormat from "components/PercentFormat";

function CLOTranchePricesTable({
  data,
  pending,
  onReload,
  dealCode,
  onRequestEdit,
}) {
  const [editingTrancheId, setEditingTrancheId] = React.useState(null);
  const [reloadKey, setReloadKey] = React.useState(0);

  return (
    <React.Fragment>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Table
            data={orderBy(
              data,
              ["tranche_class", (tranche) => tranche.subord_percent || ""],
              ["asc", "desc"],
            )}
            pending={pending}
            bordered={true}
            rowKey="id"
            expandedRowRender={(item) => (
              <Box maxWidth={640}>
                <CLORatingsTable
                  key={reloadKey}
                  dealCode={dealCode}
                  trancheId={item.id}
                />
              </Box>
            )}
            scroll={{
              x:
                tranchePricesTableColumns.reduce(
                  (acc, column) => acc + column.width,
                  0,
                ) + 240,
            }}
            columns={[
              ...(isAllowed
                ? [
                    {
                      title: "Actions",
                      dataIndex: "",
                      key: "",
                      width: 240,
                      render: (item) => (
                        <Row alignItems="center">
                          <Button
                            icon="edit"
                            size="sm"
                            onClick={() => onRequestEdit(item)}
                          />
                          <Separator />
                          <DeleteCLOTranchePrice
                            tranchePrice={item}
                            onDelete={onReload}
                          />
                          <Separator />
                          <Box width={130}>
                            <Button
                              text="Manage ratings"
                              intent="success"
                              size="sm"
                              onClick={() => setEditingTrancheId(item.id)}
                            />
                          </Box>
                        </Row>
                      ),
                    },
                  ]
                : []),
                ...tranchePricesTableColumns,
            ]}
          />
        )}
      </PermissionValidator>
      <CLORatingsFormModal
        dealCode={dealCode}
        trancheId={editingTrancheId}
        onRequestClose={() => setEditingTrancheId(null)}
        onData={() => {
          setReloadKey(reloadKey + 1);
          setEditingTrancheId(null);
        }}
      />
    </React.Fragment>
  );
}

CLOTranchePricesTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onReload: PropTypes.func,
};

CLOTranchePricesTable.defaultProps = {
  data: [],
  pending: false,
  onReload: () => {},
};

export default CLOTranchePricesTable;

const tranchePricesTableColumns = [
  {
    title: "Deal code",
    key: "deal_code",
    dataIndex: "deal_code",
    width: 120,
  },
  {
    title: "Tranche class",
    key: "tranche_class",
    dataIndex: "tranche_class",
    width: 150,
  },
  {
    title: "Class amount",
    key: "class_amount",
    dataIndex: "class_amount",
    width: 150,
    render: (value) => <CurrencyFormat amount={value || 0} />,
  },
  {
    title: "Notional amount",
    key: "notional_amount",
    dataIndex: "notional_amount",
    width: 150,
    render: (value) => <CurrencyFormat amount={value || 0} />,
  },
  {
    title: "Denomination",
    key: "denomination",
    dataIndex: "denomination",
    width: 240,
  },
  {
    title: "Denomination amount",
    key: "denomination_amount",
    dataIndex: "denomination_amount",
    width: 240,
    render: (value) => <CurrencyFormat amount={value || 0} />,
  },
  {
    title: "Subord percent",
    key: "subord_percent",
    dataIndex: "subord_percent",
    width: 120,
    render: (value) => <PercentFormat amount={value || 0} />,
  },
  {
    title: "Coupon",
    key: "coupon",
    dataIndex: "coupon",
    width: 120,
    render: (value) => <PercentFormat amount={value || 0} />,
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    width: 150,
    render: (value) => <CurrencyFormat amount={value || 0} />,
  },
  {
    title: "Yield",
    key: "yield",
    dataIndex: "yield",
    width: 120,
    render: (value) => <PercentFormat amount={value || 0} />,
  },
  {
    title: "Maturity Date",
    key: "maturity_date",
    dataIndex: "maturity_date",
    width: 150,
  },
  {
    title: "Weighted-average life",
    key: "wal",
    dataIndex: "wal",
    width: 240,
  },
  {
    title: "Spread",
    key: "spread",
    dataIndex: "spread",
    width: 240,
  },
  {
    title: "Benchmark",
    key: "benchmark",
    dataIndex: "benchmark",
    width: 240,
  },
  {
    title: "Rate type",
    key: "rate",
    dataIndex: "rate",
    width: 150,
  },
];
