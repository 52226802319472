import useAsync from 'hooks/useAsync';
import useUrlParams from 'hooks/useUrlParams';

export default ({ promiseFn, eager = true, initialParams = {}, onError }) => {
  const [params, setParams] = useUrlParams({
    page: 1,
    size: 20,
    ...initialParams,
  });

  const [fetch, pending, data = [], error] = useAsync({
    promiseFn,
    params,
    eager,
    onError,
  });

  return { fetch, pending, data, error, params, setParams };
};
