import fetch from "../fetch";
import { pickBy } from "lodash";

export default ({ city, state, page, size } = {}) =>
  fetch({
    path: "/re/markets",
    params: pickBy({
      city,
      state_code: state,
      page,
      size,
    }),
  });
