import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import InputGroup from "../../components/InputGroup";
import Form from "components/Form";
import SelectABSDealType from "components/SelectABSDealType";
import SelectCollateralCode from "components/SelectCollateralCode";
import SelectABSOfferingType from "components/SelectABSOfferingType";
import SelectCountryCode from "components/SelectCountryCode";
import SelectRegionCode from "components/SelectRegionCode";
import SelectEntityMaster from "components/SelectEntityMaster";
import Separator from "components/Separator";
import Button from "components/Button";
import FormGroup from "components/FormGroup";
import AdvancedSearch from "components/AdvancedSearch";
import DateRange from "components/DateRange";
import CurrentFiltersList from "components/CurrentFiltersList";
import { Row, Box } from "jsxstyle";
import Switch from "components/Switch";
import moment from "moment";

function ABSFilters({ onChange, currentFilters }) {
  const [isAdvancedFiltersOpen, setAdvancedFiltersOpen] = React.useState(false);
  const [dealCodeValue, setDealCodeValue] = React.useState(
    currentFilters.deal_code
  );
  const advancedSearchFilters = omit(currentFilters, ["deal_code"]);
  React.useEffect(() => {
    if (!currentFilters.deal_code) {
      setDealCodeValue("");
    }
  }, [currentFilters.deal_code]);

  return (
    <>
      <Row alignItems="flex-start">
        <Form
          onSubmit={() => {
            onChange({ deal_code: dealCodeValue });
          }}
        >
          <Row width={350} alignItems="flex-start">
            <Box flex={1}>
              <FormGroup>
                <InputGroup
                  leftIcon="search"
                  placeholder="Search by deal code"
                  value={dealCodeValue}
                  onChange={value => setDealCodeValue(value)}
                  fullWidth
                />
              </FormGroup>
            </Box>
            <Separator />
            <Button
              intent="primary"
              icon="search"
              text="Search"
              type="submit"
            />
          </Row>
        </Form>
        <Separator />
        <Row alignItems="center">
          <AdvancedSearch
            currentFilters={advancedSearchFilters}
            isOpen={isAdvancedFiltersOpen}
            onRequestOpen={() => setAdvancedFiltersOpen(true)}
            onRequestClose={() => setAdvancedFiltersOpen(false)}
            onSubmit={values =>
              onChange({
                ...values,
                pricing_date:
                  values.pricing_date &&
                  values.pricing_date.map(date =>
                    moment(date).format("YYYY-MM-DD")
                  )
              })
            }
          >
            {({ getValue, setValue }) => (
              <Row flexWrap="wrap" marginTop={-15} marginLeft={-15}>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Deal abbreviation">
                    <InputGroup
                      value={getValue("abbrv")}
                      onChange={value => setValue("abbrv", value)}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Issuer">
                    <InputGroup
                      value={getValue("issuer")}
                      onChange={value => setValue("issuer", value)}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Series">
                    <InputGroup
                      value={getValue("series")}
                      onChange={value => setValue("series", value)}
                      maxLength={50}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Deal type">
                    <SelectABSDealType
                      onSelect={value => setValue("deal_type", value)}
                      value={getValue("deal_type")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Collateral type">
                    <SelectCollateralCode
                      onSelect={value => setValue("collateral", value)}
                      value={getValue("collateral")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Secondary collateral type">
                    <SelectCollateralCode
                      onSelect={value => setValue("collateral_sub", value)}
                      value={getValue("collateral_sub")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Offering type">
                    <SelectABSOfferingType
                      onSelect={value => setValue("offering_type", value)}
                      value={getValue("offering_type")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Country of collateral">
                    <SelectCountryCode
                      onSelect={value => {
                        setValue("country_code", value);
                      }}
                      value={getValue("country_code")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Region of collateral">
                    <SelectRegionCode
                      onSelect={value => setValue("region_code", value)}
                      value={getValue("region_code")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Underwriter">
                    <SelectEntityMaster
                      onSelect={value =>
                        setValue("underwriter_entity_id", value)
                      }
                      value={getValue("underwriter_entity_id")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Bookrunners only">
                    <Switch
                      value={getValue("only_book_runners_underwriter")}
                      onChange={value =>
                        setValue(
                          "only_book_runners_underwriter",
                          !getValue("only_book_runners_underwriter")
                        )
                      }
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Rating Agency">
                    <SelectEntityMaster
                      onSelect={value => setValue("rating_entity_id", value)}
                      value={getValue("rating_entity_id")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Seller">
                    <SelectEntityMaster
                      onSelect={value => setValue("seller", value)}
                      value={getValue("seller")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Sponsor">
                    <SelectEntityMaster
                      onSelect={value => setValue("sponsor", value)}
                      value={getValue("sponsor")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Trustee">
                    <SelectEntityMaster
                      onSelect={value => setValue("trustee", value)}
                      value={getValue("trustee")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 0 300px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Legal counsel">
                    <SelectEntityMaster
                      onSelect={value => setValue("counsel_entity_id", value)}
                      value={getValue("counsel_entity_id")}
                    />
                  </FormGroup>
                </Box>
                <Box flex="1 1 460px" marginTop={15} marginLeft={15}>
                  <FormGroup label="Pricing date">
                    <DateRange
                      onChange={value => setValue("pricing_date", value)}
                      value={getValue("pricing_date")}
                    />
                  </FormGroup>
                </Box>
              </Row>
            )}
          </AdvancedSearch>
          <Separator />
          <Button
            icon="remove"
            minimal
            onClick={() => onChange({})}
            text="Clear filters"
          />
        </Row>
      </Row>
      <CurrentFiltersList
        filters={currentFilters}
        onRemove={key => {
          onChange({
            ...currentFilters,
            [key]: null
          });
        }}
      />
    </>
  );
}

ABSFilters.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default ABSFilters;
