import React from "react";
import {
  Navbar as BluePrintNavbar,
  Alignment,
  Tab,
  Tabs
} from "@blueprintjs/core";
import { useRegionContext } from 'contexts/RegionContext';
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import SalePortfolioForm from "./SalePortfolioForm";
import SalePortfolioProperties from "./SalePortfolioProperties";
import useAsync from "hooks/useAsync";
import getRESaleById from "services/re/getRESaleById";
import ScreenLoading from "components/ScreenLoading";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function EditSalePortfolioScreen({ match }) {
  const { regionName } = useRegionContext();
  const [currentTab, setCurrentTab] = React.useState(0);
  const { saleId } = match.params;

  const [fetch, pending, data] = useAsync({
    promiseFn: getRESaleById
  });

  React.useEffect(() => {
    fetch({ saleId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleId]);

  return (
    <PrivateLayout>
      <PermissionValidator
        allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
        shouldHide={false}
      >
        {({ isAllowed }) => (
          <Heading size="md">{`${
            isAllowed ? "Edit" : "View"
          } ${regionName} Portfolio Sale - ${saleId}`}</Heading>
        )}
      </PermissionValidator>
      <Separator />
      {pending ? (
        <ScreenLoading />
      ) : (
        <React.Fragment>
          <BluePrintNavbar
            style={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
          >
            <BluePrintNavbar.Group align={Alignment.LEFT}>
              <Tabs
                large={true}
                onChange={nextTabId => setCurrentTab(nextTabId)}
                selectedTabId={currentTab}
              >
                <Tab id={0} title="Sale" />
                <Tab id={1} title="Properties" />
              </Tabs>
            </BluePrintNavbar.Group>
          </BluePrintNavbar>
          {
            {
              0: <SalePortfolioForm initialValues={data} isNew={false} />,
              1: <SalePortfolioProperties saleId={saleId} />
            }[currentTab]
          }
        </React.Fragment>
      )}
    </PrivateLayout>
  );
}

export default EditSalePortfolioScreen;
