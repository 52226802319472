import React from "react";
import PropTypes from "prop-types";
import { Box } from "jsxstyle";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import SelectABSDealType from "components/SelectABSDealType";
import SelectABSOfferingType from "components/SelectABSOfferingType";
import SelectEntityMaster from "components/SelectEntityMaster";
import DateInputGroup from "components/DateInputGroup";
import InputGroup from "components/InputGroup";
import DecimalInputGroup from "components/DecimalInputGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import SelectCurrencyCode from "components/SelectCurrencyCode";
import SelectCountryCode from "components/SelectCountryCode";
import SelectRegionCode from "components/SelectRegionCode";
import SelectCollateralCode from "components/SelectCollateralCode";
import Select from "components/Select";
import Switch from "components/Switch";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import SelectCreatableAbbrv from "components/SelectCreatableAbbrv";
import SelectCreatableIssuer from "components/SelectCreatableIssuer";

function ABSFields({ getValue, setValue, getError, isSubmitting, isNew }) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <FormGrid>
          <FormGrid.Item>
            <FormGroup
              label="Deal code"
              required
              disabled={isSubmitting || !isNew}
              error={getError("deal_code")}
            >
              <IntegerInputGroup
                minLength={8}
                maxLength={8}
                intent={getError("deal_code") ? "danger" : null}
                value={getValue("deal_code")}
                onChange={(value) => setValue("deal_code", value)}
                buttonPosition="none"
                disabled={isSubmitting || !isNew}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Offering type"
              required
              disabled={isSubmitting}
              error={getError("offering_type")}
            >
              <SelectABSOfferingType
                onSelect={(value) => setValue("offering_type", value)}
                value={getValue("offering_type")}
                error={getError("offering_type")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Deal type"
              required
              disabled={isSubmitting}
              error={getError("deal_type")}
            >
              <SelectABSDealType
                onSelect={(value) => setValue("deal_type", value)}
                value={getValue("deal_type")}
                error={getError("deal_type")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Pricing date *"
              disabled={isSubmitting}
              error={getError("pricing_date")}
            >
              <DateInputGroup
                intent={getError("pricing_date") ? "danger" : null}
                value={getValue("pricing_date")}
                onChange={(value) => setValue("pricing_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Closing date"
              disabled={isSubmitting}
              error={getError("closing_date")}
            >
              <DateInputGroup
                intent={getError("closing_date") ? "danger" : null}
                value={getValue("closing_date")}
                onChange={(value) => setValue("closing_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Abbreviation"
              disabled={isSubmitting}
              error={getError("abbrv")}
            >
              <SelectCreatableAbbrv
                variant="ABS"
                disabled={isSubmitting}
                error={getError("abbrv")}
                value={getValue("abbrv")}
                onCreate={(abbrv) => setValue("abbrv", abbrv)}
                onSelect={(value) => setValue("abbrv", value)}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Issuer *"
              disabled={isSubmitting}
              error={getError("issuer")}
            >
              <SelectCreatableIssuer
                variant="ABS"
                readOnly={!isAllowed}
                disabled={isSubmitting}
                error={getError("issuer")}
                value={getValue("issuer")}
                onCreate={(issuer) => setValue("issuer", issuer)}
                onSelect={(value) => setValue("issuer", value)}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Series"
              disabled={isSubmitting}
              error={getError("series")}
            >
              <InputGroup
                intent={getError("series") ? "danger" : null}
                value={getValue("series")}
                onChange={(value) => setValue("series", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                maxLength={50}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Deal amount *"
              disabled={isSubmitting}
              error={getError("deal_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("deal_amount") ? "danger" : null}
                value={getValue("deal_amount")}
                onChange={(value) => setValue("deal_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Retained amount"
              disabled={isSubmitting}
              error={getError("retained_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("retained_amount") ? "danger" : null}
                value={getValue("retained_amount")}
                onChange={(value) => setValue("retained_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <Box className="col-2">
            <FormGroup
              label="Upsized"
              disabled={isSubmitting}
              error={getError("upsized")}
            >
              <Switch
                value={getValue("upsized")}
                onChange={() => setValue("upsized", !getValue("upsized"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
          <Box className="col-2">
            <FormGroup
              label="Club deal"
              disabled={isSubmitting}
              error={getError("club")}
            >
              <Switch
                value={getValue("club")}
                onChange={() => setValue("club", !getValue("club"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
          <Box className="col-2">
            <FormGroup
              label="Government facility"
              disabled={isSubmitting}
              error={getError("govt_facility")}
            >
              <Switch
                value={getValue("govt_facility")}
                onChange={() =>
                  setValue("govt_facility", !getValue("govt_facility"))
                }
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
          <FormGrid.Item>
            <FormGroup
              label="Primary collateral type"
              disabled={isSubmitting}
              error={getError("collateral")}
            >
              <SelectCollateralCode
                onSelect={(value) => setValue("collateral", value)}
                value={getValue("collateral")}
                error={getError("collateral")}
                disabled={isSubmitting}
                params={{ is_col: true }}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Secondary collateral type"
              disabled={isSubmitting}
              error={getError("collateral_sub")}
            >
              <SelectCollateralCode
                onSelect={(value) => setValue("collateral_sub", value)}
                value={getValue("collateral_sub")}
                error={getError("collateral_sub")}
                disabled={isSubmitting}
                params={{ is_col2: true }}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Seasoned / resecuritization"
              disabled={isSubmitting}
              error={getError("collateral_sub2")}
            >
              <SelectCollateralCode
                onSelect={(value) => setValue("collateral_sub2", value)}
                value={getValue("collateral_sub2")}
                error={getError("collateral_sub2")}
                disabled={isSubmitting}
                params={{ is_col3: true }}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Seller"
              disabled={isSubmitting}
              error={getError("seller")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("seller", value)}
                value={getValue("seller")}
                error={getError("seller")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Sponsor"
              disabled={isSubmitting}
              error={getError("sponsor")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("sponsor", value)}
                value={getValue("sponsor")}
                error={getError("sponsor")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Master servicer"
              disabled={isSubmitting}
              error={getError("master_servicer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("master_servicer", value)}
                value={getValue("master_servicer")}
                error={getError("master_servicer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Primary servicer"
              disabled={isSubmitting}
              error={getError("primary_servicer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("primary_servicer", value)}
                value={getValue("primary_servicer")}
                error={getError("primary_servicer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Special servicer"
              disabled={isSubmitting}
              error={getError("special_servicer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("special_servicer", value)}
                value={getValue("special_servicer")}
                error={getError("special_servicer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Enhancement provider"
              disabled={isSubmitting}
              error={getError("enhancement")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("enhancement", value)}
                value={getValue("enhancement")}
                error={getError("enhancement")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Trustee"
              disabled={isSubmitting}
              error={getError("trustee")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("trustee", value)}
                value={getValue("trustee")}
                error={getError("trustee")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Underwriter counsel"
              disabled={isSubmitting}
              error={getError("counsel_uw")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("counsel_uw", value)}
                value={getValue("counsel_uw")}
                error={getError("counsel_uw")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Issuer counsel"
              disabled={isSubmitting}
              error={getError("counsel_issuer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("counsel_issuer", value)}
                value={getValue("counsel_issuer")}
                error={getError("counsel_issuer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination"
              disabled={isSubmitting}
              error={getError("denomination")}
            >
              <SelectCurrencyCode
                onSelect={(value) => setValue("denomination", value)}
                value={getValue("denomination")}
                error={getError("denomination")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination amount"
              disabled={isSubmitting}
              error={getError("denomination_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("denomination_amount") ? "danger" : null}
                value={getValue("denomination_amount")}
                onChange={(value) => setValue("denomination_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination retained"
              disabled={isSubmitting}
              error={getError("denomination_retained")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("denomination_retained") ? "danger" : null}
                value={getValue("denomination_retained")}
                onChange={(value) => setValue("denomination_retained", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Synthetic pool"
              disabled={isSubmitting}
              error={getError("synthetic_pool")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("synthetic_pool") ? "danger" : null}
                value={getValue("synthetic_pool")}
                onChange={(value) => setValue("synthetic_pool", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Pay frequency"
              disabled={isSubmitting}
              error={getError("pay_frequency")}
            >
              <Select
                items={[
                  {
                    id: "M",
                    label: "Monthly",
                  },
                  {
                    id: "Q",
                    label: "Quarterly",
                  },
                  {
                    id: "S",
                    label: "Semi-annual",
                  },
                  {
                    id: "A",
                    label: "Annual",
                  },
                ]}
                onSelect={(value) => setValue("pay_frequency", value)}
                value={getValue("pay_frequency")}
                error={getError("pay_frequency")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Number of loans"
              disabled={isSubmitting}
              error={getError("num_of_loans")}
            >
              <IntegerInputGroup
                intent={getError("num_of_loans") ? "danger" : null}
                value={getValue("num_of_loans")}
                onChange={(value) => setValue("num_of_loans", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                maxLength={16}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Average loan amount"
              disabled={isSubmitting}
              error={getError("avg_loan_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("avg_loan_amount") ? "danger" : null}
                value={getValue("avg_loan_amount")}
                onChange={(value) => setValue("avg_loan_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="FICO average"
              disabled={isSubmitting}
              error={getError("fico_avg")}
            >
              <DecimalInputGroup
                intent={getError("fico_avg") ? "danger" : null}
                value={getValue("fico_avg")}
                onChange={(value) => setValue("fico_avg", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Country of collateral"
              disabled={isSubmitting}
              error={getError("country_code")}
            >
              <SelectCountryCode
                onSelect={(value) => {
                  setValue("country_code", value);
                }}
                value={getValue("country_code")}
                disabled={isSubmitting}
                error={getError("country_code")}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Region of collateral"
              disabled={isSubmitting}
              error={getError("region_code")}
            >
              <SelectRegionCode
                onSelect={(value) => setValue("region_code", value)}
                value={getValue("region_code")}
                error={getError("region_code")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Distribution region"
              disabled={isSubmitting}
              error={getError("distribution_region")}
            >
              <SelectRegionCode
                onSelect={(value) => setValue("distribution_region", value)}
                value={getValue("distribution_region")}
                error={getError("distribution_region")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Overcollateralization"
              disabled={isSubmitting}
              error={getError("overcol_percent")}
            >
              <CurrencyInputGroup
                leftIcon="percentage"
                intent={getError("overcol_percent") ? "danger" : null}
                value={getValue("overcol_percent")}
                onChange={(value) => setValue("overcol_percent", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Reserve amount"
              disabled={isSubmitting}
              error={getError("reserve_amount")}
            >
              <CurrencyInputGroup
                leftIcon="percentage"
                intent={getError("reserve_amount") ? "danger" : null}
                value={getValue("reserve_amount")}
                onChange={(value) => setValue("reserve_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Excess spread percent"
              disabled={isSubmitting}
              error={getError("excess_spread_percent")}
            >
              <CurrencyInputGroup
                leftIcon="percentage"
                intent={getError("excess_spread_percent") ? "danger" : null}
                value={getValue("excess_spread_percent")}
                onChange={(value) => setValue("excess_spread_percent", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Risk retention"
              disabled={isSubmitting}
              error={getError("risk_retention")}
            >
              <Select
                items={[
                  {
                    id: "H",
                    label: " Horizontal",
                  },
                  {
                    id: "V",
                    label: "Vertical",
                  },
                  {
                    id: "L",
                    label: "L-shaped",
                  },
                ]}
                onSelect={(value) => setValue("risk_retention", value)}
                value={getValue("risk_retention")}
                error={getError("risk_retention")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Asset reviewer"
              disabled={isSubmitting}
              error={getError("asset_reviewer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("asset_reviewer", value)}
                value={getValue("asset_reviewer")}
                error={getError("asset_reviewer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Self issuance"
              disabled={isSubmitting}
              error={getError("self_issuance")}
            >
              <InputGroup
                intent={getError("self_issuance") ? "danger" : null}
                value={getValue("self_issuance")}
                onChange={(value) => setValue("self_issuance", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                maxLength={10}
              />
            </FormGroup>
          </FormGrid.Item>
        </FormGrid>
      )}
    </PermissionValidator>
  );
}

ABSFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isNew: PropTypes.bool,
};

ABSFields.defaultProps = {
  isSubmitting: false,
  isNew: true,
};

export default ABSFields;
