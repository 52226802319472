import React from "react";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "components/Separator";
import PrivateLayout from "components/PrivateLayout";
import NewHYFundStepper from "./NewHYFundStepper";
import HYFundBasicInfoTab from "./basic-info/HYFundBasicInfoTab";
import HYFundFeeStructureTab from "./fee-structure/HYFundFeeStructureTab";
import HYFundTargetAssetsTab from "./target-assets/HYFundTargetAssetsTab";
import HYFundTargetRegionsTab from "./target-regions/HYFundTargetRegionsTab";
import HYFundPlacementAgentTab from "./placement-agent/HYFundPlacementAgentTab";
import HYFundManagementSponsorsTab from "./management-sponsors/HYFundManagementSponsorsTab";

function NewHYFundLayout({ fund, onFundChange }) {
  return (
    <PrivateLayout>
      <Heading size="md">New HY Fund</Heading>
      <Separator />
      <NewHYFundStepper>
        <Card>
          <HYFundBasicInfoTab onSubmit={onFundChange} initialValues={fund} />
        </Card>
        <Card>
          <HYFundTargetAssetsTab onSubmit={onFundChange} initialValues={fund} />
        </Card>
        <Card>
          <HYFundTargetRegionsTab
            onSubmit={onFundChange}
            initialValues={fund}
          />
        </Card>
        <Card>
          <HYFundManagementSponsorsTab
            onSubmit={onFundChange}
            initialValues={fund}
          />
        </Card>
        <Card>
          <HYFundFeeStructureTab />
        </Card>
        <Card>
          <HYFundPlacementAgentTab />
        </Card>
      </NewHYFundStepper>
    </PrivateLayout>
  );
}

export default NewHYFundLayout;
