import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

function SelectABSOfferingType({ onSelect, value, error, disabled, readOnly }) {
  return (
    <Select
      items={[
        {
          id: "A",
          label: "Rule 144A"
        },
        {
          id: "I",
          label: "Outside U.S"
        },
        {
          id: "P",
          label: "Private placement"
        },
        {
          id: "S",
          label: "SEC-registered"
        }
      ]}
      onSelect={onSelect}
      value={value}
      error={error}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

SelectABSOfferingType.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default SelectABSOfferingType;
