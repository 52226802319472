import React from "react";
import { Row } from "jsxstyle";
import PropTypes from "prop-types";
import history from "browserHistory";
import useUrlParams from "hooks/useUrlParams";
import { TableWithFixedHeader } from "components/Table";
import EditButton from "components/EditButton";
import columns from "./ContactTableColumns";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Separator from "components/Separator";
import DeleteContact from "./DeleteContact";

function ContactTable({ data, pending, onSortChange, onReload, sortable }) {
  const [params] = useUrlParams({
    order_by: "first_name",
    order_direction: "ascend",
  });
  const { order_by, order_direction } = params;
  const contactTableColumns = JSON.parse(
    localStorage.getItem("contactColumns"),
  );
  const visibleColumns = contactTableColumns
    ? contactTableColumns.map((column) =>
        columns.find((tableColumn) => tableColumn.title === column),
      )
    : columns;

  return (
    <React.Fragment>
      <TableWithFixedHeader
        pending={pending}
        rowKey="contact_id"
        scroll={{
          x:
            visibleColumns.reduce((acc, column) => acc + column.width, 0) + 210,
        }}
        columns={[
          {
            title: "ID",
            dataIndex: "contact_id",
            key: "contact_ID",
            width: 120,
            fixed: "left",
            sortable: sortable !== false,
            sortOrder:
              order_by === "contact_id" &&
              {
                ASC: "ascend",
                DESC: "descend",
              }[order_direction],
          },
          ...visibleColumns.map((column) => ({
            ...column,
            sortable: sortable !== false,
          })),
          {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            fixed: "right",
            width: 90,
            render: (item) => (
              <Row>
                <EditButton
                  onClick={() =>
                    history.push(`/contacts/edit/${item.contact_id}`)
                  }
                />
                <PermissionValidator
                  allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
                  shouldHide
                >
                  <Separator size="xs" />
                  <DeleteContact contact={item} onDelete={onReload} />
                </PermissionValidator>
              </Row>
            ),
          },
        ]}
        data={data}
        onSortChange={onSortChange}
      />
    </React.Fragment>
  );
}

ContactTable.propTypes = {
  sortable: PropTypes.bool,
};

ContactTable.defaultProps = {
  sortable: true,
};

export default ContactTable;
