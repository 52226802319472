import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "components/AsyncAutoComplete";
import loadCLODealTypesService from "services/clo/loadCLODealTypesService";

function SelectCMBSDealType({
  onSelect,
  disabled,
  error,
  placeholder,
  value,
  readOnly
}) {
  return (
    <AsyncAutoComplete
      promiseFn={loadCLODealTypesService}
      onSelect={onSelect}
      textPath="deal_type_desc"
      labelPath="deal_code"
      idPath="deal_code"
      disabled={disabled}
      error={error}
      intent={!!error ? "danger" : null}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
    />
  );
}

SelectCMBSDealType.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.object,
  placeholder: PropTypes.string
};

SelectCMBSDealType.defaultProps = {
  value: undefined,
  disabled: false,
  error: null,
  initialValue: {},
  placeholder: "Search..."
};

export default SelectCMBSDealType;
