import React from "react";
import { Row } from "jsxstyle";
import PropTypes from "prop-types";
import Form from "components/Form";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import ABSFields from "./ABSFields";
import Separator from "components/Separator";
import Button from "components/Button";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import saveABSDealService from "services/abs/saveABSDealService";
import useForm from "hooks/useForm";
import isRequired from "validators/isRequired";

function ABSEditDealTab({ initialValues, onSubmit }) {
  const { showError, showSuccess } = useToast();

  const [fetch, pending] = useAsync({
    promiseFn: saveABSDealService,
    onData: (deal) => {
      showSuccess("ABS deal saved.");
      onSubmit(deal);
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues,
    onSubmit: (values) => fetch({ values, dealCode: values.deal_code }),
    validations: {
      offering_type: [isRequired],
      deal_type: [isRequired],
      pricing_date: [isRequired],
      issuer: [isRequired],
      deal_amount: [isRequired],
    },
  });

  return (
    <Form onSubmit={() => submit()}>
      <TwoColumnFormLayout size="sm" title="General Information">
        <ABSFields
          getValue={getValue}
          setValue={setValue}
          getError={getError}
          isSubmitting={pending}
          isNew={false}
        />
      </TwoColumnFormLayout>
      <Separator size="xl" />
      <Row justifyContent="flex-end">
        <Button type="submit" text="Save" intent="primary" disabled={pending} />
      </Row>
    </Form>
  );
}

ABSEditDealTab.propTypes = {
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

ABSEditDealTab.defaultProps = {
  initialValues: {},
  isSubmitting: false,
};

export default ABSEditDealTab;
