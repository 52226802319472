import React from "react";
import useAsync from "hooks/useAsync";
import Button from "./Button";
import signOutService from "services/authentication/signOutService";

function SignOut() {
  const [fetch, pending] = useAsync({
    promiseFn: signOutService,
    onData: () => {
      localStorage.removeItem("role");
      sessionStorage.removeItem("role");
      window.location.reload();
    }
  });
  return <Button icon="log-out" onClick={fetch} loading={pending} />;
}

export default SignOut;
