import React from "react";
import ReactSelect from "react-select";
import PropTypes from "prop-types";

function Select({
  closeMenuOnSelect,
  idPath,
  isMulti,
  items,
  onSelect,
  error,
  placeholder,
  value,
  disabled,
  readOnly,
}) {
  const [selectedValue, setSelectedValue] = React.useState(null);

  React.useEffect(() => {
    const getSelectedItem = value => {
      const selectedItem = items.find(item => item.id === value);
      return { label: selectedItem.label, value: selectedItem.id };
    };
    if (!selectedValue && items.length) {
      if (value) {
        setSelectedValue(isMulti
          ? value.map(id => getSelectedItem(id))
          : getSelectedItem(value)
        );
      } else {
        setSelectedValue(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, items]);

  return (
    <ReactSelect
      styles={getStyle(error)}
      closeMenuOnSelect={closeMenuOnSelect}
      isDisabled={disabled || readOnly}
      isClearable
      isMulti={isMulti}
      isSearchable
      value={selectedValue}
      onChange={(selected) => {
        setSelectedValue(selected);

        let value = null;
        if (selected) {
          value = isMulti ? selected.map(s => s.value) : selected.value;
        }
        onSelect(value);
      }}
      name="color"
      placeholder={placeholder}
      options={items.map((item) => ({ label: item.label, value: item.id }))}
      menuPortalTarget={document.getElementById("dropdown")}
    />
  );
}

Select.propTypes = {
  closeMenuOnSelect: PropTypes.bool,
  isMulti: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialQuery: PropTypes.string,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  closeMenuOnSelect: true,
  isMulti: false,
  labelPath: undefined,
  error: null,
  initialQuery: null,
  disabled: false,
};

const getStyle = (error) => ({
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : null,
    background: state.isSelected ? "#137cbd" : null,
  }),
  control: (provided, state) => ({
    borderRadius: 3,
    background: state.isDisabled ? "#ced9e080" : "#ffffff",
    // height: 30,
    padding: "0 10px",
    verticalAlign: "middle",
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
    boxShadow: error
      ? "0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)"
      : state.isFocused
      ? "0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2)"
      : "0 0 0 0 rgba(19, 124, 189, 0),0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)",
  }),
  singleValue: ({ transform, top, ...provided }) => ({
    ...provided,
  }),
  indicatorSeparator: () => ({}),
  // indicatorsContainer: provided => ({ ...provided, padding: 0 }),
  menu: (provided) => ({ ...provided, zIndex: 100 }),
});

export default Select;
