import React from "react";
import PropTypes from "prop-types";
// import { Steps } from "antd";
import Steps from "antd/es/steps";
import "antd/es/steps/style/css";
import Separator from "./Separator";
import "./Stepper.css";
const Step = Steps.Step;

function Stepper({ children, steps, currentStep, navigationWidth }) {
  return (
    <React.Fragment>
      <Steps
        style={{
          maxWidth: navigationWidth,
          margin: navigationWidth ? "0 auto" : 0,
        }}
        current={currentStep}
      >
        {steps.map((step, index) => (
          <Step key={index} title={step} />
        ))}
      </Steps>
      <Separator />
      {children[currentStep]}
    </React.Fragment>
  );
}

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
  navigationWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Stepper.defaultProps = {
  navigationWidth: "auto",
};

export default Stepper;
