import React from "react";
import { useRegionContext } from 'contexts/RegionContext';
import PrivateLayout from "components/PrivateLayout";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "components/Separator";
import REPropForm from "./REPropForm";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";

function REPropsFormScreen() {
  const { regionName } = useRegionContext();
  return (
    <PrivateLayout>
      <Heading size="md">{`New ${regionName} Property`}</Heading>
      <Separator />
      <Card>
        <TwoColumnFormLayout size="sm" title="Property">
          <REPropForm />
        </TwoColumnFormLayout>
      </Card>
    </PrivateLayout>
  );
}

export default REPropsFormScreen;
