import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tag } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import CurrencyFormat from "components/CurrencyFormat";
import loadEntityMasterRoles from "services/entityMaster/loadEntityMasterRoles";
import moment from "moment";

function EntityMasterRERelationshipsTable({ entityId }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    promiseFn: loadEntityMasterRoles,
    onError: () => showError("Error loading the RE Relationships"),
  });

  React.useEffect(() => {
    fetch({
      id: entityId,
      type: "re",
      params: {
        page: 1,
        size: 20,
      },
    });
  }, [fetch, entityId]);

  return (
    <Table
      pending={pending}
      data={data && data.data}
      rowKey="sale_id"
      scroll={{ x: 2720 }}
      columns={[
        {
          title: "Roles",
          dataIndex: "roles",
          key: "roles",
          fixed: "left",
          render: (roles) => (
            <Row>
              {roles.map((role) => (
                <React.Fragment key={role}>
                  <Tag intent="primary" key={role}>
                    {role}
                  </Tag>
                  <Separator size="xs" />
                </React.Fragment>
              ))}
            </Row>
          ),
        },
        {
          title: "Sale ID",
          dataIndex: "sale_id",
          key: "sale_id",
          // fixed: "left",
          width: 120,
        },
        {
          title: "Sale Date",
          dataIndex: "sale_date",
          key: "sale_date",
          width: 120,
          render: (date) => (date ? moment(date).format("MM/DD/YYYY") : null),
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          width: 120,
          render: (amount) => <CurrencyFormat amount={amount || 0} />,
        },
        {
          title: "Price Accuracy",
          dataIndex: "price_accuracy",
          key: "price_accuracy",
          width: 120,
          render: (amount) => <CurrencyFormat amount={amount || 0} />,
        },
        {
          title: "Conv",
          dataIndex: "conv",
          key: "conv",
          width: 120,
        },
        {
          title: "Dev",
          dataIndex: "dev",
          key: "dev",
          width: 120,
        },
        {
          title: "Stake",
          dataIndex: "stake",
          key: "stake",
          width: 120,
        },
        {
          title: "Sf Price",
          dataIndex: "sf_price",
          key: "sf_price",
          width: 120,
          render: (amount) => <CurrencyFormat amount={amount || 0} />,
        },
        {
          title: "Unit Price",
          dataIndex: "unit_price",
          key: "unit_price",
          width: 120,
          render: (amount) => <CurrencyFormat amount={amount || 0} />,
        },
        {
          title: "Broker 1",
          dataIndex: "broker_1.long_name",
          key: "broker_1.long_name",
          width: 120,
        },
        {
          title: "Broker 2",
          dataIndex: "broker_2.long_name",
          key: "broker_2.long_name",
          width: 120,
        },

        {
          title: "Sale Leaseback",
          dataIndex: "sale_leaseback",
          key: "sale_leaseback",
          width: 120,
          render: (sale_leaseback) => (sale_leaseback ? "Yes" : "No"),
        },
        {
          title: "Property Type",
          dataIndex: "portfolio",
          key: "portfolio",
          width: 120,
          render: (portfolio) => {
            return portfolio ? "Portfolio" : "Property";
          },
        },
        {
          title: "Num Props",
          dataIndex: "num_props",
          key: "num_props",
          width: 120,
        },
        {
          title: "Closed",
          dataIndex: "closed",
          key: "closed",
          width: 120,
          render: (closed) => (closed ? "Yes" : "No"),
        },
        {
          title: "Portfolio Type",
          dataIndex: "portfolio_type",
          key: "portfolio_type",
          width: 120,
        },
        {
          title: "Portifolio Code",
          dataIndex: "portfolio_code",
          key: "portfolio_code",
          width: 120,
        },
        {
          title: "Leasehold",
          dataIndex: "leasehold",
          key: "leasehold",
          width: 120,
          render: (leasehold) => (leasehold ? "Yes" : "No"),
        },
        {
          title: "Distressed",
          dataIndex: "distressed",
          key: "distressed",
          width: 120,
          render: (distressed) => (distressed ? "Yes" : "No"),
        },
        {
          title: "Cap Rate",
          dataIndex: "cap_rate",
          key: "cap_rate",
          width: 120,
        },

        {
          title: "Occupancy",
          dataIndex: "occupancy",
          key: "occupancy",
          width: 120,
        },
        {
          title: "Buyer Broker",
          dataIndex: "buyer_broker.long_name",
          key: "buyer_broker.long_name",
          width: 120,
        },
        {
          title: "Vehicle Buyer",
          dataIndex: "vehicle_buyer.long_name",
          key: "vehicle_buyer.long_name",
          width: 120,
        },
        {
          title: "Vehicle Seller",
          dataIndex: "vehicle_seller.long_name",
          key: "vehicle_seller.long_name",
          width: 120,
        },
        {
          title: "Notes",
          dataIndex: "notes",
          key: "notes",
          width: 120,
        },
        {
          title: "Flag",
          dataIndex: "flag",
          key: "flag",
          width: 120,
        },
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
          width: 120,
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "",
          width: 100,
          fixed: "right",
          render: (item) => (
            <Link
              to={`/re/sale/${item.portfolio ? "portfolio/" : ""}${
                item.sale_id
              }`}
              target="_blank"
            >
              <Button size="sm" icon="document-open" onClick={null} />
            </Link>
          ),
        },
      ]}
    />
  );
}

EntityMasterRERelationshipsTable.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default EntityMasterRERelationshipsTable;
