/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Table from "components/Table";
import Button from "components/Button";
import PermissionValidator from "components/PermissionValidator";

function HYManagerAssociationTable({ managers, onRequestDelete }) {
  return managers.length ? (
    <Table
      data={managers}
      rowKey={record => record.manager_id.entity_id}
      columns={[
        {
          title: "Manager",
          dataIndex: "manager_id.long_name",
          key: "manager_id.long_name",
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "",
          width: 100,
          render: (item) => (
            <PermissionValidator
              allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
              shouldHide
            >
              <Button
                size="sm"
                icon="trash"
                intent="none"
                onClick={() => onRequestDelete(item)}
              />
            </PermissionValidator>
          ),
        },
      ]}
    />
  ) : null;
}

HYManagerAssociationTable.propTypes = {
  managers: PropTypes.arrayOf(PropTypes.object),
  onRequestDelete: PropTypes.func.isRequired,
};

HYManagerAssociationTable.defaultProps = {
  managers: [],
};

export default HYManagerAssociationTable;
