import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tag } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import CurrencyFormat from "components/CurrencyFormat";
import loadEntityMasterRoles from "services/entityMaster/loadEntityMasterRoles";
import moment from "moment";

function EntityMasterCLORelationshipsTable({ entityId }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    promiseFn: loadEntityMasterRoles,
    onError: () => showError("Error loading the CLO Relationships"),
  });

  React.useEffect(() => {
    fetch({
      id: entityId,
      type: "clo",
      params: {
        page: 1,
        size: 20,
      },
    });
  }, [fetch, entityId]);

  return (
    <Table
      pending={pending}
      data={data && data.data}
      rowKey="deal_code"
      scroll={{ x: 2720 }}
      columns={[
        {
          title: "Roles",
          dataIndex: "roles",
          key: "roles",
          fixed: "left",
          render: (roles) => (
            <Row>
              {roles.map((role) => (
                <React.Fragment key={role}>
                  <Tag intent="primary" key={role}>
                    {role}
                  </Tag>
                  <Separator size="xs" />
                </React.Fragment>
              ))}
            </Row>
          ),
        },
        {
          title: "Deal Code",
          dataIndex: "deal_code",
          key: "deal_code",
          width: 120,
        },
        {
          title: "Offering Type",
          dataIndex: "offering_type",
          key: "offering_type",
          width: 180,
        },
        {
          title: "Deal Type",
          dataIndex: "deal_type",
          key: "deal_type",
          width: 140,
        },
        {
          title: "Parent Code",
          dataIndex: "parent_code",
          key: "parent_code",
          width: 120,
        },
        {
          title: "Pricing Date",
          dataIndex: "pricing_date",
          key: "pricing_date",
          width: 120,
          render: (date) => (date ? moment(date).format("MM/DD/YYYY") : null),
        },
        {
          title: "Closing Date",
          dataIndex: "closing_date",
          key: "closing_date",
          width: 120,
          render: (date) => (date ? moment(date).format("MM/DD/YYYY") : null),
        },
        {
          title: "Abbrv",
          dataIndex: "abbrv",
          key: "abbrv",
          width: 120,
        },
        {
          title: "Issuer",
          dataIndex: "issuer",
          key: "issuer",
          width: 120,
        },
        {
          title: "Deal Amount",
          dataIndex: "deal_amount",
          key: "deal_amount",
          width: 120,
          render: (amount) => <CurrencyFormat amount={amount} />,
        },
        {
          title: "Retained Amount",
          dataIndex: "retained_amount",
          key: "retained_amount",
          width: 120,
          render: (amount) => <CurrencyFormat amount={amount || 0} />,
        },
        {
          title: "Collateral",
          dataIndex: "collateral",
          key: "collateral",
          width: 120,
        },
        {
          title: "Collateral Sub",
          dataIndex: "collateral_sub",
          key: "collateral_sub",
          width: 120,
        },
        {
          title: "Noncall Date",
          dataIndex: "noncall_date",
          key: "noncall_date",
          width: 120,
        },
        {
          title: "Noncall Years",
          dataIndex: "noncall_years",
          key: "noncall_years",
          width: 120,
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          width: 120,
        },
        {
          title: "End Year",
          dataIndex: "end_year",
          key: "end_year",
          width: 120,
        },
        {
          title: "Pay frequency",
          dataIndex: "pay_frequency",
          key: "pay_frequency",
          width: 120,
        },
        {
          title: "Current Manager",
          dataIndex: "current_manager",
          key: "current_manager",
          width: 120,
        },
        {
          title: "Seller",
          dataIndex: "seller",
          key: "seller",
          width: 120,
        },
        {
          title: "Sponsor",
          dataIndex: "sponsor",
          key: "sponsor",
          width: 120,
        },
        {
          title: "Master Servicer",
          dataIndex: "master_servicer",
          key: "master_servicer",
          width: 120,
        },
        {
          title: "Transfer Date",
          dataIndex: "transfer_date",
          key: "transfer_date",
          width: 120,
          render: (date) => (date ? moment(date).format("MM/DD/YYYY") : null),
        },
        {
          title: "Enhancement",
          dataIndex: "enhancement",
          key: "enhancement",
          width: 120,
        },
        {
          title: "Trustee",
          dataIndex: "trustee",
          key: "trustee",
          width: 120,
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "",
          width: 100,
          fixed: "right",
          render: (item) => (
            <Link to={`/clo/edit/${item.deal_code}`} target="_blank">
              <Button size="sm" icon="document-open" onClick={null} />
            </Link>
          ),
        },
      ]}
    />
  );
}

EntityMasterCLORelationshipsTable.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default EntityMasterCLORelationshipsTable;
