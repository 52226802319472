/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import getHYManagerByIdService from "services/hy/getHYManagerByIdService";
import HYFundTableColumns from "../fund/HYFundTableColumns";

function HYFundSubTable({ managerId, bordered }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    eager: true,
    promiseFn: getHYManagerByIdService,
    onError: (error) => {
      showError("Error loading Subtable HY Funds");
    },
  });

  React.useEffect(() => {
    fetch({ id: managerId });
  }, []);

  const columns = HYFundTableColumns.slice(0, 8).map((column) => column);

  return (
    <Table
      rowKey="fund_id.entity_id"
      pending={pending}
      bordered={bordered}
      data={data.funds}
      columns={columns}
    />
  );
}

HYFundSubTable.propTypes = {
  managerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  bordered: PropTypes.bool,
};
HYFundSubTable.defaultProps = {
  bordered: false,
};

export default HYFundSubTable;
