import fetch from "../fetch";

export default ({ contactId, fundId, contact_type }) =>
  fetch({
    path: `/hy/funds/${fundId}/contacts/${contactId}`,
    method: "PUT",
    data: {
      contact_type,
    },
  });
