import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Row, Box } from "jsxstyle";
import Table from "components/Table";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import useQueryStringFilters from "hooks/useQueryStringFilters";
import loadREPropService from "services/re/loadREPropService";
import Separator from "components/Separator";
import Button from "components/Button";
import Pagination from "components/Pagination";
import PropertySelectFilters from "./PropertySelectFilters";
import Heading from "components/Heading";
import { useRegionContext } from 'contexts/RegionContext';
import { REGION_CODES } from "consts";

const PAGE_SIZE = 10;

function PropertySelectTable({
  onRequestModalClose,
  onSelect,
  single,
  cancelButton,
  confirmButton,
  value
}) {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState(value);
  const [selectedRows, setSelectedRows] = React.useState();
  const { region } = useRegionContext();
  const isEurope = region === REGION_CODES.EUROPE;

  const { filters, setFilters } = useQueryStringFilters({
    defaultFilters: { page: 1, size: PAGE_SIZE }
  });

  const { showError } = useToast();

  const [fetch, pending = true, data] = useAsync({
    promiseFn: loadREPropService,
    eager: true,
    onError: () => {
      showError("Error loading Properties");
    }
  });

  React.useEffect(() => {
    fetch({ params: filters });
  }, [filters, fetch]);

  const handleReload = () => {
    fetch({ params: filters });
  };

  const handleFiltersChange = (filters = {}) => {
    setFilters(currentFilters => ({
      ...currentFilters,
      ...filters
    }));
  };

  const handleSortChange = sortObject => {
    setFilters(currentFilters => ({
      ...currentFilters,
      page: 1,
      size: PAGE_SIZE,
      order_by: sortObject.order_by,
      order_direction: sortObject.order_direction
    }));
  };

  const handleChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  return (
    <React.Fragment>
      <Row>
        <Box flex={1}>
          <Heading size="md">Properties</Heading>
        </Box>
        <PropertySelectFilters onChange={handleFiltersChange} />
      </Row>
      <Separator />
      <Table
        rowKey="property_id"
        pending={pending}
        onSortChange={handleSortChange}
        onReload={handleReload}
        rowSelection={{
          selectedRowKeys,
          onChange: handleChange,
          type: single ? "radio" : undefined
        }}
        columns={[
          {
            title: "Property 1",
            dataIndex: "property_1",
            key: "property_1",
            width: 198
          },
          {
            title: "Property 2",
            dataIndex: "property_2",
            key: "property_2",
            width: 198
          },
          {
            title: "City",
            dataIndex: "city",
            key: "city",
            width: 198
          },
          {
            title: isEurope ? "Country" : "State",
            dataIndex: isEurope ? "country_code" : "state",
            key: isEurope ? "country_code" : "state",
            width: 198
          },
          {
            title: "Prop type",
            dataIndex: "prop_type",
            key: "prop_type",
            width: 198
          }
        ]}
        data={get(data, "data", [])}
      />

      <Separator />
      {data && (
        <Row justifyContent="flex-end">
          <Pagination
            page={filters.page}
            size={filters.size}
            total={data.total}
            onChange={handleFiltersChange}
          />
        </Row>
      )}
      <Separator />
      <Row justifyContent="flex-end">
        <Button
          onClick={onRequestModalClose}
          type="button"
          text={cancelButton.title}
          intent={cancelButton.intent}
        />
        <Separator />
        <Button
          text={confirmButton.title}
          intent={confirmButton.intent}
          disabled={isEmpty(selectedRowKeys)}
          onClick={() => onSelect(selectedRows)}
        />
      </Row>
    </React.Fragment>
  );
}

PropertySelectTable.propTypes = {
  onRequestModalClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  single: PropTypes.bool,
  cancelButton: PropTypes.shape({
    title: PropTypes.string.isRequired,
    intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"])
  }),
  confirmButton: PropTypes.shape({
    title: PropTypes.string.isRequired,
    intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"])
  })
};

PropertySelectTable.defaultProps = {
  single: true,
  cancelButton: {
    title: "Cancel",
    intent: "none"
  },
  confirmButton: {
    title: "Select property",
    intent: "success"
  }
};

export default PropertySelectTable;
