import React from "react";
import { withRouter } from "react-router-dom";
import Stepper from "components/Stepper";

const steps = ["Basic Info", "Funds"];

function NewContactStepper({ match, children }) {
  const { currentView } = match.params;
  const currentStep =
    {
      funds: 1,
    }[currentView] || 0;
  return (
    <Stepper steps={steps} currentStep={currentStep}>
      {children}
    </Stepper>
  );
}

NewContactStepper.propTypes = {};

NewContactStepper.defaultProps = {};

export default withRouter(NewContactStepper);
