import fetch from "../fetch";
import get from "lodash/get";

export default ({ data, isNew }) =>
  fetch({
    path: `/hy/managers${
      isNew ? "" : `/${get(data, "manager_id.entity_id", "")}`
    }`,
    method: isNew ? "POST" : "PUT",
    data: {
      ...data,
      funds: get(data, "funds", []).map(
        ({ fund_id: { entity_id } }) => entity_id,
      ),
      vehicle_types: get(data, "vehicle_types", []).map(({ id }) => id),
    },
  });
