import fetch from "../fetch";
import pickBy from "lodash/pickBy";

export default ({ params }) => {
  const apiParams = pickBy(params);

  return fetch({
    path: "/cmbs/deals",
    params: {
      ...apiParams,
      offering_type: apiParams.offering_type
        ? {
            "U.S.": ["A", "S", "P"],
            "Non-U.S.": ["I"],
            Agency: ["F"],
          }[apiParams.offering_type]
        : undefined,
    },
  });
};
