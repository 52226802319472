import React from "react";
import PropTypes from "prop-types";
import { Alert as BluePrintAlert } from "@blueprintjs/core";

function Alert({
  confirmButtonText,
  intent,
  onCancel,
  onConfirm,
  children,
  isOpen,
  icon
}) {
  return (
    <BluePrintAlert
      cancelButtonText="Cancel"
      canEscapeKeyCancel={true}
      canOutsideClickCancel={true}
      confirmButtonText={confirmButtonText}
      intent={intent}
      onCancel={onCancel}
      onClose={onCancel}
      onConfirm={onConfirm}
      isOpen={isOpen}
      icon={icon}
    >
      <p>{children}</p>
    </BluePrintAlert>
  );
}

Alert.propTypes = {
  confirmButtonText: PropTypes.string,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  icon: PropTypes.string
};

Alert.defaultProps = {
  confirmButtonText: "Confirm",
  intent: "none",
  icon: null
};

export default Alert;
