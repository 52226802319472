import React from "react";
import PropTypes from "prop-types";
import pickBy from "lodash/pickBy";
import join from "lodash/join";
import orderBy from "lodash/orderBy";
import startCase from "lodash/startCase";
import { Row } from "jsxstyle";
import Tag from "./Tag";
import InBetweenSpacing from "./InBetweenSpacing";

function CurrentFiltersList({ filters, onRemove }) {
  const activeFilters = pickBy(filters);
  return (
    <Row>
      <InBetweenSpacing size="xs">
        {orderBy(Object.keys(activeFilters)).map(key => (
          <Tag key={key} intent="primary" onRemove={() => onRemove(key)}>
            <b>{startCase(key)}:</b>{" "}
            {Array.isArray(activeFilters[key])
              ? join(activeFilters[key], " - ")
              : activeFilters[key]}
          </Tag>
        ))}
      </InBetweenSpacing>
    </Row>
  );
}

CurrentFiltersList.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func
};

CurrentFiltersList.defaultProps = {};

export default CurrentFiltersList;
