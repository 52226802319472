import React from "react";
import get from "lodash/get";
import {
  Button,
  Tab,
  Tabs,
  Alignment,
  Navbar,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { Row, Box } from "jsxstyle";
import useAsync from "hooks/useAsync";
import useUrlParams from "hooks/useUrlParams";
import loadGlobalSearchService from "services/global-search/loadGlobalSearchService";
import Form from "components/Form";
import Card from "components/Card";
import Badge from "components/Badge";
import Heading from "components/Heading";
import Separator from "components/Separator";
import InputGroup from "components/InputGroup";
import PrivateLayout from "components/PrivateLayout";
import ABSTable from "screens/abs/ABSTable";
import CLOTable from "screens/clo/CLOTable";
import CMBSTable from "screens/cmbs/CMBSTable";
import RESaleTable from "screens/re/sale/RESaleTable";
import REPropTable from "screens/re/prop/REPropTable";
import HYFundTable from "screens/hy/fund/HYFundTable";
import ContactTable from "screens/contact/ContactTable";
import EntityMasterTable from "screens/entity-master/EntityMasterTable";

function GlobalSearchResultScreen() {
  const [params, setParams] = useUrlParams({
    criteria: "",
    currentTab: 0,
  });

  const [criteria, setCriteria] = React.useState(params.criteria);

  // eslint-disable-next-line no-unused-vars
  const [fetch, pending, data = {}] = useAsync({
    promiseFn: loadGlobalSearchService,
    params: { criteria: params.criteria },
    eager: true,
  });

  const handleSubmit = () => {
    setParams({ criteria, currentTab: 0 });
  };

  const dataABS = get(data, "abs", []);
  const dataCMBS = get(data, "cmbs", []);
  const dataCLO = get(data, "clo", []);
  const dataSALES = get(data, "sales", []);
  const dataPROPERTIES = get(data, "properties", []);
  const dataENTITIES = get(data, "entities", []);
  const dataCONTACTS = get(data, "contacts", []);
  const dataFUNDS = get(data, "funds", []);

  const noResults =
    !dataABS.length &&
    !dataCMBS.length &&
    !dataCLO.length &&
    !dataSALES.length &&
    !dataPROPERTIES.length &&
    !dataENTITIES.length &&
    !dataCONTACTS.length &&
    !dataFUNDS.length;

  return (
    <PrivateLayout>
      <Box display="flex" justifyContent="center">
        <Heading size="sm">Search results</Heading>
      </Box>
      <Separator />
      <Box display="flex" justifyContent="center">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Box width="340px">
              <InputGroup
                leftIcon="search"
                placeholder="Search..."
                value={criteria}
                onChange={(value) => setCriteria(value)}
                fullWidth
              />
            </Box>
            <Separator />
            <Button
              type="submit"
              intent="primary"
              icon="search"
              text="Search"
            />
          </Row>
        </Form>
      </Box>
      <Separator />
      {noResults ? (
        <Box height="400px">
          <NonIdealState
            icon="search"
            title={pending ? "Searching..." : "No search results"}
            description={
              <Box height={50}>
                {pending ? (
                  <Spinner size={20} />
                ) : (
                  <>
                    <Box>Your search didn't match any data.</Box>
                    <Box>Try searching for something else.</Box>
                  </>
                )}
              </Box>
            }
          />
        </Box>
      ) : (
        <>
          <Navbar>
            <Navbar.Group align={Alignment.LEFT}>
              <Tabs
                large={true}
                onChange={(newTabId) =>
                  setParams({ ...params, currentTab: newTabId })
                }
                selectedTabId={params.currentTab}
              >
                <Tab
                  id={0}
                  title={
                    <Box position="relative" padding="0px 20px">
                      ABS
                      {dataABS.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataABS.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  id={1}
                  title={
                    <Box position="relative" padding="0px 20px">
                      CMBS
                      {dataCMBS.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataCMBS.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  id={2}
                  title={
                    <Box position="relative" padding="0px 20px">
                      CLO
                      {dataCLO.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataCLO.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  id={3}
                  title={
                    <Box position="relative" padding="0px 20px">
                      Sales
                      {dataSALES.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataSALES.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  id={4}
                  title={
                    <Box position="relative" padding="0px 20px">
                      Properties
                      {dataPROPERTIES.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataPROPERTIES.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  id={5}
                  title={
                    <Box position="relative" padding="0px 20px">
                      Entities
                      {dataENTITIES.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataENTITIES.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  id={6}
                  title={
                    <Box position="relative" padding="0px 20px">
                      Contacts
                      {dataCONTACTS.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataCONTACTS.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  id={7}
                  title={
                    <Box position="relative" padding="0px 20px">
                      Funds
                      {dataFUNDS.length > 0 && (
                        <Box position="absolute" top={5} right={0}>
                          <Badge>{dataFUNDS.length}</Badge>
                        </Box>
                      )}
                    </Box>
                  }
                />
              </Tabs>
            </Navbar.Group>
          </Navbar>
          <Card style={{ flexGrow: 1 }}>
            {
              {
                0: (
                  <ABSTable data={dataABS} pending={pending} sortable={false} />
                ),
                1: (
                  <CMBSTable
                    data={dataCMBS}
                    pending={pending}
                    sortable={false}
                  />
                ),
                2: (
                  <CLOTable data={dataCLO} pending={pending} sortable={false} />
                ),
                3: (
                  <RESaleTable
                    data={dataSALES}
                    pending={pending}
                    sortable={false}
                  />
                ),
                4: (
                  <REPropTable
                    data={dataPROPERTIES}
                    pending={pending}
                    sortable={false}
                  />
                ),
                5: (
                  <EntityMasterTable
                    data={dataENTITIES}
                    pending={pending}
                    sortable={false}
                  />
                ),
                6: (
                  <ContactTable
                    data={dataCONTACTS}
                    pending={pending}
                    sortable={false}
                  />
                ),
                7: (
                  <HYFundTable
                    data={dataFUNDS}
                    pending={pending}
                    sortable={false}
                  />
                ),
              }[params.currentTab]
            }
          </Card>
        </>
      )}
    </PrivateLayout>
  );
}

export default GlobalSearchResultScreen;
