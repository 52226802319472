import React from "react";
import { Box, Row, Col } from "jsxstyle";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import Form from "components/Form";
import Separator from "components/Separator";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import isRequired from "validators/isRequired";
import useAsync from "hooks/useAsync";
import saveCMBSBPieceBuyerService from "services/cmbs/saveCMBSBPieceBuyerService";
import CurrencyFormat from "components/CurrencyFormat";
import { get } from "lodash";

function CMBSBPieceBuyersForm({
  onData,
  initialValues,
  onCancel,
  dealSize,
  bPieceBuyerCredit,
}) {
  const { showSuccess, showError } = useToast();

  const {
    getValue,
    setValue,
    setValues,
    getValues,
    getError,
    submit,
    reset,
    clear,
  } = useForm({
    onSubmit: (values) =>
      fetch({
        values,
        dealCode: values.dealCode,
        buyerId: values.id,
      }),
    initialValues: {
      ...(initialValues || {}),
      amount_percentage: Number(
        (((initialValues.b_piece_amount || 0) / dealSize) * 100).toFixed(2),
      ),
    },
    validations: {
      buyer_entity_id: [isRequired],
      b_piece_amount: [isRequired],
    },
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveCMBSBPieceBuyerService,
    onData: () => {
      showSuccess("Buyer saved.");
      reset();
      onData();
    },
    onError: (error) => {
      const type = get(error, "data.error");
      showError(
        {
          duplicated_key: "Cannot add same Buyer twice to the same deal",
        }[type] || "An error happened.",
      );
    },
  });

  const handleCancel = React.useCallback(() => {
    onCancel();
    reset();
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditing = Boolean(getValue("id"));

  return (
    <Form onSubmit={() => submit()}>
      <FormGrid>
        <Box className="col-6">
          <FormGroup
            label="Buyer"
            disabled={isSubmitting}
            error={getError("buyer_entity_id")}
          >
            <SelectEntityMaster
              onSelect={(value) => setValue("buyer_entity_id", value)}
              value={getValue("buyer_entity_id")}
              error={getError("buyer_entity_id")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
        <Box className="col-3">
          <FormGroup
            key={getValue("amountKey")}
            label="Amount"
            disabled={isSubmitting}
            error={getError("b_piece_amount")}
          >
            <CurrencyInputGroup
              leftIcon="dollar"
              intent={getError("b_piece_amount") ? "danger" : null}
              value={getValue("b_piece_amount")}
              onChange={(value) =>
                setValues({
                  ...getValues(),
                  b_piece_amount: value,
                  amount_percentage: Number(
                    ((value / dealSize) * 100).toFixed(2),
                  ),
                  percentageKey: getValue("percentageKey", 0) + 1,
                })
              }
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
        <Box className="col-3">
          <FormGroup
            key={getValue("percentageKey")}
            label="Amount(%)"
            disabled={isSubmitting}
            error={getError("amount_percentage")}
          >
            <CurrencyInputGroup
              leftIcon="percentage"
              value={getValue("amount_percentage")}
              onChange={(value) =>
                setValues({
                  ...getValues(),
                  b_piece_amount: Number(((value * dealSize) / 100).toFixed(2)),
                  amount_percentage: value,
                  amountKey: getValue("amountKey", 0) + 1,
                })
              }
              disabled={isSubmitting}
            />
          </FormGroup>
        </Box>
        <Row className="col-12">
          <Row flex={1} alignItems="flex-start">
            {isEditing && (
              <React.Fragment>
                <Button
                  text="Cancel"
                  onClick={handleCancel}
                  disabled={isSubmitting}
                />
                <Separator />
              </React.Fragment>
            )}
            <Button
              type="submit"
              text={isEditing ? "Save" : "Add"}
              intent="success"
              onClick={() => {}}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Row>
          <Col alignItems="flex-end">
            <Box component="p">
              Deal size:&nbsp;
              <Box component="strong">
                <CurrencyFormat amount={dealSize} />
              </Box>
            </Box>
            <Box component="p">
              Remaining B-piece buyer credit:&nbsp;
              <Box component="strong">
                <CurrencyFormat amount={dealSize - bPieceBuyerCredit} />
              </Box>
            </Box>
          </Col>
        </Row>
      </FormGrid>
    </Form>
  );
}

CMBSBPieceBuyersForm.propTypes = {};

CMBSBPieceBuyersForm.defaultProps = {};

export default CMBSBPieceBuyersForm;
