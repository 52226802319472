import React from "react";
import { useRegionContext } from 'contexts/RegionContext';
import PrivateLayout from "components/PrivateLayout";
import Heading from "components/Heading";
import Separator from "components/Separator";
import Stepper from "components/Stepper";
import SalePortfolioForm from "./SalePortfolioForm";
import SalePortfolioProperties from "./SalePortfolioProperties";

function NewSalePortfolioScreen({ match }) {
  const { regionName } = useRegionContext();
  const { saleId } = match.params;

  return (
    <PrivateLayout>
      <Heading size="md">{`New ${regionName} Portfolio Sale`}</Heading>
      <Separator />
      <Stepper
        steps={["Sale", "Properties"]}
        currentStep={saleId ? 1 : 0}
        navigationWidth={990}
      >
        <SalePortfolioForm />
        <SalePortfolioProperties saleId={saleId} />
      </Stepper>
    </PrivateLayout>
  );
}

export default NewSalePortfolioScreen;
