import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import deleteHYFeeStructureService from "services/hy/deleteHYFeeStructureService";
import Alert from "components/Alert";
import Button from "components/Button";

function DeleteFeeStructure({ match, feeStructure, onDelete }) {
  const fundId = match.params.id;
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteHYFeeStructureService,
    onError: (error) => {
      showError("Error deleting Fee Structure");
    },
    onData: () => {
      showSuccess("Fee Structure deleted successfully.");
      onDelete();
    },
  });

  return (
    <>
      <Button
        size="sm"
        icon="trash"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ fundId, feeStructureId: feeStructure.id })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete the Fee Structure{" "}
        <b>{feeStructure.id}</b>?
      </Alert>
    </>
  );
}

DeleteFeeStructure.propTypes = {
  feeStructure: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

DeleteFeeStructure.defaultProps = {
  feeStructure: {},
};

export default withRouter(DeleteFeeStructure);
