import React from "react";
import { withRouter } from "react-router-dom";
import {
  Navbar as BluePrintNavbar,
  Alignment,
  Button,
} from "@blueprintjs/core";
import SignOut from "./SignOut";
import { Box } from "jsxstyle";
import Logo from "./Logo";
import MenuRERegions from "./MenuRERegions";
import PermissionValidator from "./PermissionValidator";
import { ADMIN_ROLE } from "consts";
import GlobalSearchOmnibar from "../screens/global-search/GlobalSearchOmnibar";

function PrivateNavbar({ history, location }) {
  return (
    <BluePrintNavbar className="bp3-dark">
      <Box className="container-fluid">
        <BluePrintNavbar.Group align={Alignment.LEFT}>
          <BluePrintNavbar.Heading>
            <Logo color="white" height={27} useIcon={true} />
          </BluePrintNavbar.Heading>
          <BluePrintNavbar.Divider />
        </BluePrintNavbar.Group>
        <BluePrintNavbar.Group align={Alignment.LEFT}>
          <Button
            className="bp3-minimal"
            text="ABS"
            onClick={() => history.push("/abs")}
            active={location.pathname.includes("abs")}
          />
          <Button
            className="bp3-minimal"
            text="CMBS"
            onClick={() => history.push("/cmbs")}
            active={location.pathname.includes("cmbs")}
          />
          <Button
            className="bp3-minimal"
            text="CLO"
            onClick={() => history.push("/clo")}
            active={location.pathname.includes("clo")}
          />
          <MenuRERegions />
          <BluePrintNavbar.Divider />
          <Button
            className="bp3-minimal"
            text="Entity Master"
            onClick={() => history.push("/entity-master")}
            active={location.pathname.includes("entity-master")}
          />
          <PermissionValidator allowedFor={[ADMIN_ROLE]} shouldHide>
            <Button
              className="bp3-minimal"
              text="Accounts"
              onClick={() => history.push("/accounts")}
              active={location.pathname.includes("accounts")}
            />
          </PermissionValidator>
          <BluePrintNavbar.Divider />
          <Button
            className="bp3-minimal"
            text="Contacts"
            onClick={() => history.push("/contacts")}
            active={location.pathname.includes("contacts")}
          />
          <Button
            className="bp3-minimal"
            text="High Yield"
            onClick={() => history.push("/hy")}
            active={location.pathname.includes("hy")}
          />
        </BluePrintNavbar.Group>
        <BluePrintNavbar.Group align={Alignment.RIGHT}>
          {!location.pathname.includes("global-search") ? (
            <>
              <GlobalSearchOmnibar history={history} />
              <BluePrintNavbar.Divider />
            </>
          ) : null}
          <SignOut />
        </BluePrintNavbar.Group>
      </Box>
    </BluePrintNavbar>
  );
}

PrivateNavbar.propTypes = {};

PrivateNavbar.defaultProps = {};

export default withRouter(PrivateNavbar);
