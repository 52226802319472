import React from "react";
import PropTypes from "prop-types";
import { Box } from "jsxstyle";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import SelectOfferingType from "components/SelectOfferingType";
import SelectCMBSDealType from "components/SelectCMBSDealType";
import SelectEntityMaster from "components/SelectEntityMaster";
import SelectCurrencyCode from "components/SelectCurrencyCode";
import SelectRegionCode from "components/SelectRegionCode";
import SelectRateType from "components/SelectRateType";
import SelectCountryCode from "components/SelectCountryCode";
import SelectSecurityType from "components/SelectSecurityType";
import Select from "components/Select";
import Switch from "components/Switch";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import DateInputGroup from "components/DateInputGroup";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import TextArea from "components/TextArea";
import SelectCreatableAbbrv from "components/SelectCreatableAbbrv";
import SelectCreatableIssuer from "components/SelectCreatableIssuer";

function CMBSGeneralInformationFields({
  getValue,
  setValue,
  getError,
  isSubmitting,
  isNew,
  isAllowed,
}) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <FormGrid>
          <Box className="col-12">
            <FormGroup
              label="Deal profile"
              disabled={isSubmitting}
              error={getError("deal_profile")}
            >
              <TextArea
                intent={getError("deal_profile") ? "danger" : "primary"}
                value={getValue("deal_profile")}
                onChange={(value) => setValue("deal_profile", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                fullWidth
                size="lg"
                className="taller"
              />
            </FormGroup>
          </Box>
          <FormGrid.Item>
            <FormGroup
              label="Deal code"
              required
              disabled={isSubmitting}
              error={getError("deal_code")}
            >
              <IntegerInputGroup
                minLength={8}
                maxLength={8}
                intent={getError("deal_code") ? "danger" : null}
                value={getValue("deal_code")}
                onChange={(value) => setValue("deal_code", value)}
                buttonPosition="none"
                disabled={isSubmitting || !isNew}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Offering type"
              required
              disabled={isSubmitting}
              error={getError("offering_type")}
            >
              <SelectOfferingType
                onSelect={(value) => setValue("offering_type", value)}
                value={getValue("offering_type")}
                error={getError("offering_type")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Deal type"
              required
              disabled={isSubmitting}
              error={getError("deal_type")}
            >
              <SelectCMBSDealType
                onSelect={(value) => setValue("deal_type", value)}
                value={getValue("deal_type")}
                error={getError("deal_type")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Abbreviation"
              disabled={isSubmitting}
              error={getError("abbrv")}
            >
              <SelectCreatableAbbrv
                variant="CMBS"
                disabled={isSubmitting}
                error={getError("abbrv")}
                value={getValue("abbrv")}
                onCreate={(abbrv) => setValue("abbrv", abbrv)}
                onSelect={(value) => setValue("abbrv", value)}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Issuer *"
              disabled={isSubmitting}
              error={getError("issuer")}
            >
              <SelectCreatableIssuer
                variant="CMBS"
                readOnly={!isAllowed}
                disabled={isSubmitting}
                error={getError("issuer")}
                value={getValue("issuer")}
                onCreate={(issuer) => setValue("issuer", issuer)}
                onSelect={(value) => setValue("issuer", value)}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Series"
              disabled={isSubmitting}
              error={getError("series")}
            >
              <InputGroup
                intent={getError("series") ? "danger" : null}
                value={getValue("series")}
                onChange={(value) => setValue("series", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                maxLength={50}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Deal amount *"
              disabled={isSubmitting}
              error={getError("deal_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("deal_amount") ? "danger" : null}
                value={getValue("deal_amount")}
                onChange={(value) => setValue("deal_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Pricing date *"
              disabled={isSubmitting}
              error={getError("pricing_date")}
            >
              <DateInputGroup
                intent={getError("pricing_date") ? "danger" : null}
                value={getValue("pricing_date")}
                onChange={(value) => setValue("pricing_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Closing date"
              disabled={isSubmitting}
              error={getError("closing_date")}
            >
              <DateInputGroup
                intent={getError("closing_date") ? "danger" : null}
                value={getValue("closing_date")}
                onChange={(value) => setValue("closing_date", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Operating Advisor"
              disabled={isSubmitting}
              error={getError("operating_advisor")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("operating_advisor", value)}
                value={getValue("operating_advisor")}
                error={getError("operating_advisor")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Asset reviewer"
              disabled={isSubmitting}
              error={getError("asset_reviewer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("asset_reviewer", value)}
                value={getValue("asset_reviewer")}
                error={getError("asset_reviewer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Trustee"
              disabled={isSubmitting}
              error={getError("trustee")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("trustee", value)}
                value={getValue("trustee")}
                error={getError("trustee")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Certificate administrator"
              disabled={isSubmitting}
              error={getError("certificate_admin")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("certificate_admin", value)}
                value={getValue("certificate_admin")}
                error={getError("certificate_admin")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Underwriter counsel"
              disabled={isSubmitting}
              error={getError("counsel_uw")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("counsel_uw", value)}
                value={getValue("counsel_uw")}
                error={getError("counsel_uw")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Issuer counsel"
              disabled={isSubmitting}
              error={getError("counsel_issuer")}
            >
              <SelectEntityMaster
                onSelect={(value) => setValue("counsel_issuer", value)}
                value={getValue("counsel_issuer")}
                error={getError("counsel_issuer")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination of securities"
              disabled={isSubmitting}
              error={getError("denomination")}
            >
              <SelectCurrencyCode
                onSelect={(value) => setValue("denomination", value)}
                value={getValue("denomination")}
                error={getError("denomination")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Denomination amount"
              disabled={isSubmitting}
              error={getError("denomination_amount")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("denomination_amount") ? "danger" : null}
                value={getValue("denomination_amount")}
                onChange={(value) => setValue("denomination_amount", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Synthetic pool"
              disabled={isSubmitting}
              error={getError("synthetic_pool")}
            >
              <CurrencyInputGroup
                leftIcon="dollar"
                intent={getError("synthetic_pool") ? "danger" : null}
                value={getValue("synthetic_pool")}
                onChange={(value) => setValue("synthetic_pool", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Distribution region"
              disabled={isSubmitting}
              error={getError("distribution_region")}
            >
              <SelectRegionCode
                onSelect={(value) => setValue("distribution_region", value)}
                value={getValue("distribution_region")}
                error={getError("distribution_region")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Payment frequency"
              disabled={isSubmitting}
              error={getError("pay_frequency")}
            >
              <Select
                items={[
                  {
                    id: "M",
                    label: "Monthly",
                  },
                  {
                    id: "Q",
                    label: "Quarterly",
                  },
                  {
                    id: "S",
                    label: "Semi-annual",
                  },
                  {
                    id: "A",
                    label: "Annual",
                  },
                ]}
                onSelect={(value) => setValue("pay_frequency", value)}
                value={getValue("pay_frequency")}
                error={getError("pay_frequency")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Tax treatment"
              disabled={isSubmitting}
              error={getError("tax_treatment")}
            >
              <SelectSecurityType
                onSelect={(value) => setValue("tax_treatment", value)}
                value={getValue("tax_treatment")}
                error={getError("tax_treatment")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Originated for securitization"
              disabled={isSubmitting}
              error={getError("ofs")}
            >
              <Switch
                value={getValue("ofs")}
                onChange={(value) => setValue("ofs", !getValue("ofs"))}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <Box className="col-2">
            <FormGroup
              label="Lease backed"
              disabled={isSubmitting}
              error={getError("lease_backed")}
            >
              <Switch
                value={getValue("lease_backed")}
                onChange={(value) =>
                  setValue("lease_backed", !getValue("lease_backed"))
                }
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
          <Box className="col-2">
            <FormGroup
              label="Single borrower"
              disabled={isSubmitting}
              error={getError("multi_single")}
            >
              <Switch
                value={getValue("multi_single")}
                onChange={(value) =>
                  setValue("multi_single", !getValue("multi_single"))
                }
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </Box>
          <FormGrid.Item>
            <FormGroup
              label="Risk retention"
              disabled={isSubmitting}
              error={getError("risk_retention")}
            >
              <Select
                items={[
                  {
                    id: "H",
                    label: " Horizontal",
                  },
                  {
                    id: "V",
                    label: "Vertical",
                  },
                  {
                    id: "L",
                    label: "L-shaped",
                  },
                ]}
                onSelect={(value) => setValue("risk_retention", value)}
                value={getValue("risk_retention")}
                error={getError("risk_retention")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Number of properties"
              disabled={isSubmitting}
              error={getError("num_of_props")}
            >
              <IntegerInputGroup
                intent={getError("num_of_props") ? "danger" : null}
                value={getValue("num_of_props")}
                onChange={(value) => setValue("num_of_props", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Number of loans"
              disabled={isSubmitting}
              error={getError("num_of_loans")}
            >
              <IntegerInputGroup
                intent={getError("num_of_loans") ? "danger" : null}
                value={getValue("num_of_loans")}
                onChange={(value) => setValue("num_of_loans", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Loan quality"
              disabled={isSubmitting}
              error={getError("quality")}
            >
              <Select
                items={[
                  {
                    id: "P",
                    label: "Performing",
                  },
                  {
                    id: "N",
                    label: "Non-performing",
                  },
                  {
                    id: "X",
                    label: "Mixed",
                  },
                ]}
                onSelect={(value) => setValue("quality", value)}
                value={getValue("quality")}
                error={getError("quality")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Loan rate"
              disabled={isSubmitting}
              error={getError("rate_loans")}
            >
              <SelectRateType
                onSelect={(value) => setValue("rate_loans", value)}
                value={getValue("rate_loans")}
                error={getError("rate_loans")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Note rate"
              disabled={isSubmitting}
              error={getError("rate_notes")}
            >
              <SelectRateType
                onSelect={(value) => setValue("rate_notes", value)}
                value={getValue("rate_notes")}
                error={getError("rate_notes")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Top states by collateral concentration"
              disabled={isSubmitting}
              error={getError("geo_all")}
            >
              <InputGroup
                intent={getError("geo_all") ? "danger" : null}
                value={getValue("geo_all")}
                onChange={(value) => setValue("geo_all", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                maxLength={100}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Region of collateral"
              disabled={isSubmitting}
              error={getError("region")}
            >
              <SelectRegionCode
                onSelect={(value) => setValue("region", value)}
                value={getValue("region")}
                error={getError("region")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
              />
            </FormGroup>
          </FormGrid.Item>
          <FormGrid.Item>
            <FormGroup
              label="Country of collateral"
              disabled={isSubmitting}
              error={getError("country")}
            >
              <SelectCountryCode
                onSelect={(value) => {
                  setValue("country", value);
                }}
                value={getValue("country")}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                error={getError("country")}
              />
            </FormGroup>
          </FormGrid.Item>

          <Box className="col-12">
            <FormGroup
              label="Collateral profile"
              disabled={isSubmitting}
              error={getError("collateral_profile")}
            >
              <TextArea
                intent={getError("collateral_profile") ? "danger" : null}
                value={getValue("collateral_profile")}
                onChange={(value) => setValue("collateral_profile", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                fullWidth
                size="lg"
              />
            </FormGroup>
          </Box>
          <Box className="col-12">
            <FormGroup
              label="Risk retention profile"
              disabled={isSubmitting}
              error={getError("risk_retention_profile")}
            >
              <TextArea
                intent={getError("risk_retention_profile") ? "danger" : null}
                value={getValue("risk_retention_profile")}
                onChange={(value) => setValue("risk_retention_profile", value)}
                disabled={isSubmitting}
                readOnly={!isAllowed}
                fullWidth
                size="lg"
              />
            </FormGroup>
          </Box>
        </FormGrid>
      )}
    </PermissionValidator>
  );
}

CMBSGeneralInformationFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isNew: PropTypes.bool,
};

CMBSGeneralInformationFields.defaultProps = {
  isSubmitting: false,
  isNew: true,
};

export default CMBSGeneralInformationFields;
