import React from "react";
import { Box } from "jsxstyle";
import PropTypes from "prop-types";
import { RangeSlider as BluePrintRangeSlider } from "@blueprintjs/core";

function RangeSlider({
  min,
  max,
  stepSize,
  labelStepSize,
  onChange,
  value,
  vertical,
  showTrackFill,
  labelRenderer,
  disabled,
}) {
  const minValue = value ? parseFloat(value[0]) : min;
  const maxValue = value ? parseFloat(value[1]) : max;
  return (
    <Box padding="0 8px">
      <BluePrintRangeSlider
        min={min}
        max={max}
        stepSize={stepSize}
        labelStepSize={labelStepSize}
        onChange={onChange}
        value={[minValue, maxValue]}
        vertical={vertical}
        showTrackFill={showTrackFill}
        labelRenderer={labelRenderer}
        disabled={disabled}
      />
    </Box>
  );
}

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  stepSize: PropTypes.number,
  labelStepSize: PropTypes.number,
  vertical: PropTypes.bool,
  showTrackFill: PropTypes.bool,
  onChange: PropTypes.func,
  labelRenderer: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf([Number, Number]),
    PropTypes.instanceOf([String, String]),
  ]),
  disabled: PropTypes.bool,
};

RangeSlider.defaultProps = {
  disabled: false,
  vertical: false,
  showTrackFill: true,
  labelRenderer: (step) => step,
};

export default RangeSlider;
