import React from "react";
import PropTypes from "prop-types";
import { Row, Box, Col } from "jsxstyle";
import Form from "components/Form";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import Button from "components/Button";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import isRequired from "validators/isRequired";
import useAsync from "hooks/useAsync";
import saveABSGeographicDistributionService from "services/abs/saveABSGeographicDistributionService";
import Separator from "components/Separator";
import PercentFormat from "components/PercentFormat";
import handleApiError from "utils/handleApiError";
import SelectCountryCode from "components/SelectCountryCode";
import SelectNorthAmericanState from "components/SelectNorthAmericanState";

function GeographicDistribution({
  onData,
  initialValues,

  onCancel,
  totalGeographicPercentage,
}) {
  const { showSuccess, showError } = useToast();

  const {
    getValue,
    getValues,
    setValue,
    getError,
    submit,
    reset,
    setValues,
  } = useForm({
    initialValues,
    validations: { geo_country: [isRequired], geo_percentage: [isRequired] },
    onSubmit: (values) =>
      fetch({
        values,
        dealCode: values.dealCode,
        geoId: values.id,
      }),
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveABSGeographicDistributionService,
    onData: () => {
      showSuccess("Geographic Distribution saved.");
      reset();
      onData();
    },
    onError: (error) => {
      showError(handleApiError(error));
    },
  });

  const handleCancel = React.useCallback(() => {
    onCancel();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditing = Boolean(getValue("id"));

  return (
    <Form onSubmit={() => submit()}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup label="Country" error={getError("geo_country")}>
            <SelectCountryCode
              onSelect={(value) => {
                setValues({
                  ...getValues(),
                  geo_country: value,
                  geo_state: null,
                });
              }}
              value={getValue("geo_country")}
              error={getError("geo_country")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="State"
            disabled={getValue("geo_country") !== "USA"}
            error={getError("geo_state")}
          >
            <SelectNorthAmericanState
              disabled={isSubmitting || getValue("geo_country") !== "USA"}
              onSelect={(value) => setValue("geo_state", value)}
              value={getValue("geo_state")}
              error={getError("geo_state")}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Collateral percentage"
            disabled={isSubmitting}
            error={getError("geo_percentage")}
          >
            <CurrencyInputGroup
              leftIcon="percentage"
              intent={getError("geo_percentage") ? "danger" : null}
              value={getValue("geo_percentage")}
              onChange={(value) => setValue("geo_percentage", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <Row className="col-12">
          <Row flex={1} alignItems="flex-start">
            {isEditing && (
              <React.Fragment>
                <Button
                  text="Cancel"
                  onClick={handleCancel}
                  disabled={isSubmitting}
                />
                <Separator />
              </React.Fragment>
            )}
            <Button
              type="submit"
              text={isEditing ? "Save" : "Add"}
              intent="success"
              onClick={() => {}}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Row>
          <Col alignItems="flex-end">
            <Box component="p">
              Percentage assigned:&nbsp;
              <Box component="strong">
                <PercentFormat amount={totalGeographicPercentage} />
              </Box>
            </Box>
          </Col>
        </Row>
      </FormGrid>
    </Form>
  );
}

GeographicDistribution.propTypes = {
  initialValues: PropTypes.object,
  validations: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

GeographicDistribution.defaultProps = {
  initialValues: {},
  validations: {},
  isSubmitting: false,
};

export default GeographicDistribution;
