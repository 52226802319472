import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tag } from "@blueprintjs/core";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import Table from "components/Table";
import Button from "components/Button";
import Separator from "components/Separator";
import CurrencyFormat from "components/CurrencyFormat";
import loadEntityMasterRoles from "services/entityMaster/loadEntityMasterRoles";
import moment from "moment";

function EntityMasterCMBSRelationshipsTable({ entityId }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    promiseFn: loadEntityMasterRoles,
    onError: () => showError("Error loading the Entity Master"),
  });

  React.useEffect(() => {
    fetch({
      id: entityId,
      type: "cmbs",
      params: {
        page: 1,
        size: 20,
      },
    });
  }, [fetch, entityId]);

  return (
    <Table
      pending={pending}
      data={data && data.data}
      rowKey="deal_code"
      scroll={{ x: 2400 }}
      columns={[
        {
          title: "Roles",
          dataIndex: "roles",
          key: "roles",
          fixed: "left",
          render: (roles) => (
            <Row>
              {roles.map((role) => (
                <React.Fragment key={role}>
                  <Tag intent="primary" key={role}>
                    {role}
                  </Tag>
                  <Separator size="xs" />
                </React.Fragment>
              ))}
            </Row>
          ),
        },
        {
          title: "Deal Code",
          dataIndex: "deal_code",
          key: "deal_code",
          width: 120,
        },
        {
          title: "Offering Type",
          dataIndex: "offering_type",
          key: "offering_type",
          width: 200,
        },
        {
          title: "Deal Type",
          dataIndex: "deal_type",
          key: "deal_type",
          width: 160,
        },
        {
          title: "Abbrv",
          dataIndex: "abbrv",
          key: "abbrv",
        },
        {
          title: "Issuer",
          dataIndex: "issuer",
          key: "issuer",
        },
        {
          title: "Series",
          dataIndex: "series",
          key: "series",
        },
        {
          title: "Deal Amount",
          dataIndex: "deal_amount",
          key: "deal_amount",
          render: (deal_amount) => <CurrencyFormat amount={deal_amount || 0} />,
        },
        {
          title: "Pricing Date",
          dataIndex: "pricing_date",
          key: "pricing_date",
          render: (date) => (date ? moment(date).format("MM/DD/YYYY") : null),
        },

        {
          title: "Asset Reviewer",
          dataIndex: "asset_reviewer",
          key: "asset_reviewer",
        },
        {
          title: "Trustee",
          dataIndex: "trustee",
          key: "trustee",
        },
        {
          title: "Certificate admin",
          dataIndex: "certificate_admin",
          key: "certificate_admin",
        },
        {
          title: "Counsel uw",
          dataIndex: "counsel_uw",
          key: "counsel_uw",
        },
        {
          title: "Counsel issuer",
          dataIndex: "counsel_issuer",
          key: "counsel_issuer",
        },
        {
          title: "Denomination",
          dataIndex: "denomination",
          key: "denomination",
        },
        {
          title: "Denomination amount",
          dataIndex: "denomination_amount",
          key: "denomination_amount",
        },
        {
          title: "Synthetic pool",
          dataIndex: "synthetic_pool",
          key: "synthetic_pool",
        },
        {
          title: "Distribution region",
          dataIndex: "distribution_region",
          key: "distribution_region",
        },
        {
          title: "Pay frequency",
          dataIndex: "pay_frequency",
          key: "pay_frequency",
        },
        {
          title: "Tax treatment",
          dataIndex: "tax_treatment",
          key: "tax_treatment",
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "",
          width: 100,
          fixed: "right",
          render: (item) => (
            <Link to={`/cmbs/edit/${item.deal_code}`} target="_blank">
              <Button size="sm" icon="document-open" onClick={null} />
            </Link>
          ),
        },
      ]}
    />
  );
}

EntityMasterCMBSRelationshipsTable.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default EntityMasterCMBSRelationshipsTable;
