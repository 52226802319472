import React from "react";
import PropTypes from "prop-types";
import Select from "components/Select";

const SelectOfferingTypeFilter = ({ onSelect, value, error, disabled }) => (
  <Select
    items={[
      { id: "U.S.", label: "U.S." },
      { id: "Non-U.S.", label: "Non-U.S." },
      { id: "Agency", label: "Agency" },
    ]}
    onSelect={onSelect}
    value={value}
    error={error}
    disabled={disabled}
  />
);

SelectOfferingTypeFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectOfferingTypeFilter.defaultProps = {
  value: undefined,
  error: undefined,
  disabled: false,
};

export default SelectOfferingTypeFilter;
