import React from "react";
import PropTypes from "prop-types";
import { Row } from "jsxstyle";
import { TableWithFixedHeader } from "components/Table";
import Button from "components/Button";
import history from "browserHistory";
import Separator from "components/Separator";
import DeleteCLO from "./DeleteCLO";
import DuplicateCLOForm from "./DuplicateCLOForm";
import columns from "./CLOTableColumns";
import EditButton from "components/EditButton";
import PermissionValidator from "components/PermissionValidator";
import Modal, { ModalContent } from "components/Modal";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";

function CLOTable({ data, pending, onSortChange, onReload, sortable }) {
  const [CLOToDuplicate, setCLOToDuplicate] = React.useState(null);
  const cloTableColumns = JSON.parse(localStorage.getItem("cloColumns"));

  const visibleColumns = cloTableColumns
    ? cloTableColumns.map((column) =>
        columns.find((tableColumn) => tableColumn.title === column),
      )
    : columns;

  return (
    <React.Fragment>
      <TableWithFixedHeader
        pending={pending}
        rowKey="deal_code"
        scroll={{
          x:
            visibleColumns.reduce((acc, column) => acc + column.width, 0) + 240,
        }}
        columns={[
          {
            title: "Deal Code",
            dataIndex: "deal_code",
            key: "deal_code",
            sortable: sortable !== false,
            fixed: "left",
            width: 120,
          },
          ...visibleColumns.map((column) => ({
            ...column,
            sortable: sortable !== false,
          })),
          {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            fixed: "right",
            width: 120,
            render: (item) => (
              <Row>
                <EditButton
                  onClick={() => history.push(`/clo/edit/${item.deal_code}`)}
                />
                <PermissionValidator allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}>
                  <Separator size="xs" />
                  <DeleteCLO clo={item} onDelete={onReload} />
                  <Separator size="xs" />
                  <Button
                    size="sm"
                    icon="duplicate"
                    onClick={() => setCLOToDuplicate(item)}
                  />
                </PermissionValidator>
              </Row>
            ),
          },
        ]}
        data={data}
        onSortChange={onSortChange}
      />
      <Modal
        visible={CLOToDuplicate}
        onRequestClose={() => setCLOToDuplicate(null)}
      >
        <ModalContent>
          <DuplicateCLOForm
            onRequestClose={() => setCLOToDuplicate(null)}
            onDuplicate={onReload}
            initialValues={CLOToDuplicate}
          />
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}

CLOTable.propTypes = {
  attribute: PropTypes.bool,
};

CLOTable.defaultProps = {
  sortable: true,
};

export default CLOTable;
