import React from "react";
import { Row, Box } from "jsxstyle";
import pickBy from "lodash/pickBy";
import Modal, { ModalTitle, ModalContent, ModalFooter } from "components/Modal";
import Button from "components/Button";
import Separator from "components/Separator";
import Form from "components/Form";
import useForm from "hooks/useForm";

function AdvancedSearch({
  isOpen,
  currentFilters,
  onRequestClose,
  onRequestOpen,
  children,
  onSubmit,
}) {
  const activeFilters = pickBy(currentFilters);
  const filtersCount = Object.keys(activeFilters).length;

  return (
    <>
      <Button
        onClick={onRequestOpen}
        text={`Advanced Filters ${filtersCount > 0 ? `(${filtersCount})` : ""}`}
        icon="filter"
      />
      <Modal visible={isOpen} onRequestClose={onRequestClose}>
        {isOpen ? (
          <AdvancedSearchForm
            initialValues={currentFilters}
            onSubmit={onSubmit}
            onRequestClose={onRequestClose}
          >
            {children}
          </AdvancedSearchForm>
        ) : <></>}
      </Modal>
    </>
  );
}

function AdvancedSearchForm({
  initialValues,
  onSubmit,
  onRequestClose,
  children,
}) {
  const { getValue, setValue, getValues, setValues, submit } = useForm({
    initialValues: initialValues,
    onSubmit,
  });

  return (
    <Form onSubmit={submit}>
      <Box width="calc(100vw - 120px)">
        <ModalTitle>Advanced Search</ModalTitle>
        <ModalContent>
          {children({ getValue, setValue, getValues, setValues })}
        </ModalContent>
        <ModalFooter>
          <Row justifyContent="flex-end">
            <Button
              type="submit"
              text="Search"
              intent="primary"
              icon="search"
              onClick={() => {
                onRequestClose();
              }}
            />
            <Separator />
            <Button text="Cancel" onClick={onRequestClose} />
          </Row>
        </ModalFooter>
      </Box>
    </Form>
  );
}

AdvancedSearch.propTypes = {};

AdvancedSearch.defaultProps = {};

export default AdvancedSearch;
