import React from "react";
import PropTypes from "prop-types";
import { Switch as BluePringSwitch } from "@blueprintjs/core";

function Switch({ onChange, value, disabled, readOnly }) {
  return (
    <BluePringSwitch
      checked={Boolean(value)}
      onChange={onChange}
      disabled={disabled || readOnly}
    />
  );
}

Switch.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
Switch.defaultProps = {
  value: undefined,
  disabled: false
};
export default Switch;
