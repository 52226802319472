import React from "react";
import { Row, Col, Box } from "jsxstyle";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Form from "components/Form";
import Button from "components/Button";
import FormGroup from "components/FormGroup";
import FormGrid from "components/FormGrid";
import InputGroup from "components/InputGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import SelectCurrencyCode from "components/SelectCurrencyCode";
import DateInputGroup from "components/DateInputGroup";
import SelectRateType from "components/SelectRateType";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import saveABSTranchePriceService from "services/abs/saveABSTranchePriceService";
import DecimalInputGroup from "components/DecimalInputGroup";
import SelectBenchmark from "components/SelectBenchmark";
import Separator from "components/Separator";
import CurrencyFormat from "components/CurrencyFormat";

function ABSServicersForm({
  onData,

  initialValues,
  onCancel,
  dealSize,
  allocated,
}) {
  const { showSuccess, showError } = useToast();

  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: (values) =>
      fetch({ values, dealCode: values.dealCode, trancheId: values.id }),
    initialValues: isEmpty(initialValues)
      ? {}
      : {
          ...initialValues,
          // class_buyer: get(initialValues.class_buyer, "entity_id", null),
          denomination: get(initialValues.denomination, "currency_code", null),
        },
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveABSTranchePriceService,
    onData: () => {
      showSuccess("Tranche price saved.");
      reset();
      onData();
    },
    onError: (error) => {
      const type = get(error, "data.error");
      showError(
        {
          duplicated_key:
            "Cannot add same Tranche Class twice to the same deal",
        }[type] || "An error happened.",
      );
    },
  });

  const handleCancel = React.useCallback(() => {
    onCancel();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditing = Boolean(getValue("id"));

  return (
    <Form onSubmit={() => submit()}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Class name"
            disabled={isSubmitting}
            error={getError("tranche_class")}
          >
            <InputGroup
              intent={getError("tranche_class") ? "danger" : null}
              value={getValue("tranche_class")}
              onChange={(value) => setValue("tranche_class", value)}
              disabled={isSubmitting}
              maxLength={25}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Class amount"
            disabled={isSubmitting}
            error={getError("class_amount")}
          >
            <CurrencyInputGroup
              leftIcon="dollar"
              intent={getError("class_amount") ? "danger" : null}
              value={getValue("class_amount")}
              onChange={(value) => setValue("class_amount", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Notional amount"
            disabled={isSubmitting}
            error={getError("notional_amount")}
          >
            <CurrencyInputGroup
              leftIcon="dollar"
              intent={getError("notional_amount") ? "danger" : null}
              value={getValue("notional_amount")}
              onChange={(value) => setValue("notional_amount", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Denomination"
            disabled={isSubmitting}
            error={getError("denomination")}
          >
            <SelectCurrencyCode
              onSelect={(value) => setValue("denomination", value)}
              value={getValue("denomination")}
              error={getError("denomination")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Denomination amount"
            disabled={isSubmitting}
            error={getError("denomination_amount")}
          >
            <CurrencyInputGroup
              leftIcon="dollar"
              intent={getError("denomination_amount") ? "danger" : null}
              value={getValue("denomination_amount")}
              onChange={(value) => setValue("denomination_amount", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Subordination"
            disabled={isSubmitting}
            error={getError("subord_percent")}
          >
            <CurrencyInputGroup
              leftIcon="percentage"
              intent={getError("subord_percent") ? "danger" : null}
              value={getValue("subord_percent")}
              onChange={(value) => setValue("subord_percent", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Coupon"
            disabled={isSubmitting}
            error={getError("coupon")}
          >
            <CurrencyInputGroup
              leftIcon="percentage"
              intent={getError("coupon") ? "danger" : null}
              value={getValue("coupon")}
              onChange={(value) => setValue("coupon", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Price"
            disabled={isSubmitting}
            error={getError("price")}
          >
            <CurrencyInputGroup
              leftIcon="dollar"
              intent={getError("price") ? "danger" : null}
              value={getValue("price")}
              onChange={(value) => setValue("price", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Yield"
            disabled={isSubmitting}
            error={getError("yield")}
          >
            <CurrencyInputGroup
              leftIcon="percentage"
              intent={getError("yield") ? "danger" : null}
              value={getValue("yield")}
              onChange={(value) => setValue("yield", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Maturity date"
            disabled={isSubmitting}
            error={getError("maturity_date")}
          >
            <DateInputGroup
              intent={getError("maturity_date") ? "danger" : null}
              value={getValue("maturity_date")}
              onChange={(value) => setValue("maturity_date", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Weighted-average life"
            disabled={isSubmitting}
            error={getError("wal")}
          >
            <DecimalInputGroup
              intent={getError("wal") ? "danger" : null}
              value={getValue("wal")}
              onChange={(value) => setValue("wal", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Spread"
            disabled={isSubmitting}
            error={getError("spread")}
          >
            <DecimalInputGroup
              intent={getError("spread") ? "danger" : null}
              value={getValue("spread")}
              onChange={(value) => setValue("spread", value)}
              disabled={isSubmitting}
              decimalScale={2}
              max={99999.99}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Benchmark"
            disabled={isSubmitting}
            error={getError("benchmark")}
          >
            <SelectBenchmark
              onSelect={(value) => setValue("benchmark", value)}
              value={getValue("benchmark")}
              error={getError("benchmark")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Rate type"
            disabled={isSubmitting}
            error={getError("rate")}
          >
            <SelectRateType
              onSelect={(value) => setValue("rate", value)}
              value={getValue("rate")}
              error={getError("rate")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        {/* <FormGrid.Item>
          <FormGroup
            label="Wal ext"
            disabled={isSubmitting}
            error={getError("wal_ext")}
          >
            <NumericInputGroup
              stepSize={0.1}
              intent={getError("wal_ext") ? "danger" : null}
              value={getValue("wal_ext")}
              onChange={value => setValue("wal_ext", value)}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <FormGroup
            label="Class buyer"
            disabled={isSubmitting}
            error={getError("class_buyer")}
          >
            <SelectEntityMaster
              onSelect={value => setValue("class_buyer", value)}
              value={getValue("class_buyer")}
              error={getError("class_buyer")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item> */}
        {/* <FormGrid.Item>
          <FormGroup
            label="Proj spread"
            disabled={isSubmitting}
            error={getError("proj_spread")}
          >
            <NumericInputGroup
              stepSize={0.1}
              intent={getError("proj_spread") ? "danger" : null}
              value={getValue("proj_spread")}
              onChange={value => setValue("proj_spread", value)}
              disabled={isSubmitting}
              maxLength={20}
            />
          </FormGroup>
        </FormGrid.Item> */}
        <Row className="col-12">
          <Row flex={1} alignItems="flex-start">
            {isEditing && (
              <React.Fragment>
                <Button
                  text="Cancel"
                  onClick={handleCancel}
                  disabled={isSubmitting}
                />
                <Separator />
              </React.Fragment>
            )}
            <Button
              type="submit"
              text={isEditing ? "Save" : "Add"}
              intent="success"
              onClick={() => {}}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Row>
          <Col alignItems="flex-end">
            <Box component="p">
              Deal size:&nbsp;
              <Box component="strong">
                <CurrencyFormat amount={dealSize} />
              </Box>
            </Box>

            <Box component="p">
              Remaining to allocate:&nbsp;
              <Box component="strong">
                <CurrencyFormat amount={dealSize - allocated} />
              </Box>
            </Box>
          </Col>
        </Row>
      </FormGrid>
    </Form>
  );
}

ABSServicersForm.propTypes = {};

ABSServicersForm.defaultProps = {};

export default ABSServicersForm;
