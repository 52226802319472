import React from "react";
import PropTypes from "prop-types";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { Row } from "jsxstyle";
import useToast from "hooks/useToast";
import Form from "components/Form";
import Button from "components/Button";
import Card from "components/Card";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import saveRESaleService from "services/re/saveRESaleService";
import RESaleFields from "../RESaleFields";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import history from "browserHistory";
import Separator from "components/Separator";
import InBetweenSpacing from "components/InBetweenSpacing";
import REBuyersForm from "../buyers/REBuyersForm";
import REBuyersTable from "../buyers/REBuyersTable";
import RESellersTable from "../sellers/RESellersTable";
import RESellersForm from "../sellers/RESellersForm";
import PermissionValidator from "components/PermissionValidator";
import { useRegionContext } from "contexts/RegionContext";
import { ADMIN_ROLE, DATA_TEAM_ROLE, REGION_CODES } from "consts";
import isRequired from "validators/isRequired";
import { isNil } from "lodash";

function SalePropertyForm({ initialValues, isNew }) {
  const { region, getRegionPath } = useRegionContext();
  const { showSuccess, showError } = useToast();
  const isUSA = region === REGION_CODES.USA
  const isCanada = region === REGION_CODES.CANADA

  // eslint-disable-next-line no-unused-vars
  const [fetch, pending] = useAsync({
    promiseFn: saveRESaleService,
    onData: ({ sale_id }) => {
      showSuccess("Sale saved.");
      if (isNew) {
        history.push(getRegionPath(`/re/sale/portfolio/new/${sale_id}`));
      }
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const {
    getValue,
    setValue,
    getValues,
    setValues,
    getError,
    submit,
  } = useForm({
    onSubmit: (values) => {
      if (values.sale_id) {
        fetch({ values: omit(values, "sale_id"), saleId: values.sale_id });
      } else {
        fetch({ values });
      }
    },
    validations: {
      country_code: [isRequired],
      num_props: [isRequired],
      portfolio_name: [isRequired],
      price_accuracy: [isRequired],
      sale_date: [isRequired],
      sector_id: [isRequired],
      stake: [isRequired],
      source: [isRequired],
      updated: [isRequired],
      // only require price for USA sales
      ...(isUSA ? {
        price: [isRequired],
      } : {}),
    },
    initialValues: isEmpty(initialValues)
      ? {
          measurement_unit: isUSA ? 'SF' : '',
          portfolio: true,
          stake: 100,
          currency_code: isUSA ? 'USD' : '',
          country_code: (
            isUSA ? 'USA'
            : isCanada ? 'CAN'
            : ''
          )
        }
      : pickBy({
          ...initialValues,
          sellers: initialValues.sellers.map((seller) => seller.entity_id),
          buyers: initialValues.buyers.map((buyer) => buyer.entity_id),
        }, value => !isNil(value)),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sellers = React.useMemo(() => getValue("sellers"), [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getValue("sellers").length,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const buyers = React.useMemo(() => getValue("buyers"), [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getValue("buyers").length,
  ]);

  return (
    <Card>
      <Form onSubmit={submit}>
        <TwoColumnFormLayout title="Sale" size="sm">
          <RESaleFields
            getValue={getValue}
            setValue={setValue}
            getValues={getValues}
            setValues={setValues}
            getError={getError}
            isSubmitting={pending}
            portfolio
            region={region}
          />
        </TwoColumnFormLayout>
        <TwoColumnFormLayout title="Sellers" size="sm">
          <InBetweenSpacing>
            <PermissionValidator
              allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
              shouldHide
            >
              <RESellersForm
                onSubmit={(seller) => {
                  setValue("sellers", [...getValue("sellers"), seller]);
                }}
              />
            </PermissionValidator>
            <RESellersTable
              entities={sellers}
              onRequestDelete={(seller) =>
                setValue(
                  "sellers",
                  getValue("sellers").filter(
                    (entity_id) => entity_id !== seller.entity_id,
                  ),
                )
              }
            />
          </InBetweenSpacing>
        </TwoColumnFormLayout>
        <Separator />
        <TwoColumnFormLayout title="Buyers" size="sm">
          <InBetweenSpacing>
            <PermissionValidator
              allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
              shouldHide
            >
              <REBuyersForm
                onSubmit={(buyer) => {
                  setValue("buyers", [...getValue("buyers"), buyer]);
                }}
              />
            </PermissionValidator>
            <REBuyersTable
              entities={buyers}
              onRequestDelete={(buyer) =>
                setValue(
                  "buyers",
                  getValue("buyers").filter(
                    (entity_id) => entity_id !== buyer.entity_id,
                  ),
                )
              }
            />
          </InBetweenSpacing>
        </TwoColumnFormLayout>
        <PermissionValidator
          allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
          shouldHide
        >
          <Row justifyContent="flex-end" className="col-12">
            <Button
              type="submit"
              text={isNew ? "Next" : "Save"}
              intent="primary"
              onClick={() => {}}
              disabled={pending}
              loading={pending}
            />
          </Row>
        </PermissionValidator>
      </Form>
    </Card>
  );
}

SalePropertyForm.propTypes = {
  initialValues: PropTypes.object,
  nextStep: PropTypes.func,
  goToStep: PropTypes.func,
  isNew: PropTypes.bool,
};
SalePropertyForm.defaultProps = {
  initialValues: {},
  nextStep: null,
  goToStep: null,
  isNew: true,
};
export default SalePropertyForm;
