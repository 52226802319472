/* eslint-disable no-unused-vars */
import React from "react";
import LoginForm from "./LoginForm";
import Separator from "components/Separator";
import PublicLayout from "components/PublicLayout";
import Button from "components/Button";
import useAsync from "hooks/useAsync";
import signInService from "services/authentication/signInService";
import completeNewPasswordService from "services/authentication/completeNewPasswordService";
import ChangePasswordForm from "./ChangePasswordForm";
import ForgotPasswordScreen from "./ForgotPasswordScreen";
import jwtDecode from "jwt-decode";
import useToast from "hooks/useToast";

function LoginScreen({ setAuthenticated }) {
  const [viewState, setViewState] = React.useState("LOGIN");
  const [user, setUser] = React.useState();
  const { showError } = useToast();

  const setUserRole = user => {
    if (user.signInUserSession) {
      const tokenData = jwtDecode(user.signInUserSession.idToken.jwtToken);
      sessionStorage.setItem("role", tokenData["cognito:groups"][0]);
    }
  };

  const [login, loginPending, loginData, loginError] = useAsync({
    promiseFn: signInService,
    onData: user => {
      setUserRole(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setViewState("NEW_PASSWORD_REQUIRED");
        setUser(user);
      } else {
        setAuthenticated(true);
      }
    },
    onError: error => {
      showError(error.message);
    }
  });

  const [
    completeNewPassword,
    completeNewPasswordPending,
    completeNewPasswordData,
    completeNewPasswordError
  ] = useAsync({
    promiseFn: completeNewPasswordService,
    onData: user => {
      setUserRole(user);
      setAuthenticated(true);
    },
    onError: error => {
      console.log("error", error);
    }
  });

  return (
    <PublicLayout>
      {viewState === "LOGIN" ? (
        <>
          <LoginForm
            pending={loginPending}
            onSubmit={login}
            error={loginError}
          />
          <Separator />
          <Button
            small
            onClick={() => setViewState("FORGOT_PASSWORD")}
            text="Forgot password?"
          />
        </>
      ) : viewState === "NEW_PASSWORD_REQUIRED" ? (
        <ChangePasswordForm
          pending={completeNewPasswordPending}
          onSubmit={({ password }) => completeNewPassword({ user, password })}
          error={completeNewPasswordError}
        />
      ) : (
        <ForgotPasswordScreen backToLogin={() => setViewState("LOGIN")} />
      )}
    </PublicLayout>
  );
}

export default LoginScreen;
