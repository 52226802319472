/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { Row } from "jsxstyle";
import Form from "components/Form";
import Button from "components/Button";
import Grid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import Separator from "components/Separator";
import InputGroup from "components/InputGroup";
import useQueryStringFilters from "hooks/useQueryStringFilters";
import useForm from "hooks/useForm";

function HYManagerFilters({ onChange }) {
  const { getQuery } = useQueryStringFilters();
  const query = getQuery();
  const { getValue, setValue, clear, submit, clearCount } = useForm({
    onSubmit: onChange,
    initialValues: {
      manager_id: query.manager_id,
    },
  });

  React.useEffect(() => {
    if (clearCount) {
      submit();
    }
  }, [clearCount]);

  return (
    <React.Fragment>
      <Form onSubmit={submit}>
        <Grid>
          <Grid.Item>
            <FormGroup>
              <InputGroup
                leftIcon="search"
                placeholder="Search by manager id"
                value={getValue("manager_id")}
                onChange={(value) => setValue("manager_id", value)}
                fullWidth
                maxLength={6}
              />
            </FormGroup>
          </Grid.Item>
          <Grid.Item>
            <FormGroup>
              <InputGroup
                placeholder="Search by manager name"
                value={getValue("name")}
                onChange={(value) => setValue("name", value)}
              />
            </FormGroup>
          </Grid.Item>
          <Grid.Item>
            <Row>
              <Button
                intent="primary"
                icon="search"
                text="Search"
                type="submit"
              />
              <Separator />
              <Button intent="none" text="Clear filters" onClick={clear} />
            </Row>
          </Grid.Item>
        </Grid>
      </Form>
    </React.Fragment>
  );
}

HYManagerFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default HYManagerFilters;
