import React from "react";
import PropTypes from "prop-types";
import { Box } from "jsxstyle";

const getSize = size =>
  ({
    xs: 10,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30
  }[size]);

function Separator({ size }) {
  return <Box height={getSize(size)} width={getSize(size)} />;
}

Separator.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
};

Separator.defaultProps = {
  size: "sm"
};

export default Separator;
