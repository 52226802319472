import React from "react";
import omit from "lodash/omit";
import get from "lodash/get";
import PropTypes from "prop-types";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import saveRESaleService from "services/re/saveRESaleService";
import Button from "components/Button";
import history from "browserHistory";

function DuplicateRESale({ initialValues, onDuplicate }) {
  const { showError, showSuccess } = useToast();

  const [fetch, pending] = useAsync({
    promiseFn: saveRESaleService,
    onData: ({ sale_id }) => {
      showSuccess("Sale saved.");
      onDuplicate();
      history.push(`/re/sale/${sale_id}`);
    },
    onError: error => {
      console.error(error);
      showError("An error happened.");
    }
  });

  return (
    <Button
      size="sm"
      icon="duplicate"
      onClick={() =>
        fetch({
          values: {
            ...omit(initialValues, "sale_id"),
            broker_1: get(initialValues, "broker_1.entity_id", null),
            broker_2: get(initialValues, "broker_2.entity_id", null),
            buyer_broker: get(initialValues, "buyer_broker.entity_id", null),
            vehicle_buyer: get(initialValues, "vehicle_buyer.entity_id", null),
            vehicle_seller: get(initialValues, "vehicle_seller.entity_id", null)
          }
        })
      }
      disabled={pending}
    />
  );
}

DuplicateRESale.propTypes = {
  initialValues: PropTypes.object
};
DuplicateRESale.defaultProps = {
  initialValues: {}
};
export default DuplicateRESale;
