import React from "react";
import { Box } from "jsxstyle";
import useForm from "hooks/useForm";
import required from "validators/isRequired";
import Form from "components/Form";
import Button from "components/Button";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectHYVehicleType from "components/SelectHYVehicleType";
import loadHYVehicleTypesService from "services/hy/loadHYVehicleTypesService";
import useData from "hocs/useData";

function HYVehicleTypeAssociationForm({ onSubmit, isSubmitting }) {
  const {
    data: { data: vehicleTypes },
  } = useData({
    initialParams: { size: 9999 },
    promiseFn: loadHYVehicleTypesService,
  });

  const { getValue, setValue, getError, submit } = useForm({
    onSubmit: ({ vehicleTypeId }, { reset, clear }) => {
      onSubmit(vehicleTypes.find(({ id }) => id === vehicleTypeId));
      reset();
      clear();
    },
    validations: {
      vehicleTypeId: [required],
    },
  });

  return (
    <Form onSubmit={submit}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Vehicle Type"
            error={getError("vehicleTypeId")}
            disabled={isSubmitting}
            required
          >
            <SelectHYVehicleType
              onSelect={(value) => setValue("vehicleTypeId", value)}
              value={getValue("vehicleTypeId")}
              error={getError("vehicleTypeId")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <FormGrid.Item>
          <Box marginTop={22}>
            <Button
              type="submit"
              text="Add"
              intent="success"
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Box>
        </FormGrid.Item>
      </FormGrid>
    </Form>
  );
}

HYVehicleTypeAssociationForm.propTypes = {};

HYVehicleTypeAssociationForm.defaultProps = {};

export default HYVehicleTypeAssociationForm;
