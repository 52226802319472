import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import Button from "components/Button";
import { useRegionContext } from 'contexts/RegionContext';
import { REGION_CODES } from "consts";

function PropertyTable({ data, saleId, pending, onRequestDelete }) {
  const { region } = useRegionContext();
  const isEurope = region === REGION_CODES.EUROPE;

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Table
          data={data}
          pending={pending}
          rowKey="property_id"
          columns={[
            {
              title: "Property Id",
              dataIndex: "property_id",
              key: "property_id"
            },
            {
              title: "Property 1",
              dataIndex: "property_1",
              key: "property_1"
            },
            {
              title: "Property 2",
              dataIndex: "property_2",
              key: "property_2"
            },
            {
              title: "City",
              dataIndex: "city",
              key: "city"
            },
            {
              title: isEurope ? "Country" : "State",
              dataIndex: isEurope ? "country_code" : "state",
              key: isEurope ? "country_code" : "state"
            },
            {
              title: "Prop type",
              dataIndex: "prop_type",
              key: "prop_type"
            },
            ...(isAllowed
              ? [
                  {
                    title: "Actions",
                    dataIndex: "",
                    key: "",
                    width: 100,
                    render: item => (
                      // item._fromApi ? (
                      //   <DeleteProperty
                      //     propertyId={item.property_id}
                      //     saleId={saleId}
                      //     onDelete={() => onRequestDelete(item)}
                      //   />
                      // ) : (
                      <Button
                        size="sm"
                        icon="trash"
                        onClick={() => onRequestDelete(item)}
                        loading={pending}
                      />
                    )
                    // )
                  }
                ]
              : [])
          ]}
        />
      )}
    </PermissionValidator>
  );
}

PropertyTable.propTypes = {
  data: PropTypes.array,
  pending: PropTypes.bool,
  onRequestDelete: PropTypes.func.isRequired
};

PropertyTable.defaultProps = {
  pending: false,
  data: []
};

export default PropertyTable;
