import React from "react";
import PropTypes from "prop-types";
import sum from "lodash/sum";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import CurrencyInputGroup from "components/CurrencyInputGroup";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import { Row, Box, Col } from "jsxstyle";
import PercentFormat from "components/PercentFormat";

function CMBSPercentageBreakdownsFields({
  getValue,
  setValue,
  getError,
  isSubmitting,
}) {
  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <Col>
          <FormGrid>
            <FormGrid.Item>
              <FormGroup
                label="Office percent"
                disabled={isSubmitting}
                error={getError("office_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("office_percent") ? "danger" : null}
                  value={getValue("office_percent")}
                  onChange={(value) => setValue("office_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Hotel percent"
                disabled={isSubmitting}
                error={getError("hotel_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("hotel_percent") ? "danger" : null}
                  value={getValue("hotel_percent")}
                  onChange={(value) => setValue("hotel_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Multi-family percent"
                disabled={isSubmitting}
                error={getError("multi_family_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("multi_family_percent") ? "danger" : null}
                  value={getValue("multi_family_percent")}
                  onChange={(value) => setValue("multi_family_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Healthcare percent"
                disabled={isSubmitting}
                error={getError("healthcare_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("healthcare_percent") ? "danger" : null}
                  value={getValue("healthcare_percent")}
                  onChange={(value) => setValue("healthcare_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>

            <FormGrid.Item>
              <FormGroup
                label="Mobile-home percent"
                disabled={isSubmitting}
                error={getError("mobile_home_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("mobile_home_percent") ? "danger" : null}
                  value={getValue("mobile_home_percent")}
                  onChange={(value) => setValue("mobile_home_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Retail percent"
                disabled={isSubmitting}
                error={getError("retail_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("retail_percent") ? "danger" : null}
                  value={getValue("retail_percent")}
                  onChange={(value) => setValue("retail_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Industrial/warehouse percent"
                disabled={isSubmitting}
                error={getError("industrial_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("industrial_percent") ? "danger" : null}
                  value={getValue("industrial_percent")}
                  onChange={(value) => setValue("industrial_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Self-storage percent"
                disabled={isSubmitting}
                error={getError("self_storage_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("self_storage_percent") ? "danger" : null}
                  value={getValue("self_storage_percent")}
                  onChange={(value) => setValue("self_storage_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Land percent"
                disabled={isSubmitting}
                error={getError("land_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("land_percent") ? "danger" : null}
                  value={getValue("land_percent")}
                  onChange={(value) => setValue("land_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Construction loan percent"
                disabled={isSubmitting}
                error={getError("constr_loan_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("constr_loan_percent") ? "danger" : null}
                  value={getValue("constr_loan_percent")}
                  onChange={(value) => setValue("constr_loan_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Mixed-use percent"
                disabled={isSubmitting}
                error={getError("mixed_use_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("mixed_use_percent") ? "danger" : null}
                  value={getValue("mixed_use_percent")}
                  onChange={(value) => setValue("mixed_use_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
            <FormGrid.Item>
              <FormGroup
                label="Other percent"
                disabled={isSubmitting}
                error={getError("other_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("other_percent") ? "danger" : null}
                  value={getValue("other_percent")}
                  onChange={(value) => setValue("other_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>

            <FormGrid.Item>
              <FormGroup
                label="Unknown percent"
                disabled={isSubmitting}
                error={getError("unknown_percent")}
              >
                <CurrencyInputGroup
                  leftIcon="percentage"
                  intent={getError("unknown_percent") ? "danger" : null}
                  value={getValue("unknown_percent")}
                  onChange={(value) => setValue("unknown_percent", value)}
                  disabled={isSubmitting}
                  readOnly={!isAllowed}
                />
              </FormGroup>
            </FormGrid.Item>
          </FormGrid>
          <Row flex={1} justifyContent="flex-end" component="p">
            Assigned total:&nbsp;
            <Box component="strong">
              <PercentFormat
                amount={sum([
                  getValue("office_percent") || 0,
                  getValue("hotel_percent") || 0,
                  getValue("multi_family_percent") || 0,
                  getValue("healthcare_percent") || 0,
                  getValue("mobile_home_percent") || 0,
                  getValue("retail_percent") || 0,
                  getValue("industrial_percent") || 0,
                  getValue("self_storage_percent") || 0,
                  //getValue("land_percent") || 0,
                  getValue("constr_loan_percent") || 0,
                  //getValue("mixed_use_percent") || 0,
                  getValue("other_percent") || 0,
                  getValue("unknown_percent") || 0,
                ])}
              />
            </Box>
          </Row>
        </Col>
      )}
    </PermissionValidator>
  );
}

CMBSPercentageBreakdownsFields.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

CMBSPercentageBreakdownsFields.defaultProps = {
  isSubmitting: false,
};

export default CMBSPercentageBreakdownsFields;
