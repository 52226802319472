import { join } from "lodash";

export default (error) => {
  let message = null;
  if (error.data.error === "invalid_fields") {
    message = join(
      error.data.fields.reduce((acc, field) => [...acc, field.message], []),
      ",",
    );
  } else {
    message = error.data.message;
  }
  return message || "An error happened.";
};
