import React from "react";
import { range, reverse } from "lodash";
import PropTypes from "prop-types";
import { Row, Box } from "jsxstyle";
import omit from "lodash/omit";
import Form from "components/Form";
import Button from "components/Button";
import Select from "components/Select";
import FormGroup from "components/FormGroup";
import Separator from "components/Separator";
import InputGroup from "components/InputGroup";
import RangeSlider from "components/RangeSlider";
import AdvancedSearch from "components/AdvancedSearch";
import CurrencyFormat from "components/CurrencyFormat";
import SelectHYManager from "components/SelectHYManager";
import SelectHYFundType from "components/SelectHYFundType";
import CurrentFiltersList from "components/CurrentFiltersList";
import SelectCurrencyCode from "components/SelectCurrencyCode";

const ACTIVE_OPTIONS = [
  { id: "ACTIVE", label: "Active Funds only" },
  { id: "INACTIVE", label: "Inactive Funds only" },
  { id: "ALL", label: "All funds" },
];

const CLOSED_YEAR_OPTIONS = [
  { id: "NO_CLOSED_YEAR", label: "No Closed Year" },
  ...reverse(
    range(1900, new Date().getFullYear() + 1).map((year) => ({
      id: year,
      label: year,
    })),
  ),
];

const TARGET_OPTIONS = [
  { id: "YES", label: "Yes" },
  { id: "NO", label: "No" },
  { id: "ALL", label: "All funds" },
];

function HYFundFilters({ onChange, currentFilters }) {
  const [isAdvancedFiltersOpen, setAdvancedFiltersOpen] = React.useState(false);
  const [managerEntityId, setManagerEntityId] = React.useState(
    currentFilters.fund_id,
  );
  const advancedSearchFilters = omit(currentFilters, ["fund_id"]);
  React.useEffect(() => {
    if (!currentFilters.fund_id) {
      setManagerEntityId("");
    }
  }, [currentFilters.fund_id]);

  return (
    <>
      <Row alignItems="flex-start">
        <Form
          onSubmit={() => {
            onChange({ fund_id: managerEntityId });
          }}
        >
          <Row width={350} alignItems="flex-start">
            <Box flex={1}>
              <FormGroup>
                <InputGroup
                  leftIcon="search"
                  placeholder="Search by fund id"
                  value={managerEntityId}
                  onChange={(value) => setManagerEntityId(value)}
                  fullWidth
                  maxLength={6}
                />
              </FormGroup>
            </Box>
            <Separator />
            <Button
              intent="primary"
              icon="search"
              text="Search"
              type="submit"
            />
          </Row>
        </Form>
        <Separator />
        <Row alignItems="center">
          <AdvancedSearch
            currentFilters={advancedSearchFilters}
            isOpen={isAdvancedFiltersOpen}
            onRequestOpen={() => setAdvancedFiltersOpen(true)}
            onRequestClose={() => setAdvancedFiltersOpen(false)}
            onSubmit={(values) => onChange(values)}
          >
            {({ getValue, setValue, getValues, setValues }) => (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, 300px)"
                gridGap={16}
              >
                <FormGroup label="Fund Name">
                  <InputGroup
                    value={getValue("name")}
                    onChange={(value) => setValue("name", value)}
                  />
                </FormGroup>
                <FormGroup label="Manager">
                  <SelectHYManager
                    onSelect={(value) => setValue("manager_entity_id", value)}
                    value={getValue("manager_entity_id")}
                  />
                </FormGroup>
                <FormGroup label="Fund type">
                  <SelectHYFundType
                    onSelect={(value) => setValue("fund_type", value)}
                    value={getValue("fund_type")}
                  />
                </FormGroup>
                <FormGroup label="Denomination">
                  <SelectCurrencyCode
                    onSelect={(value) => setValue("denomination", value)}
                    value={getValue("denomination")}
                  />
                </FormGroup>
                <FormGroup label="Goal Amount Current">
                  <RangeSlider
                    value={getValue("goal_amount_current")}
                    onChange={(value) => setValue("goal_amount_current", value)}
                    min={0}
                    max={6000}
                    stepSize={250}
                    labelStepSize={2000}
                    labelRenderer={(label) => <CurrencyFormat amount={label} />}
                  />
                </FormGroup>
                <FormGroup label="Raised Amount">
                  <RangeSlider
                    value={getValue("raised_amount")}
                    onChange={(value) => setValue("raised_amount", value)}
                    min={0}
                    max={6000}
                    stepSize={250}
                    labelStepSize={2000}
                    labelRenderer={(label) => <CurrencyFormat amount={label} />}
                  />
                </FormGroup>
                <FormGroup label="Closed Year">
                  <Select
                    value={
                      getValue("only_null_closed_year")
                        ? "NO_CLOSED_YEAR"
                        : parseInt(getValue("closed_year"))
                    }
                    onSelect={(value) =>
                      setValues({
                        ...getValues(),
                        only_null_closed_year: value === "NO_CLOSED_YEAR",
                        closed_year:
                          value === "NO_CLOSED_YEAR" ? undefined : value,
                      })
                    }
                    items={CLOSED_YEAR_OPTIONS}
                  />
                </FormGroup>
                <FormGroup label="Active">
                  <Select
                    value={
                      ("active",
                      {
                        true: "ACTIVE",
                        false: "INACTIVE",
                      }[getValue("active")] || "ALL")
                    }
                    onSelect={(value) => {
                      setValue(
                        "active",
                        {
                          ACTIVE: "true",
                          INACTIVE: "false",
                        }[value] || "",
                      );
                    }}
                    items={ACTIVE_OPTIONS}
                  />
                </FormGroup>
                <FormGroup label="Target Us">
                  <Select
                    value={
                      ("target_us",
                      {
                        true: "YES",
                        false: "NO",
                      }[getValue("target_us")] || "ALL")
                    }
                    onSelect={(value) => {
                      setValue(
                        "target_us",
                        {
                          YES: "true",
                          NO: "false",
                        }[value] || "",
                      );
                    }}
                    items={TARGET_OPTIONS}
                  />
                </FormGroup>
                <FormGroup label="Target Non Us">
                  <Select
                    value={
                      ("target_non_us",
                      {
                        true: "YES",
                        false: "NO",
                      }[getValue("target_non_us")] || "ALL")
                    }
                    onSelect={(value) => {
                      setValue(
                        "target_non_us",
                        {
                          YES: "true",
                          NO: "false",
                        }[value] || "",
                      );
                    }}
                    items={TARGET_OPTIONS}
                  />
                </FormGroup>
              </Box>
            )}
          </AdvancedSearch>
          <Separator />
          <Button
            icon="remove"
            minimal
            onClick={() => onChange({})}
            text="Clear filters"
          />
        </Row>
      </Row>
      <CurrentFiltersList
        filters={currentFilters}
        onRemove={(key) => {
          onChange({
            ...currentFilters,
            [key]: null,
          });
        }}
      />
    </>
  );
}

HYFundFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default HYFundFilters;
