import React from "react";
import PropTypes from "prop-types";
import { Row, Box } from "jsxstyle";
import omit from "lodash/omit";
import get from "lodash/get";
import Heading from "components/Heading";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import IntegerInputGroup from "components/IntegerInputGroup";
import Button from "components/Button";
import Separator from "components/Separator";
import useForm from "hooks/useForm";
import InBetweenSpacing from "components/InBetweenSpacing";
import useToast from "hooks/useToast";
import useAsync from "hooks/useAsync";
import saveABSDealService from "services/abs/saveABSDealService";
import history from "browserHistory";
import isRequired from "validators/isRequired";

function DuplicateABS({ initialValues, onRequestClose, onDuplicate }) {
  const { showError, showSuccess } = useToast();

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveABSDealService,
    onData: ({ deal_code }) => {
      showSuccess("ABS deal saved.");
      onDuplicate();
      onRequestClose();
      history.push(`/abs/edit/${deal_code}`);
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    onSubmit: (values) =>
      fetch({
        values: {
          ...values,
          deal_type: get(values, "deal_type.deal_code", null),
          seller: get(values, "seller.entity_id", null),
          sponsor: get(values, "sponsor.entity_id", null),
          master_servicer: get(values, "master_servicer.entity_id", null),
          primary_servicer: get(values, "primary_servicer.entity_id", null),
          special_servicer: get(values, "special_servicer.entity_id", null),
          enhancement: get(values, "enhancement.entity_id", null),
          trustee: get(values, "trustee.entity_id", null),
          counsel_uw: get(values, "counsel_uw.entity_id", null),
          counsel_uw_2: get(values, "counsel_uw_2.entity_id", null),
          asset_reviewer: get(values, "asset_reviewer", null),
          counsel_issuer: get(values, "counsel_issuer.entity_id", null),
          counsel_issuer_2: get(values, "counsel_issuer_2.entity_id", null),
          denomination: get(values, "denomination.currency_code", null),
          country_code: get(values, "country_code.country_code", null),
          region_code: get(values, "region_code.region_code", null),
          collateral: get(values, "collateral.collateral_code", null),
          collateral_sub: get(values, "collateral_sub.collateral_code", null),
          collateral_sub2: get(values, "collateral_sub2.collateral_code", null),
          distribution_region: get(
            values,
            "distribution_region.region_code",
            null,
          ),
        },
      }),
    initialValues: omit(initialValues, "deal_code"),
    validations: { deal_code: [isRequired] },
  });

  return (
    <InBetweenSpacing size="lg">
      <Box>
        <Heading size="sm">New Deal Code</Heading>
        <Box color="#5f646d">
          Please enter a deal code in order to duplicate the selected deal
        </Box>
      </Box>
      <Form onSubmit={() => submit()}>
        <FormGroup
          label="Deal code"
          required
          disabled={isSubmitting}
          error={getError("deal_code")}
        >
          <IntegerInputGroup
            minLength={8}
            maxLength={8}
            intent={getError("deal_code") ? "danger" : null}
            value={getValue("deal_code")}
            onChange={(value) => setValue("deal_code", value)}
            buttonPosition="none"
            disabled={isSubmitting}
          />
        </FormGroup>

        <Row justifyContent="flex-end">
          <Button
            minimal
            text="Cancel"
            onClick={onRequestClose}
            disabled={isSubmitting}
          />
          <Separator size="md" />
          <Button
            intent="primary"
            text="Save"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={() => {}}
          />
        </Row>
      </Form>
    </InBetweenSpacing>
  );
}

DuplicateABS.propTypes = {
  onDuplicate: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

DuplicateABS.defaultProps = {
  initialValues: {},
};

export default DuplicateABS;
