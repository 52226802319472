import React from "react";
import { Row } from "jsxstyle";
import Form from "components/Form";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";
import Button from "components/Button";
import PermissionValidator from "components/PermissionValidator";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import saveCMBSDealService from "services/cmbs/saveCMBSDealService";
import useToast from "hooks/useToast";
import InBetweenSpacing from "components/InBetweenSpacing";
import CMBSPercentageBreakdownsFields from "./CMBSPercentageBreakdownsFields";

function CMBSEditDealTab({ initialValues, onData }) {
  const { showError, showSuccess } = useToast();

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveCMBSDealService,
    onData: data => {
      onData(data);
      showSuccess("CMBS deal saved.");
    },
    onError: error => {
      console.error(error);
      showError("An error happened.");
    }
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues,
    validations: {},
    onSubmit: values => fetch({ values, dealCode: values.deal_code })
  });

  return (
    <Form onSubmit={submit}>
      <InBetweenSpacing size="xl">
        <TwoColumnFormLayout size="sm" title="Percentage Breakdowns">
          <CMBSPercentageBreakdownsFields
            getValue={getValue}
            setValue={setValue}
            getError={getError}
            isSubmitting={isSubmitting}
          />
        </TwoColumnFormLayout>
        <PermissionValidator
          allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
          shouldHide={false}
        >
          {({ isAllowed }) =>
            isAllowed && (
              <Row justifyContent="flex-end">
                <Button
                  type="submit"
                  text="Save"
                  intent="primary"
                  disabled={isSubmitting}
                />
              </Row>
            )
          }
        </PermissionValidator>
      </InBetweenSpacing>
    </Form>
  );
}

export default CMBSEditDealTab;
