import React from "react";
import { Row, Box } from "jsxstyle";
import get from "lodash/get";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import useData from "hocs/useData";
import loadContactsService from "services/contact/loadContactsService";
import Card from "components/Card";
import Button from "components/Button";
import Heading from "components/Heading";
import AddButton from "components/AddButton";
import Separator from "components/Separator";
import Pagination from "components/Pagination";
import PrivateLayout from "components/PrivateLayout";
import ContactTable from "./ContactTable";
import ContactFilters from "./ContactFilters";

function ContactListScreen({ history }) {
  const { fetch, pending, data, params, setParams } = useData({
    initialParams: {
      order_by: "first_name",
      order_direction: "ASC",
    },
    promiseFn: loadContactsService,
  });

  return (
    <PrivateLayout>
      <Row alignItems="center">
        <Heading size="md">Contact</Heading>
        <Separator />
        <AddButton onClick={() => history.push("/contacts/new")} />
      </Row>
      <Separator />
      <Card style={{ flexGrow: 1 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          <Box flex="1">
            <ContactFilters
              currentFilters={omit(params, ["page", "size"])}
              onChange={(newFilters) => {
                setParams({
                  ...pickBy(newFilters),
                  page: 1,
                  size: 20,
                });
              }}
            />
          </Box>
          <Separator size="xl" />
          <Box flex="0 0 150px">
            <Button
              icon="cog"
              text="Configure Table"
              onClick={() => history.push("/contacts/table-configuration")}
            />
          </Box>
        </Box>
        <Separator />
        <ContactTable
          onSortChange={(sortObject) =>
            setParams({
              ...params,
              ...pickBy(sortObject),
              page: 1,
            })
          }
          onReload={() => fetch({ params })}
          data={get(data, "data", [])}
          pending={pending}
        />
        <Separator />
        {!pending && (
          <Row justifyContent="flex-end">
            <Pagination
              page={params.page}
              size={params.size}
              total={data.total}
              onChange={({ page }) => setParams({ ...params, page })}
            />
          </Row>
        )}
      </Card>
    </PrivateLayout>
  );
}

export default ContactListScreen;
