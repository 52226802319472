import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import loadREPropSalesService from "services/re/loadREPropSalesService";
import RESaleTableColumns from "../sale/RESaleTableColumns";

function REPropSaleTable({ propertyId, bordered }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    promiseFn: loadREPropSalesService,
    eager: true,
    onError: error => {
      showError("Error loading Sale Props");
    }
  });

  React.useEffect(() => {
    fetch({ propertyId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = RESaleTableColumns.slice(0, 8).map(column => column);

  return (
    <Table
      pending={pending}
      bordered={bordered}
      data={data}
      rowKey="property_id"
      columns={columns}
    />
  );
}

REPropSaleTable.propTypes = {
  propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  bordered: PropTypes.bool
};
REPropSaleTable.defaultProps = {
  bordered: false
};

export default REPropSaleTable;
