import React from "react";
import PropTypes from "prop-types";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import { Row, Col, Box } from "jsxstyle";
import Form from "components/Form";
import Button from "components/Button";
import Heading from "components/Heading";
import FormGroup from "components/FormGroup";
import Separator from "components/Separator";
import InputGroup from "components/InputGroup";
import SelectRating from "components/SelectRating";
import deleteCMBSTranchePriceRating from "services/cmbs/deleteCMBSTranchePriceRating";
import saveCMBSTranchePriceRatingsService from "services/cmbs/saveCMBSTranchePriceRatingsService";

function CMBSRatingsForm({
  onRequestClose,
  onData,
  trancheId,
  dealCode,
  ratingAgencies,
  tranches,
}) {
  const { showSuccess } = useToast();
  const [pending, setPending] = React.useState(false);

  const { getValue, setValue, submit } = useForm({
    initialValues: {
      tranchesMap: tranches.reduce((acc, tranche) => {
        const ratingAgencyExists = Boolean(
          ratingAgencies.find(
            (ratingAgency) =>
              ratingAgency.entity_id.entity_id === tranche.entity_id.entity_id,
          ),
        );
        if (ratingAgencyExists) {
          return { ...acc, [tranche.entity_id.entity_id]: tranche };
        }
        return acc;
      }, {}),
    },
    onSubmit: async ({ tranchesMap }) => {
      try {
        const tranches = Object.keys(tranchesMap).map((key) => ({
          ...tranchesMap[key],
          entity_id: key,
        }));
        const removedOnes = tranches.filter(
          (tranche) => tranche.id && !tranche.rating,
        );
        const savedOnes = tranches
          .filter((tranche) => tranche.rating)
          .map((tranche) => ({
            ...tranche,
            deal_code: dealCode,
            tranche_id: trancheId,
          }));

        const saveTranches = Promise.all([
          ...removedOnes.map((tranche) =>
            deleteCMBSTranchePriceRating({
              dealCode,
              id: tranche.id,
              trancheId: trancheId,
            }),
          ),
          ...savedOnes.map((tranche) =>
            saveCMBSTranchePriceRatingsService({
              dealCode,
              id: tranche.id,
              values: {
                entity_id: tranche.entity_id,
                rating: tranche.rating,
              },
              trancheId: trancheId,
            }),
          ),
        ]);
        setPending(true);
        await saveTranches;
        showSuccess("Ratings saved.");
        onData();
      } catch (error) {
        console.log(error);
        setPending(false);
      }
    },
  });

  return (
    <Form onSubmit={submit}>
      <Col padding={20} maxHeight={"70vh"} width={600} overflowY="scroll">
        <Heading size="sm">Manage Ratings</Heading>
        <Separator />
        {ratingAgencies.map((rating) => (
          <Row key={rating.entity_id.entity_id} alignItems="center">
            <Box flexBasis={245} flexGrow={0}>
              <FormGroup label="Rating Agency" disabled={pending}>
                <InputGroup
                  value={rating.entity_id.long_name}
                  readOnly={true}
                />
              </FormGroup>
            </Box>
            <Separator />
            <Box flexBasis={245} flexGrow={0}>
              <FormGroup label="Rating" disabled={pending}>
                <SelectRating
                  entity_id={rating.entity_id.entity_id}
                  onSelect={(value) =>
                    setValue(
                      `tranchesMap[${rating.entity_id.entity_id}].rating`,
                      value,
                    )
                  }
                  value={getValue(
                    `tranchesMap[${rating.entity_id.entity_id}].rating`,
                  )}
                  disabled={pending}
                />
              </FormGroup>
            </Box>
          </Row>
        ))}
      </Col>
      <Row justifyContent="flex-end" backgroundColor="#eee" padding="12px 20px">
        <Button
          minimal
          text="Cancel"
          onClick={onRequestClose}
          disabled={pending}
        />
        <Separator size="md" />
        <Button
          intent="primary"
          text="Save"
          type="submit"
          disabled={pending}
          loading={pending}
        />
      </Row>
    </Form>
  );
}

CMBSRatingsForm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onData: PropTypes.func.isRequired,
  trancheId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  dealCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  initialValues: PropTypes.array,
};

CMBSRatingsForm.defaultProps = {
  onRequestClose: null,
  pending: false,
};

export default CMBSRatingsForm;
