import React from "react";
import PropTypes from "prop-types";
import { Col } from "jsxstyle";
import { Spinner } from "@blueprintjs/core";
import ABSRatingsForm from "./ABSRatingsForm";
import useAsync from "hooks/useAsync";
import Modal from "components/Modal";
import loadABSRatingsService from "services/abs/loadABSRatingsService";
import loadABSTranchePriceRatingsService from "services/abs/loadABSTranchePriceRatingsService";

function ABSRatingsFormModal({ trancheId, onData, onRequestClose, dealCode }) {
  const [
    fetchRatingAgencies,
    pendingRatingAgencies,
    dataRatingAgencies,
  ] = useAsync({
    promiseFn: loadABSRatingsService,
  });

  const [fetchTranches, pendingTranches, dataTranches] = useAsync({
    promiseFn: loadABSTranchePriceRatingsService,
  });

  React.useEffect(() => {
    if (dealCode && trancheId) {
      fetchRatingAgencies({ dealCode: dealCode });
      fetchTranches({ dealCode: dealCode, trancheId: trancheId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealCode, trancheId]);

  return (
    <Modal visible={trancheId} onRequestClose={onRequestClose}>
      {pendingRatingAgencies || pendingTranches ? (
        <Col
          width={600}
          minHeight={482}
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Col>
      ) : (
        <ABSRatingsForm
          ratingAgencies={dataRatingAgencies || []}
          tranches={dataTranches || []}
          dealCode={dealCode}
          trancheId={trancheId}
          onRequestClose={onRequestClose}
          onData={onData}
        />
      )}
    </Modal>
  );
}

ABSRatingsFormModal.propTypes = {
  trancheId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dealCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onData: PropTypes.func,
  onRequestClose: PropTypes.func,
};

ABSRatingsFormModal.defaultProps = {
  onData: () => {},
  onRequestClose: () => {},
  trancheId: null,
};

export default ABSRatingsFormModal;
