import React from "react";
import { Row } from "jsxstyle";
import history from "browserHistory";
import { Button } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { ADMIN_ROLE, DATA_TEAM_ROLE } from "consts";
import isEmpty from "lodash/isEmpty";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import saveHYFundService from "services/hy/saveHYFundService";
import Form from "components/Form";
import Switch from "components/Switch";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import InputGroup from "components/InputGroup";
import PermissionValidator from "components/PermissionValidator";
import TwoColumnFormLayout from "components/TwoColumnFormLayout";

function HYFundTargetAssetsTab({
  match,
  onSubmit,
  initialValues,
  isNew = true,
}) {
  const { showError, showSuccess } = useToast();
  const id = match.params.id;

  const [fetch, pending] = useAsync({
    promiseFn: saveHYFundService,
    onData: (fund) => {
      showSuccess("HY Fund saved.");
      onSubmit(fund);
      if (isNew) {
        history.push(`/hy/fund/new/${id}/target-regions`);
      }
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: isEmpty(initialValues) ? {} : initialValues,
    onSubmit: (values) => fetch({ id, values }),
  });

  return (
    <PermissionValidator
      allowedFor={[ADMIN_ROLE, DATA_TEAM_ROLE]}
      shouldHide={false}
    >
      {({ isAllowed }) => (
        <TwoColumnFormLayout title="Target Assets" size="sm">
          <Form onSubmit={submit}>
            <FormGrid>
              <FormGrid.Item>
                <FormGroup
                  label="Target Assets Description"
                  error={getError("target_assets_description")}
                  disabled={pending}
                >
                  <InputGroup
                    intent={
                      getError("target_assets_description") ? "danger" : null
                    }
                    value={getValue("target_assets_description")}
                    onChange={(value) =>
                      setValue("target_assets_description", value)
                    }
                    readOnly={!isAllowed}
                    disabled={pending}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Office"
                  error={getError("target_office")}
                >
                  <Switch
                    value={getValue("target_office")}
                    onChange={() =>
                      setValue("target_office", !getValue("target_office"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Target Mf" error={getError("target_mf")}>
                  <Switch
                    value={getValue("target_mf")}
                    onChange={() =>
                      setValue("target_mf", !getValue("target_mf"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Retail"
                  error={getError("target_retail")}
                >
                  <Switch
                    value={getValue("target_retail")}
                    onChange={() =>
                      setValue("target_retail", !getValue("target_retail"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Industrial"
                  error={getError("target_industrial")}
                >
                  <Switch
                    value={getValue("target_industrial")}
                    onChange={() =>
                      setValue(
                        "target_industrial",
                        !getValue("target_industrial"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Hotel"
                  error={getError("target_hotel")}
                >
                  <Switch
                    value={getValue("target_hotel")}
                    onChange={() =>
                      setValue("target_hotel", !getValue("target_hotel"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Data Center"
                  error={getError("target_data_center")}
                >
                  <Switch
                    value={getValue("target_data_center")}
                    onChange={() =>
                      setValue(
                        "target_data_center",
                        !getValue("target_data_center"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Medical Office"
                  error={getError("target_medical_office")}
                >
                  <Switch
                    value={getValue("target_medical_office")}
                    onChange={() =>
                      setValue(
                        "target_medical_office",
                        !getValue("target_medical_office"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Senior Housing"
                  error={getError("target_senior_housing")}
                >
                  <Switch
                    value={getValue("target_senior_housing")}
                    onChange={() =>
                      setValue(
                        "target_senior_housing",
                        !getValue("target_senior_housing"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Student Housing"
                  error={getError("target_student_housing")}
                >
                  <Switch
                    value={getValue("target_student_housing")}
                    onChange={() =>
                      setValue(
                        "target_student_housing",
                        !getValue("target_student_housing"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Distressed Debt"
                  error={getError("target_distressed_debt")}
                >
                  <Switch
                    value={getValue("target_distressed_debt")}
                    onChange={() =>
                      setValue(
                        "target_distressed_debt",
                        !getValue("target_distressed_debt"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Originations"
                  error={getError("target_originations")}
                >
                  <Switch
                    value={getValue("target_originations")}
                    onChange={() =>
                      setValue(
                        "target_originations",
                        !getValue("target_originations"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Pe Mezz"
                  error={getError("target_pe_mezz")}
                >
                  <Switch
                    value={getValue("target_pe_mezz")}
                    onChange={() =>
                      setValue("target_pe_mezz", !getValue("target_pe_mezz"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Target Cmbs" error={getError("target_cmbs")}>
                  <Switch
                    value={getValue("target_cmbs")}
                    onChange={() =>
                      setValue("target_cmbs", !getValue("target_cmbs"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Diverse"
                  error={getError("target_diverse")}
                >
                  <Switch
                    value={getValue("target_diverse")}
                    onChange={() =>
                      setValue("target_diverse", !getValue("target_diverse"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup label="Target Land" error={getError("target_land")}>
                  <Switch
                    value={getValue("target_land")}
                    onChange={() =>
                      setValue("target_land", !getValue("target_land"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Other"
                  error={getError("target_other")}
                >
                  <Switch
                    value={getValue("target_other")}
                    onChange={() =>
                      setValue("target_other", !getValue("target_other"))
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
              <FormGrid.Item>
                <FormGroup
                  label="Target Opportunity Zone"
                  error={getError("target_opportunity_zone")}
                >
                  <Switch
                    value={getValue("target_opportunity_zone")}
                    onChange={() =>
                      setValue(
                        "target_opportunity_zone",
                        !getValue("target_opportunity_zone"),
                      )
                    }
                    readOnly={!isAllowed}
                  />
                </FormGroup>
              </FormGrid.Item>
            </FormGrid>
            <Row justifyContent="flex-end">
              <Button
                disabled={pending}
                loading={pending}
                type="submit"
                intent="primary"
                text={isNew ? "Next" : "Save"}
              />
            </Row>
          </Form>
        </TwoColumnFormLayout>
      )}
    </PermissionValidator>
  );
}

export default withRouter(HYFundTargetAssetsTab);
