import React from "react";
import PropTypes from "prop-types";
import { Card as BluePrintCard } from "@blueprintjs/core";

function Card({ children, onClick, elevation, style }) {
  return (
    <BluePrintCard
      style={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        background: "#f9f9f9",
        ...style,
      }}
      interactive={!!onClick}
      elevation={elevation}
    >
      {children}
    </BluePrintCard>
  );
}

Card.propTypes = {
  elevation: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  elevation: 1,
  onClick: null,
};

export default Card;
