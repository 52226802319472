import fetch from "../fetch";
import omit from "lodash/omit";

export default ({ id, values }) => {
  return fetch({
    path: id ? `/ct/contacts/${id}` : "/ct/contacts",
    method: id ? "PUT" : "POST",
    data: omit(values, ["contact_id"]),
  });
};
