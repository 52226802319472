import loadRESalePropsService from "./loadRESalePropsService";
import getREPropertyPortfolioById from "./getREPropertyPortfolioById";
import getRESaleById from "./getRESaleById";

export default async ({ saleId }) => {
  try {
    const data = await Promise.all([
      loadRESalePropsService({ saleId }),
      getRESaleById({ saleId })
    ]);

    const portfolios = await Promise.all(
      data[0].map(property =>
        getREPropertyPortfolioById({
          saleId,
          propertyId: property.property_id
        })
      )
    );

    const totalPropertiesPrice = portfolios.reduce(
      (acc, property) => acc + property.price,
      0
    );

    return {
      properties: data[0],
      portfolioPrice: data[1].price,
      totalPropertiesPrice
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
