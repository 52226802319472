import React from "react";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Separator from "components/Separator";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
import Form from "components/Form";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import isRequired from "validators/isRequired";
import useAsync from "hooks/useAsync";
import saveCMBSBorrowerService from "services/cmbs/saveCMBSBorrowerService";

function CMBSBorrowersForm({ onData, initialValues, onCancel }) {
  const { showSuccess, showError } = useToast();

  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: (values) =>
      fetch({
        values,
        dealCode: values.dealCode,
        borrowerId: values.id,
      }),
    initialValues,
    validations: {
      entity_id: [isRequired],
    },
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveCMBSBorrowerService,
    onData: () => {
      showSuccess("Borrower saved.");
      onData();
      reset();
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const handleCancel = React.useCallback(() => {
    onCancel();
    reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditing = Boolean(getValue("id"));

  return (
    <Form onSubmit={() => submit()}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Borrower"
            disabled={isSubmitting}
            error={getError("entity_id")}
          >
            <SelectEntityMaster
              onSelect={(value) => {
                setValue("entity_id", value);
              }}
              value={getValue("entity_id")}
              error={getError("entity_id")}
              disabled={isSubmitting}
            />
          </FormGroup>
        </FormGrid.Item>
        <Row display="flex" className="col-12">
          {isEditing && (
            <React.Fragment>
              <Button
                text="Cancel"
                onClick={handleCancel}
                disabled={isSubmitting}
              />
              <Separator />
            </React.Fragment>
          )}
          <Button
            type="submit"
            text={isEditing ? "Save" : "Add"}
            intent="success"
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Row>
      </FormGrid>
    </Form>
  );
}

CMBSBorrowersForm.propTypes = {};

CMBSBorrowersForm.defaultProps = {};

export default CMBSBorrowersForm;
