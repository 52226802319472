import React from "react";
import { Row } from "jsxstyle";
import history from "browserHistory";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import useForm from "hooks/useForm";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";
import saveContactService from "services/contact/saveContactService";
import Form from "components/Form";
import Button from "components/Button";
import ContactBasicInfoFields from "./ContactBasicInfoFields";

function ContactBasicInfoTab({ match, onSubmit, initialValues }) {
  const { action } = match.params;
  const { showSuccess, showError } = useToast();
  const isNew = action === "new";

  const [fetch, pending] = useAsync({
    promiseFn: saveContactService,
    onData: (contact) => {
      showSuccess("Contact saved.");
      onSubmit(contact);
      if (isNew) {
        history.push(`/contacts/new/${contact.contact_id}/funds`);
      }
    },
    onError: (error) => {
      console.error(error);
      showError("An error happened.");
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: isEmpty(initialValues) ? {} : initialValues,
    onSubmit: (values) => fetch({ id: values.contact_id, values }),
    validations: {},
  });

  return (
    <Form onSubmit={submit}>
      <ContactBasicInfoFields
        getValue={getValue}
        setValue={setValue}
        getError={getError}
        isSubmitting={pending}
      />
      <Row justifyContent="flex-end">
        <Button
          type="submit"
          text={isNew ? "Next" : "Save"}
          intent="primary"
          disabled={pending}
          loading={pending}
        />
      </Row>
    </Form>
  );
}

export default withRouter(ContactBasicInfoTab);
