/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { useMachine } from "@xstate/react";
import get from "lodash/get";
import debounceFetchMachine from "stateMachines/debounceFetchMachine";
import loadEntityRoles from "services/entityMaster/loadEntityRoles";
import Select from "./Select";

function SelectMultiEntityRoles({ onSelect, value, error, disabled, readOnly }) {
  const searchMachine = debounceFetchMachine.withContext({
    promiseFn: loadEntityRoles,
  });
  const [current, send] = useMachine(searchMachine);

  React.useEffect(() => {
    send({
      type: "UPDATE_PARAMS",
    });
  }, []);

  const options = get(current, 'context.data', []).map((item) => ({
    ...item,
    id: item.entity_role_id,
    label: item.entity_role,
  }));
  return (
    <Select
      closeMenuOnSelect={false}
      items={options}
      onSelect={onSelect}
      value={value}
      error={error}
      disabled={disabled}
      readOnly={readOnly}
      isMulti
      idPath="entity_role_id"
    />
  );
}

SelectMultiEntityRoles.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default SelectMultiEntityRoles;
