import React from "react";
import { Row } from "jsxstyle";
import Button from "components/Button";
import Separator from "components/Separator";
import FormGrid from "components/FormGrid";
import FormGroup from "components/FormGroup";
import SelectEntityMaster from "components/SelectEntityMaster";
// import SelectRating from "components/SelectRating";
import Form from "components/Form";
import useToast from "hooks/useToast";
import useForm from "hooks/useForm";
import isRequired from "validators/isRequired";
import useAsync from "hooks/useAsync";
import saveCLORatingService from "services/clo/saveCLORatingService";
import { get } from "lodash";

function CLORatingAgenciesForm({ onData, initialValues, onCancel }) {
  const { showSuccess, showError } = useToast();

  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: (values) =>
      fetch({
        values,
        dealCode: values.dealCode,
        ratingId: values.id,
      }),
    initialValues,
    validations: {
      entity_id: [isRequired],
    },
  });

  const [fetch, isSubmitting] = useAsync({
    promiseFn: saveCLORatingService,
    onData: () => {
      showSuccess("Rating saved.");
      onData();
      reset();
    },
    onError: (error) => {
      const type = get(error, "data.error");
      showError(
        {
          duplicated_key:
            "Cannot add same Rating Agency twice to the same deal",
        }[type] || "An error happened.",
      );
    },
  });

  const handleCancel = React.useCallback(() => {
    onCancel();
    reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditing = Boolean(getValue("id"));

  return (
    <Form onSubmit={() => submit()}>
      <FormGrid>
        <FormGrid.Item>
          <FormGroup
            label="Agency"
            disabled={isSubmitting}
            error={getError("entity_id")}
          >
            <SelectEntityMaster
              onSelect={(value) => {
                setValue("entity_id", value);
              }}
              value={getValue("entity_id")}
              error={getError("entity_id")}
              disabled={isSubmitting}
              params={{ entity_type: "rtnagc" }}
            />
          </FormGroup>
        </FormGrid.Item>
        {/* <FormGrid.Item>
          <FormGroup
            label="Rating"
            disabled={isSubmitting}
            error={getError("rating")}
          >
            <SelectRating
              entity_id={getValue("entity_id") || undefined}
              onSelect={value => setValue("rating", value)}
              value={getValue("rating")}
              error={getError("rating")}
              disabled={isSubmitting || !getValue("entity_id")}
            />
          </FormGroup>
        </FormGrid.Item> */}
        <Row display="flex" className="col-12">
          {isEditing && (
            <React.Fragment>
              <Button
                text="Cancel"
                onClick={handleCancel}
                disabled={isSubmitting}
              />
              <Separator />
            </React.Fragment>
          )}
          <Button
            type="submit"
            text={isEditing ? "Save" : "Add"}
            intent="success"
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Row>
      </FormGrid>
    </Form>
  );
}

CLORatingAgenciesForm.propTypes = {};

CLORatingAgenciesForm.defaultProps = {};

export default CLORatingAgenciesForm;
