import React from "react";
import PrivateLayout from "components/PrivateLayout";
import Card from "components/Card";
import Heading from "components/Heading";
import Separator from "../../components/Separator";
import EntityMasterForm from "./EntityMasterForm";
import useAsync from "hooks/useAsync";
import getEntityMasterByIdService from "services/entityMaster/getEntityMasterByIdService";
import { Spinner } from "@blueprintjs/core";
import useToast from "hooks/useToast";
import EntityMasterRelationships from "./EntityMasterRelationships";

function EntityMasterListScreen({ match }) {
  const { showError } = useToast();

  const [fetch, pending, data] = useAsync({
    promiseFn: getEntityMasterByIdService,
    onError: () => showError("Error loading the Entity Master")
  });

  React.useEffect(() => {
    if (match.params.id) {
      fetch({ id: match.params.id });
    }
  }, [match.params.id, fetch]);

  return (
    <PrivateLayout>
      <Heading size="md">
        {match.params.id
          ? `Edit Entity Master - ${match.params.id}`
          : "New Entity Master"}
      </Heading>
      <Separator />
      <Card>
        {pending ? (
          <Spinner />
        ) : (
          <EntityMasterForm
            isNew={!match.params.id}
            initialValues={match.params.id ? data : {}}
          />
        )}
      </Card>
      {match.params.id && (
        <React.Fragment>
          <Separator />
          <Heading size="md">Relationships</Heading>
          <Separator />
          <EntityMasterRelationships entityId={match.params.id} />
        </React.Fragment>
      )}
    </PrivateLayout>
  );
}

export default EntityMasterListScreen;
