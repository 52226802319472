import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";
import deleteAccountService from "services/account/deleteAccountService";
import useAsync from "hooks/useAsync";
import useToast from "hooks/useToast";

function DeleteAccount({ userName, onDelete }) {
  const { showSuccess, showError } = useToast();
  const [alertVisible, setAlertVisible] = React.useState(false);

  const [fetch, pending] = useAsync({
    promiseFn: deleteAccountService,
    onData: () => {
      showSuccess("Account deleted successfully.");
      onDelete();
    },
    onError: error => {
      console.error(error);
      showError("Error deleting Account");
    }
  });

  return (
    <React.Fragment>
      <Button
        size="sm"
        icon="trash"
        intent="danger"
        onClick={() => setAlertVisible(true)}
        loading={pending}
      />
      <Alert
        isOpen={alertVisible}
        onConfirm={() => fetch({ userName })}
        intent="danger"
        icon="trash"
        onCancel={() => setAlertVisible(false)}
      >
        Are you sure you want to delete this account?
      </Alert>
    </React.Fragment>
  );
}

DeleteAccount.propTypes = {
  tranchePrice: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

DeleteAccount.defaultProps = {
  underwriter: {}
};

export default DeleteAccount;
